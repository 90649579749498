import * as React from 'react';
import Stack from '@mui/material/Stack';
import { PieChart } from '@mui/x-charts/PieChart';
import { useEffect } from 'react';
import { useState } from 'react';

export default function GaugeCharts({ invoicedValue, goals }) {
    const [difference, setDifference] = useState('');

    useEffect(() => {
        const invoicedValueWithoutDecimal = parseInt(invoicedValue);

        const differenceValue = goals - invoicedValueWithoutDecimal;

        setDifference(differenceValue > 0 ? differenceValue : 0);
    }, [invoicedValue, goals]);

    const data = [
        { label: 'Faturado', value: parseInt(invoicedValue) },
        { label: 'Meta', value: difference }
    ];

    return (
        <Stack direction="row">
            <PieChart
                series={[
                    {
                        startAngle: -90,
                        endAngle: 90,
                        paddingAngle: 5,
                        innerRadius: 60,
                        outerRadius: 100,
                        data
                    }
                ]}
                margin={{ right: 5 }}
                width={200}
                height={300}
                slotProps={{
                    legend: { hidden: true }
                }}
                colors={['#7e57c2', '#409ae9']}
            />
        </Stack>
    );
}
