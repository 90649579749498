export const formatPhone = (phone) => {
    // Remove todos os caracteres não numéricos
    const cleaned = phone.replace(/\D/g, '');

    // Aplica a máscara
    const match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
    }

    return phone;
};

export const formatCNPJ = (cnpj) => {
    // Remove todos os caracteres não numéricos
    const cleaned = cnpj?.replace(/\D/g, '');

    // Aplica a máscara se o CNPJ tiver 11 dígitos numéricos
    const match = cleaned?.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})$/);
    if (match) {
        return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
    }

    // Retorna o CNPJ original se não for possível aplicar a máscara
    return cnpj;
};

export const formatarCEP = (cep) => {
    const padrao = /^(\d{5})(\d{3})$/;
    return cep.replace(padrao, '$1-$2');
};
export function removeMask(value) {
    return value.replace(/[^\d]/g, '');
}

export function formatPhoneNumber(phoneNumber) {
    if (typeof phoneNumber !== 'string') {
        return phoneNumber; // Retorna o número original se não for uma string
    }

    const digitsOnly = phoneNumber.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

    const phoneRegex = /^(\d{2})(\d{4,5})(\d{4})$/;
    const formattedPhone = digitsOnly.replace(phoneRegex, '($1) $2-$3');

    return formattedPhone;
}

export function removeSpecialCharacter(text) {
    return text.replace(/[./-]/g, '');
}

export function formatarData(data) {
    if (typeof data === 'string') {
        const dataObj = new Date(data);
        const dia = String(dataObj.getUTCDate()).padStart(2, '0');
        const mes = String(dataObj.getUTCMonth() + 1).padStart(2, '0');
        const ano = dataObj.getUTCFullYear();

        return `${dia}/${mes}/${ano}`;
    }

    return ''; // Ou outro valor padrão que você preferir
}

export function formatHora(timestamp) {
    const data = new Date(timestamp);
    const horas = String(data.getUTCHours()).padStart(2, '0');
    const minutos = String(data.getUTCMinutes()).padStart(2, '0');
    const segundos = String(data.getUTCSeconds()).padStart(2, '0');

    return `${horas}:${minutos}:${segundos}`;
}

export function formatarValor(value) {
    let price = value.toString().split('').reverse().join('').replace('.', '');
    price = price.replace(/(\d{2})/, '$1,');
    price = price.replace(/(\d{3}(?!$))/g, '$1.');
    return price.split('').reverse().join('');
}

export function numberFormatCompact(value) {
    const format = new Intl.NumberFormat('pt-BR', {
        notation: 'compact',
        compactDisplay: 'short'
    }).format(value);

    return format;
}
