import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import ActionsButton from './Components/ActionsButton';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import DrawerInsert from './Components/DrawerInsert';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { Table, Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';
import { Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { formatarData, formatarValor } from 'utils/regex';
import { PDFDownloadLink } from '@react-pdf/renderer';
import GeneretePdf from './Components/GeneretePdf';
import LoopIcon from '@mui/icons-material/Loop';
import { toast } from 'react-toastify';
import MenuTable from './Components/MenuTable';
import SkeletonTable from 'ui-component/skeletonTable';
import { ModalLoadAnimated } from 'ui-component/LoadBw';
import { ObservationManager } from './Components/ObservationManager';
import CollapsibleTable from './Components/Collapsible';

const SalesBudget = () => {
    const api = useApi();
    const navigate = useNavigate();
    const [openDrawerInsert, setOpenDrawerInsert] = useState(false);
    const [data, setData] = useState([]);
    const [currentClientModel, setCurrentClientModel] = useState([]);
    const [allcustomer, setAllCustomer] = useState([]);
    const [currentClientData, setCurrentClientData] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openObservation, setOpenObservation] = useState(false);

    const [selectedField, setSelectedField] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);

    //column datadrid

    const role = localStorage.getItem('role');
    const roleParse = JSON.parse(role);
    const name = localStorage.getItem('name');
    const nameParse = JSON.parse(name);

    const getBudgetsClients = async () => {
        setLoading(true);
        try {
            const res = await api.getBudgetClient();

            // const allValues = res.data.map((item) => item.budget.map((value) => value.total));

            if (roleParse === 'Representante') {
                const filteredDataAgent = res.data?.filter((item) => item.agent?.name === nameParse);
                console.log('🚀 ~ getBudgetsClients ~ filteredDataAgent:', filteredDataAgent);

                const row = filteredDataAgent.map((item, index) => ({
                    ...item,
                    id: item?.id,
                    emissao: item?.createdAt ? formatarData(item?.createdAt) : '',
                    cnpj: item?.client?.cnpj,
                    social: item?.client?.name,
                    seller: item?.seller?.name,
                    rep: item?.agent?.name,
                    construction: item.construction ? item.construction : 'Não informado'
                    // valor: '1'
                }));
                setRows(row);
                setFilteredResults(row);
            }
            if (roleParse !== 'Representante') {
                const row = res.data.map((item, index) => ({
                    ...item,
                    id: item?.id,
                    emissao: item?.createdAt ? formatarData(item?.createdAt) : '',
                    cnpj: item?.client?.cnpj,
                    social: item?.client?.name,
                    seller: item?.seller?.name,
                    rep: item?.agent?.name,
                    construction: item.construction ? item.construction : 'Não informado'
                    // valor: '1'
                }));
                setRows(row);
                setFilteredResults(row);
            }

            setData(data);

            setAllCustomer(res.data);
            setLoading(false);
        } catch (error) {
            console.log('🚀 ~ file: index.js:52 ~ getBudgetsClients ~ error:', error.message);
        }
    };

    useEffect(() => {
        getBudgetsClients();
    }, []);

    useEffect(() => {
        const findCurrentCostumer = allcustomer.find((item) => item.id === currentClientModel.id);

        setCurrentClientData(findCurrentCostumer);
    }, [currentClientModel]);

    const selectTypes = ['Orçamento', 'Razão Social', 'Obra', 'Vendedor'];

    const handleFieldChange = (event) => {
        const newSelectedField = event.target.value;
        setSelectedField(newSelectedField);
        filterResults(newSelectedField, searchTerm);
    };

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        filterResults(selectedField, newSearchTerm);
    };

    const filterResults = (field, term) => {
        if (!field || !term) {
            setFilteredResults(rows);
            return;
        }

        const fieldKey = {
            Orçamento: 'id',
            'Razão Social': 'social',
            Obra: 'construction',
            Vendedor: 'seller'
        }[field];

        const results = rows.filter((item) => {
            const fieldValue = item[fieldKey]?.toString().toLowerCase();
            return fieldValue?.includes(term.toLowerCase());
        });

        setFilteredResults(results);
    };

    return (
        <MainCard title="Orçamento de venda">
            <>
                <DrawerInsert Insert open={openDrawerInsert} setOpen={setOpenDrawerInsert} />
                {loading && <ModalLoadAnimated open={loading} />}
                <ObservationManager open={openObservation} setOpen={setOpenObservation} />
                <Grid container spacing={gridSpacing} sx={{ padding: '10px 20px', position: 'relative' }}>
                    <Box sx={{ display: 'flex', position: 'absolute', top: '-60px', left: '50%', transform: 'translateX(-62%)' }}>
                        <TextField
                            sx={{ width: '170px', borderRight: 'none' }}
                            onChange={(e) => handleFieldChange(e)}
                            variant="filled"
                            select
                            label="Selecione um filtro"
                        >
                            {selectTypes.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            variant="filled"
                            onChange={(e) => handleSearchChange(e)}
                            sx={{ width: '250px', marginLeft: '-2px' }}
                            label="Pesquise ..."
                        />
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '20px' }}>
                        {roleParse === 'Administrador' ||
                            (roleParse === 'Gestor Comercial' && (
                                <Button variant="contained" onClick={() => setOpenObservation(true)}>
                                    <p style={{ margin: 0 }}>
                                        Cadastro <span style={{ textTransform: 'lowercase' }}>de</span>{' '}
                                        <span style={{ textTransform: 'lowercase' }}>observações</span>
                                    </p>
                                </Button>
                            ))}

                        <Button variant="contained" sx={{ width: '200px' }} onClick={() => setOpenDrawerInsert(true)}>
                            Novo orçamento
                        </Button>
                    </Box>
                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {/* <DataGrid style={{ marginTop: '20px' }} rows={rows} columns={columns} /> */}
                        <CollapsibleTable rows={filteredResults} getBudgetsClients={getBudgetsClients} />
                    </Box>
                </Grid>
            </>
        </MainCard>
    );
};

export default SalesBudget;
