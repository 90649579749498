import { Button, Modal, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';

const AddNewOpenQuestion = ({ open, setOpen, get }) => {
    const api = useApi();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: '8px',
        maxHeight: '650px',
        overflow: 'auto'
    };

    const [title, setTitle] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.createQuestion('Aberta', title);
            setOpen(false);
            get();
            setTitle('');
            toast.success('Pergunta cadastrada com sucesso!');
        } catch (error) {
            console.log('🚀 ~ file: AddNewCloseAnswer.js:56 ~ handleRegisterQuestion ~ error:', error);
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                setOpen(false);
                setTitle('');
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <h3>Registro de pergunta aberta</h3>
                <Box>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <TextField
                            label="Título da pergunta aberta"
                            sx={{ width: '100%' }}
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                        />
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <Button variant="contained" sx={{ margin: '20px auto' }} type="submit">
                                Cadastrar pergunta
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddNewOpenQuestion;
