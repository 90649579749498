import { Box, Button, Divider, FormControl, FormControlLabel, Modal, Switch, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';

import dashboard from '../../menu-items/dashboard';
import budget from '../../menu-items/budget';
import records from '../../menu-items/records';
import commercial from '../../menu-items/commercial';
import pcp from '../../menu-items/pcp';
import costs from '../../menu-items/costs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};

export function CustomizationModal({ open, setOpen }) {
    const handleClose = () => setOpen(false);
    const [logoPrincipal, setLogoPrincipal] = useState('');
    const [logoSecundaria, setLogoSecundaria] = useState('');
    const [logoPdf, setLogoPdf] = useState('');
    const [logoSimulacao, setLogoSimulacao] = useState('');

    const [switchState, setSwitchState] = useState({});

    const api = useApi();

    useEffect(() => {
        const imagesStorage = localStorage.getItem('@images');
        if (imagesStorage) {
            const images = JSON.parse(imagesStorage);
            setLogoPrincipal(images['logoPrincipal']);
            setLogoSecundaria(images['loginBackground']);
            setLogoPdf(images['logoPdf']);
            setLogoSimulacao(images['simulacaoDefault']);
        }
    }, []);

    const handleFileChange = (event, setter) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setter(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    function clearAllFiles() {
        setLogoPrincipal('');
        setLogoSecundaria('');
        setLogoPdf('');
        setLogoSimulacao('');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            logoPrincipal: logoPrincipal,
            loginBackground: logoSecundaria,
            logoPdf: logoPdf,
            simulacaoDefault: logoSimulacao
        };

        const toastId = toast.loading('Enviando dados...');
        try {
            await api.changeCustomization(payload);
            toast.update(toastId, {
                render: 'Imagens salvas com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 5000
            });

            clearAllFiles();
        } catch (error) {
            toast.update(toastId, {
                render: 'Erro ao enviar dados',
                type: 'error',
                isLoading: false,
                autoClose: 5000
            });
        }
    };

    function handleSwitchChange(event, item) {
        setSwitchState({ ...switchState, [item]: event.target.checked });
    }

    async function handleParams() {
        const payload = { params: JSON.stringify(switchState) };
        const toastId = toast.loading('Enviando dados...');
        try {
            await api.setParams(payload);
            toast.update(toastId, {
                render: 'Imagens salvas com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 5000
            });
        } catch (error) {
            toast.update(toastId, {
                render: 'Erro ao enviar dados',
                type: 'error',
                isLoading: false,
                autoClose: 5000
            });
        }
    }

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Box sx={{ textAlign: 'center' }}>
                    <h2>Customização do sistema</h2>
                    <p>Personalize as imagens do sistema</p>
                    <Divider sx={{ marginBottom: 2 }} />
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', gap: '30px', flexWrap: 'wrap' }}>
                        <Box>
                            <p style={{ margin: 0 }}>Logo Principal</p>
                            <TextField
                                type="file"
                                onChange={(event) => handleFileChange(event, setLogoPrincipal)}
                                fullWidth
                                margin="normal"
                            />
                        </Box>
                        <Box>
                            <p style={{ margin: 0 }}>Logo background</p>
                            <TextField
                                type="file"
                                onChange={(event) => handleFileChange(event, setLogoSecundaria)}
                                fullWidth
                                margin="normal"
                            />
                        </Box>
                        <Box>
                            <p style={{ margin: 0 }}>Logo PDF</p>
                            <TextField type="file" onChange={(event) => handleFileChange(event, setLogoPdf)} fullWidth margin="normal" />
                        </Box>
                        <Box>
                            <p style={{ margin: 0 }}>Logo Simulação</p>
                            <TextField
                                type="file"
                                onChange={(event) => handleFileChange(event, setLogoSimulacao)}
                                fullWidth
                                margin="normal"
                            />
                        </Box>
                    </Box>

                    <Button type="submit" variant="contained" fullWidth sx={{ margin: '20px 0' }} color="primary" onClick={handleSubmit}>
                        Salvar Imagens
                    </Button>
                </form>
                {/* <p style={{ textAlign: 'center' }}>Personalize o menu</p>
                <Divider />

                <Box sx={{ height: '200px', overflow: 'auto' }}>
                    <h4>{budget.title}</h4>
                    {budget.children.map((item) => {
                        return (
                            <FormControlLabel
                                control={
                                    <Switch checked={switchState[item.title]} onChange={(event) => handleSwitchChange(event, item.title)} />
                                }
                                label={item.title}
                            />
                        );
                    })}

                    <h4>{records.title}</h4>
                    {records.children[0].children.map((item) => {
                        return (
                            <FormControlLabel
                                control={
                                    <Switch checked={switchState[item.title]} onChange={(event) => handleSwitchChange(event, item.title)} />
                                }
                                label={item.title}
                            />
                        );
                    })}

                    <h4>{commercial.title}</h4>
                    {commercial.children.map((item) => {
                        return (
                            <FormControlLabel
                                control={
                                    <Switch checked={switchState[item.title]} onChange={(event) => handleSwitchChange(event, item.title)} />
                                }
                                label={item.title}
                            />
                        );
                    })}

                    <h4>{pcp.title}</h4>
                    {pcp.children.map((item) => {
                        return (
                            <FormControlLabel
                                control={
                                    <Switch checked={switchState[item.title]} onChange={(event) => handleSwitchChange(event, item.title)} />
                                }
                                label={item.title}
                            />
                        );
                    })}

                    <h4>{costs.title}</h4>
                    {costs.children.map((item) => {
                        return (
                            <FormControlLabel
                                control={
                                    <Switch checked={switchState[item.title]} onChange={(event) => handleSwitchChange(event, item.title)} />
                                }
                                label={item.title}
                            />
                        );
                    })}
                </Box>
                <Button variant="contained" fullWidth sx={{ margin: '20px 0' }} color="primary" onClick={handleParams}>
                    Salvar Parametros
                </Button> */}
            </Box>
        </Modal>
    );
}
