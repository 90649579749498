import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from '../../../assets/images/Logos/sulmetais.png';
import { Paper, TableContainer } from '@mui/material';
import { formatCNPJ, formatHora, formatarData, formatarValor } from 'utils/regex';
import { useEffect } from 'react';
import { useState } from 'react';
import { formatarMoeda } from 'utils/formatMoeda';
import { formatarValorIntl } from 'utils/formatValueIntl';
import LogoJGF from '../../../assets/jgf.png';

let total;
let totalService;

function DinamicTable({ dados }) {
    const styles = {
        table: { width: '100%', borderCollapse: 'collapse', margin: '10px' },
        cell: { borderBottom: '1px solid #999', padding: '5px', wordWrap: 'break-word', display: 'flex', justifyContent: 'center' }
    };

    return (
        <View>
            <View style={styles.table}>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ ...styles.cell, width: '5%' }}>
                        <Text>Item</Text>
                    </View>
                    {/* <View style={{ ...styles.cell, width: '8%' }}>
                        <Text>Qtd</Text>
                    </View> */}

                    <View style={{ ...styles.cell, width: '65%' }}>
                        <Text>Desc. Produto</Text>
                    </View>
                    <View style={{ ...styles.cell, width: '12%' }}>
                        <Text>Unitário</Text>
                    </View>
                    <View style={{ ...styles.cell, width: '12%' }}>
                        <Text>Total</Text>
                    </View>
                </View>

                {dados?.map((item, index) => (
                    <View key={index} style={{ flexDirection: 'row' }}>
                        <View style={{ ...styles.cell, width: '5%' }}>
                            <Text>{index + 1}</Text>
                        </View>
                        {/* <View style={{ ...styles.cell, width: '8%' }}>
                            <Text>
                                {item.qtd}
                                {item.un}
                            </Text>
                        </View> */}

                        <View style={{ ...styles.cell, width: '65%' }}>
                            <View>
                                <Text style={{ fontWeight: 'extrabold' }}>{item.desc}</Text>
                                <Text style={{ fontSize: '9px', color: '#343634' }}>{item?.note}</Text>
                            </View>
                        </View>
                        <View style={{ ...styles.cell, width: '12%' }}>
                            <Text>{formatarValorIntl(item.valorU)}</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '12%' }}>
                            <Text>{formatarValorIntl(item.valorT)}</Text>
                        </View>
                    </View>
                ))}
            </View>
        </View>
    );
}

const styles = {
    page: {
        backgroundColor: '#fff',
        fontSize: 10,
        fontWeight: 'normal',
        paddingTop: 15,
        paddingBottom: 15,
        paddingHorizontal: 15,
        rap: 'wrap'
    },
    header: {
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    image: {
        width: 200,
        backgroundSize: 'cover'
    },
    text1: {
        fontSize: '12px'
    },
    box: {
        border: '1px solid #ddd',
        padding: 4,
        margin: 2,
        borderRadius: 5
    },
    between: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    labelArea: {
        backgroundColor: '#ccc',
        padding: '8px',
        borderRadius: 5,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 900
    },
    table: { margin: 10 },
    row: { flexDirection: 'row', borderBottom: '1 solid #000' },
    cell: { flexGrow: 1, padding: 5 }
};

const GeneretePdf = ({ currentData, sum, currentIcms, observationArray, copyId }) => {
    const [product, setProduct] = useState([]);
    const [service, setService] = useState([]);
    const [currentImage, setCurrentImage] = useState('');

    useEffect(() => {
        const images = localStorage.getItem('@images');
        if (images) {
            const allImages = JSON.parse(images);
            setCurrentImage(allImages['logoPdf']);
        } else {
            setCurrentImage(Logo);
        }
    }, []);

    useEffect(() => {
        const isService = currentData?.budgets?.filter((item) => item?.service === true);
        const isProduct = currentData?.budgets?.filter((item) => item?.service === false);

        //area do produto
        const productItens = isProduct?.map((item) => ({
            id: item?.id,
            desc: item?.budget_Name,
            un: item?.unitSize,
            qtd: item?.quantity,
            valorU: item?.unitPrice,
            valorT: item?.totalPrice,
            note: item?.note
        }));

        const sumProductValues = productItens?.reduce((acumulador, item) => {
            return acumulador + parseFloat(item.valorT);
        }, 0);

        total = formatarValorIntl(sumProductValues);
        setProduct(productItens);

        //area do Serviços
        const serviceItens = isService?.map((item) => ({
            id: item?.id,
            desc: item?.budget_Name,
            un: item?.unitSize,
            qtd: item?.quantity,
            valorU: item?.unitPrice,
            valorT: item?.totalPrice,
            note: item?.note
        }));

        const sumServiceValues = serviceItens?.reduce((acumulador, item) => {
            return acumulador + parseFloat(item.valorT);
        }, 0);

        totalService = formatarValorIntl(sumServiceValues);
        setService(serviceItens);
    }, [currentData]);

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Image src={currentImage} style={styles.image} alt="logo" height={100} width={100} />
                    <View>
                        <Text>Proposta Comercial Nº {copyId == 0 ? currentData?.id : copyId}</Text>
                        <Text>Data: {formatarData(currentData?.createdAt)}</Text>
                    </View>
                </View>

                <View style={styles.box}>
                    <View style={styles.between}>
                        <Text>Cliente: {currentData?.client?.name}</Text>
                        <Text>CNPJ: {formatCNPJ(currentData?.client?.cnpj)}</Text>
                    </View>
                    <View style={{ borderBottom: '1px dashed #ccc', marginBottom: '4px', marginTop: '4px' }}></View>
                    <View>
                        <Text>
                            Endereço: {currentData?.client?.logradouro}, n.º {currentData?.client?.number}, {currentData?.client?.state}
                        </Text>
                        <Text>Contato: {currentData?.client?.agentName}</Text>
                        <Text>Telefone: {currentData?.client?.phone}</Text>
                        <Text>E-mail: {currentData?.client?.email}</Text>
                        <Text>Obra: {currentData?.construction}</Text>
                        <View style={styles.between}>
                            <Text>Vendedor: {currentData?.seller?.name}</Text>
                            <Text>E-mail: {currentData?.seller?.email}</Text>
                            <Text>Representante: {currentData?.agent?.name}</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.box}>
                    <View style={styles.labelArea}>
                        <Text>Produtos Oferecidos</Text>
                    </View>
                    <View>
                        <DinamicTable dados={product} />
                        <View
                            style={{
                                ...styles.labelArea,
                                display: 'flex',
                                alignItems: 'flex-end'
                            }}
                        >
                            <Text>Total dos Produtos: {total}</Text>
                        </View>
                    </View>
                </View>
                {service.length > 0 && (
                    <View style={styles.box}>
                        <View style={styles.labelArea}>
                            <Text>Serviços Oferecidos</Text>
                        </View>
                        <View>
                            <DinamicTable dados={service} />
                            <View
                                style={{
                                    ...styles.labelArea,
                                    display: 'flex',
                                    alignItems: 'flex-end'
                                }}
                            >
                                <Text>Total dos serviços: {totalService}</Text>
                            </View>
                        </View>
                    </View>
                )}
                <View
                    style={{
                        ...styles.labelArea,
                        display: 'flex',
                        alignItems: 'flex-end',
                        margin: '5px'
                    }}
                >
                    <Text>Total da proposta: {sum}</Text>
                </View>
                <View style={styles.box}>
                    <View style={styles.labelArea}>
                        <Text>Premissas e Observações</Text>
                    </View>
                    <View style={{ padding: '10px' }}>
                        <Text style={{ marginBottom: '4px' }}>{currentData?.observation}</Text>

                        {observationArray.map((item, index) => (
                            <View key={index} style={{ marginBottom: '4px' }}>
                                <Text>{item.observation}</Text>
                            </View>
                        ))}
                    </View>
                </View>

                <View style={styles.box}>
                    <View style={styles.labelArea}>
                        <Text>Condiçoes Gerais</Text>
                    </View>
                    <View style={{ ...styles.between, padding: 10 }}>
                        <View>
                            <Text>Validade: 10 dias</Text>
                            <Text style={{ marginTop: 10, marginBottom: 10 }}>
                                Tipo de frete: {currentData && currentData.cifFob ? 'FOB' : 'CIF'}
                            </Text>
                            <Text>Entrega: {currentData.deliveryTime} dias</Text>
                        </View>
                        <View>
                            <Text>ICMS: {currentIcms[0]?.percentage}%</Text>
                            <Text style={{ marginTop: 10, marginBottom: 10 }}>
                                Cond. de Pagamento: {currentData?.paymentCondition?.name}
                            </Text>
                            <Text>Garantia: 5 anos</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.between}>
                    <View style={{ paddingLeft: 20 }}>
                        <Text>Atenciosamente,</Text>
                        <Text style={{ marginTop: 5, marginBottom: 5 }}>{currentData?.seller?.name}</Text>
                        <Text style={{ marginBottom: 5 }}>{currentData?.seller?.email}</Text>
                        <Text>Vendedor</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Text style={{ marginRight: '50px' }}>Aprovação</Text>
                        <View style={{ margin: 25 }}></View>

                        <View style={{ marginRight: 50, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <View style={{ borderBottom: '1px solid #ccc', width: '220px', marginBottom: '5px' }}></View>
                            <Text>Assinatura / Carimbo</Text>
                        </View>
                    </View>
                </View>
                {/* <View style={{ borderBottom: '1px solid #ccc', marginBottom: '5px', marginTop: '5px', width: '100%' }}></View>
                <View style={{ padding: '12px 20px' }}>
                    <Text style={{ marginBottom: 5 }}>SM indústria de Forros, Brises e Revestimentos Metálicos EIRELI</Text>
                    <Text style={{ marginBottom: 5 }}>CNPJ: 03.305.220/0001-11</Text>
                    <Text style={{ marginBottom: 5 }}>(11) 2066-2800</Text>
                    <Text style={{ marginBottom: 5 }}>Av. Deputado rubens Granja, 451, vila Vermelha - São Paulo | 04298-000</Text>
                </View> */}
                <Text
                    style={{ marginLeft: '88%', paddingBottom: '10px', paddingTop: '10px' }}
                    fixed
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                />
            </Page>
        </Document>
    );
};

export default GeneretePdf;
