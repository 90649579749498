import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    TextField,
    Typography,
    Box,
    InputLabel,
    Select,
    MenuItem,
    ButtonGroup,
    OutlinedInput,
    Chip,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    TablePagination,
    Collapse,
    Tooltip,
    IconButton,
    TableSortLabel
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import ProductCard from 'ui-component/ProductCard';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { useNavigate } from 'react-router';
import TableRowsIcon from '@mui/icons-material/TableRows';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconPencil } from '@tabler/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import SubCard from 'ui-component/cards/SubCard';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Row from './Row';
// ==================== PRODUCT VIEW ====================

function ProducView() {
    const api = useApi();

    const [loading, setLoading] = useState(true);

    const [products, setProducts] = useState([]);
    // product states
    const [productName, setProductName] = useState('');
    const [productPrice, setProductPrice] = useState(0);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    // =============== FORM STATES ================
    // unity
    const [unit, setUnit] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState('');
    const handleChangeUnit = (event) => {
        setSelectedUnit(event.target.value);
    };
    // groups
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);

    const handleChangeGroup = (event) => {
        setSelectedGroup(event.target.value);
    };
    // types
    const types = [
        { id: 1, description: 'Matéria Prima' },
        { id: 2, description: 'Produto Final' },
        { id: 3, description: 'Serviço' }
    ];
    const [selectedTypeId, setSelectedTypeId] = useState('');
    const handleChangeType = (event) => {
        setSelectedTypeId(event.target.value);
    };
    // origin
    const [origins, setOrigins] = useState([
        { id: 1, description: 'Comprado' },
        { id: 2, description: 'Genérico' },
        { id: 3, description: 'Fabricado' }
    ]);
    const [selectedOriginId, setSelectedOriginId] = useState('');
    const handleChangeOrigin = (event) => {
        setSelectedOriginId(event.target.value);
    };
    // se tipo === Matéria Prima => origem [ Comprado, Genérico ]
    const mpOrigins = origins.filter((origin) => origin.id !== 3 && origin.id !== 1);
    // se tipo === Produto Final => origem [ Fabricado ]
    const pfOrigins = origins.filter((origin) => origin.id === 3);

    // cores
    const [colors, setColors] = useState([]);
    const [selectedColor, setSelectedColor] = useState([]);
    const handleChangeColor = (e) => {
        setSelectedColor(e.target.value);
    };

    // =============== CLEAR FORM STATES ================
    const handleClearForm = () => {
        setProductName('');
        setProductPrice('');
        setSelectedColor([]);
        setSelectedUnit('');
        setSelectedGroup([]);
        setSelectedTypeId('');
        setSelectedOriginId('');
    };

    // =============== SUBMIT ================

    const [primary, setPrimary] = useState(false);
    const [fabricated, setFabricated] = useState(false);
    const [generic, setGeneric] = useState(false);
    const [final, setFinal] = useState(false);
    const [buy, setBuy] = useState(false);

    // =============== EFFECT / SUBMIT ================

    const getProducts = async () => {
        const responseProducts = await api.getAllProducts();
        setLoading(false);
        setProducts(responseProducts.data.filter((item) => item.productBuy !== true));
    };
    const getGroups = async () => {
        const response = await api.getAllGroups();
        setGroups(response.data);
    };
    const getUnits = async () => {
        const responseUnit = await api.getAllUnits();
        setUnit(responseUnit.data);
    };
    const getColors = async () => {
        const responseColor = await api.getAllColorsGroup();
        setColors(responseColor.data);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // const preco = productPrice > 0 ? parseFloat(productPrice) : 0;
        const groups = selectedGroup.map((id) => ({ id: parseInt(id.split(',')[0]) }));

        const createdProduct = {
            productName: productName,
            productGroup: groups,
            unit: selectedUnit,
            // groupColors: { id: 1 },
            productBuy: buy,
            productPrimary: selectedTypeId === 1 ? true : false,
            productFabricated: selectedTypeId === 2 ? true : false,
            productIntermediary: false,
            productGeneric: selectedTypeId === 1 ? true : false,
            productFinal: selectedTypeId === 2 || selectedTypeId === 3 ? true : false,
            productPrice: 0,
            service: selectedTypeId === 3 ? true : false
        };

        const response = await api.createProduct(createdProduct);
        console.log('teste de envio', response.data);

        //console.log(response);
        if (response.status === 201 && response.data.productFinal === true) {
            setProductName('');
            handleClearForm();
            setFormOpen(false);
            getProducts();
            navigate(`/main/questionaires/create/${response.data.id}`);
        } else {
            setProductName('');
            handleClearForm();
            setFormOpen(false);
            getProducts();
        }
    };

    // =============== ACTIVE NEW PRODUCT FORM ================
    const [formOpen, setFormOpen] = useState(false);

    const handleDeleteProduct = async (id) => {
        try {
            const response = await api.deleteProduct(id);
            getProducts();
        } catch (error) {
            window.alert('O produto está associado a algum questionário ou orçamento, não é possível excluí-lo no momento.');
        }
    };

    const handleChangec = (event) => {
        const {
            target: { value }
        } = event;
        setSelectedColor(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };
    // =============== TABLE PAGINATION =================/
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    console.log('PRODUTOS', products);
    useEffect(() => {
        getProducts();
        getGroups();
        getUnits();
        getColors();
    }, []);

    const handleChangeGroupsSelect = (event) => {
        const {
            target: { value }
        } = event;
        setSelectedGroup(typeof value === 'string' ? value.split(',') : value);
    };

    //order by
    const [orderByNome, setOrderByNome] = useState('asc');
    const [orderById, setOrderById] = useState('asc');

    const handleSortById = () => {
        const sortedItens = [...products].sort((a, b) => {
            if (orderById === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setProducts(sortedItens);
        setOrderById(orderById === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByNome = () => {
        const sortedItens = [...products].sort((a, b) => {
            const nomeA = a.productName.toUpperCase();
            const nomeB = b.productName.toUpperCase();

            if (orderByNome === 'asc') {
                if (nomeA < nomeB) return -1;
                if (nomeA > nomeB) return 1;
            } else {
                if (nomeA > nomeB) return -1;
                if (nomeA < nomeB) return 1;
            }

            return 0;
        });

        setProducts(sortedItens);
        setOrderByNome(orderByNome === 'asc' ? 'desc' : 'asc');
    };
    const handleSortByUnit = () => {
        const sortedItens = [...products].sort((a, b) => {
            const nomeA = a.unit?.unitSize.toUpperCase();
            const nomeB = b.unit?.unitSize.toUpperCase();

            if (orderByNome === 'asc') {
                if (nomeA < nomeB) return -1;
                if (nomeA > nomeB) return 1;
            } else {
                if (nomeA > nomeB) return -1;
                if (nomeA < nomeB) return 1;
            }

            return 0;
        });

        setProducts(sortedItens);
        setOrderByNome(orderByNome === 'asc' ? 'desc' : 'asc');
    };

    return (
        <MainCard title="Produtos">
            <Grid container spacing={gridSpacing}>
                {formOpen && (
                    <Grid item xs={12} sm={12}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                sx={{ marginLeft: '90%' }}
                                onClick={() => {
                                    setFormOpen(!formOpen);
                                }}
                            >
                                <CloseIcon />
                            </Button>
                        </Box>
                        <form
                            onSubmit={(e) => handleSubmit(e, productName)}
                            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                        >
                            <Typography sx={{ fontSize: '20px' }}>Cadastro de novo produto</Typography>
                            <TextField
                                label="Nome do Produto"
                                variant="outlined"
                                onChange={(e) => setProductName(e.target.value)}
                                required
                            />
                            <FormControl sx={{ width: '100%', mb: '15px' }}>
                                <InputLabel id="unit">Unidade de medida *</InputLabel>
                                <Select
                                    labelId="unit"
                                    value={selectedUnit || ''}
                                    label="Unidade de medida"
                                    onChange={handleChangeUnit}
                                    required
                                >
                                    {unit.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.unitSize}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <input type="hidden" name="selectedUnit" value={selectedUnit} />
                            </FormControl>
                            <FormControl sx={{ width: '100%', mb: '15px' }}>
                                <InputLabel id="tipo">Tipo *</InputLabel>
                                <Select labelId="tipo" value={selectedTypeId} label="Tipos" onChange={handleChangeType} required>
                                    {types.map((type) => (
                                        <MenuItem key={type.id} value={type.id}>
                                            {type.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <input type="hidden" name="selectedTypeId" value={selectedTypeId} />
                            </FormControl>

                            <FormControl sx={{ width: '100%', mb: '15px' }}>
                                <InputLabel id="demo-multiple-chip-label">Grupos</InputLabel>

                                <Select
                                    labelId="Grupos"
                                    label="Grupos"
                                    multiple
                                    disabled={selectedTypeId === 1 || selectedTypeId === '' ? true : false}
                                    value={selectedGroup}
                                    onChange={handleChangeGroupsSelect}
                                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {groups.map((group) => (
                                        <MenuItem key={group.id} value={`${group.id},${group.groupDescription.toString()}`}>
                                            {group.groupDescription}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <input type="hidden" name="selectedGroup" value={selectedGroup} />
                            </FormControl>

                            <ButtonGroup sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="reset"
                                    sx={{ margin: '1rem', width: '30%' }}
                                    onClick={handleClearForm}
                                >
                                    Limpar
                                </Button>
                                {selectedTypeId === 2 ? (
                                    <Button variant="contained" color="primary" type="submit" sx={{ margin: '1rem', width: '30%' }}>
                                        Próximo
                                    </Button>
                                ) : (
                                    <Button variant="contained" color="primary" type="submit" sx={{ margin: '1rem', width: '30%' }}>
                                        Cadastrar
                                    </Button>
                                )}
                            </ButtonGroup>
                        </form>
                    </Grid>
                )}

                {!formOpen && (
                    <Grid item container sm={12} columns={12} spacing={2} sx={{ justifyContent: 'center' }}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '2em',
                                padding: '1em'
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setFormOpen(!formOpen);
                                }}
                                variant="contained"
                                sx={{ textTransform: 'none' }}
                            >
                                Cadastrar novo produto
                            </Button>

                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={products}
                                getOptionLabel={(product) => product.productName}
                                value={selectedProduct}
                                onChange={(event, newValue) => {
                                    setSelectedProduct(newValue);
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Pesquisar Produtos" />}
                            />
                        </Box>
                        {loading && <CircularProgress sx={{ marginTop: '2em' }} aria-label="progress" />}
                        {!loading && !products.length > 0 && (
                            <Typography variant="h4" sx={{ textAlign: 'center', marginTop: '1.3em' }}>
                                Ainda não existem produtos cadastrados
                            </Typography>
                        )}

                        <TableContainer sx={{ maxHeight: '500px' }}>
                            <Table stickyHeader aria-label="collapsible table" size="small">
                                <TableHead>
                                    {products.length > 0 && (
                                        <TableRow>
                                            <TableCell>
                                                <TableSortLabel onClick={handleSortByNome}> Nome do produto</TableSortLabel>
                                            </TableCell>
                                            <TableCell align="center">
                                                <TableSortLabel onClick={handleSortByUnit}> Unidade de medida</TableSortLabel>
                                            </TableCell>
                                            <TableCell align="center">Tipo</TableCell>
                                            <TableCell align="center">Origem</TableCell>
                                            <TableCell sx={{ pr: 3 }} align="right">
                                                Ações
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableHead>
                                <TableBody>
                                    {products
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .filter((product) =>
                                            selectedProduct ? product.productName.includes(selectedProduct.productName) : true
                                        )
                                        .map((product, index) => (
                                            <Row
                                                key={index}
                                                product={product}
                                                units={unit}
                                                groups={groups}
                                                origins={origins}
                                                colors={colors}
                                                handleDeleteProduct={handleDeleteProduct}
                                                getProduct={getProducts}
                                            />
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {products.length > 5 && (
                            <TablePagination
                                rowsPerPageOptions={[25, 50, 100]}
                                component="div"
                                count={products.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        )}
                    </Grid>
                )}
            </Grid>
        </MainCard>
    );
}

export default ProducView;
