import React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

export default function PieChartComponent({ percentageValues }) {
    const keys = Object.keys(percentageValues);

    const keyNoPrazo = keys.find((key) => key.includes('NO PRAZO'));

    const valorNoPrazo = percentageValues[keyNoPrazo]?.toFixed(2);

    const data = [
        {
            id: 0,
            value: isNaN(percentageValues?.ATRASADO) ? 'R$ 0,00' : percentageValues?.ATRASADO?.toFixed(2),
            label: 'Atrasados'
        },
        {
            id: 1,
            value: isNaN(percentageValues?.ADIANTADO) ? 'R$ 0,00' : percentageValues?.ADIANTADO?.toFixed(2),
            label: 'Adiantados'
        },
        { id: 2, value: isNaN(valorNoPrazo) ? 'R$ 0,00' : parseFloat(valorNoPrazo), label: 'No Prazo' }
    ];

    return (
        <PieChart
            series={[
                {
                    data,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    innerRadius: 30,
                    outerRadius: 100,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    startAngle: -90,
                    endAngle: 180,
                    arcLabel: (item) => `${item.value} %`
                }
            ]}
            colors={['#7e57c2', '#409ae9', '#a1d2fa']}
            height={200}
        />
    );
}
