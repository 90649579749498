import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { toast } from 'react-toastify';

const AddNewCloseAnswer = ({ open, setOpen, get }) => {
    const api = useApi();

    const initialState = {
        formula: '',
        description: '',
        value: 0,
        product: { id: null },
        publicDescription: ''
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: '8px',
        maxHeight: '650px',
        overflow: 'auto'
    };

    const [fields, setFields] = useState([initialState]);
    const [title, setTitle] = useState('');
    const [products, setProducts] = useState([]);
    const [questionData, setQuestionData] = useState(null);

    const getAllProducts = async () => {
        try {
            const { data } = await api.getAllProducts();
            console.log('🚀 ~ file: AddNewCloseAnswer.js:37 ~ getAllProducts ~ data:', data);
            setProducts(data);
        } catch (error) {
            console.log('🚀 ~ file: AddNewCloseAnswer.js:38 ~ getAllProducts ~ error:', error);
        }
    };

    useEffect(() => {
        getAllProducts();
    }, []);

    const handleRegisterQuestion = async () => {
        try {
            const { data } = await api.createQuestion('Fechada', title);
            console.log('🚀 ~ file: AddNewCloseAnswer.js:55 ~ handleRegisterQuestion ~ data:', data);
            setQuestionData(data);
        } catch (error) {
            console.log('🚀 ~ file: AddNewCloseAnswer.js:56 ~ handleRegisterQuestion ~ error:', error);
        }
    };

    //function referente ao fields
    const atualizarCampo = (indiceCampo, campo, valor) => {
        setFields((prevCampos) => {
            const novosCampos = [...prevCampos];
            if (campo === 'value') {
                novosCampos[indiceCampo][campo] = Number(valor);
            } else {
                novosCampos[indiceCampo][campo] = valor;
            }

            if (campo === 'product') {
                novosCampos[indiceCampo][campo] = { id: Number(valor) };
            }
            return novosCampos;
        });
    };

    const adicionarCampo = () => {
        setFields([...fields, initialState]);
    };

    const removerCampo = (indiceCampo) => {
        setFields((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos.splice(indiceCampo, 1);
            return novosCampos;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(fields);
    };

    const handleAddNewAnswer = async () => {
        try {
            let respostas = fields;

            const answerPromises = respostas.map(async (answer) => {
                await api.createAnswer(
                    answer.formula,
                    answer.description,
                    answer.value,
                    questionData.id,
                    answer.product,
                    answer.publicDescription
                );
            });

            await Promise.all(answerPromises);

            setFields([initialState]);
            get();
            setOpen(false);
            setQuestionData(null);
            setTitle('');
            toast.success('Pergunta cadastrada com sucesso!');
        } catch (error) {
            console.log('🚀 ~ file: AddNewCloseAnswer.js:101 ~ handleAddNewAnswer ~ error:', error);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setFields([initialState]);
        setQuestionData(null);
        setTitle('');
    };
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3>Cadastro de pergunta fechada</h3>

                    <FormControl fullWidth>
                        <TextField name="title" label="Título da pergunta" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </FormControl>
                    <Button variant="contained" sx={{ marginTop: '10px' }} onClick={handleRegisterQuestion}>
                        Criar Questão
                    </Button>
                    {questionData && (
                        <form onSubmit={(e) => handleSubmit(e)}>
                            {fields?.map((field, index) => (
                                <Box key={index} sx={{ border: '1px solid #ccc', padding: '5px', marginTop: '10px', borderRadius: '8px' }}>
                                    <Typography variant="h5" sx={{ marginBottom: '2em', marginTop: '10px' }}>
                                        Resposta N° {index + 1}
                                    </Typography>
                                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                        <FormControl sx={{ width: '48%', mb: '15px' }}>
                                            <TextField
                                                label="Valor Associado"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={field.value}
                                                onChange={(e) => atualizarCampo(index, 'value', e.target.value)}
                                            />
                                        </FormControl>
                                        <FormControl sx={{ width: '48%', mb: '15px' }}>
                                            <InputLabel id="product">Produto Associado</InputLabel>
                                            <Select
                                                labelId="product"
                                                label="Produto Associado"
                                                value={field.product?.id || ''}
                                                onChange={(e) => atualizarCampo(index, 'product', e.target.value)}
                                            >
                                                <MenuItem value="" disabled>
                                                    Selecione um produto
                                                </MenuItem>
                                                {products.map((product) => (
                                                    <MenuItem key={product.id} value={product.id}>
                                                        {product.productName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <FormControl sx={{ width: '48%', mb: '15px' }}>
                                            <TextField
                                                label="Descrição privada"
                                                type="text"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={field.description}
                                                onChange={(e) => atualizarCampo(index, 'description', e.target.value)}
                                            />
                                        </FormControl>
                                        <FormControl sx={{ width: '48%', mb: '15px' }}>
                                            <TextField
                                                label="Descrição pública"
                                                type="text"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={field.publicDescription}
                                                onChange={(e) => {
                                                    atualizarCampo(index, 'publicDescription', e.target.value);
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Button onClick={() => removerCampo(index)}>Deletar Resposta</Button>
                                </Box>
                            ))}
                            <Button sx={{ marginTop: '10px' }} variant="contained" aria-label="Adicionar campo" onClick={adicionarCampo}>
                                <ControlPointIcon />
                            </Button>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                <Button variant="contained" type="submit" onClick={handleAddNewAnswer}>
                                    Salvar pergunta Fechada
                                </Button>
                            </Box>
                        </form>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default AddNewCloseAnswer;
