import PropTypes from 'prop-types';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';

// assets
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import { formatarData } from 'utils/regex';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',

    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.warning.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.warning.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

// ==============================|| DASHBOARD - TOTAL INCOME LIGHT CARD ||============================== //

const AppliedFilters = ({ initialData, finalData }) => {
    const theme = useTheme();

    return (
        <>
            <CardWrapper border={false} content={false}>
                <Box sx={{ p: 1 }}>
                    <List sx={{ py: 0 }}>
                        <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                            <ListItemAvatar>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        ...theme.typography.commonAvatar,
                                        ...theme.typography.largeAvatar,
                                        backgroundColor: theme.palette.warning.light,
                                        color: theme.palette.warning.dark
                                    }}
                                >
                                    <FilterAltOutlinedIcon fontSize="inherit" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{
                                    py: 0,
                                    mt: 0.1,
                                    mb: 0.1
                                }}
                                primary={<Typography variant="h4">Filtros aplicados</Typography>}
                                secondary={
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: theme.palette.grey[500],
                                            display: 'flex',
                                            gap: '10px',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {initialData !== null && (
                                            <div style={{ marginTop: '5px' }}>
                                                <p style={{ margin: 0, fontSize: '15px' }}>De {initialData}</p>
                                            </div>
                                        )}

                                        {finalData !== null && (
                                            <div style={{ marginTop: '5px' }}>
                                                <p style={{ margin: 0, fontSize: '15px' }}>Até {finalData}</p>
                                            </div>
                                        )}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                </Box>
            </CardWrapper>
        </>
    );
};

AppliedFilters.propTypes = {
    isLoading: PropTypes.bool
};

export default AppliedFilters;
