import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    useMediaQuery,
    MenuItem,
    Chip
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import InputMask from 'react-input-mask';
// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useApi } from 'Service/axios';

import React from 'react';

const NewMpForm = ({ ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const api = useApi();
    const [units, setUnits] = useState([]);
    const [groups, setGroups] = useState([]);
    const [colorGroups, setColorGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const getAll = async () => {
        // unit
        const response = await api.getAllUnits();
        setUnits(response.data);

        // groups
        const responseGroups = await api.getAllGroups();
        setGroups(responseGroups.data);

        // colorGroups
        const responseColorsGroups = await api.getAllColorsGroup();
        setColorGroups(responseColorsGroups.data);
    };

    const handleChangeGroupsSelect = (event, setFieldValue) => {
        const {
            target: { value }
        } = event;
        setSelectedGroup(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
        setFieldValue('groups', typeof value === 'string' ? value.split(',') : value);
    };

    // const handleChangeColorsSelect = (event, setFieldValue) => {
    //     const {
    //         target: { value }
    //     } = event;
    //     setFieldValue('color_groups', typeof value === 'string' ? value.split(',') : value);
    // };

    const handleRegister = async (values) => {
        const colorGroupID = values.color_groups.split(',')[0];
        const payload = {
            productName: values.name,
            unit: values.unit,
            productPrimary: values.productPrimary,
            productBuy: values.productBuy,
            productGroup: values.groups === '' ? null : { id: values.groups },
            groupColors: { id: parseInt(colorGroupID) },
            productPrice: parseFloat(values.price),
            productFabricated: false,
            productGeneric: false,
            productIntermediary: false,
            productFinal: false
        };

        const createdMP = await api.createProduct(payload);
        window.location.reload();
    };

    useEffect(() => {
        getAll();
    }, []);

    return (
        <Box
            sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '1em' }}
        >
            <Typography sx={{ fontSize: '20px', textAlign: 'center', marginTop: '1em', marginBottom: '2em' }}>
                Cadastrar nova matéria prima
            </Typography>
            <Formik
                initialValues={{
                    name: '',
                    unit: '',
                    productPrimary: true,
                    productBuy: true,
                    groups: '',
                    color_groups: '',
                    price: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required('Nome obrigatório'),
                    unit: Yup.string().max(255).required('Unidade de medida obrigatório'),
                    price: Yup.string().max(255).required('Preço de medida obrigatório')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        handleRegister(values);
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form noValidate onSubmit={handleSubmit} {...others} style={{ width: '70%' }}>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
                                    <InputLabel htmlFor="outlined-adornment-name-register">Nome</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-name-register"
                                        type="text"
                                        label="Nome"
                                        value={values.name}
                                        name="name"
                                        onChange={handleChange}
                                        sx={{ marginBottom: '20px' }}
                                    />
                                    {touched.name && errors.name && (
                                        <FormHelperText error id="outlined-adornment-name-register">
                                            {errors.name}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl error={Boolean(touched.unit && errors.unit)} fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-unit-register">Unidade de medida</InputLabel>
                                    <Select
                                        id="outlined-adornment-unit-register"
                                        type="text"
                                        label="Unidade de medida"
                                        value={values.unit}
                                        name="unit"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    >
                                        {units.length > 0 &&
                                            units.map((unit) => (
                                                <MenuItem key={unit.id} value={unit.id}>
                                                    {unit.unitSize}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {touched.unit && errors.unit && (
                                        <FormHelperText error id="outlined-adornment-unit-register">
                                            {errors.unit}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>

                        <FormControl fullWidth error={Boolean(touched.color_groups && errors.color_groups)}>
                            <InputLabel htmlFor="outlined-adornment-color_groups-register">Grupo de Cor</InputLabel>
                            <Select
                                labelId="outlined-adornment-color_groups-register"
                                id="outlined-adornment-color_groups-register"
                                name="color_groups"
                                value={values.color_groups}
                                label="Grupo de Cor"
                                sx={{ marginBottom: '20px' }}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                            >
                                {colorGroups.map((group) => (
                                    <MenuItem key={group.id} value={`${group.id},${group.name.toString()}`}>
                                        {group.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {touched.color_groups && errors.color_groups && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.color_groups}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl fullWidth error={Boolean(touched.price && errors.price)}>
                            <InputLabel htmlFor="outlined-adornment-price-register">Preço</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-price-register"
                                type="number"
                                label="Preço"
                                value={values.price}
                                name="price"
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.price && errors.price && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.price}
                                </FormHelperText>
                            )}
                        </FormControl>

                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={() => window.location.reload()} color="error">
                                Voltar
                            </Button>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Cadastrar
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default NewMpForm;
