import { Button, TextField } from '@mui/material';
import React from 'react';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import { useFormula } from 'hooks/useFormula';
const styleFields = {
    display: 'flex',
    gap: '20px',
    marginTop: '20px',
    width: '100%',
    marginBottom: '30px'
};

const OpenRestriction = ({ indexposition, quesitonObj, quizId, setOpen, positionModel }) => {
    console.log('🚀 ~ OpenRestriction ~ positionModel:', positionModel);
    const { position } = useFormula();
    console.log('🚀 ~ OpenRestriction ~ position:', position);

    const api = useApi();

    // const payload = {
    //             id: position.id,
    //             position: position.position,
    //             imageUrl: url,
    //             openQuestionRestriction: position.openQuestionRestriction,
    //             question: currentQuesiton
    //         };

    const formik = useFormik({
        initialValues: {
            min: positionModel.openQuestionRestriction?.minimumValue || '',
            max: positionModel.openQuestionRestriction?.maximumValue || '',
            description: positionModel.openQuestionRestriction?.description || ''
        },
        validate: (values) => {
            const errors = {};

            // Check for empty fields

            if (!values.min) {
                errors.min = 'Este campo é obrigatório';
            }
            if (!values.max) {
                errors.max = 'Este campo é obrigatório';
            }

            // Check if min is greater than max
            if (values.min && values.max && parseFloat(values.min) > parseFloat(values.max)) {
                errors.min = 'Verifique os valores';
            }

            return errors;
        },
        onSubmit: async (values) => {
            const { description, max, min } = values;
            const payload = {
                id: position.id,
                position: position.position,
                imageUrl: position.imageUrl,
                openQuestionRestriction: {
                    description: description,
                    minimumValue: min,
                    maximumValue: max
                },
                question: quesitonObj
            };

            try {
                const response = await api.updateImageAndOpenQuestion(position.positionId, payload);
                console.log('response', response);
                toast.success('Restrição salva com sucesso!');
                // setOpen(false);
                window.location.reload();
            } catch (error) {
                console.log(error.message);
            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3>Pergunta atual:</h3>
                <p style={{ marginLeft: '10px' }}>{quesitonObj.description}</p>
            </div>
            <h3 style={{ fontWeight: 'normal' }}>Adicione uma restrição:</h3>
            <div style={styleFields}>
                <div>
                    <TextField
                        type="number"
                        id="min"
                        name="min"
                        onChange={formik.handleChange}
                        label="Valor minimo"
                        variant="outlined"
                        value={formik.values.min}
                    />
                    {formik.errors.min ? <div style={{ fontSize: '12px', color: 'red' }}>{formik.errors.min}</div> : null}
                </div>
                <div>
                    <TextField
                        type="number"
                        id="max"
                        name="max"
                        onChange={formik.handleChange}
                        label="Valor maximo"
                        variant="outlined"
                        value={formik.values.max}
                    />
                    {formik.errors.max ? <div style={{ fontSize: '12px', color: 'red' }}>{formik.errors.max}</div> : null}
                </div>
            </div>
            <h3 style={{ fontWeight: 'normal' }}>Campo de observação:</h3>
            <div>
                <TextField
                    type="text"
                    id="description"
                    fullWidth
                    label="Adicione uma descrição"
                    variant="outlined"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                />
                {formik.errors.description ? <div style={{ fontSize: '12px', color: 'red' }}>{formik.errors.description}</div> : null}
            </div>

            <Button sx={{ marginTop: '30px' }} variant="contained" fullWidth type="submit">
                Salvar Restrição
            </Button>
        </form>
    );
};

export default OpenRestriction;
