import { lazy } from 'react';

import Register from 'views/pages/authentication/authentication3/Register3';
import ProtectedRoute from './ProtectedRouter';
import NotAllowed from 'views/NotAllowed';
const role = localStorage.getItem('role');
const roleParse = JSON.parse(role);
const RegisterRoute = {
    path: '/register',
    element: roleParse === 'Administrador' ? <Register /> : <NotAllowed />
};

export default RegisterRoute;
