import PropTypes from 'prop-types';

// material-ui
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

// ===========================|| HOVER SOCIAL CARD ||=========================== //

const HoverSocialCard = ({ primary, secondary, iconPrimary, color, sx }) => {
    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary ? <IconPrimary fontSize="large" /> : null;

    return (
        <Card
            sx={{
                ...sx,
                background: color,
                color: '#fff',
                minWidth: '120px',
                '&:hover svg': {
                    opacity: '1',
                    transform: 'scale(1.1)'
                },
                height: '65px',
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'center',
                justifyContent: 'space-around'
            }}
        >
            <Box
                sx={{
                    color: '#fff',
                    '&> svg': {
                        width: 40,
                        height: 40,
                        opacity: '0.4',
                        transition: 'all .3s ease-in-out'
                    }
                }}
            >
                {primaryIcon}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h1" color="inherit">
                    {secondary}
                </Typography>

                <Typography variant="subtitle2" color="inherit">
                    {primary}
                </Typography>
            </Box>
        </Card>
    );
};

HoverSocialCard.propTypes = {
    primary: PropTypes.string,
    secondary: PropTypes.string,
    iconPrimary: PropTypes.object,
    color: PropTypes.string
};

export default HoverSocialCard;
