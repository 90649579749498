import {
    Button,
    Collapse,
    Dialog,
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField
} from '@mui/material';
import { Box } from '@mui/system';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ControlledAccordions from './Accordion';

const FirstModal = ({ open, setOpen, currentRow, setRowSelectedSecondModal, setOpenSecondModal }) => {
    const api = useApi();
    const [data, setData] = useState([]);
    const [openCollapseArray, setOpenCollapseArray] = useState(new Array(data.length).fill(false));

    // logica collapse
    const handleCollapseClick = (index) => {
        const newArray = [...openCollapseArray];
        newArray[index] = !newArray[index];
        setOpenCollapseArray(newArray);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        maxHeight: 900,
        borderRadius: 4
    };

    const getStocks = async () => {
        try {
            const res = await api.getStock(currentRow?.ord_in_codigo);
            setData(res.data);
        } catch (error) {
            console.log('🚀 ~ file: FirstModal.js:47 ~ get ~ error:', error);
        }
    };

    useEffect(() => {
        getStocks();
    }, [currentRow]);

    return (
        <Modal
            open={open}
            keepMounted
            onClose={() => {
                setOpen(false);
                setOpenCollapseArray([]);
            }}
        >
            <Box sx={style}>
                <h3>Lista de Materiais</h3>
                <Box sx={{ display: 'flex', gap: '1em', marginBottom: '2em', width: '100%' }}>
                    <TextField sx={{ width: '20%', fontSize: '12px' }} value={currentRow?.ord_in_codigo} placeholder="ORDEM" disabled />
                    <TextField sx={{ width: '70%', fontSize: '12px' }} value={currentRow?.pro_st_descricao} placeholder="ITEM" disabled />
                    <TextField sx={{ width: '10%', fontSize: '12px' }} value={currentRow?.uni_st_unidade} placeholder="UN" disabled />
                    <TextField
                        sx={{ width: '20%', fontSize: '12px' }}
                        value={currentRow?.ord_re_qtde_ordem}
                        placeholder="QUANTIDADE"
                        disabled
                    />
                </Box>
                <div>
                    <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: '500px' }}>
                        <Table sx={{ minWidth: 750 }} stickyHeader size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel></TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel>Códgo</TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel>Descriçao</TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel>UN</TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">Qtd</TableCell>
                                    <TableCell align="center">Reservado</TableCell>
                                    <TableCell align="center">Disponível</TableCell>
                                    <TableCell align="center">Compra</TableCell>
                                    <TableCell align="center">Similar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <>
                                        <TableRow key={row.item1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell>
                                                <IconButton aria-label="expand row" size="small" onClick={() => handleCollapseClick(index)}>
                                                    {openCollapseArray && <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={{ fontSize: '12px' }}>
                                                {row.lis_pro_in_codigo}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {row.lis_pro_st_descricao}
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontSize: '12px' }}>
                                                {row.lis_uni_st_unidade}
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontSize: '12px' }}>
                                                {row.lis_dde_re_qtde_necessaria}
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontSize: '12px' }}>
                                                {row.lis_mvs_re_quantidade}
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontSize: '12px' }}>
                                                {row.lis_mvs_re_quantidade_re}
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontSize: '12px' }}>
                                                {row.lis_mvs_re_quantidade}
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontSize: '12px' }}>
                                                <Button
                                                    onClick={() => {
                                                        setOpenSecondModal(true);
                                                        setRowSelectedSecondModal(row);
                                                    }}
                                                >
                                                    <VisibilityIcon sx={{ width: '18px' }} />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                                <Collapse in={openCollapseArray[index]} timeout="auto" unmountOnExit>
                                                    <Box sx={{ width: '100%' }}>
                                                        <ControlledAccordions />
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Box>
        </Modal>
    );
};

export default FirstModal;
