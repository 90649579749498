import React from 'react';
import { useApi } from 'Service/axios';
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import HoverSocialCard from './HoverCard';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import EventNoteIcon from '@mui/icons-material/EventNote';
import TableHoverCard from './TableHoverCard';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { useNavigate } from 'react-router';
const ActionButtons = ({ row, openFirst, setCurrentRow }) => {
    console.log('🚀 ~ file: ActionButtons.js:28 ~ ActionButtons ~ row:', row);
    const navigate = useNavigate();
    const handleOpen = async () => {
        openFirst(true);

        setCurrentRow(row);
    };

    return (
        <div>
            <Button onClick={() => navigate(`/main/pcp/analise-ordens/${row.ord_in_codigo}`)}>
                <ContentPasteSearchIcon />
            </Button>
        </div>
    );
};

export default ActionButtons;
