import React from 'react';
import Chart from 'react-google-charts';

export default function ComboChart({ dataYearCharts, allGoals, fullDate }) {
    const getYear = (date) => {
        if (date) {
            return new Date(date).getFullYear();
        } else {
            return new Date().getFullYear();
        }
    };

    const year = getYear(fullDate);

    const monthNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    const result = dataYearCharts.map((item) => {
        const mes = monthNames[Number(item.mes) - 1]; // Subtrai 1 para considerar que o array de meses começa com 0
        const valorMeta = allGoals.find((goal) => goal.dataMeta.split('/')[1] === item.mes)?.valor || 0;
        const valorFaturamento = item.valor;

        return { mes, valorMeta, valorFaturamento };
    });

    const data2 = [
        ['Mês', 'Meta', 'Faturamento'],
        ...result.map(({ mes, valorMeta, valorFaturamento }) => [mes, valorMeta, valorFaturamento])
    ];

    const options = {
        title: `Meta x Faturamento de ${year}`,
        curveType: 'function',
        legend: { position: 'right' },
        colors: ['#7e57c2', '#409ae9'],
        series: {
            0: {
                color: '#7e57c2',
                visibleInLegend: true,
                crosshair: {
                    color: 'transparent',
                    trigger: 'focus'
                }
            },
            1: {
                color: '#409ae9',
                visibleInLegend: true,
                crosshair: {
                    color: 'transparent',
                    trigger: 'focus'
                }
            }
        },
        hAxis: {
            format: 'M', // Use o formato de mês abreviado
            ticks: Array.from({ length: 12 }, (_, i) => i + 1) // Crie uma lista de números de 1 a 12
        }
    };

    return <Chart chartType="LineChart" width="930px" height="400px" data={data2} options={options} />;
}
