import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px'
};

export default function Conf({
    openUrlModal,
    setOpenUrlModal,
    handleRemoveOption,
    currentOption,
    allPosition,
    setAllPosition,
    savingPosition
}) {
    const handleClose = () => setOpenUrlModal(false);
    const [shouldSavePosition, setShouldSavePosition] = useState(false);

    const handleDelete = () => {
        const newPosition = allPosition.filter((item) => item.id !== currentOption.id);
        setAllPosition(newPosition);
        handleRemoveOption(currentOption);
        handleClose();
        setShouldSavePosition(true);
    };

    const savingPositionFunc = () => {
        // Lógica para salvar posição
        savingPosition();
        setShouldSavePosition(false); // Resetar a flag para evitar chamadas repetidas
    };

    useEffect(() => {
        if (shouldSavePosition) {
            savingPositionFunc();
        }
    }, [shouldSavePosition]);

    return (
        <div>
            <Modal open={openUrlModal} onClose={handleClose}>
                <Box sx={style}>
                    <h3 style={{ margin: 0 }}>Deseja mesmo deletar a pergunta ?</h3>
                    <span style={{ display: 'block', marginTop: '5px', marginBottom: '20px' }}>Todas as alterações serão descartadas!</span>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                        <Button onClick={handleClose} variant="text">
                            Fechar
                        </Button>
                        <Button onClick={handleDelete} variant="contained">
                            Confirmar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
