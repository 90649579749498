import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';

const InsertSkeleton = () => {
    return (
        <Box sx={{ display: 'flex', gap: '70px', marginTop: '40px', marginLeft: '20px' }}>
            <Skeleton animation="wave" variant="rounded" width={300} height={35} />
            <Skeleton animation="wave" variant="rounded" width={300} height={35} />
        </Box>
    );
};

export default InsertSkeleton;
