import {
    Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import Box from '@mui/material/Box';
import { useMemo, useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SnackBar from 'ui-component/SnakBar';
import { useParams } from 'react-router';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import SearchIcon from '@mui/icons-material/Search';
import NewFormulaModal from '../newFormulaModal';
import { toast } from 'react-toastify';
import { useFormula } from 'hooks/useFormula';
import { DataGrid } from '@mui/x-data-grid';

const style = {
    width: '100%',
    maxHeight: '92vh',
    overflowY: 'auto'
};

export default function FormulaCloseQuestion({ question, selectedList, currentId, setOpenAllModal, savingPosition }) {
    const api = useApi();
    const { id } = useParams();
    const [quizId, setQuizId] = useState(id);

    const initialState = {
        pergunta: '',
        resposta: '',
        op: ''
    };

    const [fields, setFields] = useState([initialState]);

    const [itemSelected, setitemSelected] = useState(null);
    const [selectedItemId, setSelectedItemId] = useState('');
    const [currentAnswers, setCurrentAnswers] = useState([]);
    const [selectedAnswersInput, setSelectedAnswersInput] = useState([]);
    const [selectedAnwser, setSelectedAnwser] = useState('');
    const [selectedOperations, setSelectedOperations] = useState('');
    const [openSnack, setOpenSnack] = useState(false);
    const [snackTitle, setSnackTitle] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [answer, setAnswer] = useState(currentAnswers);
    const [newFormula, setNewFormula] = useState(false);

    const selectedOptions = useMemo(() => {
        const selectedIndex = selectedList?.findIndex((item) => item.id === currentId);
        return selectedIndex !== -1 ? selectedList?.slice(0, selectedIndex) : [];
    }, [currentId, selectedList]);

    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const [currentAnswer, setCurrentAnswer] = useState([]);
    const [allAnswersv2, setAllAnswersv2] = useState([]);

    const getAnswerv2 = async (id) => {
        try {
            const { data } = await api.getAnswerByCloseQuestion(id);
            setCurrentAnswer(data);
            setAllAnswersv2((prev) => [...prev, ...data]);
        } catch (error) {
            console.log('🚀 ~ file: index.js:75 ~ getAnswerv2 ~ error:', error);
        }
    };
    const handleSelectChange = (value, index, name) => {
        const selectedValue = typeof value === 'object' ? value.target.value : value;
        if (name === 'pergunta') getAnswerv2(selectedValue);

        setFields((prevFields) => {
            const newFields = [...prevFields];
            newFields[index] = {
                ...newFields[index],
                [name]: selectedValue
            };
            return newFields;
        });

        const newAnswers = combinedQuestionsAndAnswers.filter((item) => item.question === selectedValue);
        if (newAnswers.length > 0) {
            const answers = newAnswers.map((item) => item.answer);
            setAnswer(answers);
            if (name === 'resposta') setSelectedAnswer(answers);
        }
    };

    const [combinedQuestionsAndAnswers, setCombinedQuestionsAndAnswers] = useState([]);
    const [allAnswers, setAllAnswers] = useState([]);

    const operations = [
        { id: 1, op: 'or', symbol: 'Ou' },
        { id: 2, op: 'end', symbol: 'E' }
    ];

    const addNewField = () => {
        if (fields.some((field) => field.pergunta === '' || field.resposta === '' || field.op === ''))
            return toast.warn('Todos os campos devem ser preenchidos');
        toast.success('Formula adicionada');
        setFields(
            fields.concat({
                pergunta: '',
                resposta: '',
                op: ''
            })
        );
    };

    const removeField = (index) => {
        const newFields = [...fields];
        if (newFields.length > 1) {
            newFields.splice(index, 1);
            setFields(newFields);
        }
    };

    //
    //
    //  respostas
    //
    //

    const [currentAnswerQuestion, setCurrentAnswerQuestion] = useState([]);
    const [answerQuestion, setAnswerQuestion] = useState(currentAnswerQuestion);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loadingAnswer, setLoadingAnswer] = useState(false);
    const getCurrentAnwser = async (currentId) => {
        setLoadingAnswer(true);
        try {
            const { data } = await api.getQuestionAndAnswers(currentId);
            setCurrentAnswerQuestion(data.answers);
            setLoadingAnswer(false);
        } catch (error) {
            console.error('Ocorreu um erro ao obter as respostas:', error);
        }
    };

    useEffect(() => {
        const updatedSelectedFullItems = currentAnswers.filter((item) => selectedItems.includes(item.id));
        setSelectedFullItems(updatedSelectedFullItems);
    }, []);

    //effect do item selecionado na primeira pagina
    useEffect(() => {
        getCurrentAnwser(currentId);
    }, []);

    useEffect(() => {
        const filteredAnswers = currentAnswerQuestion.filter((row) => row.description.toLowerCase().includes(searchValue.toLowerCase()));
        console.log('🚀 ~ file: index.js:188 ~ useEffect ~ filteredAnswers:', filteredAnswers);
        const rows = filteredAnswers.map((row) => ({
            id: row.id,
            response: row.description
        }));
        setAnswerQuestion(rows);
    }, [currentAnswerQuestion, searchValue]);

    // lógica para datagrid

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'response', headerName: 'Resposta', flex: 1 }
    ];

    const [selectionModel, setSelectionModel] = useState([]);

    const [selectedFullItems, setSelectedFullItems] = useState([]);

    //
    //
    //requisições e clean
    //
    //

    const [SaveLoading, setSaveLoading] = useState(false);

    const savingFormula = async () => {
        setSaveLoading(true);

        const mappedFields = fields.map((field) => {
            const answer = allAnswersv2.find((item) => item.description === field.resposta);

            return {
                pergunta: field.pergunta,
                resposta: answer,
                op: field.op === 1 ? 'or' : 'and'
            };
        });
        console.log('🚀 ~ mappedFields ~ mappedFields:', mappedFields);

        const copySelectedItems = [...selectionModel];

        const payload = [
            {
                answersIfTrue: copySelectedItems.map((item) => ({ id: item })),
                formulas: mappedFields.map((item) => ({
                    type: item.op,
                    question: { id: item.pergunta },
                    answer: { id: item.resposta.id }
                }))
            }
        ];

        console.log('🚀 ~ file: index.js:230 ~ savingFormula ~ payload:', payload);

        try {
            await api.addNewFormula(id, currentId, payload);
            setFields([initialState]);
            setSaveLoading(false);
            setSelectedItems([]);
            setSelectionModel([]);
            setNewFormula(true);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box sx={style}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3>Pergunta atual:</h3>
                <p style={{ marginLeft: '10px' }}>{question}</p>
            </div>
            <SnackBar openSnack={openSnack} setOpenSnack={setOpenSnack} title={snackTitle} />
            <NewFormulaModal
                newFormula={newFormula}
                setNewFormula={setNewFormula}
                closeAllModal={setOpenAllModal}
                savingPosition={savingPosition}
            />
            {fields.map((field, index) => (
                <Box key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            width: '100%',
                            justifyContent: 'center',
                            maxWidth: '900px'
                        }}
                    >
                        <h4 style={{ fontWeight: 'normal' }}>Selecione uma pergunta anterior</h4>
                        <Box>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h5 style={{ fontWeight: 'normal' }}>Se pergunta: </h5>
                                <div>
                                    <FormControl sx={{ m: 1, width: '300px' }}>
                                        <InputLabel id="perguntas-anteriores-label">Perguntas Anteriores</InputLabel>
                                        <Select
                                            labelId="perguntas-anteriores-label"
                                            id="perguntas-anteriores-select"
                                            autoWidth
                                            clearIcon={null}
                                            label="Perguntas Anteriores"
                                            value={field.pergunta}
                                            onChange={(event) => {
                                                handleSelectChange(event, index, 'pergunta');
                                            }}
                                        >
                                            {selectedOptions.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {`Id-${item.id}  ${item.description}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <h1 style={{ fontWeight: 'normal' }}>=</h1>

                                <div>
                                    <FormControl sx={{ m: 1, width: 300 }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            clearIcon={null}
                                            options={currentAnswer}
                                            sx={{ width: 300 }}
                                            value={field.resposta || ''}
                                            onChange={(event, value) => {
                                                handleSelectChange(value.description, index, 'resposta');
                                            }}
                                            getOptionLabel={(option) => option}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    ID - {option.id} {option.description}
                                                </Box>
                                            )}
                                            renderInput={(params) => <TextField {...params} label="Respostas" />}
                                            clearOnEscape={true}
                                        />
                                    </FormControl>
                                </div>
                                <div>
                                    <FormControl sx={{ width: '100px' }}>
                                        <InputLabel id="operacao">Operação</InputLabel>
                                        <Select
                                            labelId="operacao"
                                            id="operacao"
                                            autoWidth
                                            label="Operação"
                                            value={field.op}
                                            onChange={(event) => {
                                                handleSelectChange(event, index, 'op');
                                            }}
                                        >
                                            {operations.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.symbol}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <IconButton
                                        size="large"
                                        sx={{
                                            marginLeft: '20px',
                                            '&:hover': {
                                                backgroundColor: '#ff6961'
                                            }
                                        }}
                                        onClick={() => removeField(index)}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div>
                                <Button variant="contained" onClick={addNewField}>
                                    <AddIcon />
                                </Button>
                            </div>
                        </Box>
                    </div>
                </Box>
            ))}
            {!loadingAnswer ? (
                <>
                    <>
                        <div style={{ maxHeight: 380, width: '100%', marginBottom: '5px', marginTop: '30px' }}>
                            <DataGrid
                                rows={answerQuestion}
                                columns={columns}
                                pageSize={5}
                                checkboxSelection
                                selectionModel={selectionModel}
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setSelectionModel(newRowSelectionModel);
                                }}
                                sx={{ maxHeight: '380px' }}
                            />
                        </div>
                    </>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            style={{ marginLeft: '20px', marginBottom: '20px', width: '400px' }}
                            onClick={savingFormula}
                        >
                            {SaveLoading ? <CircularProgress size={24} color="inherit" /> : ' Salvar Formula'}
                        </Button>
                    </div>
                </>
            ) : (
                <div style={{ width: '200px', margin: '0 auto' }}>
                    <p>Carregando respostas ...</p>
                </div>
            )}
        </Box>
    );
}
