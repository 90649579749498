import {
    Autocomplete,
    Button,
    CircularProgress,
    Drawer,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import { useMemo } from 'react';
import { toast } from 'react-toastify';

const InsertRo = ({ open, setOpen, currentRo, closeQuestions, getCurrentRo, currentQuizData }) => {
    const api = useApi();

    const [colorGroup, setColorGroup] = useState([]);
    const [closeQuestion, setCloseQuestion] = useState([]);

    const [selectedDrilling, setSelectedDrilling] = useState('');
    const [selectedDrillingAnswer, setSelectedDrillingAnswer] = useState([]);

    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [selectedMaterialAnswer, setSelectedMaterialAnswer] = useState([]);

    const [loading, setLoading] = useState(false);

    //aceitar mais de uma resposta
    const handleChangeDrilling = (event) => {
        const {
            target: { value }
        } = event;

        setSelectedDrillingAnswer(value);

        setInitialValues((prev) => ({
            ...prev,
            drillingResponse: value
        }));
    };

    const handleChangeMaterial = (event) => {
        const {
            target: { value }
        } = event;

        setSelectedMaterialAnswer(value);

        setInitialValues((prev) => ({
            ...prev,
            materialResponse: value
        }));
    };

    //get gp de cor
    const getGroups = async () => {
        const response = await api.getAllColorsGroup();
        setColorGroup(response.data);
    };

    useEffect(() => {
        getGroups();
    }, []);

    //valores dos inputs

    const [initialValues, setInitialValues] = useState({
        colorGroup: null,
        startingFootage: '',
        finalFootage: '',
        drillingQuest: '',
        drillingResponse: [],
        materialQuest: '',
        materialResponse: [],
        ro: ''
    });

    //logica para pegar respostas de acordo com a pergunta
    //useMemo para melhorar a performace do calculo
    // const drillingAnswerOptions = useMemo(() => {
    //     return closeQuestions.length > 0 && closeQuestions.find((item) => item.question.id === selectedDrilling);
    // }, [selectedDrilling]);

    // const materialAnswerOptions = useMemo(() => {
    //     return closeQuestions.length > 0 && closeQuestions.find((item) => item.question.id === selectedMaterial);
    // }, [selectedMaterial]);

    //envio de formulário

    const handleSubmit = async (event) => {
        event.preventDefault();

        const newRoObject = {
            groupColor: initialValues.colorGroup.id,
            initialMeter: initialValues.startingFootage,
            finalMeter: initialValues.finalFootage,
            questionPerfuracao: Number(initialValues.drillingQuest) ? Number(initialValues.drillingQuest) : null,
            answerDrilling: initialValues.drillingResponse.map((item) => ({
                id: item
            })),
            questionMaterial: Number(initialValues.materialQuest),
            answerMaterial: initialValues.materialResponse.map((item) => ({
                id: item
            })),
            operationalResult: initialValues.ro
        };

        const payload = {
            groupColorsId: newRoObject.groupColor,
            initialMeter: Number(newRoObject.initialMeter),
            finalMeter: Number(newRoObject.finalMeter),
            operationalResult: Number(newRoObject.operationalResult),
            questionMaterialId: newRoObject.questionMaterial,
            questionPerfuracaoId: newRoObject.questionPerfuracao,
            questionsMaterial: newRoObject.answerMaterial,
            questionsPerfuracao: newRoObject.answerDrilling
        };

        try {
            await api.insertNewRoFormula(currentRo.id, payload);
            setLoading(false);
            toast.success('Cadastrado com sucesso');
            getCurrentRo();
            setInitialValues({
                colorGroup: null,
                startingFootage: '',
                finalFootage: '',
                drillingQuest: '',
                drillingResponse: [],
                materialQuest: '',
                materialResponse: [],
                ro: ''
            });
            setMaterialAnswer([]);
            setDrillingAnswer([]);
            setSelectedDrillingAnswer([]);
            setSelectedMaterialAnswer([]);
            setSelectedDrilling('');
            setSelectedMaterial('');
        } catch (error) {
            console.log('🚀 ~ file: InsertRo.js:66 ~ handleSubmit ~ error:', error.message);
        }
    };

    //logica para pegar respostas de relacionadas ou não com as cores

    const [drillingAnswer, setDrillingAnswer] = useState([]);
    const [materialAnswer, setMaterialAnswer] = useState([]);

    const getOptionOneAnswer = async (object) => {
        const questionSelected = object.description.includes('cor');

        if (questionSelected) {
            try {
                const { data } = await api.getAnswerByColorGroupt(object.id, initialValues.colorGroup.id);
                setDrillingAnswer(data);
            } catch (error) {
                console.log('🚀 ~ file: InsertRo.js:180 ~ getOptionOneAnswer ~ error:', error);
            }
        }

        if (!questionSelected) {
            try {
                const { data } = await api.getAnswerByCloseQuestion(object.id);
                setDrillingAnswer(data);
            } catch (error) {
                console.log('🚀 ~ file: InsertRo.js:172 ~ getOptionOneAnswer ~ error:', error);
            }
        }
    };
    const getOptionTwoAnswer = async (object) => {
        const questionSelected = object.description.includes('cor');

        if (questionSelected) {
            try {
                const { data } = await api.getAnswerByColorGroupt(object.id, initialValues.colorGroup.id);
                console.log('🚀 ~ file: InsertRo.js:193 ~ getOptionTwoAnswer ~ data:', data);
                setMaterialAnswer(data);
            } catch (error) {
                console.log('🚀 ~ file: InsertRo.js:180 ~ getOptionOneAnswer ~ error:', error);
            }
        }

        if (!questionSelected) {
            try {
                const { data } = await api.getAnswerByCloseQuestion(object.id);
                console.log('🚀 ~ file: InsertRo.js:204 ~ getOptionTwoAnswer ~ data:', data);
                setMaterialAnswer(data);
            } catch (error) {
                console.log('🚀 ~ file: InsertRo.js:172 ~ getOptionOneAnswer ~ error:', error);
            }
        }
    };

    return (
        <Drawer open={open} onClose={() => setOpen(false)} anchor="right">
            <form
                style={{ padding: '30px 15px' }}
                onSubmit={(e) => {
                    handleSubmit(e);
                    setLoading(true);
                }}
            >
                <h2>Inserindo R.O fórmula</h2>
                {/* <Box>
                    <TextField
                        label="Custo fixo"
                        value={currentRo?.fixedCost}
                        disabled
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box> */}
                <Box>
                    <h3 style={{ margin: '14px 0 10px 0' }}>Condicionais</h3>
                </Box>
                <Box>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        sx={{ width: 300, marginBottom: '20px' }}
                        options={colorGroup}
                        value={initialValues.colorGroup}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            setInitialValues((prev) => ({
                                ...prev,
                                colorGroup: newValue
                            }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Selecione um grupo de cor" />}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl>
                            <TextField
                                sx={{ width: '300px' }}
                                label="Metragem Inicial [m²]"
                                type="number"
                                value={initialValues.startingFootage}
                                onChange={(event) => setInitialValues((prev) => ({ ...prev, startingFootage: event.target.value }))}
                            />
                        </FormControl>
                    </Box>
                    <h3 style={{ marginRight: '20px', marginLeft: '20px' }}>Até</h3>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl>
                            <TextField
                                sx={{ width: '300px' }}
                                label="Metragem Final [m²]"
                                type="number"
                                value={initialValues.finalFootage}
                                onChange={(event) => setInitialValues((prev) => ({ ...prev, finalFootage: event.target.value }))}
                            />
                        </FormControl>
                    </Box>
                </Box>
                <h3 style={{ margin: '14px 0 10px 0' }}>Perfuração</h3>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '50px' }}>
                    <FormControl>
                        <InputLabel id="drillingQuestion" InputLabelProps={{ shrink: true }}>
                            Perguntas Associadas
                        </InputLabel>
                        <Select
                            sx={{ width: '300px' }}
                            labelId="drillingQuestion"
                            label="Perguntas Associadas"
                            value={selectedDrilling}
                            onChange={(event) => {
                                const selected = event.target.value;
                                setSelectedDrilling(selected);

                                setInitialValues((prev) => ({ ...prev, drillingQuest: selected.id }));
                                getOptionOneAnswer(selected);
                            }}
                        >
                            <MenuItem value="">Selecione uma opção</MenuItem>
                            {closeQuestions?.map((item) => (
                                <MenuItem key={item?.id} value={item}>
                                    {item?.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {drillingAnswer.length > 0 && (
                        <FormControl>
                            <InputLabel id="drillingAnswer" InputLabelProps={{ shrink: true }}>
                                Resposta
                            </InputLabel>
                            <Select
                                sx={{ width: '300px' }}
                                labelId="drillingAnswer"
                                label="Resposta"
                                multiple
                                value={initialValues.drillingResponse}
                                onChange={handleChangeDrilling}
                            >
                                <MenuItem value="">Selecione uma opção</MenuItem>
                                {drillingAnswer.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.description}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Box>
                <h3 style={{ margin: '14px 0 10px 0' }}>Material</h3>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '50px' }}>
                    <FormControl>
                        <InputLabel id="materialQuestion" InputLabelProps={{ shrink: true }}>
                            Perguntas Associadas
                        </InputLabel>
                        <Select
                            sx={{ width: '300px' }}
                            labelId="materialQuestion"
                            value={selectedMaterial}
                            label="Perguntas Associadas"
                            onChange={(event) => {
                                const selected = event.target.value;
                                setSelectedMaterial(selected);
                                setInitialValues((prev) => ({
                                    ...prev,
                                    materialQuest: selected.id
                                }));
                                getOptionTwoAnswer(selected);
                            }}
                        >
                            <MenuItem value="">Selecione uma opção</MenuItem>
                            {closeQuestions?.map((item) => (
                                <MenuItem key={item?.id} value={item}>
                                    {item?.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {materialAnswer.length > 0 && (
                        <FormControl>
                            <InputLabel id="materialAnswer" InputLabelProps={{ shrink: true }}>
                                Resposta
                            </InputLabel>
                            <Select
                                sx={{ width: '300px' }}
                                labelId="materialAnswer"
                                label="Resposta"
                                multiple
                                value={initialValues.materialResponse}
                                onChange={handleChangeMaterial}
                            >
                                <MenuItem value="">Selecione uma opção</MenuItem>
                                {materialAnswer.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.description}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Box>
                <h3 style={{ margin: '14px 0 10px 0' }}>Resultado Operacional</h3>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <TextField
                        label="R.O"
                        value={initialValues.ro}
                        onChange={(event) =>
                            setInitialValues((prev) => ({
                                ...prev,
                                ro: event.target.value
                            }))
                        }
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" type="submit" sx={{ width: '300px', marginTop: '40px' }}>
                        {loading ? <CircularProgress size={24} color="inherit" sx={{ marginRight: 1 }} /> : 'Salvar'}
                    </Button>
                </Box>
            </form>
        </Drawer>
    );
};

export default InsertRo;
