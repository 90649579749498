import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import ModalError from 'views/pages/modal';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Row from './Row';

// ====================== || GROUP COLORS VIEW || ======================
function GroupColor() {
    const [data, setData] = useState([]);
    const [name, setName] = useState('');
    const [error, setError] = useState(false);
    const [colorsError, setColorsError] = useState(false);
    const [openId, setOpenId] = useState(null);

    const [primaryProducts, setPrimaryProducts] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [groups, setGroups] = useState([]);

    const [loading, setLoading] = useState(true);

    //modal error variables logic
    const [isOpen, setIsOpen] = useState(false);
    const [errorMensage, setErrorMensage] = useState('');
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openSnakBar, setOpenSnackBar] = useState(false);
    const [openSnakBarCancelado, setOpenSnackBarCancelado] = useState(false);

    const getProduct = async () => {
        const response = await api.getAllPrimaryProducts();
        setPrimaryProducts(response.data);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const api = useApi();

    const getGroups = async () => {
        const response = await api.getAllColorsGroup();
        setGroups(response.data);
        setLoading(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const existe = groups.find((item) => item.name === name) !== undefined;

        if (name == '' || existe) {
            setError(true);
            setColorsError(true);
            return;
        } else {
            try {
                const grupo = [
                    {
                        name
                    }
                ];
                const transformedGroups = grupo.map((group) => ({
                    name: group.name
                }));

                const obj = transformedGroups[0];

                // TODO - fazer o map para salvar as cores igual as respostas

                //const response = await api.createColor(colorType, color, value);
                const response = await api.createGroupColor(obj);

                window.location.reload();

                //console.log(response);

                if (response.status === 200) {
                    setName('');
                    getGroups();
                }
            } catch (error) {
                // setErrorMensage('Algo deu errado');
                // setIsOpen(true);
            }
        }
    };
    const theme = useTheme();

    // TODO - fazer deleteGroupColor
    const handleDeleteColor = async (id) => {
        try {
            const response = await api.deleteGroupColor(id);
            getGroups();
        } catch (error) {
            setErrorMensage('Esta cor esta ligado a um produto');
            setIsOpen(true);
            window.alert('Não foi possível prosseguir, este grupo de cor esta registrado em algum produto cadastrado');
        }
    };

    const handleEditGroup = async (id, description, value, type) => {
        const response = await api.updateColor(id, description, value, type);
        get();
    };

    const getGroup = async (id) => {
        const response = await api.getColorGroupById(id);
        return response.data;
    };

    useEffect(() => {
        getGroups();
        getProduct();
    }, []);

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleClickOpenEdit = (itemId) => {
        setOpenEdit((prevState) => ({
            ...prevState,
            [itemId]: true
        }));
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenSnackBarCancelado(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
        setOpenSnackBarCancelado(false);
    };

    const [formOpen, setFormOpen] = useState(false);
    const handleToggle = (id) => {
        setOpenId(openId === id ? null : id);
    };

    const [selectedGroup, setSelectedGroup] = useState(null);

    return (
        <MainCard title="Grupo de Cores">
            <Grid container spacing={gridSpacing}>
                {!loading && groups.length === 0 && (
                    <Box sx={{ display: 'flex', mt: '5rem', justifyContent: 'center', width: '100%' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>Ainda nao existem grupos criados</Typography>
                    </Box>
                )}
                {!formOpen && (
                    <Grid item container sm={12} columns={12} spacing={2} sx={{ justifyContent: 'center' }}>
                        {loading && <CircularProgress sx={{ marginTop: '2em', marginLeft: '2em' }} aria-label="progress" />}
                        <Box
                            sx={{
                                width: '100%',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '2em',
                                padding: '1em'
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setFormOpen(!formOpen);
                                }}
                                variant="contained"
                                sx={{ textTransform: 'none' }}
                            >
                                Cadastrar novo grupo de cores
                            </Button>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={groups}
                                getOptionLabel={(group) => group.name}
                                value={selectedGroup}
                                onChange={(event, newValue) => {
                                    setSelectedGroup(newValue);
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Pesquisar Grupos de cores" />}
                            />
                        </Box>
                        {!loading && groups.length > 0 && (
                            <>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ pl: 3 }} />
                                                <TableCell>ID</TableCell>
                                                <TableCell align="right">Nome do Grupo</TableCell>
                                                <TableCell align="right">Ações</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {groups.length > 0 &&
                                                groups
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .filter((group) => (selectedGroup ? group?.name.includes(selectedGroup?.name) : true))
                                                    .map((item, index) => <Row key={index} group={item} getGroups={getGroups} />)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {groups.length > 5 && (
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={groups.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                )}
                            </>
                        )}
                    </Grid>
                )}

                {formOpen && (
                    <Grid item xs={12} sm={12}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setFormOpen(!formOpen);
                                }}
                            >
                                Ver todos os grupos
                            </Button>
                        </Box>
                        <form style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <Typography sx={{ fontSize: '20px' }}>Cadastrar novo grupo</Typography>
                            <TextField
                                id="outlined-select-currency"
                                label="Nome do grupo"
                                variant="outlined"
                                error={error}
                                value={name}
                                helperText={error ? 'Este campo não pode ficar vazio ou ter o mesmo valor de um campo já existente' : null}
                                onChange={(event) => {
                                    setName(event.target.value);
                                    setError(false);
                                }}
                            />

                            <Button variant="contained" color="primary" type="submit" onClick={(e) => handleSubmit(e)}>
                                cadastrar
                            </Button>
                        </form>
                        <ModalError isOpen={isOpen} handleClose={handleCloseModal} text={errorMensage} />
                    </Grid>
                )}
            </Grid>
        </MainCard>
    );
}

export default GroupColor;
