import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { toast } from 'react-toastify';

const EditCloseQuestion = ({ open, setOpen, get, currentAnswer }) => {
    const api = useApi();

    const initialState = {
        formula: '',
        description: currentAnswer?.description,
        value: currentAnswer?.value,
        product: { id: currentAnswer?.productId },
        publicDescription: currentAnswer?.descriptionPub
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: '8px',
        maxHeight: '650px',
        overflow: 'auto'
    };

    const [fields, setFields] = useState([initialState]);
    const [products, setProducts] = useState([]);

    const getAllProducts = async () => {
        try {
            const { data } = await api.getAllProducts();
            setProducts(data);
        } catch (error) {
            console.log('🚀 ~ file: AddNewCloseAnswer.js:38 ~ getAllProducts ~ error:', error);
        }
    };

    useEffect(() => {
        getAllProducts();
    }, []);

    //function referente ao fields
    const atualizarCampo = (indiceCampo, campo, valor) => {
        setFields((prevCampos) => {
            const novosCampos = [...prevCampos];
            if (campo === 'value') {
                novosCampos[indiceCampo][campo] = Number(valor);
            } else {
                novosCampos[indiceCampo][campo] = valor;
            }

            if (campo === 'product') {
                novosCampos[indiceCampo][campo] = { id: Number(valor) };
            }
            return novosCampos;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(fields);
        const payload = {
            formula: fields[0].formula,
            description: fields[0].description,
            value: fields[0].value,
            product: fields[0].product,
            publicDescription: fields[0].publicDescription
        };

        try {
            await api.updateAnswer(currentAnswer.id, payload);
            toast.success('Resposta editada com sucesso!');
            setOpen(false);
            get();
        } catch (error) {
            console.log('🚀 ~ file: EditCloseQuestion.js:80 ~ handleSubmit ~ error:', error);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setFields([initialState]);
    };

    useEffect(() => {
        setFields([initialState]);
    }, [currentAnswer]);

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3>Editar resposta</h3>

                    <form onSubmit={(e) => handleSubmit(e)}>
                        {fields?.map((field, index) => (
                            <Box key={index} sx={{ border: '1px solid #ccc', padding: '5px', marginTop: '15px', borderRadius: '8px' }}>
                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '10px' }}>
                                    <FormControl sx={{ width: '48%', mb: '15px' }}>
                                        <TextField
                                            label="Valor Associado"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            value={field.value}
                                            onChange={(e) => atualizarCampo(index, 'value', e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ width: '48%', mb: '15px' }}>
                                        <InputLabel id="product">Produto Associado</InputLabel>
                                        <Select
                                            labelId="product"
                                            label="Produto Associado"
                                            value={field.product?.id || ''}
                                            onChange={(e) => atualizarCampo(index, 'product', e.target.value)}
                                        >
                                            <MenuItem value="" disabled>
                                                Selecione um produto
                                            </MenuItem>
                                            {products.map((product) => (
                                                <MenuItem key={product.id} value={product.id}>
                                                    {product.productName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <FormControl sx={{ width: '48%', mb: '15px' }}>
                                        <TextField
                                            label="Descrição privada"
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            value={field.description}
                                            onChange={(e) => atualizarCampo(index, 'description', e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ width: '48%', mb: '15px' }}>
                                        <TextField
                                            label="Descrição pública"
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            value={field.publicDescription}
                                            onChange={(e) => {
                                                atualizarCampo(index, 'publicDescription', e.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        ))}

                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                            <Button variant="contained" type="submit">
                                Salvar Resposta
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
};

export default EditCloseQuestion;
