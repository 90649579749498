import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Badge, Box, Modal } from '@mui/material';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList({ budgetId, observationArray, handleGetObservationById, isFinished }) {
    const api = useApi();
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    async function getAllCustomObservations() {
        try {
            const { data } = await api.getCustomObservation();

            if (observationArray.length > 0) {
                const left = data.filter((item) => observationArray.some((element) => element.standardNoteId === item.id));
                const right = data.filter((item) => !observationArray.some((element) => element.standardNoteId === item.id));
                setLeft(left);
                setRight(right);
            } else {
                setRight(data);
                setLeft([]);
            }
        } catch (error) {
            console.log('🚀 ~ getAllCustomObservations ~ error:', error);
        }
    }

    useEffect(() => {
        getAllCustomObservations();
    }, []);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items) => (
        <Paper sx={{ width: 470, height: 530, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItemButton key={value} sx={{ border: '1px solid #ccc' }} role="listitem" onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.observation} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Paper>
    );

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 4
    };

    async function deleteAssociation() {
        try {
            await api.deleteObservationsAssosiation(budgetId);
            if (left.length > 0) {
                handleSelected();
            } else {
                handleGetObservationById();
                handleClose();
            }
        } catch (error) {
            console.log('🚀 ~ deleteAssociation ~ error:', error);
        }
    }

    async function handleSelected() {
        const toastId = toast.loading('Salvando observações ...');
        const payload = left.map((item, index) => ({
            standardNote: {
                id: item.id
            },
            budgetClientModel: {
                id: budgetId
            },
            position: index
        }));

        try {
            await api.createAssociations(payload);
            handleGetObservationById();
            handleClose();
            toast.update(toastId, { render: 'Observações salvas com sucesso!', type: 'success', isLoading: false, autoClose: 3000 });
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao salvar observações!', type: 'error', isLoading: false, autoClose: 3000 });
            console.log('🚀 ~ handleSelected ~ error:', error);
        }
    }

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Badge badgeContent={observationArray.length} color="secondary">
                <Button onClick={handleOpen} variant="contained" disabled={isFinished}>
                    Inserir observações
                </Button>
            </Badge>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h2 style={{ textAlign: 'center' }}>Observações</h2>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box>
                            <h3>Selecionados</h3>
                            <Grid item>{customList(left)}</Grid>
                        </Box>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllRight}
                                    disabled={left.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllLeft}
                                    disabled={right.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                            </Grid>
                        </Grid>
                        <Box>
                            <h3>Não selecionados</h3>
                            <Grid item>{customList(right)}</Grid>
                        </Box>
                    </Box>
                    <Button onClick={deleteAssociation} variant="contained" color="secondary">
                        Salvar selecionadas
                    </Button>
                </Box>
            </Modal>
        </Grid>
    );
}
