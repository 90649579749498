import { Modal, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function ModalError({ isOpen, handleClose, text }) {
    return (
        <>
            <Modal open={isOpen} onClose={handleClose}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '1rem',
                        padding: '1rem',
                        backgroundColor: 'white',
                        maxWidth: '300px',
                        margin: 'auto',
                        marginTop: '20vh',
                        borderRadius: '10px',
                        height: '300px'
                    }}
                >
                    <ErrorOutlineIcon style={{ fontSize: '100px' }} />
                    <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}>{text}</Typography>
                </div>
            </Modal>
        </>
    );
}

export default ModalError;
