import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

export default function ControlledAccordions() {
    const [expandedPanel1, setExpandedPanel1] = useState(false);
    const [expandedPanel2, setExpandedPanel2] = useState(false);
    const [expandedPanel3, setExpandedPanel3] = useState(false);

    const handleChangePanel1 = () => {
        setExpandedPanel1(!expandedPanel1);
    };

    const handleChangePanel3 = () => {
        setExpandedPanel3(!expandedPanel3);
    };

    const handleChangePanel2 = () => {
        setExpandedPanel2(!expandedPanel2);
    };

    return (
        <div style={{ width: '100%' }}>
            <Accordion expanded={expandedPanel1} onChange={handleChangePanel1}>
                <AccordionSummary
                    sx={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        gap: '30px',
                        marginLeft: '20px'
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>17048</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Máquina de lavar louça 8 func</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer sx={{ margin: '0', marginLeft: '15px' }}>
                        <Table sx={{ margin: 0 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Código</TableCell>
                                    <TableCell align="right">Descrição</TableCell>
                                    <TableCell align="right">UN</TableCell>
                                    <TableCell align="right">QNT</TableCell>
                                    <TableCell align="right">Saldo</TableCell>
                                    <TableCell align="right">Compra</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        1
                                    </TableCell>
                                    <TableCell align="right">2</TableCell>
                                    <TableCell align="right">3</TableCell>
                                    <TableCell align="right">4</TableCell>
                                    <TableCell align="right">5</TableCell>
                                    <TableCell align="right">5</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Accordion expanded={expandedPanel3} onChange={handleChangePanel3}>
                        <AccordionSummary
                            sx={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                gap: '30px',
                                marginLeft: '60px'
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>17433</Typography>
                            <Typography sx={{ color: 'text.secondary' }}>Álcool para limpesa profunda</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer sx={{ width: '100%' }}>
                                <Table sx={{ margin: 0, maxWidth: '1000px', marginLeft: '50px' }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Descrição</TableCell>
                                            <TableCell>UN</TableCell>
                                            <TableCell>QNT</TableCell>
                                            <TableCell>Saldo</TableCell>
                                            <TableCell>Compra</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>1</TableCell>
                                            <TableCell>2</TableCell>
                                            <TableCell>3</TableCell>
                                            <TableCell>4</TableCell>
                                            <TableCell>5</TableCell>
                                            <TableCell>5</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Accordion expanded={expandedPanel2} onChange={handleChangePanel2}>
                                <AccordionSummary
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row-reverse',
                                        gap: '30px',
                                        marginLeft: '90px'
                                    }}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>17433</Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>Álcool para limpesa profunda</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer sx={{ width: '100%' }}>
                                        <Table
                                            sx={{ margin: 0, maxWidth: '950px', marginLeft: '80px' }}
                                            size="small"
                                            aria-label="a dense table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Código</TableCell>
                                                    <TableCell>Descrição</TableCell>
                                                    <TableCell>UN</TableCell>
                                                    <TableCell>QNT</TableCell>
                                                    <TableCell>Saldo</TableCell>
                                                    <TableCell>Compra</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>1</TableCell>
                                                    <TableCell>2</TableCell>
                                                    <TableCell>3</TableCell>
                                                    <TableCell>4</TableCell>
                                                    <TableCell>5</TableCell>
                                                    <TableCell>5</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
