import { Box } from '@mui/system';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import {
    Button,
    Divider,
    Drawer,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    TextField,
    Autocomplete
} from '@mui/material';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

const DrawerInsert = ({ open, setOpen }) => {
    const navigate = useNavigate();
    const api = useApi();
    const userInfo = localStorage.getItem('userInfo');
    const userInfoObject = JSON.parse(userInfo);
    //stats
    const [costumer, setCostumer] = useState([]);
    const [seller, setSeller] = useState([]);
    const [representative, setRepresentative] = useState([]);
    const [representativeSelected, setRepresentativeSelected] = useState([]);
    const [paymentTerms, setPaymentTerms] = useState([]);
    const [freightState, setFreightState] = useState([]);
    // get's
    const getCostumer = async () => {
        try {
            const res = await api.getAllCostumers();
            setCostumer(res.data);
        } catch (error) {
            console.log('🚀 ~ file: index.js:50 ~ getCostumer ~ error:', error.message);
        }
    };

    const getSellers = async () => {
        try {
            const res = await api.getAllUsers();
            const seller = res.data.filter((item) => item.role === 'Vendedor' || item.role === 'Gestor Comercial');
            const representative = res.data.filter((item) => item.role === 'Representante');

            setRepresentative(representative);

            //permitindo apenas o vendedor logado
            const sellerFilter = seller.filter((item) => item.id === userInfoObject.id);

            if (sellerFilter.length === 0) {
                setSeller(seller);
            } else {
                setSeller(sellerFilter);
            }

            //permitindo apenas representante logado
            if (userInfoObject?.role === 'Representante') {
                const representativeFilter = representative.filter((item) => item.id === userInfoObject?.id);
                setRepresentative(representativeFilter);

                const sellerFiltered = res.data.filter((item) => item.id === userInfoObject?.user?.id);
                setSeller(sellerFiltered);
            }

            if (userInfoObject?.role === 'Gestor Comercial') {
                setSeller(res.data.filter((item) => item.role === 'Vendedor' || item.role === 'Gestor Comercial'));
            }
        } catch (error) {
            console.log('🚀 ~ file: index.js:65 ~ getSellers ~ error:', error.message);
        }
    };

    const getPaymentTerms = async () => {
        try {
            const res = await api.getPaymentsTerms();
            setPaymentTerms(res.data);
        } catch (error) {
            console.log('🚀 ~ file: index.js:81 ~ getPaymentTerms ~ error:', error.message);
        }
    };

    const getFreight = async () => {
        try {
            const res = await api.getStatic();
            setFreightState(res.data);
        } catch (error) {
            console.log('🚀 ~ file: index.js:90 ~ getFreight ~ error:', error.message);
        }
    };

    useEffect(() => {
        getCostumer();
        getSellers();
        getPaymentTerms();
        getFreight();
    }, []);

    // funcao para enviar os dados do usuario para a simulação
    const handleData = async (values) => {
        const toastId = toast.loading('Cadastrando orçamento...');
        try {
            const data = {
                comission: values.commission ? values.commission : representativeSelected.commission,
                delivery: values.freight ? values.freight : freightState?.deliveryPercentage,
                observation: values.description,
                packaging: Number(values.packaging),
                construction: values.construction ? values.construction : 'Não informado'
            };
            const res = await api.insertBudgetClient(values.costumer, values.seller, values.representative, values.paymentTerms, data);
            navigate(`/main/novo-orcamento/${res.data.id}/0`);
            toast.update(toastId, {
                render: 'Orçamento cadastrado com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
        } catch (error) {
            console.log('🚀 ~ file: DrawerInsert.js:86 ~ handleData ~ error:', error.message);
            toast.update(toastId, {
                render: 'Erro ao cadastrar orçamento!',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    };

    const handleKeyPress = (event) => {
        // Verifica se a tecla pressionada é uma vírgula e impede sua entrada
        if (event.key === ',') {
            event.preventDefault();
        }
    };

    return (
        <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
            <Formik
                initialValues={{
                    costumer: '',
                    seller: '',
                    representative: '',
                    commission: '',
                    freight: '',
                    paymentTerms: '',
                    description: '',
                    packaging: '',
                    construction: ''
                }}
                validationSchema={Yup.object().shape({
                    costumer: Yup.string().max(255).required('Obrigatório'),
                    seller: Yup.string().max(255).required('Obrigatório'),
                    // representative: Yup.string().max(255).required('Obrigatório'),
                    paymentTerms: Yup.string().max(255).required('Obrigatório'),
                    // commission: Yup.string().max(255).required('Obrigatório'),
                    freight: Yup.string().max(255).required('Obrigatório'),
                    packaging: Yup.string().max(255).required('Obrigatório')
                })}
                onSubmit={async (values) => {
                    handleData(values);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (
                    <form
                        onSubmit={handleSubmit}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '100%',
                            gap: '16px',
                            padding: '20px'
                        }}
                    >
                        <h2>Iniciar orçamento</h2>
                        <div>
                            <FormControl fullWidth error={Boolean(touched.costumer && errors.costumer)}>
                                <Autocomplete
                                    id="costumer"
                                    name="costumer"
                                    options={costumer}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: 'costumer',
                                                value: newValue ? newValue.id : ''
                                            }
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Cliente *"
                                            variant="outlined"
                                            error={Boolean(touched.costumer && errors.costumer)}
                                            helperText={touched.costumer && errors.costumer}
                                        />
                                    )}
                                    sx={{ width: '100%', marginRight: '20px' }}
                                />
                            </FormControl>
                        </div>

                        <div>
                            <TextField
                                sx={{ width: '100%' }}
                                id="construction"
                                label="Obra"
                                value={values.construction}
                                onChange={handleChange}
                                name="construction"
                            />
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                            <div>
                                <FormControl fullWidth error={Boolean(touched.seller && errors.seller)}>
                                    <Autocomplete
                                        id="seller"
                                        name="seller"
                                        options={seller}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {
                                            handleChange({
                                                target: {
                                                    name: 'seller',
                                                    value: newValue ? newValue.id : ''
                                                }
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Vendedor *"
                                                variant="outlined"
                                                error={Boolean(touched.seller && errors.seller)}
                                                helperText={touched.seller && errors.seller}
                                            />
                                        )}
                                        sx={{ width: '300px' }}
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="representative"
                                        name="representative"
                                        options={representative}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {
                                            setRepresentativeSelected(newValue ? newValue.id : '');
                                            setFieldValue('representative', newValue ? newValue.id : '');
                                            setFieldValue('commission', newValue ? newValue.commission : '');
                                            handleChange({
                                                target: {
                                                    name: 'representative',
                                                    value: newValue ? newValue.id : ''
                                                }
                                            });
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Representante" variant="outlined" />}
                                        sx={{ width: '300px' }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                            <div>
                                <FormControl>
                                    <InputLabel htmlFor="% de comissão">% de Comissão</InputLabel>
                                    <OutlinedInput
                                        label="% de Comissão"
                                        id="% de comissão"
                                        value={values.commission || representativeSelected.commission || ''}
                                        InputLabelProps={{ shrink: true }}
                                        name="commission"
                                        disabled={userInfoObject.role === 'Representante'}
                                        sx={{ width: '130px' }}
                                        onKeyPress={handleKeyPress}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setFieldValue('commission', e.target.value);
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl error={Boolean(touched.freight && errors.freight)}>
                                    <InputLabel htmlFor="freight">% de Frete *</InputLabel>
                                    <OutlinedInput
                                        label="% de Frete *"
                                        labelId="% de Frete"
                                        InputLabelProps={{ shrink: true }}
                                        value={values.freight || freightState?.deliveryPercentage || ''}
                                        name="freight"
                                        sx={{ width: '130px' }}
                                        onKeyPress={handleKeyPress}
                                        onChange={handleChange}
                                    />
                                    {touched.freight && errors.freight && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.freight}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </div>
                            <div>
                                <FormControl error={Boolean(touched.packaging && errors.packaging)}>
                                    <InputLabel htmlFor="packaging">% de Embalagem *</InputLabel>
                                    <OutlinedInput
                                        labelId="% de Embalagem"
                                        label="% de Embalagem *"
                                        sx={{ width: '160px' }}
                                        value={values.packaging}
                                        onChange={handleChange}
                                        onKeyPress={handleKeyPress}
                                        name="packaging"
                                        inputProps={{ pattern: '[^,]*', title: 'Não é permitido inserir a vírgula (,)' }} // Adiciona validação diretamente no input
                                    />
                                    {touched.packaging && errors.packaging && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.packaging}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </div>
                            <div>
                                <FormControl fullWidth error={Boolean(touched.paymentTerms && errors.paymentTerms)}>
                                    <Autocomplete
                                        id="paymentTerms"
                                        name="paymentTerms"
                                        options={paymentTerms}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {
                                            handleChange({
                                                target: {
                                                    name: 'paymentTerms',
                                                    value: newValue ? newValue.id : ''
                                                }
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Cond. Pagamentos *"
                                                variant="outlined"
                                                error={Boolean(touched.paymentTerms && errors.paymentTerms)}
                                                helperText={touched.paymentTerms && errors.paymentTerms}
                                            />
                                        )}
                                        sx={{ width: '250px' }}
                                    />
                                </FormControl>
                            </div>
                        </div>

                        <div>
                            <TextField
                                sx={{ width: '100%' }}
                                id="observations"
                                label="Observaçães do orçamento"
                                multiline
                                rows={6}
                                value={values.description}
                                onChange={handleChange}
                                name="description"
                            />
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '80px' }}>
                            <Button type="submit" sx={{ width: '300px' }} variant="contained">
                                Cadastrar
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
        </Drawer>
    );
};

export default DrawerInsert;
