import {
    Autocomplete,
    Button,
    Grid,
    InputAdornment,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import ActionsButtonsFirstTable from './components/ActionsButtonFirstTable';
import DrawerInsertRo from './components/DrawerInsertRo';
import ConfirmModal from 'ui-component/ConfirmModal';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import AddchartIcon from '@mui/icons-material/Addchart';
import DeleteIcon from '@mui/icons-material/Delete';
import { PDFViewer } from '@react-pdf/renderer';
import PdfRecord from './components/PdfRecor';
import { Print } from '@mui/icons-material';

const OperationalResult = () => {
    const navigate = useNavigate();
    const [openInsertRo, setOpenInsertRo] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [filteredQuiz, setFilteredQuiz] = useState([]);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [currentRow, setCurrentRow] = useState('');
    const [openReportModal, setOpenReportModal] = useState(false);

    const api = useApi();
    const getAllQuiz = async () => {
        try {
            const { data } = await api.getQuizForRo();
            setFilteredQuiz(data);
        } catch (err) {
            console.log(err.message);
        }
    };

    //new r.o
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const getNewRo = async () => {
        try {
            const { data } = await api.getRov2(page);

            const newTableData = data?.payload?.map((item) => ({
                id: item.id,
                quiz: item.productName,
                un: item.unitSize,
                gp: item.groupDescription
            }));

            setTableData((prevData) => [...prevData, ...newTableData]);

            if (newTableData.length === 0) {
                setHasMore(false);
            } else {
                setPage((prevPage) => prevPage + 1);
            }
        } catch (error) {
            console.log('🚀 ~ file: index.js:74 ~ getNewRo ~ error:', error);
            setHasMore(false);
        }
    };

    useEffect(() => {
        getAllQuiz();
        getNewRo();
    }, []);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '90%',
        boxShadow: 24
    };

    const handleDelete = async () => {
        try {
            await api.deleteRo(currentRow);
            toast.success('R.O deletado com sucesso!');
            window.location.reload();
        } catch (error) {
            console.log('🚀 ~ file: ActionsButtonFirstTable.js:20 ~ handleDelete ~ error:', error);
            toast.error('Delete todas as formulas antes de deletar este R.O');
        }
    };
    const handleEdit = (id) => {
        navigate(`/main/comercial/detalhes-ro/${id}`);
    };
    return (
        <MainCard title="Resultado Operacional">
            <ConfirmModal
                open={openConfirm}
                setOpen={setOpenConfirm}
                firstMessage={'Tem certeza que deseja deletar esse R.O?'}
                secondMessage={'Essa ação não poderá ser desfeita.'}
                button1={'Cancelar'}
                button2={'Confirmar'}
                func={handleDelete}
            />
            <DrawerInsertRo open={openInsertRo} setOpen={setOpenInsertRo} quizList={filteredQuiz} getTable={getNewRo} />
            <Grid container spacing={gridSpacing} sx={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
                <Box>
                    <Button
                        variant="contained"
                        sx={{ width: '200px', marginBottom: '50px', marginTop: '20px' }}
                        onClick={() => setOpenInsertRo(true)}
                        disabled={filteredQuiz?.length === 0}
                    >
                        {filteredQuiz?.length === 0 ? 'Aguarde...' : 'Inserir'}
                    </Button>
                </Box>

                <Box style={{ width: '100%', overflow: 'auto' }}>
                    <InfiniteScroll
                        dataLength={tableData.length}
                        next={getNewRo}
                        hasMore={hasMore}
                        loader={<h4 style={{ textAlign: 'center' }}>Carregando...</h4>}
                    >
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Questionário</TableCell>
                                        <TableCell>Unidade</TableCell>
                                        <TableCell>Grupo(s)</TableCell>
                                        <TableCell>Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData
                                        .sort((a, b) => a.id - b.id)
                                        .map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.quiz}</TableCell>
                                                <TableCell>{row.un}</TableCell>
                                                <TableCell>{row.gp}</TableCell>
                                                <TableCell>
                                                    <div style={{ display: 'flex', gap: '10px', cursor: 'pointer' }}>
                                                        <Tooltip
                                                            title="Consultar"
                                                            onClick={() => {
                                                                handleEdit(row.id);
                                                            }}
                                                        >
                                                            <AddchartIcon />
                                                        </Tooltip>
                                                        <Tooltip
                                                            title="Deletar"
                                                            onClick={() => {
                                                                setOpenConfirm(true);
                                                                setCurrentRow(row.id);
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </Tooltip>
                                                        <Tooltip
                                                            title="Relatório"
                                                            onClick={() => {
                                                                setOpenReportModal(true);
                                                                setCurrentRow(row);
                                                            }}
                                                        >
                                                            <Print />
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </InfiniteScroll>
                </Box>

                <Modal
                    open={openReportModal}
                    onClose={() => setOpenReportModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <PDFViewer width={'100%'} height={'100%'}>
                            <PdfRecord currentRow={currentRow} />
                        </PDFViewer>
                    </Box>
                </Modal>
            </Grid>
        </MainCard>
    );
};

export default OperationalResult;
