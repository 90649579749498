import { Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { useApi } from 'Service/axios';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { useState } from 'react';
import { IconPencil } from '@tabler/icons';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmModal from 'ui-component/ConfirmModal';
import { toast } from 'react-toastify';
import EditCloseQuestion from './components/EditCloseQuestion';
import AddMoreAnswers from './components/AddMoreAnswers';

const QuestionPageId = () => {
    const api = useApi();
    const { id } = useParams();
    const [rows, setRows] = useState([]);
    const [currentId, setCurrentId] = useState(null);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [questionData, setQuestionData] = useState(null);
    const [openEditAnswer, setOpenEditAnswer] = useState(false);
    const [currentAnswer, setCurrentAnswer] = useState(null);
    const [openAddMoreAnswers, setOpenAddMoreAnswers] = useState(false);
    const [editQuestionName, setEditQuestionName] = useState(false);
    const [newQuestionName, setNewQuestionName] = useState('');

    useEffect(() => {
        setNewQuestionName(questionData?.description);
    }, [questionData]);

    const getCurrentQuestion = async () => {
        try {
            const { data } = await api.getQuestionAndAnswers(id);
            console.log('🚀 ~ file: index.js:14 ~ getCurrentQuestion ~ data:', data);
            setQuestionData(data.question);
            const row = data.answers.map((item, index) => ({
                ...item,
                id: item?.id,
                description: item?.description,
                descriptionPub: item?.publicDescription,
                product: item?.product?.productName,
                value: item?.value,
                productId: item?.product?.id,
                sequence: index + 1
            }));
            setRows(row);
        } catch (error) {
            console.log('🚀 ~ file: index.js:13 ~ getCurrentQuestion ~ error:', error);
        }
    };

    useEffect(() => {
        getCurrentQuestion();
    }, []);

    const columns = [
        { field: 'sequence', headerName: 'ID', width: 70 },
        { field: 'product', headerName: 'Produto associado', width: 300 },
        { field: 'description', headerName: 'Descrição Privada', width: 300 },
        { field: 'descriptionPub', headerName: 'Descrição Publica', width: 300 },
        { field: 'value', headerName: 'Valor', width: 70 },
        {
            field: 'actions',
            headerName: 'Ações',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Editar">
                        <IconButton
                            onClick={() => {
                                setCurrentAnswer(params.row);
                                setOpenEditAnswer(true);
                            }}
                        >
                            <IconPencil />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title="Excluir"
                        onClick={() => {
                            setOpenConfirmModal(true);
                            setCurrentId(params.row.id);
                        }}
                    >
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
    ];

    const handleDelete = async () => {
        try {
            await api.deleteAnswer(currentId);
            getCurrentQuestion();
            toast.success('Resposta deletada com sucesso!');
        } catch (error) {
            console.log('🚀 ~ file: index.js:71 ~ handleDelete ~ error:', error);
            toast.error('Verifique se essa resposta está atrelada a algum outro local do sistema!!');
        }
    };

    const handleEditQuestionName = async () => {
        const payload = { description: newQuestionName };

        try {
            await api.updateQuestionV2(questionData?.id, payload);
            toast.success('Nome da pergunta editado com sucesso!');
            setEditQuestionName(false);
            getCurrentQuestion();
        } catch (error) {
            console.log(error.message);
            toast.error('Verifique se esse nome já está sendo usado!');
        }
    };

    return (
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <div style={{ width: '100%', marginLeft: '10px', display: 'flex', gap: '15px', marginTop: '15px' }}>
                    {editQuestionName === false && <h3>{questionData?.description}</h3>}
                    {editQuestionName && (
                        <TextField
                            sx={{ width: '250px' }}
                            label="Editar pergunta"
                            value={newQuestionName}
                            onChange={(event) => setNewQuestionName(event.target.value)}
                        />
                    )}
                    <Tooltip title="Editar nome da pergunta">
                        <IconButton
                            onClick={() => {
                                if (editQuestionName === false) {
                                    setEditQuestionName(true);
                                }

                                if (editQuestionName) {
                                    handleEditQuestionName();
                                }
                            }}
                        >
                            <IconPencil />
                        </IconButton>
                    </Tooltip>
                </div>

                <EditCloseQuestion
                    open={openEditAnswer}
                    setOpen={setOpenEditAnswer}
                    get={getCurrentQuestion}
                    currentAnswer={currentAnswer}
                />
                <ConfirmModal
                    open={openConfirmModal}
                    setOpen={setOpenConfirmModal}
                    button1={'Cancelar'}
                    button2={'Deletar'}
                    firstMessage={'Deseja realmente deletar essa pergunta ?'}
                    secondMessage={'Verifique se essa resposta está atrelada a algum outro local do sistema!'}
                    func={handleDelete}
                />
                <AddMoreAnswers
                    open={openAddMoreAnswers}
                    question={questionData}
                    setOpen={setOpenAddMoreAnswers}
                    get={getCurrentQuestion}
                />
                <div style={{ margin: '20px 5px' }}>
                    <Button variant="contained" onClick={() => setOpenAddMoreAnswers(true)}>
                        Cadastrar nova resposta
                    </Button>
                </div>
                <div
                    style={{
                        maxHeight: 400,
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%'
                    }}
                >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={7} // Defina o tamanho da página aqui
                        rowsPerPageOptions={[5, 10, 20]} // Opções de tamanho de página
                        pagination
                        checkboxSelection={false}
                        sx={{ maxWidth: 1200 }}
                    />
                </div>
            </Grid>
        </MainCard>
    );
};

export default QuestionPageId;
