import { Box, Skeleton } from '@mui/material';

const SkeletonDashCards = () => {
    return (
        <Box sx={{ display: 'flex', gap: '20px' }}>
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ width: '260px', height: '80px', backgroundColor: '#ccc', borderRadius: '8px' }}
            />
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ width: '260px', height: '80px', backgroundColor: '#ccc', borderRadius: '8px' }}
            />{' '}
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ width: '260px', height: '80px', backgroundColor: '#ccc', borderRadius: '8px' }}
            />{' '}
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ width: '260px', height: '80px', backgroundColor: '#ccc', borderRadius: '8px' }}
            />{' '}
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ width: '260px', height: '80px', backgroundColor: '#ccc', borderRadius: '8px' }}
            />
        </Box>
    );
};

export default SkeletonDashCards;
