import PropTypes from 'prop-types';

// material-ui
import { Card, CardContent, Grid, Typography } from '@mui/material';

// ===========================|| HOVER SOCIAL CARD ||=========================== //

const HoverSocialCard = ({ primary, secondary, iconPrimary, color, sx, title }) => {
    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary ? <IconPrimary fontSize="large" /> : null;

    return (
        <Card
            sx={{
                ...sx,
                background: color,
                position: 'relative',
                color: '#fff',
                height: '70px',
                minWidth: '170px',
                padding: '0px',
                '&:hover svg': {
                    opacity: '1',
                    transform: 'scale(1.1)'
                }
            }}
        >
            <CardContent title={title}>
                <Typography
                    variant="body2"
                    sx={{
                        position: 'absolute',
                        right: 22,
                        top: 15,
                        color: '#fff',
                        '&> svg': {
                            width: 40,
                            height: 40,
                            opacity: '0.4',
                            transition: 'all .3s ease-in-out'
                        }
                    }}
                >
                    {primaryIcon}
                </Typography>
                <Grid container spacing={0} sx={{ marginTop: '-10px' }}>
                    <Grid item xs={8}>
                        <Typography variant="h2" color="inherit">
                            {secondary}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="subtitle2" color="inherit">
                            {primary}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

HoverSocialCard.propTypes = {
    primary: PropTypes.string,
    secondary: PropTypes.string,
    iconPrimary: PropTypes.object,
    color: PropTypes.string
};

export default HoverSocialCard;
