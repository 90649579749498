import { Fab, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate, useParams } from 'react-router';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { formatarNumero } from 'utils/formatarNumeroReal';
import { formatarData } from 'utils/regex';
import ReplyIcon from '@mui/icons-material/Reply';

const Expenses = () => {
    const navigate = useNavigate();

    const { id } = useParams();
    const api = useApi();
    const [rows, setRows] = useState([]);

    const getExpenses = async () => {
        try {
            const { data } = await api.getExpenses(id);
            const row = data.map((item, index) => ({
                ...item,
                data: formatarData(item.dtMovimento),
                cod: item.codConsumo,
                desc: item.descConsumo,
                un: item.unConsumo,
                qtd: item.qtdConsumo,
                d_fixa: item.despFixa,
                d_variavel: item.despVariavel,
                ggf: item.ggf,
                depreciacao: item.depreciacao,
                vunit: formatarNumero(item.mvlrUnitConsumo),
                vtotal: formatarNumero(item.mvlrTotalConsumo),
                analise: formatarNumero(item.analiseConsumo),
                id: index
            }));
            setRows(row);
        } catch (error) {
            console.log('🚀 ~ file: index.js:19 ~ getExpenses ~ error:', error);
        }
    };
    useEffect(() => {
        getExpenses();
    }, []);

    const columns = [
        { field: 'data', headerName: 'Data', width: 130 },
        { field: 'cod', headerName: 'Código', width: 60 },
        { field: 'desc', headerName: 'Descrição', type: 'number', width: 330, align: 'center' },
        { field: 'un', headerName: 'UNID', type: 'number', width: 100 },
        { field: 'qtd', headerName: 'QTDE', type: 'number', width: 100 },
        { field: 'd_fixa', headerName: 'D. fixa', type: 'number', width: 100 },
        { field: 'd_variavel', headerName: 'D. Variável', type: 'number', width: 120 },
        { field: 'ggf', headerName: 'GGF', type: 'number', width: 100 },
        { field: 'depreciacao', headerName: 'Depreciação', type: 'number', width: 130 },
        { field: 'vunit', headerName: 'V. Uni.', type: 'number', width: 90 },
        { field: 'vtotal', headerName: 'V. Total.', type: 'number', width: 90 },
        { field: 'analise', headerName: 'Análise', type: 'number', width: 90 }
    ];

    return (
        <MainCard title={`Despesas - Projeto ${id}`}>
            <Fab
                onClick={() => navigate(-1)}
                variant="extended"
                sx={{ position: 'fixed', bottom: '50px', display: 'flex', alignItems: 'center', gap: '5px' }}
            >
                <ReplyIcon />
                voltar
            </Fab>
            <Grid container spacing={gridSpacing} sx={{ display: 'flex', flexDirection: 'column' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        margin: 'auto',
                        marginTop: '10px',
                        overflow: 'auto'
                    }}
                >
                    <DataGrid columns={columns} rows={rows} hideFooter autoHeight sx={{ maxWidth: '1380px', marginLeft: '5px' }} />
                </div>
            </Grid>
        </MainCard>
    );
};

export default Expenses;
