export const formatarValorIntl = (valor) => {
    // Cria uma instância de Intl.NumberFormat para o Real Brasileiro
    const formatoMoeda = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });

    // Formata o valor usando a instância criada
    return formatoMoeda.format(valor);
};
