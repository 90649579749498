import { Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router';
import Labor from './Labor';
import ExpensesModal from './ExpensesModal';
import ConsumptionModal from './ConsumptionModal';
import TaxesModal from './TaxesModal';
const Actions = ({ params, currentMode }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const [openLabor, setOpenLabor] = useState(false);
    const [currentProject, setCurrentProject] = useState(null);
    const [openExpenses, setOpenExpenses] = useState(false);
    const [openConsumption, setOpenConsumption] = useState(false);
    const [openTaxes, setOpenTaxes] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Labor currentMode={currentMode} open={openLabor} setOpen={setOpenLabor} dataParams={params} />

            <ExpensesModal currentMode={currentMode} open={openExpenses} setOpen={setOpenExpenses} dataParams={params} />

            <ConsumptionModal currentMode={currentMode} open={openConsumption} setOpen={setOpenConsumption} dataParams={params} />

            <TaxesModal currentMode={currentMode} open={openTaxes} setOpen={setOpenTaxes} dataParams={params} />

            <Button
                id={`button-${params.id}`}
                aria-controls={open ? `menu-${params.id}` : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="contained"
                //Renderizar uma das tres cores que vem do params.status
                color={params.status === 'VERDE' ? 'success' : params.status === 'AMARELO' ? 'warning' : 'error'}
            >
                <MoreVertIcon />
            </Button>
            <Menu
                id={`menu-${params.id}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': `button-${params.id}`
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setOpenConsumption(true);
                    }}
                >
                    Consumo
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setOpenExpenses(true);
                    }}
                >
                    Despesas
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setOpenTaxes(true);
                    }}
                >
                    Impostos
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setOpenLabor(true);
                    }}
                >
                    Mão de obra
                </MenuItem>
            </Menu>
        </div>
    );
};

export default Actions;
