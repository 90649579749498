import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px'
};

export default function NewFormulaModal({ newFormula, setNewFormula, closeAllModal, savingPosition }) {
    const handleClose = () => setNewFormula(false);

    return (
        <div>
            <Modal open={newFormula} onClose={handleClose}>
                <Box sx={style}>
                    <h3 style={{ margin: 0 }}>
                        Sua formula foi salva com <span style={{ color: '#2196f3' }}>sucesso!</span>{' '}
                    </h3>
                    <span style={{ display: 'block', marginTop: '5px', marginBottom: '20px' }}>
                        Deseja adicionar uma nova formula a essa questão ?
                    </span>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                        <Button
                            onClick={() => {
                                closeAllModal(false);
                                savingPosition();
                            }}
                            variant="text"
                        >
                            Ir para o questionário
                        </Button>
                        <Button variant="contained" onClick={handleClose}>
                            Adicionar nova formula
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
