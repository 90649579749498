import { IconBrandTrello } from '@tabler/icons';

const icons = {
    IconBrandTrello
};

const costs = {
    id: 'costs',
    title: 'Custos',
    type: 'group',
    children: [
        {
            id: 'costs-project',
            title: 'Result. Projeto',
            type: 'item',
            url: '/main/resultado-projeto',
            icon: icons.IconBrandTrello,
            breadcrumbs: false
        },
        {
            id: 'costs-preiodo',
            title: 'Result. Período',
            type: 'item',
            url: '/main/resultado-periodo',
            icon: icons.IconBrandTrello,
            breadcrumbs: false
        }
    ]
};

export default costs;
