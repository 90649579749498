import styled from '@emotion/styled';

export const Header = styled.header`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
`;

export const HeaderAreaButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const HoverSocialCardArea = styled.div`
    display: flex;
    gap: 10px;
`;

export const SwitchArea = styled.div`
    position: absolute;
    top: -60px;
    right: 0;
    display: flex;
    align-items: center;
`;

export const CardFilteredArea = styled.div`
    position: absolute;
    top: 7%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
