// assets
import {
    IconUserPlus,
    IconReceiptTax,
    IconCurrencyReal,
    IconAdjustments,
    IconFiretruck,
    IconCashBanknote,
    IconBrandVimeo,
    IconBrandRevolut,
    IconReportAnalytics,
    IconCoins
} from '@tabler/icons';

const icons = {
    IconUserPlus,
    IconReceiptTax,
    IconCurrencyReal,
    IconAdjustments,
    IconFiretruck,
    IconCashBanknote,
    IconBrandVimeo,
    IconBrandRevolut,
    IconReportAnalytics,
    IconCoins
};

const role = localStorage.getItem('role');
const roleParse = JSON.parse(role);
let commercial;
if (roleParse === 'Vendedor' || roleParse === 'Gestor Comercial') {
    commercial = {
        id: 'comercial',
        title: 'Comercial',
        type: 'group',
        children: [
            {
                id: 'cadastros',
                title: 'Cadastros',
                type: 'collapse',
                icon: icons.IconAdjustments,
                children: [
                    {
                        id: 'payments',
                        title: 'Cond. Pagamentos',
                        type: 'item',
                        url: '/main/comercial/condpagamentos',
                        icon: icons.IconCurrencyReal,
                        breadcrumbs: false
                    },
                    {
                        id: 'icms',
                        title: 'ICMS',
                        type: 'item',
                        url: '/main/comercial/icms',
                        icon: icons.IconReceiptTax,
                        breadcrumbs: false
                    },
                    {
                        id: 'costumer',
                        title: 'Clientes',
                        type: 'item',
                        url: '/main/comercial/clientes',
                        icon: icons.IconUserPlus,
                        breadcrumbs: false
                    }
                ]
            }
        ]
    };
} else if (roleParse === 'Representante') {
    commercial = {
        id: 'comercial',
        title: 'Comercial',
        type: 'group',
        children: [
            {
                id: 'costumer',
                title: 'Clientes',
                type: 'item',
                url: '/main/comercial/clientes',
                icon: icons.IconUserPlus,
                breadcrumbs: false
            }
        ]
    };
} else {
    commercial = {
        id: 'comercial',
        title: 'Comercial',
        type: 'group',
        children: [
            {
                id: 'cadastros',
                title: 'Cadastros',
                type: 'collapse',
                icon: icons.IconAdjustments,
                children: [
                    {
                        id: 'payments',
                        title: 'Cond. Pagamentos',
                        type: 'item',
                        url: '/main/comercial/condpagamentos',
                        icon: icons.IconCurrencyReal,
                        breadcrumbs: false
                    },
                    {
                        id: 'icms',
                        title: 'ICMS',
                        type: 'item',
                        url: '/main/comercial/icms',
                        icon: icons.IconReceiptTax,
                        breadcrumbs: false
                    },
                    {
                        id: 'costumer',
                        title: 'Clientes',
                        type: 'item',
                        url: '/main/comercial/clientes',
                        icon: icons.IconUserPlus,
                        breadcrumbs: false
                    },
                    {
                        id: 'ro',
                        title: 'Resultado Operacional',
                        type: 'item',
                        url: '/main/comercial/resultado-operacional',
                        icon: icons.IconReportAnalytics,
                        breadcrumbs: false
                    }
                ]
            }
        ]
    };
}

export default commercial;
