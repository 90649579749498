import { IconButton, Snackbar } from '@mui/material';
import React from 'react';

const SnackBar = ({ setOpenSnack, openSnack, title }) => {
    //snack
    const handleClickSnack = () => {
        setOpenSnack(true);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };

    const action = (
        <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                x
            </IconButton>
        </>
    );

    return (
        <div>
            {openSnack && (
                <Snackbar action={action} open={handleClickSnack} autoHideDuration={2000} onClose={handleCloseSnack} message={title} />
            )}
        </div>
    );
};

export default SnackBar;
