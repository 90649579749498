import dashboard from './dashboard';
import budget from './budget';
import records from './records';
import commercial from './commercial';
import pcp from './pcp';
import costs from './costs';

const roleMenus = {
    Administrador: [dashboard, records, budget, commercial, pcp, costs],
    Representante: [dashboard, budget],
    Vendedor: [dashboard, budget, commercial],
    PCP: [dashboard, pcp],
    Manutencao: [dashboard, commercial],
    'Gestor Comercial': [dashboard, records, budget, commercial],
    SuperAdmin: [dashboard, records, budget, commercial, pcp, costs]
};

const role = localStorage.getItem('role');
const roleParse = JSON.parse(role);

const menuItems = { items: roleMenus[roleParse] || [] };

export default menuItems;
