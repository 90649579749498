import { Button, Modal, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useState, useDeferredValue } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '6px'
};
const AddNote = ({ open, setOpen, currentRow, get }) => {
    const api = useApi();
    const [observationText, setObservationText] = useState();
    const deferredObservationText = useDeferredValue(observationText);

    useEffect(() => {
        setObservationText(currentRow.observacao ? currentRow.observacao : '');
    }, [currentRow]);

    const handleNoteChange = async () => {
        if (!deferredObservationText) {
            toast.warn('Por favor, insira uma observação');
            return;
        }
        const payload = {
            text: deferredObservationText
        };
        try {
            await api.addNotePcpProduct(currentRow.comInCodigo, currentRow?.pedInCodigo, payload.text);
            setOpen(false);
            toast.success(`Observação adicionada ao pedido ${currentRow?.pedido}`);
            setObservationText('');
            get();
        } catch (error) {
            console.log('🚀 ~ handleNoteChange ~ error:', error);
        }
    };
    const handleDeleteNote = async () => {
        const text = ' ';
        try {
            await api.addNotePcpProduct(currentRow.comInCodigo, currentRow?.pedInCodigo, text);
            setOpen(false);
            setObservationText('');
            toast.success(`Observação excluida do pedido ${currentRow?.pedido}`);
            get();
        } catch (error) {
            console.log('🚀 ~ handleDeleteNote ~ error:', error);
        }
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <h2 style={{ textAlign: 'center' }}>Adicione uma observação ao pedido {currentRow?.pedido} </h2>
                <div>
                    <TextField
                        sx={{ width: '100%' }}
                        value={observationText}
                        id="outlined-multiline-flexible"
                        label={'Observação'}
                        multiline
                        onChange={(e) => setObservationText(event.target.value)}
                        rows={3}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button type="submit" variant="contained" color="error" onClick={handleDeleteNote}>
                            Deletar observação
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleNoteChange}>
                            Adicionar observação
                        </Button>
                    </Box>
                </div>
            </Box>
        </Modal>
    );
};

export default AddNote;
