import { Box, Skeleton } from '@mui/material';

const SkeletonSimulator = () => {
    return (
        <Box sx={{ display: 'flex', gap: '70px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '200px', backgroundColor: '#999' }} />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '200px', backgroundColor: '#999' }} />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '200px', backgroundColor: '#999' }} />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '200px', backgroundColor: '#999' }} />
                <Box sx={{ marginTop: '50px', display: 'flex', alignItems: 'center', gap: '40px', backgroundColor: '#999' }}>
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '100px', backgroundColor: '#999' }} />
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '100px', backgroundColor: '#999' }} />
                </Box>
            </Box>

            <Skeleton
                animation="wave"
                variant="rounded"
                width={310}
                height={200}
                sx={{ fontSize: '1rem', width: '500px', backgroundColor: '#999' }}
            />
        </Box>
    );
};

export default SkeletonSimulator;
