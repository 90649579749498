import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
import ConfirmModal from 'ui-component/ConfirmModal';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const ActionButtons = ({
    row,
    currentRo,
    getRo,
    setCurrentRow,
    setOpenEditRo,
    setCurrentIndex,
    setCurrentRoFormula,
    getCurrentRoFormula
}) => {
    const api = useApi();

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const handleEdit = () => {
        setCurrentRow(row.id);
        setOpenEditRo(true);
        setCurrentIndex(row.index);
        setCurrentRoFormula(row);
    };

    const handleDelete = async () => {
        try {
            await api.deleteNewRoFormula(row.id);
            toast.success('Item deletado com sucesso!');
            getCurrentRoFormula();
        } catch (error) {
            console.log('🚀 ~ file: ActionsButtons.js:23 ~ handleDelete ~ error:', error);
        }
    };

    return (
        <div style={{ display: 'flex', gap: '10px', cursor: 'pointer' }}>
            <ConfirmModal
                firstMessage={'Deseja deletar esse R.O?'}
                secondMessage={'Após deletado os dados não poderão ser recuperados'}
                open={showConfirmDelete}
                setOpen={setShowConfirmDelete}
                button1={'Cancelar'}
                button2={'Deletar'}
                func={handleDelete}
            />
            <Tooltip title="Editar" onClick={handleEdit}>
                <EditIcon />
            </Tooltip>
            <Tooltip title="Deletar" onClick={() => setShowConfirmDelete(true)}>
                <DeleteIcon />
            </Tooltip>
        </div>
    );
};

export default ActionButtons;
