import PropTypes from 'prop-types';

// material-ui
import { Card, CardContent, Grid, Typography } from '@mui/material';

// ===========================|| HOVER SOCIAL CARD ||=========================== //

const TableHoverCard = ({ primary, secondary, iconPrimary, color, sx }) => {
    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary ? <IconPrimary fontSize="large" /> : null;

    return (
        <Card
            sx={{
                ...sx,
                background: color,
                position: 'relative',
                color: '#fff',
                minWidth: '100px',
                '&:hover svg': {
                    opacity: '1',
                    transform: 'scale(1.1)'
                }
            }}
        >
            <CardContent sx={{ textAlign: 'center' }}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Typography variant="h4" color="inherit">
                            {secondary}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h1" color="inherit">
                            {primary}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

TableHoverCard.propTypes = {
    primary: PropTypes.string,
    secondary: PropTypes.string,
    iconPrimary: PropTypes.object,
    color: PropTypes.string
};

export default TableHoverCard;
