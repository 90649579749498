import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PDFDownloadLink } from '@react-pdf/renderer';
import GeneretePdf from './GeneretePdf';
import { useNavigate } from 'react-router';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useApi } from 'Service/axios';
import ConfirmModal from 'ui-component/ConfirmModal';
import { toast } from 'react-toastify';

export default function MenuTable({ rowData, getBudgetsClients, index }) {
    const api = useApi();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = async () => {
        try {
            await api.deleteBudgetClient(rowData.id);
            getBudgetsClients();
            toast.success('Orçamento deletado com sucesso');
        } catch (error) {
            console.log('🚀 ~ handleDelete ~ error:', error);
            toast.error('Erro ao deletar orçamento');
        }
    };
    return (
        <div>
            <ConfirmModal
                button1={'Cancelar'}
                button2={'Deletar'}
                firstMessage={'Deseja mesmo deletar esse orçamento ?'}
                secondMessage={'Essa ação não poderá ser desfeita'}
                func={handleDelete}
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
            />
            <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                color="inherit"
            >
                <MoreVertIcon />
            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        const reviewPath = rowData.review_of ? `/${rowData.review_of} R.${index + 1}` : '/0';
                        navigate(`/main/novo-orcamento/${rowData.id}${reviewPath}`);
                    }}
                >
                    Consultar
                </MenuItem>

                <MenuItem onClick={() => setOpenConfirmModal(true)}>Deletar</MenuItem>
            </Menu>
        </div>
    );
}
