import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthLogin from '../auth-forms/AuthLogin';
import Logo from 'ui-component/Logo';
import Footer from 'layout/MainLayout/Footer';

import LoginBG from '../../../../assets/images/Backgrounds/loginBG.jpg';
import LogoBw from 'assets/logobw.png';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { getImageDataURL } from 'utils/convertImagesLocalStorage';

// ================================|| AUTH3 - LOGIN ||================================ //

const Login = () => {
    const api = useApi();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [logoStorage, setLogoStorage] = useState(null);

    const getCustomizationImages = async () => {
        try {
            const { data } = await api.getCustomization();

            localStorage.setItem('@images', JSON.stringify(data));
        } catch (error) {
            console.log('🚀 ~ getCustomizationImages ~ error:', error);
        }
    };

    useEffect(() => {
        const images = localStorage.getItem('@images');
        if (images) {
            const allImages = JSON.parse(images);
            setLogoStorage(allImages['loginBackground']);
        }
    }, []);

    useEffect(() => {
        getCustomizationImages();
    }, []);

    return (
        <>
            <AuthWrapper1>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-end"
                    sx={{
                        minHeight: '100vh',
                        // backgroundColor: '#0B3C5D'
                        backgroundImage: 'linear-gradient(180deg, rgba(11,60,93,1) 10%, rgba(11,60,80,1) 63%, rgba(9,109,129,1) 100%);'
                        // backgroundRepeat: 'no-repeat',
                        // backgroundSize: 'cover'
                    }}
                >
                    <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', maxWidth: '1400px' }}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                                <Grid item>
                                    <AuthCardWrapper>
                                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                                            <Grid item sx={{ mb: 3 }}>
                                                <Link to="#">
                                                    <Logo width="300" />
                                                </Link>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    direction={matchDownSM ? 'column-reverse' : 'row'}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item>
                                                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                            <Typography color="primary" gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                                                                Bem vindo de Volta
                                                            </Typography>
                                                            <Typography
                                                                variant="caption"
                                                                fontSize="16px"
                                                                textAlign={matchDownSM ? 'center' : 'inherit'}
                                                            >
                                                                Entre com suas credenciais para continuar
                                                            </Typography>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AuthLogin />
                                            </Grid>
                                        </Grid>
                                    </AuthCardWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div>
                            <img src={logoStorage ? logoStorage : LogoBw} alt="logo bw" style={{ width: '700px' }} />
                        </div>
                    </Grid>

                    <Footer />
                </Grid>
            </AuthWrapper1>
        </>
    );
};

export default Login;
