import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Button, Modal } from '@mui/material';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { useState } from 'react';

export default function StatusModal({ open, setOpen, currentRow, get }) {
    const api = useApi();
    const [selectedOption, setSelectedOption] = useState('ABERTO');
    console.log('🚀 ~ StatusModal ~ currentRow:', currentRow);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px'
    };

    const styleRadio = {
        dissplay: 'block',
        padding: '10px 20px',
        border: '1px solid #888',
        borderRadius: '4px',
        margin: 0,
        marginBottom: '8px'
    };

    const changeStatus = async () => {
        try {
            await api.changeStatusPcpProduct(currentRow?.id, selectedOption);
            toast.success('Status alterado com sucesso!');
            get();
            setOpen(false);
        } catch (error) {
            console.log('🚀 ~ changeStatus ~ error:', error);
        }
    };
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    return (
        <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <FormControl sx={style}>
                <h3>Selecione o status</h3>
                <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedOption} onChange={handleOptionChange}>
                    <FormControlLabel sx={styleRadio} value="ABERTO" control={<Radio />} label="ABERTO" />
                    <FormControlLabel sx={styleRadio} value="PR" control={<Radio />} label="PR" />
                    <FormControlLabel sx={styleRadio} value="ENCERRADO" control={<Radio />} label="ENCERRADO" />
                </RadioGroup>
                <Button variant="contained" sx={{ textAlign: 'center' }} onClick={changeStatus}>
                    Selecionar
                </Button>
            </FormControl>
        </Modal>
    );
}
