import {
    Autocomplete,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Table,
    TableContainer,
    TableHead,
    TableSortLabel,
    TextField,
    Typography
} from '@mui/material';
import { IconPencil } from '@tabler/icons';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { Box, display } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress } from '@mui/material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HoverSocialCard from '../Pcp/components/HoverCard';
import { toast } from 'react-toastify';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ListAltIcon from '@mui/icons-material/ListAlt';

const OrdemLB = () => {
    ////////////////////////////////////// INSTANCIAS /////////////////////////////////////////
    const api = useApi();
    ////////////////////////////////////// STATES /////////////////////////////////////////
    const [loading, setLoading] = useState(true);
    const [ordens, setOrdens] = useState([]);
    const [quantidadeItensDistintos, setQuantidadeItensDistintos] = useState(0);
    const color = '#0588ed';
    const [openFirstModal, setOpenFirstModal] = useState(false);
    const [openSecondModal, setOpenSecondModal] = useState(false);
    const [rowSelectedSecondModal, setRowSelectedSecondModal] = useState([]);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null);

    //para sempre atualizar a tabela

    const columns = [
        { field: 'ord_in_codigo', headerName: 'Ordem', minWidth: 100, maxWidth: 200 },
        { field: 'ord_dt_liberacao', headerName: 'Liberação', width: 100 },
        { field: 'pro_in_codigo', headerName: 'Código', width: 70 },
        { field: 'pro_st_descricao', headerName: 'Item', width: 550 },
        { field: 'ped_in_codigo', headerName: 'Pedido', width: 100 },
        { field: 'agn_st_fantasia', headerName: 'Cliente', width: 350 },
        { field: 'dt_calc_prog', headerName: 'Dias Prog.', width: 100 }
        //{ field: 'acao', headerName: 'AÇÕES', flex: 1, sortable: false, renderCell: (params) => <ActionButtons row={params.row} /> }
    ];

    ////////////////////////////////////// FUNÇÕES /////////////////////////////////////////
    const formatDate = (dateString) => {
        const inputDate = new Date(dateString);
        const day = String(inputDate.getDate()).padStart(2, '0');
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const year = inputDate.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const getOrder = async () => {
        try {
            const response = await api.getOrderLB();

            const parsedData = response.data.map((i, index) => ({
                ...i,
                id: index + 1,
                ord_dt_liberacao: formatDate(i.ord_dt_liberacao)
            }));
            setOrdens(parsedData);
            console.log('🚀 ~ file: index.js:81 ~ getOrder ~ parsedData:', parsedData);

            const distinctItems = new Set(parsedData.map((item) => item.ped_in_codigo));
            setQuantidadeItensDistintos(distinctItems.size);
            setLoading(false);
        } catch (error) {
            toast.error('Erro ao conectar no banco de dados, tente novamente');
        }
    };

    useEffect(() => {
        getOrder();
    }, []);

    return (
        <MainCard title={<h2 style={{ margin: '0' }}>Ordens Liberadas</h2>}>
            <Grid container spacing={gridSpacing}>
                {loading && (
                    <Box sx={{ minHeight: '80vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
                {!loading && (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '1em' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    position: 'absolute',
                                    top: '115px',
                                    right: '40px'
                                }}
                            >
                                <HoverSocialCard
                                    sx={{ marginRight: '3em' }}
                                    primary="PEDIDOS"
                                    secondary={quantidadeItensDistintos}
                                    iconPrimary={AppRegistrationIcon}
                                    color={color}
                                />
                                <HoverSocialCard
                                    sx={{ marginRight: '3em' }}
                                    primary="ITENS"
                                    secondary={ordens.length}
                                    iconPrimary={EventNoteIcon}
                                    color={color}
                                />
                            </Box>
                        </Box>

                        <Box>
                            <DataGrid rows={ordens} columns={columns} sx={{ maxWidth: '1400px', margin: '0 auto' }} rowSelection={false} />
                        </Box>
                    </Box>
                )}
            </Grid>
        </MainCard>
    );
};

export default OrdemLB;
