import MainCard from 'ui-component/cards/MainCard';
import React from 'react';
import {
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
    CardActions,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Box,
    MenuItem,
    ButtonGroup
} from '@mui/material';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from 'react-router';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

const initialState = {
    formula: '',
    description: '',
    value: 0,
    product: { id: null }
};

function CreateView() {
    const { id } = useParams();

    const [selectIds, setSelectIds] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [allQuestions, setAllQuestion] = useState([]);

    const [message, setMessage] = useState(false);

    const handlePerguntaSelect = (pergunta) => {
        const itemAlreadySelected = selectIds.find((item) => item.id === pergunta.id);

        if (itemAlreadySelected) {
            window.alert('Essa pergunta já foi escolhida!');
            return;
        }

        setSelectedOptions((prevOptions) => [...prevOptions, { desc: pergunta.description, id: pergunta.id }]);
        setSelectIds((prevOptions) => [...prevOptions, { id: pergunta.id }]);

        setAllQuestion((prevQuestions) => prevQuestions.filter((item) => item.id !== pergunta.id));
    };

    const handleRemoveOption = (option) => {
        setSelectedOptions((prevOptions) => prevOptions.filter((item) => item.id !== option.id));
        setSelectIds((prevOptions) => prevOptions.filter((item) => item.id !== option.id));

        setAllQuestion((prevQuestions) => [...prevQuestions, { id: option.id, description: option.desc }]);
    };

    console.log(selectIds);

    const api = useApi();

    const get = async () => {
        const reponse = await api.getAllQuestion();
        setAllQuestion(reponse.data);
    };

    const [product, setProduct] = useState([]);
    const getProductById = async (id) => {
        const response = await api.getProductById(id);
        setProduct(response.data);
    };

    useEffect(() => {
        get();
        getProductById(id);
        getAllProducts();
    }, []);

    const handleCreateQuestionary = async () => {
        try {
            const response = await api.createQuestionary(id, selectIds);
            console.log('response create', response);
            navigate('/main/material');
            if (response.status === 201) {
                try {
                    const currentPosition = selectIds.map((item, index) => ({
                        position: index,
                        question: { id: item.id }
                    }));

                    await api.positionQuestionary(response.data.id, currentPosition);
                } catch (error) {
                    console.log(error.message);
                }
            }
        } catch (e) {
            console.log(e.message);
        }
    };

    // navegaçao
    const navigate = useNavigate();

    const hanldeBack = () => {
        navigate('/main/questionnaires');
    };

    // ============ teste criar pergunta

    const [newQuestion, setNewQuestion] = useState(false);

    const handleActiveNewQuestion = () => {
        setNewQuestion(true);
    };

    //logicas para paginaçao e adiçao de input
    const [etapaAtual, setEtapaAtual] = useState(1);
    const [inputs, setInputs] = useState(['']);

    //switch
    const [isChecked, setIsChecked] = useState(false);

    //Pergunta
    const [questionDescription, setQuestionDescription] = useState('');

    //reposta
    const [answerDescripton, setanswerDescripton] = useState();
    const [formula, setFormula] = useState();
    const [valorAssociado, setValorAssociado] = useState();
    const [productAssociateds, setProductAssociateds] = useState();

    const [error, setError] = useState(false);

    //respostas array
    const [answersArr, setAnswersArr] = useState([]);

    async function handleSubmit(event) {
        event.preventDefault();

        if ((isChecked && questionDescription === '') || (!isChecked && questionDescription === '')) {
            setError(true);
            return;
        } else if (etapaAtual === 1 && !isChecked) {
            let tipo = 'Aberta';
            const responseQuestion = await api.createQuestion(tipo, questionDescription);
            window.location.reload();
            setEtapaAtual(1);
            setFormOpen(false);
            setQuestionDescription('');
            get();
        } else {
            if (etapaAtual === 2) {
                try {
                    let tipo = 'Fechada';
                    if (isChecked) {
                        tipo = 'Fechada';
                    } else {
                        tipo = 'Aberta';
                    }
                    const responseQuestion = await api.createQuestion(tipo, questionDescription);
                    const questionid = responseQuestion.data.id;
                    let respostas = campos;

                    const answerPromises = respostas.map(async (answer) => {
                        const data = await api.createAnswer(answer.formula, answer.description, answer.value, questionid, answer.product);
                    });

                    // wait for all promises to resolve
                    await Promise.all(answerPromises);
                    window.location.reload();
                    setQuestionDescription('');
                    setEtapaAtual(1);
                    setFormOpen(false);
                    get();
                } catch (error) {
                    console.log(error);
                    setError(true);
                }
            }
        }
    }

    function handleBack(event) {
        event.preventDefault();
        setEtapaAtual(etapaAtual - 1);
    }

    function handleSwitchChange(event) {
        setIsChecked(!isChecked);
    }

    const adicionarInput = () => {
        let newArr = answersArr;
        newArr.push({
            formula: formula,
            valor: valorAssociado,
            description: answerDescripton,
            produto: productAssociateds
        });
        setAnswersArr(newArr);
        setInputs([...inputs, '']);
    };

    const removerInput = (index) => {
        const novosInputs = [...inputs];
        novosInputs.splice(index, 1);
        setInputs(novosInputs);
    };

    const [allProducts, setAllProducts] = useState([]);

    const getAllProducts = async () => {
        const response = await api.getAllProducts();
        setAllProducts(response.data);
    };

    let primaryProducts = allProducts.filter((product) => product.productGeneric === true);

    const [campos, setCampos] = useState([initialState]);

    const adicionarCampo = () => {
        setCampos([
            ...campos,
            {
                formula: '',
                description: '',
                value: 0,
                product: { id: null }
            }
        ]);
    };

    // const removerCampo = () => {
    //     setCampos(campos.slice(0, -1));
    // };

    const removerCampo = (indiceCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos.splice(indiceCampo, 1);
            return novosCampos;
        });
    };

    const atualizarCampo = (indiceCampo, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            if (campo === 'value') {
                novosCampos[indiceCampo][campo] = Number(valor);
            } else {
                novosCampos[indiceCampo][campo] = valor;
            }

            if (campo === 'product') {
                novosCampos[indiceCampo][campo] = { id: Number(valor) };
            }
            return novosCampos;
        });
    };

    function renderEtapaAtual() {
        switch (etapaAtual) {
            case 1:
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <Typography variant="h3">Tipo de pergunta</Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>Aberta</Typography>
                            <Switch onChange={handleSwitchChange} inputProps={{ 'aria-label': 'ant design' }} />
                            <Typography>Fechada</Typography>
                        </Stack>
                        <Typography variant="p" sx={{ marginTop: '1rem', fontSize: '20px' }}>
                            Pergunta
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <TextField
                                placeholder="Título da pergunta..."
                                type="text"
                                error={error}
                                value={questionDescription}
                                sx={{ background: 'white' }}
                                helperText={error ? 'Insira uma pergunta' : null}
                                onChange={(event) => {
                                    setQuestionDescription(event.target.value);
                                    setError(false);
                                }}
                            />
                            {error && <FormHelperText sx={{ color: 'red' }}>Insira uma Pergunta</FormHelperText>}
                            {!isChecked ? (
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        sx={{ display: 'flex', marginTop: '3rem', width: '30%' }}
                                        type="submit"
                                        disabled={error}
                                        onClick={handleSubmit}
                                    >
                                        Cadastrar
                                    </Button>
                                </Box>
                            ) : (
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    {' '}
                                    <Button
                                        variant="contained"
                                        sx={{ display: 'flex', marginTop: '3rem', width: '30%' }}
                                        type="submit"
                                        onClick={(e) => {
                                            handleSubmit(e);
                                            setEtapaAtual(etapaAtual + 1);
                                        }}
                                    >
                                        Avançar
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                );
            case 2:
                return (
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        gap: '1rem',
                                        flexDirection: 'column'
                                    }}
                                >
                                    {campos.map((campo, indiceCampo) => (
                                        <Box
                                            key={indiceCampo}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                boxShadow: '1px 2px 5px #000',
                                                padding: '.6em',
                                                borderRadius: '5px'
                                            }}
                                        >
                                            <Typography variant="h5" sx={{ marginBottom: '2em', marginTop: '2em' }}>
                                                Resposta N° {indiceCampo + 1}
                                            </Typography>
                                            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                {/* <FormControl sx={{ width: '48%', mb: '15px' }}>
                                                    <TextField
                                                        label="Fórmula"
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        value={campo.formula}
                                                        onChange={(e) => atualizarCampo(indiceCampo, 'formula', e.target.value)}
                                                    />
                                                </FormControl> */}
                                                <FormControl sx={{ width: '48%', mb: '15px' }}>
                                                    <TextField
                                                        label="Valor Associado"
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        value={campo.value}
                                                        onChange={(e) => atualizarCampo(indiceCampo, 'value', e.target.value)}
                                                    />
                                                </FormControl>
                                            </Box>

                                            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                                                <FormControl sx={{ width: '100%', mb: '15px' }}>
                                                    <InputLabel id="product">Produto Associado</InputLabel>
                                                    <Select
                                                        labelId="product"
                                                        label="product"
                                                        value={campo.product?.id || ''}
                                                        onChange={(e) => atualizarCampo(indiceCampo, 'product', e.target.value)}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Selecione um produto
                                                        </MenuItem>
                                                        {primaryProducts.map((product) => (
                                                            <MenuItem key={product.id} value={product.id}>
                                                                {product.productName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl sx={{ width: '100%', mb: '15px' }}>
                                                    <TextField
                                                        label="Descrição"
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        value={campo.description}
                                                        onChange={(e) => atualizarCampo(indiceCampo, 'description', e.target.value)}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Button fullWidth onClick={() => removerCampo(indiceCampo)}>
                                                <DeleteIcon sx={{ transition: 'all .15s ease-in', ':hover': { color: 'red' } }} />
                                            </Button>
                                        </Box>
                                    ))}
                                </Box>
                                <ButtonGroup sx={{ width: '100%' }}>
                                    <Button fullWidth onClick={adicionarCampo}>
                                        Adicionar resposta
                                    </Button>
                                    {/* <Button fullWidth onClick={removerCampo}>
                                        Remover resposta
                                    </Button> */}
                                </ButtonGroup>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    sx={{ marginTop: '1rem', alignSelf: 'flex-start', width: '150px' }}
                                    type="submit"
                                    onClick={handleBack}
                                >
                                    voltar
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ marginLeft: '1rem', marginTop: '1rem', alignSelf: 'flex-end', width: '150px' }}
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Cadastrar
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                );
            default:
                return null;
        }
    }

    return (
        <>
            <MainCard
                title="Selecione as perguntas"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    backgroundColor: 'white',
                    borderRadius: '10px'
                }}
            >
                {/* // cadastro do nome  */}
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Typography sx={{ fontSize: '20px' }}>
                        Criando um novo questionario para: <strong>{product.productName}</strong>
                    </Typography>
                </Box>
                {/* selecione as perguntas  */}
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', gap: '1rem', mt: '1rem' }}>
                    <Box sx={{ width: '50%', backgroundColor: '#F8FAFC', borderRadius: '8px', marginBottom: '8px', padding: '1rem' }}>
                        <Typography sx={{ marginBottom: '1rem' }} variant="h6">
                            Perguntas selecionados
                        </Typography>
                        {selectedOptions.map((option, index) => (
                            <>
                                <Box
                                    key={option.id}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginBottom: '8px',
                                        width: '100%',
                                        padding: '0.5rem',
                                        backgroundColor: 'white',
                                        borderRadius: '8px'
                                    }}
                                >
                                    <Box style={{ marginBottom: '8px', cursor: 'pointer', backgroundColor: 'white' }}>{option.desc}</Box>

                                    <IconButton aria-label="delete" size="large">
                                        <DeleteIcon style={{ marginLeft: 'auto' }} onClick={() => handleRemoveOption(option)} />
                                    </IconButton>
                                </Box>
                            </>
                        ))}
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <Grid item xs={12}>
                            <Card style={{ borderRadius: '8px', marginBottom: '8px', backgroundColor: '#F8FAFC' }}>
                                <CardContent>
                                    <Typography sx={{ marginBottom: '1rem' }} variant="h6">
                                        Selecione as perguntas:
                                    </Typography>
                                    {allQuestions.map((pergunta) => (
                                        <Card
                                            key={pergunta.id}
                                            onClick={() => handlePerguntaSelect(pergunta)}
                                            role="button"
                                            tabIndex="-1"
                                            style={{ marginBottom: '8px', cursor: 'pointer', backgroundColor: 'white' }}
                                        >
                                            <CardContent>
                                                <Typography variant="body1">{pergunta.description}</Typography>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem', width: '100%' }}>
                    {/* <Button variant="contained" color="primary" onClick={hanldeBack}>
                        voltar
                    </Button> */}
                    {/* {!newQuestion && (
                        <Button variant="contained" color="primary" onClick={() => setNewQuestion(true)}>
                            Nova pergunta
                        </Button>
                    )} */}
                    {!newQuestion && (
                        <Button variant="contained" color="primary" onClick={handleCreateQuestionary}>
                            Criar Questionário
                        </Button>
                    )}
                </Box>
                {newQuestion && renderEtapaAtual()}
            </MainCard>
        </>
    );
}

export default CreateView;
