import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    TextField,
    Typography,
    Box,
    InputLabel,
    Select,
    MenuItem,
    ButtonGroup,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Collapse,
    Divider,
    Tooltip,
    FormHelperText,
    TablePagination,
    CircularProgress,
    TableSortLabel
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import MaterialCard from 'ui-component/MaterialCard';
import ErrorView from './ErrorView';
import PinIcon from '@mui/icons-material/Pin';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SubCard from 'ui-component/cards/SubCard';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { IconPencil } from '@tabler/icons';
import RampRightIcon from '@mui/icons-material/RampRight';
import Autocomplete from '@mui/material/Autocomplete';

import { useParams } from 'react-router-dom';

const initialState = {
    materiaPrima: '',
    quantidade: 0,
    percentual: 0,
    itemSubstituto: '',
    formula: []
};

function Material() {
    const api = useApi();
    const { id } = useParams();
    const [filterCurrentProduct, setFilterCurrentProduct] = useState([]);
    console.log('teste', filterCurrentProduct[0]);
    console.log('id', id);
    //switch
    const [isChecked, setIsChecked] = useState(false);

    // pegando todos os produtos para filtrar por matéria prima
    const [allProducts, setAllProducts] = useState([]);

    // pegar o quiz selecionado com base no produto final
    const [selectedQuiz, setSelectedQuiz] = useState();

    // states para Material View
    const [teste, setTeste] = useState([]);

    const [toogleCard, setToogleCard] = useState(false);

    const [allList, setAllList] = useState([]);

    const [showError, setShowError] = useState(false);

    const [materiaPrimaError, setMateriaPrimaError] = useState(false);
    const [itemSubstitutoError, setItemSubstitutoError] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getAllProducts = async () => {
        const response = await api.getAllProducts();
        setAllProducts(response.data);
    };

    // ====== get all quiz => pickup all final products who has a questionary
    const [quiz, setQuiz] = useState([]);
    const [substituteItemList, setSubistituteItemList] = useState([]);

    const getAllQuiz = async () => {
        const response = await api.getAllQuiz();

        const findCurrentQuiz = response?.data?.find((itemFind) => itemFind?.product?.id === Number(id));
        console.log('🚀 ~ getAllQuiz ~ findCurrentQuiz:', findCurrentQuiz);
        getCurrentQuiz(findCurrentQuiz.id);
        setTeste(findCurrentQuiz);
    };

    //  const [allQuestionsGet, setAllQuestionsGet] = useState([]);
    //  const getallQuestions = async () => {
    //      try {
    //          const { data } = await api.getAllQuestion();
    //          setAllQuestionsGet(data);
    //      } catch (error) {
    //          console.log('🚀 ~ getQuestion ~ error:', error);
    //      }
    //  };

    //  const getCurrentQuiz = async (id) => {
    //      try {
    //          const { data } = await api.getQuizByIdV2(id);
    //          console.log('🚀 ~ getCurrentQuiz ~ data:', data);

    //          const filterQuestionQuiz = allQuestionsGet.filter((item) =>
    //              data.some((itemQuiz) => itemQuiz.description === item.description)
    //          );
    //          console.log('🚀 ~ getCurrentQuiz ~ filterQuestionQuiz:', filterQuestionQuiz);

    //          setSubistitute(filterQuestionQuiz);
    //          return setQuiz(data);
    //      } catch (error) {
    //          console.log('🚀 ~ file: EditMaterial.js:93 ~ getCurrentQuiz ~ error:', error);
    //      }
    //  };

    const getCurrentQuiz = async (id) => {
        let currentId = id;
        try {
            const { data } = await api.getQuizByIdV2(id);
            setQuiz(data);

            setQuizQuestion(data);

            console.log('🚀 ~ getCurrentQuiz ~ data:', data);
            const filterQuestionQuiz = data?.filter((item) => item.type !== 'Aberta');
            console.log('🚀 ~ getCurrentQuiz ~ filterQuestionQuiz:', filterQuestionQuiz);
            setSubistituteItemList(filterQuestionQuiz);

            setSelectedQuiz(currentId);
        } catch (error) {
            console.log('🚀 ~ file: Material.js:137 ~ getCurrentQuiz ~ error:', error);
        }
    };

    // Filtro para pegar todos os produtos de tipo === Matéria Prima
    let primaryProducts = allProducts.filter((product) => product.productPrimary === true);

    const [inputError, setInputError] = useState(false);

    const getAllList = async () => {
        try {
            const response = await api.getAllList();
            const all = response.data;
            console.log('All data:', all);

            const filter = response.data.filter((item) => item.finalProduct.id === Number(id));

            setAllList(filter);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllProducts();
        getAllQuiz();
        getAllList();
    }, []);

    // resetar os states de controle do form
    const handleResetMainStates = () => {
        setToogleCard(!toogleCard);
        setSelectedQuiz(undefined);
        setIsChecked(false);
    };

    const operacoes = [
        { id: 1, op: 'plus', symbol: '+' },
        { id: 2, op: 'minor', symbol: '-' },
        { id: 3, op: 'times', symbol: '*' },
        { id: 4, op: 'divided', symbol: '/' }
    ];

    // ================= LÓGICA DO FORMULÁRIO

    const [quizQuestion, setQuizQuestion] = useState([]);
    console.log('quizQuestion', quizQuestion);
    const handleSubmit = async (event) => {
        event.preventDefault();
        // Lógica para lidar com o envio do formulário

        const hasEmptyMateriaPrima = campos.some((item) => item.materiaPrima === '');
        const hasEmptyItemSubstituto = campos.some((item) => item.itemSubstituto === '');

        setMateriaPrimaError(hasEmptyMateriaPrima);
        // setItemSubstitutoError(hasEmptyItemSubstituto);

        if (hasEmptyMateriaPrima) {
            return;
        }

        const MATERIAL = {
            quiz: teste.id,
            finalProductId: teste.product.id,
            materialList: campos,
            itemSub: campos[0].itemSubstituto
        };

        for (let i = 0; i < MATERIAL.materialList.length; i++) {
            MATERIAL.materialList[i].formula = MATERIAL.materialList.map((item) => {
                if (item) {
                    const b = item.formula.map((teste) => {
                        const a = teste;
                        if (a.question1 && a.question2 && a.question1.id === '' && a.question2.id === '') {
                            const newTeste = {
                                valor2: parseFloat(a.valor2),
                                operation: a.operation,
                                valor1: parseFloat(a.valor1)
                            };
                            return newTeste;
                        } else if (a.question1 && a.question1.id === '') {
                            const newTeste = {
                                question2: a.question2,
                                operation: a.operation,
                                valor1: parseFloat(a.valor1),
                                valor2: parseFloat(a.valor2)
                            };
                            return newTeste;
                        } else if (a.question2 && a.question2.id === '') {
                            const newTeste = {
                                question1: a.question1,
                                operation: a.operation,
                                valor1: parseFloat(a.valor1),
                                valor2: parseFloat(a.valor2)
                            };

                            return newTeste;
                        }
                        return a;
                    });
                    return b;
                }
                return item;
            });
        }
        console.log('teste', teste);
        console.log('material', MATERIAL);
        try {
            await api.createList(MATERIAL.quiz, MATERIAL);
            getAllList();
            window.location.reload();
        } catch (error) {
            console.log(error.message);
        }

        setToogleCard(false);
    };

    const handleDelete = async (id) => {
        const response = await api.deleteList(id);
        getAllList();
    };

    const [campos, setCampos] = useState([initialState]);

    //usar essa (pai) sem a formula

    const adicionarCampo = () => {
        setCampos([
            ...campos,
            {
                materiaPrima: '',
                quantidade: 0,
                percentual: 0,
                itemSubstituto: '',
                formula: [
                    {
                        operation: '',
                        question1: { id: '' },
                        question2: { id: '' },
                        inputType: { question1: 'select', question2: 'select', valor1: 'textfield', valor2: 'textfield' },
                        valor1: 0,
                        valor2: 0
                    }
                ]
            }
        ]);
    };

    const removerCampo = () => {
        setCampos(campos.slice(0, -1));
    };
    // usar essa func
    const adicionarOutroCampo = (indiceCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].formula.push({
                operation: '',
                question1: { id: '' },
                question2: { id: '' },
                inputType: { question1: 'select', question2: 'select', valor1: 'textfield', valor2: 'textfield' },
                valor1: 0,
                valor2: 0
            });
            return novosCampos;
        });
    };

    const removerOutroCampo = (indiceCampo, indiceOutroCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].formula.splice(indiceOutroCampo, 1);
            return novosCampos;
        });
    };

    const handleChangeInputTypeQuestion1 = (indiceCampo, indiceOutroCampo, novoTipo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].formula[indiceOutroCampo].inputType.question1 = novoTipo;

            if (novoTipo === 'textfield') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor1 = '';
            } else {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor1 = 0; // Define como null quando o tipo for alterado para select
                novosCampos[indiceCampo].formula[indiceOutroCampo].question1 = { id: '' };
            }

            return novosCampos;
        });
    };

    const handleChangeInputTypeQuestion2 = (indiceCampo, indiceOutroCampo, novoTipo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].formula[indiceOutroCampo].inputType.question2 = novoTipo;

            if (novoTipo === 'textfield') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor2 = 0;
            } else {
                novosCampos[indiceCampo].formula[indiceOutroCampo].question2 = { id: '' };
            }

            return novosCampos;
        });
    };
    //essa tbm
    const atualizarCampo = (indiceCampo, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            if (campo === 'quantidade' || campo === 'percentual') {
                novosCampos[indiceCampo][campo] = Number(valor);
            } else {
                novosCampos[indiceCampo][campo] = valor;
            }
            return novosCampos;
        });
    };

    const atualizarOutroCampo = (indiceCampo, indiceOutroCampo, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            if (campo === 'operation') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].operation = valor;
            } else if (
                campo === 'valor1' &&
                novosCampos[indiceCampo].formula[indiceOutroCampo].inputType &&
                novosCampos[indiceCampo].formula[indiceOutroCampo].inputType.question1 === 'textfield'
            ) {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor1 = valor;
            } else if (
                campo === 'valor2' &&
                novosCampos[indiceCampo].formula[indiceOutroCampo].inputType &&
                novosCampos[indiceCampo].formula[indiceOutroCampo].inputType.question2 === 'textfield'
            ) {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor2 = valor;
            } else if (typeof valor === 'object' && valor !== null) {
                novosCampos[indiceCampo].formula[indiceOutroCampo][campo] = { id: valor.id };
            } else {
                novosCampos[indiceCampo].formula[indiceOutroCampo][campo] = valor;
            }

            // Atualize o valor selecionado no estado correspondente
            if (campo === 'question1') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].question1 = { id: valor };
            } else if (campo === 'question2') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].question2 = { id: valor };
            }

            return novosCampos;
        });
    };

    function Row({ material }) {
        console.log('🚀 ~ file: Material.js:381 ~ Row ~ material:', material);

        const theme = useTheme();
        const [openTable, setOpenTable] = useState(false);
        const [open, setOpen] = useState(false);
        const [openEdit, setOpenEdit] = useState(false);
        const [openSnakBar, setOpenSnackBar] = useState(false);
        const [openSnakBarCancelado, setOpenSnackBarCancelado] = useState(false);

        const translateOperation = (op) => {
            if (op === 'plus') {
                return '+';
            } else if (op === 'minor') {
                return '-';
            } else if (op === 'times') {
                return '*';
            } else if (op === 'divided') {
                return '/';
            }
        };
        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
            setOpenSnackBarCancelado(true);
        };

        const handleCloseEdit = () => {
            setOpenEdit(false);
            setOpenSnackBarCancelado(true);
        };

        const handleCloseSnackBar = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpenSnackBar(false);
            setOpenSnackBarCancelado(false);
        };

        const handleDeleteList = async () => {
            const response = await handleDelete(material?.id);
            if (response == true) {
                setOpenEdit(false);
                setOpenSnackBar(true);
            }
        };

        console.log(allList);

        return (
            <>
                <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell component="th" scope="row" align="left">
                        {material?.id}
                    </TableCell>

                    <TableCell align="center">{material?.product?.productName}</TableCell>
                    {/* <TableCell align="right">{material?.itemSubstitute?.description}</TableCell> */}
                    <TableCell align="right">{material?.quantity}</TableCell>
                    <TableCell align="right">{material?.lossPercent}%</TableCell>

                    <TableCell align="right">
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box sx={{ alignSelf: 'flex-end' }}>
                                    <Link to={`/main/editmaterial/${material?.id}`}>
                                        <Tooltip title="Editar">
                                            <IconButton>
                                                <IconPencil />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                    <Tooltip title="Excluir" onClick={handleClickOpen}>
                                        <IconButton>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-slide-description">
                                                tem certeza que quer deletar essa lista?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose}>cancelar</Button>
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    handleDeleteList(material?.id);
                                                }}
                                            >
                                                Deletar
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    {/* editar dialog */}
                                    <Dialog
                                        open={openEdit}
                                        keepMounted
                                        onClose={handleClose}
                                        aria-describedby="alert-dialog-slide-description"
                                    >
                                        <DialogContent sx={{ display: 'flex', flexDirection: 'column', minWidth: '600px', gap: '1rem' }}>
                                            <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>Editar lista de material</Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                <Tooltip>
                                                    <IconButton>
                                                        <IconPencil />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseEdit}>cancelar</Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Snackbar open={openSnakBar} autoHideDuration={1000} onClose={handleCloseSnackBar}>
                                        <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                                            deletado com sucesso!
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={openSnakBarCancelado} autoHideDuration={1000} onClose={handleCloseSnackBar}>
                                        <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                                            Cancelado
                                        </Alert>
                                    </Snackbar>
                                </Box>
                            </Box>
                        </Box>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    const [selectedList, setSelectedList] = useState(null);
    const [loading, setLoading] = useState(true);
    console.log(allList);
    //func orderby
    const [orderByNome, setOrderByNome] = useState('asc');
    const [orderById, setOrderById] = useState('asc');

    const handleSortById = () => {
        const sortedItens = [...allList].sort((a, b) => {
            if (orderById === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setAllList(sortedItens);
        setOrderById(orderById === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByNome = () => {
        const sortedItens = [...allList].sort((a, b) => {
            const nomeA = a.itemSubstitute?.description.toUpperCase();
            const nomeB = b.itemSubstitute?.description.toUpperCase();

            if (orderByNome === 'asc') {
                if (nomeA < nomeB) return -1;
                if (nomeA > nomeB) return 1;
            } else {
                if (nomeA > nomeB) return -1;
                if (nomeA < nomeB) return 1;
            }

            return 0;
        });

        setAllList(sortedItens);
        setOrderByNome(orderByNome === 'asc' ? 'desc' : 'asc');
    };
    return (
        <>
            {!showError && (
                <MainCard title="Lista de material">
                    {toogleCard && (
                        //primeiro select
                        <Grid container spacing={gridSpacing}>
                            {!isChecked && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: '1rem',
                                        width: '100%',
                                        padding: '1.6em'
                                    }}
                                >
                                    <Box sx={{ display: 'flex', width: '80%', gap: '1rem', flexDirection: 'column' }}>
                                        {campos.map((campo, indiceCampo) => (
                                            <Box key={indiceCampo} sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                <Box sx={{ display: 'flex', gap: '1rem' }}>
                                                    <FormControl sx={{ width: '55%', mb: '15px' }}>
                                                        <InputLabel id="materia prima">Matéria Prima *</InputLabel>
                                                        <Select
                                                            sx={{ width: '100%' }}
                                                            labelId="materia prima"
                                                            label="materia Prima"
                                                            error={materiaPrimaError}
                                                            value={campo.materiaPrima}
                                                            helperText={materiaPrimaError ? 'Campo obrigatório' : null}
                                                            onChange={(e) => {
                                                                setMateriaPrimaError(false);
                                                                atualizarCampo(indiceCampo, 'materiaPrima', e.target.value);
                                                            }}
                                                            required
                                                        >
                                                            {primaryProducts &&
                                                                primaryProducts.map((item) => (
                                                                    <MenuItem key={item.id} value={item}>
                                                                        Id: {item.id} - {item.productName}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                        {materiaPrimaError && (
                                                            <FormHelperText sx={{ color: 'red' }}>Campo obrigatório</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                    <TextField
                                                        label="Quantidade"
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        value={campo.quantidade}
                                                        onChange={(e) => atualizarCampo(indiceCampo, 'quantidade', e.target.value)}
                                                    />
                                                    <TextField
                                                        label="Percentual Perda"
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        value={campo.percentual}
                                                        onChange={(e) => atualizarCampo(indiceCampo, 'percentual', e.target.value)}
                                                    />
                                                    <FormControl sx={{ width: '55%', mb: '15px' }}>
                                                        <InputLabel id="item_subs">Item Substituto*</InputLabel>
                                                        <Select
                                                            sx={{ width: '100%' }}
                                                            labelId="item_subs"
                                                            label="Item Substituto*"
                                                            // error={itemSubstitutoError}
                                                            value={campo.itemSubstituto}
                                                            // helperText={itemSubstitutoError ? 'Campo obrigatório' : null}
                                                            onChange={(e) => {
                                                                setItemSubstitutoError(false);
                                                                atualizarCampo(indiceCampo, 'itemSubstituto', e.target.value);
                                                            }}
                                                        >
                                                            {quizQuestion &&
                                                                substituteItemList.map((item) => (
                                                                    <MenuItem key={item.id} value={item.id}>
                                                                        Pergunta: {item.id} - {item.description}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                {campo.formula.map((formula, indiceOutroCampo) => (
                                                    // select de operação
                                                    <Box
                                                        key={indiceOutroCampo}
                                                        sx={{
                                                            display: 'flex',
                                                            gap: '1rem',
                                                            width: '100%',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        {indiceOutroCampo > 0 && (
                                                            <FormControl sx={{ width: '25%', mb: '15px' }}>
                                                                <InputLabel id="operacao">Operação</InputLabel>
                                                                <Select
                                                                    labelId="operacao"
                                                                    label="operacao"
                                                                    value={formula.operation}
                                                                    onChange={(e) =>
                                                                        atualizarOutroCampo(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'operation',
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                >
                                                                    <MenuItem value="">Selecione uma operação</MenuItem>
                                                                    {operacoes.map((operacao) => (
                                                                        <MenuItem key={operacao.op} value={operacao.op}>
                                                                            {operacao.symbol}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                        {formula.inputType.question1 === 'select' ? (
                                                            <>
                                                                <FormControl sx={{ width: '60%', mb: '15px' }}>
                                                                    <InputLabel id="valor1">Valor 1</InputLabel>
                                                                    <Select
                                                                        labelId="valor1"
                                                                        label="valor1"
                                                                        value={
                                                                            campos[indiceCampo].formula[indiceOutroCampo].question1?.id ||
                                                                            ''
                                                                        }
                                                                        onChange={(e) => {
                                                                            atualizarOutroCampo(
                                                                                indiceCampo,
                                                                                indiceOutroCampo,
                                                                                'question1',
                                                                                e.target.value
                                                                            );
                                                                            console.log(e.target.value);
                                                                        }}
                                                                    >
                                                                        <MenuItem value="">Selecione uma opção</MenuItem>
                                                                        {quizQuestion &&
                                                                            quizQuestion.map((item) => (
                                                                                <MenuItem key={item.id} value={item.id}>
                                                                                    Pergunta: {item.id} - {item.description}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                </FormControl>
                                                                <Button
                                                                    onClick={() =>
                                                                        handleChangeInputTypeQuestion1(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'text'
                                                                        )
                                                                    }
                                                                >
                                                                    <PinIcon />
                                                                </Button>
                                                                <Button
                                                                    onClick={() =>
                                                                        handleChangeInputTypeQuestion1(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'select'
                                                                        )
                                                                    }
                                                                >
                                                                    <QuestionAnswerIcon />
                                                                </Button>
                                                                {!indiceOutroCampo > 0 && (
                                                                    <FormControl sx={{ width: '25%', mb: '15px' }}>
                                                                        <InputLabel id="operacao">Operação</InputLabel>
                                                                        <Select
                                                                            labelId="operacao"
                                                                            label="operacao"
                                                                            value={formula.operation}
                                                                            onChange={(e) =>
                                                                                atualizarOutroCampo(
                                                                                    indiceCampo,
                                                                                    indiceOutroCampo,
                                                                                    'operation',
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        >
                                                                            <MenuItem value="">Selecione uma operação</MenuItem>
                                                                            {operacoes.map((operacao) => (
                                                                                <MenuItem key={operacao.op} value={operacao.op}>
                                                                                    {operacao.symbol}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <FormControl sx={{ width: '60%', mb: '15px' }}>
                                                                    <TextField
                                                                        type="number"
                                                                        value={campos[indiceCampo].formula[indiceOutroCampo].valor1}
                                                                        placeholder="Digite o número..."
                                                                        onChange={(e) =>
                                                                            atualizarOutroCampo(
                                                                                indiceCampo,
                                                                                indiceOutroCampo,
                                                                                'valor1',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </FormControl>

                                                                <Button
                                                                    onClick={() =>
                                                                        handleChangeInputTypeQuestion1(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'text'
                                                                        )
                                                                    }
                                                                >
                                                                    <PinIcon />
                                                                </Button>
                                                                <Button
                                                                    onClick={() =>
                                                                        handleChangeInputTypeQuestion1(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'select'
                                                                        )
                                                                    }
                                                                >
                                                                    <QuestionAnswerIcon />
                                                                </Button>
                                                                {!indiceOutroCampo > 0 && (
                                                                    <FormControl sx={{ width: '25%', mb: '15px' }}>
                                                                        <InputLabel id="operacao">Operação</InputLabel>
                                                                        <Select
                                                                            labelId="operacao"
                                                                            label="operacao"
                                                                            value={formula.operation}
                                                                            onChange={(e) =>
                                                                                atualizarOutroCampo(
                                                                                    indiceCampo,
                                                                                    indiceOutroCampo,
                                                                                    'operation',
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        >
                                                                            <MenuItem value="">Selecione uma operação</MenuItem>
                                                                            {operacoes.map((operacao) => (
                                                                                <MenuItem key={operacao.op} value={operacao.op}>
                                                                                    {operacao.symbol}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                )}
                                                            </>
                                                        )}

                                                        {indiceOutroCampo === 0 ? (
                                                            // Renderizar o campo e botões para question2 apenas na primeira linha de campos.formula
                                                            <>
                                                                {formula.inputType.question2 === 'select' ? (
                                                                    <FormControl sx={{ width: '50%', mb: '15px' }}>
                                                                        <InputLabel id="valor2">Valor 2</InputLabel>
                                                                        <Select
                                                                            labelId="valor2"
                                                                            label="valor2"
                                                                            value={
                                                                                campos[indiceCampo].formula[indiceOutroCampo].question2
                                                                                    ?.id || ''
                                                                            }
                                                                            onChange={(e) => {
                                                                                atualizarOutroCampo(
                                                                                    indiceCampo,
                                                                                    indiceOutroCampo,
                                                                                    'question2',
                                                                                    e.target.value
                                                                                );
                                                                                console.log(e.target.value);
                                                                            }}
                                                                        >
                                                                            <MenuItem value="">Selecione uma opção</MenuItem>
                                                                            {quizQuestion &&
                                                                                quizQuestion.map((item) => (
                                                                                    <MenuItem key={item.id} value={item.id}>
                                                                                        Pergunta: {item.id} - {item.description}
                                                                                    </MenuItem>
                                                                                ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                ) : (
                                                                    <FormControl sx={{ width: '50%', mb: '15px' }}>
                                                                        <TextField
                                                                            type="number"
                                                                            value={campos[indiceCampo].formula[indiceOutroCampo].valor2}
                                                                            placeholder="Digite o número..."
                                                                            onChange={(e) => {
                                                                                atualizarOutroCampo(
                                                                                    indiceCampo,
                                                                                    indiceOutroCampo,
                                                                                    'valor2',
                                                                                    e.target.value
                                                                                );
                                                                                console.log(e.target.value);
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                )}

                                                                {/* Botões para mudar o tipo de input para pergunta 1 e pergunta 2 */}

                                                                <Button
                                                                    onClick={() =>
                                                                        handleChangeInputTypeQuestion2(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'text'
                                                                        )
                                                                    }
                                                                >
                                                                    <PinIcon />
                                                                </Button>
                                                                <Button
                                                                    onClick={() =>
                                                                        handleChangeInputTypeQuestion2(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'select'
                                                                        )
                                                                    }
                                                                >
                                                                    <QuestionAnswerIcon />
                                                                </Button>
                                                            </>
                                                        ) : null}

                                                        {/* {indiceOutroCampo > 0 && (
                                                            <>
                                                                <Button
                                                                    onClick={() =>
                                                                        handleChangeInputTypeQuestion1(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'text'
                                                                        )
                                                                    }
                                                                >
                                                                    Mudar pergunta 1 para input de texto
                                                                </Button>
                                                                <Button
                                                                    onClick={() =>
                                                                        handleChangeInputTypeQuestion1(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'select'
                                                                        )
                                                                    }
                                                                >
                                                                    Mudar pergunta 1 para select
                                                                </Button>
                                                            </>
                                                        )} */}
                                                        <Button onClick={() => removerOutroCampo(indiceCampo, indiceOutroCampo)}>
                                                            <DeleteIcon sx={{ color: 'red' }} />
                                                        </Button>
                                                    </Box>
                                                ))}
                                                <ButtonGroup sx={{ display: 'flex' }}>
                                                    <Button onClick={() => adicionarOutroCampo(indiceCampo)}>
                                                        <AddIcon />
                                                    </Button>
                                                </ButtonGroup>
                                            </Box>
                                        ))}
                                        <ButtonGroup sx={{ marginTop: '2.3em' }}>
                                            <Button onClick={adicionarCampo}>Adicionar MP</Button>
                                            <Button onClick={removerCampo}>Remover MP</Button>
                                        </ButtonGroup>
                                        {inputError && (
                                            <Typography variant="body2" sx={{ color: 'red' }}>
                                                Selecione um item antes de adicionar outro campo!
                                            </Typography>
                                        )}
                                    </Box>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        sx={{ width: '40%', marginTop: '2em' }}
                                        //disabled={inputError || productAssociateds.length === 0 || !inputs.length > 0}
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Cadastrar
                                    </Button>
                                    {/* <ButtonGroup
                                        sx={{ width: '80%', marginTop: '2em', justifySelf: 'center', justifyContent: 'space-between' }}
                                    >
                                        <Box sx={{ display: 'flex', width: '35%' }}>
                                            <Button
                                                variant="contained"
                                                sx={{ marginRight: '2em' }}
                                                color="primary"
                                                type="button"
                                                onClick={adicionarCampo}
                                            >
                                                Adicionar MP
                                            </Button>
                                            <Button variant="contained" color="primary" type="button" onClick={removerCampo}>
                                                Remover MP
                                            </Button>
                                        </Box>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            sx={{ width: '30%' }}
                                            //disabled={inputError || productAssociateds.length === 0 || !inputs.length > 0}
                                            type="submit"
                                            onClick={handleSubmit}
                                        >
                                            Cadastrar
                                        </Button>
                                    </ButtonGroup> */}
                                </Box>
                            )}
                        </Grid>
                    )}

                    {!toogleCard && (
                        <Grid container spacing={gridSpacing}>
                            <Grid item container sm={12} columns={12} spacing={2} sx={{ justifyContent: 'center' }}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginBottom: '2em',
                                        padding: '1em'
                                    }}
                                >
                                    <Button sx={{ textTransform: 'none' }} variant="contained" onClick={() => handleResetMainStates()}>
                                        Inserir Item
                                    </Button>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={allList}
                                        getOptionLabel={(list) => list?.product?.productName}
                                        value={selectedList}
                                        onChange={(event, newValue) => {
                                            setSelectedList(newValue);
                                        }}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Pesquisar materia prima" />}
                                    />
                                </Box>
                                {loading && <CircularProgress sx={{ marginTop: '2em', marginLeft: '2em' }} aria-label="progress" />}
                                {/* COMPLETAR COM OS DADOS DE LISTA MATERIAL (  ) */}
                                {!allList.length > 0 && !loading && <Typography>Sem listas cadastradas</Typography>}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h2 style={{ display: 'inline-block', padding: '5px 10px' }}>Lista de material do item - </h2>
                                    <div style={{ display: 'flex', padding: '5px 10px', gap: '10px' }}>
                                        <h2>{allList[0]?.finalProduct?.id}</h2>
                                        <h2>{allList[0]?.finalProduct?.productName}</h2>
                                    </div>
                                </div>
                                <TableContainer style={{ maxHeight: '500px' }}>
                                    <Table stickyHeader aria-label="sticky table" size="small">
                                        <TableHead>
                                            {allList.length > 0 && (
                                                <TableRow>
                                                    <TableCell>
                                                        <TableSortLabel onClick={handleSortById}>Id</TableSortLabel>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <TableSortLabel onClick={handleSortByNome}>Item</TableSortLabel>
                                                    </TableCell>

                                                    {/* <TableCell align="right">
                                                        <TableSortLabel onClick={handleSortByNome}>Pergunta Substituta</TableSortLabel>
                                                    </TableCell> */}
                                                    <TableCell align="right">
                                                        <TableSortLabel onClick={handleSortById}>Quantidade</TableSortLabel>
                                                    </TableCell>
                                                    <TableCell align="right">Perda</TableCell>
                                                    <TableCell sx={{ pr: 3 }} align="right">
                                                        Ações
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableHead>
                                        <TableBody>
                                            {allList.length > 0 &&
                                                allList

                                                    .filter((list) =>
                                                        selectedList
                                                            ? list?.product?.productName.includes(selectedList?.product?.productName)
                                                            : true
                                                    )
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((material, index) => <Row key={index} material={material} />)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {allList.length > 5 && (
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={allList.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                )}
                                {/* {allList.length > 0 &&
                                    allList.map((material) => (
                                        <Grid item key={material.id} xs={6} sx={{ justifySelf: 'center' }}>
                                            <MaterialCard id={material.id} material={material} deletefc={handleDelete} />
                                        </Grid>
                                    ))} */}
                            </Grid>
                        </Grid>
                    )}
                </MainCard>
            )}

            {/* caso não seja informado matéria prima no submit troca a view */}
            {showError && <ErrorView setIsChecked={setIsChecked} setSelectedQuiz={setSelectedQuiz} setShowError={setShowError} />}
        </>
    );
}

export default Material;
