import { Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import SimpleTable from 'ui-component/SimpleTableComponent';
import { formatarValorIntl } from 'utils/formatValueIntl';
import { formatarNumero } from 'utils/formatarNumeroReal';
import { formatarData } from 'utils/regex';
const ExpensesModal = ({ open, setOpen, dataParams, currentMode }) => {
    const api = useApi();
    const [rows, setRows] = useState([]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        maxHeight: '700px',
        overflow: 'auto',
        borderRadius: '8px'
    };

    function formatarData(data) {
        const meses = [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'
        ];

        const partes = data.split('-'); // Divide a data em partes [ano, mês]
        const ano = partes[0];
        const mes = parseInt(partes[1], 10) - 1; // Converte o mês para índice (0-11)

        return `${meses[mes]} de ${ano}`;
    }

    const formatCurrency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });

    const getExpenses = async () => {
        try {
            const { data } = await api.getProjCostExpenses(dataParams.mesMovimento, dataParams.mesMovimento);

            const row = data.map((item, index) => ({
                ...item,
                data: formatarData(item.mesMovimento),
                ggf: formatarValorIntl(item.ggf),
                despFixa: formatarValorIntl(item.despFixa),
                despVariavel: formatarValorIntl(item.despVariavel),
                mvlrTotalConsumo: formatarValorIntl(item.mvlrTotalConsumo)
            }));
            setRows(row);
        } catch (error) {
            console.log('🚀 ~ file: index.js:15 ~ getConsumption ~ error:', error);
        }
    };

    useEffect(() => {
        if (open) {
            getExpenses();
        }
    }, [open]);

    const columns = [
        { field: 'projeto', headerName: 'Projeto', width: 130 },
        { field: 'data', headerName: 'Período', width: 130 },
        { field: 'ggf', headerName: 'GGF', width: 100 },
        { field: 'despFixa', headerName: 'Desp. Fixa', type: 'number', width: 300 },
        { field: 'despVariavel', headerName: 'Desp Variáveis', type: 'number', width: 100 },
        { field: 'mvlrTotalConsumo', headerName: 'Total Consumo', type: 'number', width: 100 },
        {
            field: 'analiseConsumo',
            headerName: '%',
            type: 'number',
            width: 80,
            renderCell: (params) => {
                const cor = params.row.analiseConsumo.toFixed(0); // Substitua 'kpi_resultado1' pelo nome real do campo
                let backgroundColor = '#999'; // Cor padrão

                if (cor <= 30) {
                    backgroundColor = 'green';
                } else if (cor > 30 && cor <= 50) {
                    backgroundColor = '#FFD700';
                } else if (cor > 50) {
                    backgroundColor = 'red';
                }
                return (
                    <div
                        style={{
                            backgroundColor,
                            padding: '8px 5px',
                            borderRadius: 8,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '24px'
                        }}
                    >
                        {cor}%
                    </div>
                );
            }
        }
    ];

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} onClick={(e) => e.stopPropagation()}>
                    <h2 style={{ textAlign: 'center' }}>{`Despesas - ${formatarData(dataParams.mesMovimento)}`}</h2>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            margin: 'auto',
                            marginTop: '10px',
                            overflow: 'auto'
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell sx={{ fontSize: currentMode.th }} key={column.field} align="center">
                                                {column.headerName}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.id}>
                                            {columns.map((column) => (
                                                <TableCell sx={{ fontSize: currentMode.tb }} key={column.field} align="center">
                                                    {column.renderCell ? column.renderCell({ row }) : row[column.field]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default ExpensesModal;
