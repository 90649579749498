import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// Estilo do modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 4
};

export default function ObservationModal({ questions, quizId, currentObservation }) {
    console.log('🚀 ~ ObservationModal ~ currentObservation:', currentObservation);
    const [open, setOpen] = useState(false); // Estado do modal (aberto/fechado)
    const [questionId, setQuestionId] = useState(null); // Estado da pergunta selecionada
    const [observationText, setObservationText] = useState(''); // Estado da observação
    const handleOpen = () => setOpen(true); // Função para abrir o modal
    const handleClose = () => setOpen(false); // Função para fechar o modal
    const api = useApi(); // Hook customizado para chamadas API

    // Efeito para inicializar a observação com o valor atual
    useEffect(() => {
        setObservationText(currentObservation?.observation);
    }, [currentObservation]);

    // Função para manipular a seleção de perguntas
    const handleChange = (event) => {
        setQuestionId(event.target.value); // Atualiza o ID da pergunta selecionada
        setObservationText((prev) => prev + ` $${event.target.value}$ `); // Atualiza o valor do campo de texto
    };

    // Função para enviar o formulário
    async function handleSubmit(event) {
        event.preventDefault();
        const payload = {
            observation: observationText, // Obtém o valor atual da observação
            quiz: { id: quizId }
        };

        const toastId = toast.loading('Salvando observação...');
        try {
            await api.createQuizNotes(payload); // Chama a API para salvar a observação
            toast.update(toastId, { render: 'Observação salva com sucesso!', type: 'success', isLoading: false, autoClose: 3000 });
            handleClose(); // Fecha o modal
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao salvar observação!', type: 'error', isLoading: false, autoClose: 3000 });
            console.log('🚀 ~ handleSubmit ~ error:', error); // Loga o erro
        }
    }

    async function handleEditObservation(e) {
        e.preventDefault();
        const payload = {
            observation: observationText,
            quiz: { id: quizId }
        };
        const toastId = toast.loading('Atualizando observação...');
        try {
            await api.editQuizNotesId(currentObservation?.id, payload);
            toast.update(toastId, { render: 'Observação atualizada com sucesso!', type: 'success', isLoading: false, autoClose: 3000 });
            handleClose();
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao atualizar observação!', type: 'error', isLoading: false, autoClose: 3000 });
            console.log('🚀 ~ handleEditObservation ~ error:', error);
        }
    }

    return (
        <div>
            <Button variant="contained" onClick={handleOpen}>
                Observação padrão
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>Adicione uma observação personalizada</h3>

                    <form onSubmit={currentObservation?.observation?.length > 0 ? handleEditObservation : handleSubmit}>
                        <FormControl sx={{ width: '300px', marginBottom: '20px' }}>
                            <InputLabel id="demo-simple-select-label">Perguntas vinculadas</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={questionId}
                                label="Perguntas vinculadas"
                                onChange={handleChange}
                            >
                                {questions.map((question) => (
                                    <MenuItem key={question.id} value={question.id}>
                                        {question.description}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Observação"
                            value={observationText}
                            onChange={(e) => setObservationText(e.target.value)}
                            multiline
                            rows={3}
                            fullWidth
                        />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button type="submit" variant="contained" sx={{ width: '200px' }} color="primary" style={{ marginTop: '20px' }}>
                                {currentObservation?.observation?.length > 0 ? 'Atualizar observação' : 'Salvar observação'}
                            </Button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
