// assets
import { IconGrain } from '@tabler/icons';

// constant
const icons = {
    IconGrain
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const group = {
    id: 'group',
    title: 'Group',
    type: 'group',
    children: [
        {
            id: 'group',
            title: 'Grupo',
            type: 'item',
            url: '/main/groups',
            icon: icons.IconGrain,
            breadcrumbs: false
        }
    ]
};

export default group;
