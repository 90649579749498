import { Button, FormControl, Modal, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import { formatarData } from 'utils/regex';
import DeleteIcon from '@mui/icons-material/Delete';

import { formatarValorIntl } from 'utils/formatValueIntl';

const GoalsModal = ({ open, setOpen, getCurrentGoalsRequest, allGoals }) => {
    const api = useApi();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        background: '#fff',
        boxShadow: 24,
        padding: 12,
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px'
    };

    const [metaValue, setMetaValue] = useState('');
    const [monthYear, setMonthYear] = useState('');
    const [rows, setRows] = useState([]);

    const getRows = async () => {
        try {
            const { data } = await api.getAllGoalsWithoutFilter();
            console.log('🚀 ~ getRows ~ data:', data);
            const rows = data.map((goal) => ({
                id: goal.id,
                dataMeta: goal.dataMeta,
                valor: formatarValorIntl(goal.valor)
            }));
            setRows(rows);
        } catch (error) {
            console.log('🚀 ~ getRows ~ error:', error);
        }
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'dataMeta',
            headerName: 'Data',
            width: 120
        },
        {
            field: 'valor',
            headerName: 'Valor',
            width: 150
        },
        {
            field: 'action',
            headerName: 'Ações',
            width: 90,
            editable: true,
            renderCell: (params) => {
                return (
                    <Button variant="contained" color="error" onClick={() => handleDelete(params.row.id)}>
                        <DeleteIcon />
                    </Button>
                );
            }
        }
    ];

    const handleDelete = async (id) => {
        try {
            await api.deleteGoals(id);
            toast.success('Meta deletada com sucesso');
            getRows();
        } catch (error) {
            console.log('🚀 ~ handleDelete ~ error:', error);
            toast.error('Erro ao deletar meta');
        }
    };

    useEffect(() => {
        getRows();
    }, []);

    const formatCurrency = (value) => {
        // Remove caracteres não numéricos
        const numericValue = value.replace(/[^0-9]/g, '');

        // Formatar como moeda brasileira sem o símbolo de moeda
        const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(parseInt(numericValue, 10) / 100);

        return formattedValue;
    };

    const handleMonthYearChange = (event) => {
        setMonthYear(event.target.value);
    };

    function formatarNumero(valor) {
        // Remova todos os caracteres não numéricos
        valor = valor.replace(/\D/g, '');

        // Inverta o valor para facilitar a formatação
        valor = valor.split('').reverse().join('');

        // Divida o valor em grupos de 3 dígitos, adicionando um ponto no final de cada grupo
        let formattedValue = '';
        for (let i = 0; i < valor.length; i += 3) {
            let group = valor.substring(i, i + 3);
            formattedValue += group + '.';
        }

        // Remova o ponto extra no final e reverta a string de volta para a ordem original
        formattedValue = formattedValue.slice(0, -1).split('').reverse().join('');

        // Retorne o valor formatado
        return formattedValue;
    }

    const handleMetaChange = (event) => {
        const formattedValue = formatarNumero(event.target.value);
        setMetaValue(formattedValue);
    };

    const extractDigits = (value) => {
        // Remove caracteres não numéricos
        const numericValue = value.replace(/[^0-9]/g, '');
        return numericValue;
    };

    const handleSubmit = async () => {
        const newDateFormate = formatarData(monthYear);
        const payload = {
            dataMeta: newDateFormate,
            valor: extractDigits(metaValue)
        };

        try {
            await api.postGoals(payload);
            getCurrentGoalsRequest();
            getRows();
            // setOpen(false);
            toast.success('Meta Atualizada com sucesso');
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
            toast.error('Erro ao atualizar meta');
        }
    };

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={style}>
                    <h3>Metas do mês</h3>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <FormControl sx={{ width: '100%', marginBottom: '15px' }}>
                            <TextField
                                sx={{ width: '330px' }}
                                label="Ano e mês"
                                type="month"
                                value={monthYear}
                                onChange={handleMonthYearChange}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </FormControl>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField sx={{ width: '330px' }} label="Meta" type="text" value={metaValue} onChange={handleMetaChange} />
                        </FormControl>
                    </div>
                    <Button variant="contained" onClick={handleSubmit} sx={{ width: '100%' }}>
                        Salvar metas
                    </Button>
                    <div>
                        <DataGrid
                            sx={{
                                maxHeight: '300px'
                            }}
                            rows={rows}
                            columns={columns}
                            hideFooter
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default GoalsModal;
