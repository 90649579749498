import { useContext } from 'react';
import { FormulaContext } from 'store/FormulaContext';

export const useFormula = () => {
    const context = useContext(FormulaContext);

    if (!context) console.log('Contexto não encontrado');

    return context;
};
