import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    TextField,
    Typography,
    Box,
    InputLabel,
    Select,
    MenuItem,
    ButtonGroup,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Collapse,
    Divider,
    Tooltip,
    FormHelperText,
    TablePagination,
    CircularProgress
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import MaterialCard from 'ui-component/MaterialCard';
import ErrorView from './ErrorView';
import PinIcon from '@mui/icons-material/Pin';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SubCard from 'ui-component/cards/SubCard';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { IconPencil } from '@tabler/icons';
import RampRightIcon from '@mui/icons-material/RampRight';
import Autocomplete from '@mui/material/Autocomplete';

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const initialState = {
    materiaPrima: '',
    quantidade: 0,
    percentual: 0,
    itemSubstituto: '',
    formula: []
};

const NewMp = () => {
    const navigate = useNavigate();
    const api = useApi();
    //switch
    const [isChecked, setIsChecked] = useState(false);

    // pegando todos os produtos para filtrar por matéria prima
    const [allProducts, setAllProducts] = useState([]);

    // pegar o quiz selecionado com base no produto final
    const [selectedQuiz, setSelectedQuiz] = useState();

    // states para Material View
    const [teste, setTeste] = useState();

    const [toogleCard, setToogleCard] = useState(false);

    const [allList, setAllList] = useState([]);

    const [showError, setShowError] = useState(false);

    const [materiaPrimaError, setMateriaPrimaError] = useState(false);
    const [itemSubstitutoError, setItemSubstitutoError] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getAllProducts = async () => {
        const response = await api.getAllProducts();
        setAllProducts(response.data);
    };

    // ====== get all quiz => pickup all final products who has a questionary
    const [quiz, setQuiz] = useState([]);
    const getAllQuiz = async () => {
        const { data } = await api.getAllQuiz();
        setQuiz(data);
    };

    // Filtro para pegar todos os produtos de tipo === Matéria Prima
    let primaryProducts = allProducts.filter((product) => product.productPrimary === true);

    const [inputError, setInputError] = useState(false);

    const getAllList = async () => {
        try {
            const response = await api.getAllList();

            const filter = response?.data?.filter((item) => item.finalProduct.id === Number(id));

            setAllList(filter);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllProducts();
        getAllQuiz();
        getAllList();
        getallQuestions();
    }, []);

    // resetar os states de controle do form
    const handleResetMainStates = () => {
        navigate(-1);
        setToogleCard(!toogleCard);
        setSelectedQuiz(undefined);
        setIsChecked(false);
    };

    const operacoes = [
        { id: 1, op: 'plus', symbol: '+' },
        { id: 2, op: 'minor', symbol: '-' },
        { id: 3, op: 'times', symbol: '*' },
        { id: 4, op: 'divided', symbol: '/' }
    ];

    // ================= LÓGICA DO FORMULÁRIO

    const [quizQuestion, setQuizQuestion] = useState([]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Lógica para lidar com o envio do formulário

        try {
            const hasEmptyMateriaPrima = campos.some((item) => item.materiaPrima === '');
            const hasEmptyItemSubstituto = campos.some((item) => item.itemSubstituto === '');

            setMateriaPrimaError(hasEmptyMateriaPrima);
            // setItemSubstitutoError(hasEmptyItemSubstituto);

            if (hasEmptyMateriaPrima) {
                return;
            }

            const MATERIAL = {
                quiz: teste.id,
                finalProductId: selectedQuiz.product,
                materialList: campos,
                itemSub: campos[0].itemSubstituto
            };

            for (let i = 0; i < MATERIAL.materialList.length; i++) {
                MATERIAL.materialList[i].formula = MATERIAL.materialList.map((item) => {
                    if (item) {
                        const b = item.formula.map((teste) => {
                            const a = teste;
                            if (a.question1 && a.question2 && a.question1.id === '' && a.question2.id === '') {
                                const newTeste = {
                                    valor2: parseFloat(a.valor2),
                                    operation: a.operation,
                                    valor1: parseFloat(a.valor1)
                                };
                                return newTeste;
                            } else if (a.question1 && a.question1.id === '') {
                                const newTeste = {
                                    question2: a.question2,
                                    operation: a.operation,
                                    valor1: parseFloat(a.valor1),
                                    valor2: parseFloat(a.valor2)
                                };
                                return newTeste;
                            } else if (a.question2 && a.question2.id === '') {
                                const newTeste = {
                                    question1: a.question1,
                                    operation: a.operation,
                                    valor1: parseFloat(a.valor1),
                                    valor2: parseFloat(a.valor2)
                                };

                                return newTeste;
                            }
                            return a;
                        });
                        return b;
                    }
                    return item;
                });
            }

            await api.createList(MATERIAL.quiz, MATERIAL);
            getAllList();
            toast.success('Lista de material criada com sucesso!');
            window.location.reload();
            navigate(-1);
            //setToogleCard(false);
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
            toast.error('Erro ao criar lista de material');
        }
    };

    const handleDelete = async (id) => {
        const response = await api.deleteList(id);
        getAllList();
    };

    const [campos, setCampos] = useState([initialState]);

    //usar essa (pai) sem a formula

    const adicionarCampo = () => {
        setCampos([
            ...campos,
            {
                materiaPrima: '',
                quantidade: 0,
                percentual: 0,
                itemSubstituto: '',
                formula: [
                    {
                        operation: '',
                        question1: { id: '' },
                        question2: { id: '' },
                        inputType: { question1: 'select', question2: 'select', valor1: 'textfield', valor2: 'textfield' },
                        valor1: 0,
                        valor2: 0
                    }
                ]
            }
        ]);
    };

    const removerCampo = () => {
        setCampos(campos.slice(0, -1));
    };
    // usar essa func
    const adicionarOutroCampo = (indiceCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].formula.push({
                operation: '',
                question1: { id: '' },
                question2: { id: '' },
                inputType: { question1: 'select', question2: 'select', valor1: 'textfield', valor2: 'textfield' },
                valor1: 0,
                valor2: 0
            });
            return novosCampos;
        });
    };

    const removerOutroCampo = (indiceCampo, indiceOutroCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].formula.splice(indiceOutroCampo, 1);
            return novosCampos;
        });
    };

    const handleChangeInputTypeQuestion1 = (indiceCampo, indiceOutroCampo, novoTipo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].formula[indiceOutroCampo].inputType.question1 = novoTipo;

            if (novoTipo === 'textfield') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor1 = '';
            } else {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor1 = 0; // Define como null quando o tipo for alterado para select
                novosCampos[indiceCampo].formula[indiceOutroCampo].question1 = { id: '' };
            }

            return novosCampos;
        });
    };

    const handleChangeInputTypeQuestion2 = (indiceCampo, indiceOutroCampo, novoTipo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].formula[indiceOutroCampo].inputType.question2 = novoTipo;

            if (novoTipo === 'textfield') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor2 = 0;
            } else {
                novosCampos[indiceCampo].formula[indiceOutroCampo].question2 = { id: '' };
            }

            return novosCampos;
        });
    };
    //essa tbm
    const atualizarCampo = (indiceCampo, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            if (campo === 'quantidade' || campo === 'percentual') {
                novosCampos[indiceCampo][campo] = Number(valor);
            } else {
                novosCampos[indiceCampo][campo] = valor;
            }
            return novosCampos;
        });
    };

    const atualizarOutroCampo = (indiceCampo, indiceOutroCampo, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            if (campo === 'operation') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].operation = valor;
            } else if (
                campo === 'valor1' &&
                novosCampos[indiceCampo].formula[indiceOutroCampo].inputType &&
                novosCampos[indiceCampo].formula[indiceOutroCampo].inputType.question1 === 'textfield'
            ) {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor1 = valor;
            } else if (
                campo === 'valor2' &&
                novosCampos[indiceCampo].formula[indiceOutroCampo].inputType &&
                novosCampos[indiceCampo].formula[indiceOutroCampo].inputType.question2 === 'textfield'
            ) {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor2 = valor;
            } else if (typeof valor === 'object' && valor !== null) {
                novosCampos[indiceCampo].formula[indiceOutroCampo][campo] = { id: valor.id };
            } else {
                novosCampos[indiceCampo].formula[indiceOutroCampo][campo] = valor;
            }

            // Atualize o valor selecionado no estado correspondente
            if (campo === 'question1') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].question1 = { id: valor };
            } else if (campo === 'question2') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].question2 = { id: valor };
            }
            return novosCampos;
        });
    };
    const [substituteItemList, setSubistituteItemList] = useState([]);

    const [allQuestionsGet, setAllQuestionsGet] = useState([]);
    const getallQuestions = async () => {
        try {
            const { data } = await api.getAllQuestion();
            setAllQuestionsGet(data);
        } catch (error) {
            console.log('🚀 ~ getQuestion ~ error:', error);
        }
    };

    const getQuizById = async (id) => {
        try {
            // Encontrar o quiz pelo ID
            const currentQuiz = quiz.find((item) => item.id === id);
            setSelectedQuiz(currentQuiz);
            console.log('🚀 ~ getQuizById ~ currentQuiz:', currentQuiz);

            // Fazer a chamada de API
            const { data } = await api.getQuizByIdV2(id);
            console.log('🚀 ~ getCurrentQuiz ~ data:', data);

            // Filtrar as perguntas do quiz
            const filterQuestionQuiz = allQuestionsGet.filter((item) => data.some((itemQuiz) => itemQuiz.description === item.description));
            console.log('🚀 ~ getCurrentQuiz ~ filterQuestionQuiz:', filterQuestionQuiz);

            // Atualizar o estado do componente
            setSubistituteItemList(filterQuestionQuiz);
        } catch (error) {
            console.log('🚀 ~ file: EditMaterial.js:93 ~ getCurrentQuiz ~ error:', error.message);
            // Tratar ou relançar o erro conforme necessário
        }
    };

    useEffect(() => {
        console.log('selected quiz log teste', selectedQuiz);
    }, [selectedQuiz]);

    return (
        <Grid container>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '1rem',
                    width: '100%',
                    padding: '1.6em',
                    background: '#fff'
                }}
            >
                <Button variant="contained" sx={{ marginBottom: '3em' }} onClick={() => handleResetMainStates()}>
                    Ver listas cadastradas
                </Button>
                <Typography sx={{ fontSize: '20px' }}>Selecione um produto final</Typography>
                <FormControl sx={{ width: '80%', mb: '15px' }}>
                    <InputLabel id="product">Produto</InputLabel>
                    <Select
                        sx={{ width: '100%' }}
                        labelId="product"
                        value={selectedQuiz}
                        label="product"
                        onChange={(e) => {
                            const currentQuiz = e.target.value;
                            setTeste(currentQuiz);
                            setIsChecked(true);
                            getQuizById(currentQuiz.id);
                        }}
                        required
                    >
                        {quiz.map((item) => (
                            <MenuItem key={item.id} value={item}>
                                {item?.product?.productName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            {isChecked && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '1rem',
                        width: '100%',
                        padding: '1.6em',
                        background: '#fff'
                    }}
                >
                    <Box sx={{ display: 'flex', width: '80%', gap: '1rem', flexDirection: 'column' }}>
                        {campos.map((campo, indiceCampo) => (
                            <Box key={indiceCampo} sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ display: 'flex', gap: '1rem' }}>
                                    {/* {campos && campos.length > 1 && (
                                                        <Button
                                                            type="button"
                                                            disabled={campos.length === 1}
                                                            onClick={() => removerCampo(indiceCampo)}
                                                        >
                                                            Remover Campo
                                                        </Button>
                                                    )} */}

                                    <FormControl sx={{ width: '55%', mb: '15px' }}>
                                        <InputLabel id="materia prima">Matéria Prima *</InputLabel>
                                        <Select
                                            sx={{ width: '100%' }}
                                            labelId="materia prima"
                                            label="materia Prima"
                                            error={materiaPrimaError}
                                            value={campo.materiaPrima}
                                            helperText={materiaPrimaError ? 'Campo obrigatório' : null}
                                            onChange={(e) => {
                                                setMateriaPrimaError(false);
                                                atualizarCampo(indiceCampo, 'materiaPrima', e.target.value);
                                            }}
                                            required
                                        >
                                            {primaryProducts &&
                                                primaryProducts.map((item) => (
                                                    <MenuItem key={item.id} value={item}>
                                                        Id: {item.id} - {item.productName}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {materiaPrimaError && <FormHelperText sx={{ color: 'red' }}>Campo obrigatório</FormHelperText>}
                                    </FormControl>
                                    <TextField
                                        label="Quantidade"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        value={campo.quantidade}
                                        onChange={(e) => atualizarCampo(indiceCampo, 'quantidade', e.target.value)}
                                    />
                                    <TextField
                                        label="Percentual Perda"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        value={campo.percentual}
                                        onChange={(e) => atualizarCampo(indiceCampo, 'percentual', e.target.value)}
                                    />
                                    <FormControl sx={{ width: '55%', mb: '15px' }}>
                                        <InputLabel id="item_subs">Item Substituto*</InputLabel>
                                        <Select
                                            sx={{ width: '100%' }}
                                            labelId="item_subs"
                                            label="Item Substituto*"
                                            // error={itemSubstitutoError}
                                            value={campo.itemSubstituto}
                                            // helperText={itemSubstitutoError ? 'Campo obrigatório' : null}
                                            onChange={(e) => {
                                                setItemSubstitutoError(false);
                                                atualizarCampo(indiceCampo, 'itemSubstituto', e.target.value);
                                            }}
                                            required
                                        >
                                            {quizQuestion &&
                                                substituteItemList
                                                    .filter((item) => item.type === 'Fechada')
                                                    .map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            Pergunta: {item.id} - {item.description}
                                                        </MenuItem>
                                                    ))}
                                        </Select>
                                        {/* {itemSubstitutoError && <FormHelperText sx={{ color: 'red' }}>Campo obrigatório</FormHelperText>} */}
                                    </FormControl>
                                </Box>
                                {campo.formula.map((formula, indiceOutroCampo) => (
                                    // select de operação
                                    <Box
                                        key={indiceOutroCampo}
                                        sx={{
                                            display: 'flex',
                                            gap: '1rem',
                                            width: '100%',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        {indiceOutroCampo > 0 && (
                                            <FormControl sx={{ width: '25%', mb: '15px' }}>
                                                <InputLabel id="operacao">Operação</InputLabel>
                                                <Select
                                                    labelId="operacao"
                                                    label="operacao"
                                                    value={formula.operation}
                                                    onChange={(e) =>
                                                        atualizarOutroCampo(indiceCampo, indiceOutroCampo, 'operation', e.target.value)
                                                    }
                                                >
                                                    <MenuItem value="">Selecione uma operação</MenuItem>
                                                    {operacoes?.map((operacao) => (
                                                        <MenuItem key={operacao.op} value={operacao.op}>
                                                            {operacao.symbol}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {formula?.inputType?.question1 === 'select' ? (
                                            <>
                                                <FormControl sx={{ width: '60%', mb: '15px' }}>
                                                    <InputLabel id="valor1">Valor 1</InputLabel>
                                                    <Select
                                                        labelId="valor1"
                                                        label="valor1"
                                                        value={campos[indiceCampo].formula[indiceOutroCampo].question1?.id || ''}
                                                        onChange={(e) => {
                                                            atualizarOutroCampo(indiceCampo, indiceOutroCampo, 'question1', e.target.value);
                                                            console.log(e.target.value);
                                                        }}
                                                    >
                                                        <MenuItem value="">Selecione uma opção</MenuItem>
                                                        {quizQuestion &&
                                                            substituteItemList.map((item) => (
                                                                <MenuItem key={item.id} value={item.id}>
                                                                    Pergunta: {item.id} - {item.description}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                                <Button
                                                    onClick={() => handleChangeInputTypeQuestion1(indiceCampo, indiceOutroCampo, 'text')}
                                                >
                                                    <PinIcon />
                                                </Button>
                                                <Button
                                                    onClick={() => handleChangeInputTypeQuestion1(indiceCampo, indiceOutroCampo, 'select')}
                                                >
                                                    <QuestionAnswerIcon />
                                                </Button>
                                                {!indiceOutroCampo > 0 && (
                                                    <FormControl sx={{ width: '25%', mb: '15px' }}>
                                                        <InputLabel id="operacao">Operação</InputLabel>
                                                        <Select
                                                            labelId="operacao"
                                                            label="operacao"
                                                            value={formula.operation}
                                                            onChange={(e) =>
                                                                atualizarOutroCampo(
                                                                    indiceCampo,
                                                                    indiceOutroCampo,
                                                                    'operation',
                                                                    e.target.value
                                                                )
                                                            }
                                                        >
                                                            <MenuItem value="">Selecione uma operação</MenuItem>
                                                            {operacoes.map((operacao) => (
                                                                <MenuItem key={operacao.op} value={operacao.op}>
                                                                    {operacao.symbol}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <FormControl sx={{ width: '60%', mb: '15px' }}>
                                                    <TextField
                                                        type="number"
                                                        value={campos[indiceCampo].formula[indiceOutroCampo].valor1}
                                                        placeholder="Digite o número..."
                                                        onChange={(e) =>
                                                            atualizarOutroCampo(indiceCampo, indiceOutroCampo, 'valor1', e.target.value)
                                                        }
                                                    />
                                                </FormControl>

                                                <Button
                                                    onClick={() => handleChangeInputTypeQuestion1(indiceCampo, indiceOutroCampo, 'text')}
                                                >
                                                    <PinIcon />
                                                </Button>
                                                <Button
                                                    onClick={() => handleChangeInputTypeQuestion1(indiceCampo, indiceOutroCampo, 'select')}
                                                >
                                                    <QuestionAnswerIcon />
                                                </Button>
                                                {!indiceOutroCampo > 0 && (
                                                    <FormControl sx={{ width: '25%', mb: '15px' }}>
                                                        <InputLabel id="operacao">Operação</InputLabel>
                                                        <Select
                                                            labelId="operacao"
                                                            label="operacao"
                                                            value={formula.operation}
                                                            onChange={(e) =>
                                                                atualizarOutroCampo(
                                                                    indiceCampo,
                                                                    indiceOutroCampo,
                                                                    'operation',
                                                                    e.target.value
                                                                )
                                                            }
                                                        >
                                                            <MenuItem value="">Selecione uma operação</MenuItem>
                                                            {operacoes.map((operacao) => (
                                                                <MenuItem key={operacao.op} value={operacao.op}>
                                                                    {operacao.symbol}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </>
                                        )}

                                        {indiceOutroCampo === 0 ? (
                                            // Renderizar o campo e botões para question2 apenas na primeira linha de campos.formula
                                            <>
                                                {formula?.inputType?.question2 === 'select' ? (
                                                    <FormControl sx={{ width: '50%', mb: '15px' }}>
                                                        <InputLabel id="valor2">Valor 2</InputLabel>
                                                        <Select
                                                            labelId="valor2"
                                                            label="valor2"
                                                            value={campos[indiceCampo].formula[indiceOutroCampo].question2?.id || ''}
                                                            onChange={(e) => {
                                                                atualizarOutroCampo(
                                                                    indiceCampo,
                                                                    indiceOutroCampo,
                                                                    'question2',
                                                                    e.target.value
                                                                );
                                                                console.log(e.target.value);
                                                            }}
                                                        >
                                                            <MenuItem value="">Selecione uma opção</MenuItem>
                                                            {quizQuestion &&
                                                                substituteItemList.map((item) => (
                                                                    <MenuItem key={item.id} value={item.id}>
                                                                        Pergunta: {item.id} - {item.description}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                ) : (
                                                    <FormControl sx={{ width: '50%', mb: '15px' }}>
                                                        <TextField
                                                            type="number"
                                                            value={campos[indiceCampo].formula[indiceOutroCampo].valor2}
                                                            placeholder="Digite o número..."
                                                            onChange={(e) => {
                                                                atualizarOutroCampo(
                                                                    indiceCampo,
                                                                    indiceOutroCampo,
                                                                    'valor2',
                                                                    e.target.value
                                                                );
                                                                console.log(e.target.value);
                                                            }}
                                                        />
                                                    </FormControl>
                                                )}

                                                {/* Botões para mudar o tipo de input para pergunta 1 e pergunta 2 */}

                                                <Button
                                                    onClick={() => handleChangeInputTypeQuestion2(indiceCampo, indiceOutroCampo, 'text')}
                                                >
                                                    <PinIcon />
                                                </Button>
                                                <Button
                                                    onClick={() => handleChangeInputTypeQuestion2(indiceCampo, indiceOutroCampo, 'select')}
                                                >
                                                    <QuestionAnswerIcon />
                                                </Button>
                                            </>
                                        ) : null}

                                        {/* {indiceOutroCampo > 0 && (
                                                            <>
                                                                <Button
                                                                    onClick={() =>
                                                                        handleChangeInputTypeQuestion1(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'text'
                                                                        )
                                                                    }
                                                                >
                                                                    Mudar pergunta 1 para input de texto
                                                                </Button>
                                                                <Button
                                                                    onClick={() =>
                                                                        handleChangeInputTypeQuestion1(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'select'
                                                                        )
                                                                    }
                                                                >
                                                                    Mudar pergunta 1 para select
                                                                </Button>
                                                            </>
                                                        )} */}
                                        <Button onClick={() => removerOutroCampo(indiceCampo, indiceOutroCampo)}>
                                            <DeleteIcon sx={{ color: 'red' }} />
                                        </Button>
                                    </Box>
                                ))}
                                <ButtonGroup sx={{ display: 'flex' }}>
                                    <Button onClick={() => adicionarOutroCampo(indiceCampo)}>
                                        <AddIcon />
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        ))}
                        <ButtonGroup sx={{ marginTop: '2.3em' }}>
                            <Button onClick={adicionarCampo}>Adicionar MP</Button>
                            <Button onClick={removerCampo}>Remover MP</Button>
                        </ButtonGroup>
                        {inputError && (
                            <Typography variant="body2" sx={{ color: 'red' }}>
                                Selecione um item antes de adicionar outro campo!
                            </Typography>
                        )}
                    </Box>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ width: '40%', marginTop: '2em' }}
                        //disabled={inputError || productAssociateds.length === 0 || !inputs.length > 0}
                        type="submit"
                        onClick={handleSubmit}
                    >
                        Cadastrar
                    </Button>
                </Box>
            )}
        </Grid>
    );
};
export default NewMp;
