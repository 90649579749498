import {
    IconBrackets,
    IconColorPicker,
    IconGrain,
    IconListCheck,
    IconPencil,
    IconQuestionMark,
    IconTarget,
    IconWall,
    IconList,
    IconSitemap,
    IconBrandProducthunt,
    IconFileSpreadsheet
} from '@tabler/icons';

const icons = {
    IconPencil,
    IconBrackets,
    IconTarget,
    IconWall,
    IconGrain,
    IconColorPicker,
    IconQuestionMark,
    IconListCheck,
    IconList,
    IconSitemap,
    IconBrandProducthunt
};

const records = {
    id: 'product',
    title: 'Produto',
    type: 'group',
    children: [
        {
            id: 'cadastros',
            title: 'Cadastros',
            type: 'collapse',
            icon: icons.IconBrandProducthunt,
            children: [
                {
                    id: 'group',
                    title: 'Grupo',
                    type: 'item',
                    url: '/main/groups',
                    icon: icons.IconGrain,
                    breadcrumbs: false
                },
                {
                    id: 'group color',
                    title: 'Grupos de Cor',
                    type: 'item',
                    url: '/main/groupcolor',
                    icon: icons.IconList,
                    breadcrumbs: false
                },
                {
                    id: 'util-typography',
                    title: 'Unidade',
                    type: 'item',
                    url: '/main/unit',
                    icon: icons.IconBrackets,
                    breadcrumbs: false
                },
                {
                    id: 'mp',
                    title: 'Matéria Prima',
                    type: 'item',
                    url: '/main/cores',
                    icon: icons.IconSitemap,
                    breadcrumbs: false
                },
                {
                    id: 'import',
                    title: 'Importador de preços',
                    type: 'item',
                    url: '/main/importador-precos',
                    icon: IconFileSpreadsheet,
                    breadcrumbs: false
                },
                {
                    id: 'products',
                    title: 'Produtos',
                    type: 'item',
                    url: '/main/products',
                    icon: icons.IconTarget,
                    breadcrumbs: false
                },
                {
                    id: 'question',
                    title: 'Perguntas',
                    type: 'item',
                    url: '/main/questions',
                    icon: icons.IconQuestionMark,
                    breadcrumbs: false
                },
                {
                    id: 'questionnaires',
                    title: 'Questionarios',
                    type: 'item',
                    url: '/main/questionnaires',
                    icon: icons.IconListCheck,
                    breadcrumbs: false
                },
                {
                    id: 'products config',
                    title: 'Lista de material',
                    type: 'item',
                    url: '/main/material',
                    icon: icons.IconWall,
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default records;
