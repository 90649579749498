import { Button, Drawer, TextField } from '@mui/material';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';

const DrawerAdd = ({ open, setOpen, get }) => {
    const handleClose = () => setOpen(false);
    const api = useApi();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            pergentagem: ''
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().max(255).required('campo obrigatório'),
            pergentagem: Yup.string().required('campo obrigatório')
        }),
        onSubmit: async (values, { resetForm }) => {
            const payload = {
                name: values.name,
                percentage: values.pergentagem
            };
            console.log('🚀 ~ file: DrawerAdd.js:24 ~ onSubmit: ~ payload:', payload);

            try {
                await api.addPaymentTerms(payload);
                resetForm();
                setOpen(false);
                get();
                toast.success('Condição de pagamento adicionada com suceso!');
            } catch (error) {
                console.log('🚀 ~ file: DrawerAddCostumer.js:110 ~ onSubmit: ~ error:', error.message);
            }
        }
    });

    return (
        <Drawer anchor="right" open={open} onClose={handleClose}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px 80px' }}>
                <h2>Adicionar condição</h2>
            </div>
            <div>
                <form
                    onSubmit={formik.handleSubmit}
                    style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px 40px' }}
                >
                    <div>
                        <TextField
                            sx={{ width: '100%' }}
                            type="text"
                            label="Condição de pagamento"
                            name="name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </div>
                    <div>
                        <TextField
                            sx={{ width: '100%' }}
                            type="number"
                            label="Porcentagem"
                            name="pergentagem"
                            onChange={formik.handleChange}
                            value={formik.values.pergentagem}
                            error={formik.touched.pergentagem && Boolean(formik.errors.pergentagem)}
                            helperText={formik.touched.pergentagem && formik.errors.pergentagem}
                        />
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" type="submit" sx={{ width: '200px', marginTop: '40px' }}>
                            Salvar condição
                        </Button>
                    </div>
                </form>
            </div>
        </Drawer>
    );
};

export default DrawerAdd;
