import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from '../../../assets/images/Logos/sulmetais.png';
import { Paper, TableContainer } from '@mui/material';
import { formatCNPJ, formatHora, formatarData, formatarValor } from 'utils/regex';
import { useEffect } from 'react';
import { useState } from 'react';
import { formatarMoeda } from 'utils/formatMoeda';

let total;

function DinamicTable({ dados }) {
    const styles = {
        table: { width: '100%', borderCollapse: 'collapse', margin: '10px' },
        cell: { borderBottom: '1px solid #999', padding: '5px', wordWrap: 'break-word' }
    };

    return (
        <View>
            <View style={styles.table}>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ ...styles.cell, width: '70%' }}>
                        <Text>Material</Text>
                    </View>
                    <View style={{ ...styles.cell, width: '15%' }}>
                        <Text>Quantidade</Text>
                    </View>
                    <View style={{ ...styles.cell, width: '15%' }}>
                        <Text>UN</Text>
                    </View>
                </View>

                {dados?.map((item, index) => (
                    <View key={index} style={{ flexDirection: 'row' }}>
                        <View style={{ ...styles.cell, width: '70%' }}>
                            <Text>{item?.name}</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '15%' }}>
                            <Text>{item?.quantity.toFixed(2)}</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '15%' }}>
                            <Text>{item?.unity}</Text>
                        </View>
                    </View>
                ))}
            </View>
        </View>
    );
}

const styles = {
    page: {
        backgroundColor: '#fff',
        fontSize: 10,
        fontWeight: 'normal',
        paddingTop: 15,
        paddingBottom: 15,
        paddingHorizontal: 15,
        rap: 'wrap'
    },
    header: {
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    image: {
        width: 200,
        backgroundSize: 'cover'
    },
    text1: {
        fontSize: '12px'
    },
    box: {
        border: '1px solid #ddd',
        padding: 10,
        margin: 2,
        borderRadius: 5
    },
    between: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    labelArea: {
        backgroundColor: '#ccc',
        padding: '8px',
        borderRadius: 5
    },
    table: { margin: 10 },
    row: { flexDirection: 'row', borderBottom: '1 solid #000' },
    cell: { flexGrow: 1, padding: 5 }
};

const GeneretePrevia = ({ currentData, currentMaterials }) => {
    const [data, setData] = useState([]);
    const [currentImage, setCurrentImage] = useState('');

    useEffect(() => {
        const images = localStorage.getItem('@images');
        if (images) {
            const allImages = JSON.parse(images);
            setCurrentImage(allImages['logoPdf']);
        } else {
            setCurrentImage(BG);
        }
    }, []);

    useEffect(() => {
        console.log('material', currentMaterials);
        const wihtoutService = currentData?.budgets?.filter((item) => item?.service === false);
        console.log('🚀 ~ useEffect ~ wihtoutService:', wihtoutService);
        const data = wihtoutService?.budgets?.map((item) => ({
            id: item?.id,
            desc: item?.budget_Name,
            un: item?.unitSize,
            qtd: item?.quantity,
            valorU: item?.unitPrice,
            valorT: item?.totalPrice
        }));

        setData(data);
    }, [currentData]);
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Image src={currentImage} style={styles.image} alt="logo" />
                    <View>
                        <Text>Proposta Comercial Nº {currentData?.id}</Text>
                        <Text>
                            Data: {formatarData(currentData?.createdAt)} Horário: {formatHora(currentData?.createdAt)}
                        </Text>
                    </View>
                </View>

                <View style={styles.box}>
                    <View style={styles.between}>
                        <Text>Cliente: {currentData?.client?.name}</Text>
                        <Text>CNPJ: {formatCNPJ(currentData?.client?.cnpj)}</Text>
                    </View>
                    <View style={{ borderBottom: '1px dashed #ccc', marginBottom: '4px', marginTop: '4px' }}></View>
                    <View>
                        <Text>
                            Endereço: {currentData?.client?.logradouro}, n.º {currentData?.client?.number}, {currentData?.client?.state}
                        </Text>
                        <Text>Contato: {currentData?.client?.agentName}</Text>
                        <Text>E-mail: {currentData?.client?.email}</Text>
                        <Text>Obra: {currentData?.construction}</Text>
                        <View style={styles.between}>
                            <Text>Vendedor: {currentData?.seller?.name}</Text>
                            <Text>E-mail: {currentData?.seller?.email}</Text>
                            <Text>Representante: {currentData?.agent?.name}</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.box}>
                    <View style={styles.labelArea}>
                        <Text>Prévia de material</Text>
                    </View>
                    <View>
                        <DinamicTable dados={currentMaterials} />
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default GeneretePrevia;
