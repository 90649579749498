import { Box, Grid } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { Table, Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';
import EditIcon from '@mui/icons-material/Edit';
import { formatarValor } from 'utils/regex';
import DrawerEdit from './components/DrawerEdit';

const MaintenanceValues = () => {
    const api = useApi();

    const [data, setData] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [currentRow, setCurrentRow] = useState([]);

    //sort da tabela
    const [sortColumn, setSortColumn] = useState('id');
    const [sortType, setSortType] = useState('asc');
    const [loading, setLoading] = useState(false);

    // filter
    const [filterValue, setFilterValue] = useState('');

    const get = async () => {
        const response = await api.getAllPrimaryProducts();
        setData(response.data);
        console.log('🚀 ~ file: index.js:17 ~ get ~ response.data:', response.data);
    };

    useEffect(() => {
        get();
    }, []);

    //itens da tabela
    const applyFiltersAndSort = () => {
        let filteredAndSortedData = [...data];

        if (filterValue) {
            const lowerCaseFilter = filterValue.toLowerCase();
            filteredAndSortedData = filteredAndSortedData.filter(
                (item) => typeof item.social === 'string' && item.social.toLowerCase().includes(lowerCaseFilter)
            );
        }

        if (sortColumn && sortType) {
            filteredAndSortedData = filteredAndSortedData.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];

                if (typeof x === 'string' && typeof y === 'string') {
                    x = x.toLowerCase();
                    y = y.toLowerCase();
                }

                if (x > y) return sortType === 'asc' ? 1 : -1;
                if (x < y) return sortType === 'asc' ? -1 : 1;
                return 0;
            });
        }

        return filteredAndSortedData;
    };
    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 300);
    };

    const handleFilterChange = (value) => {
        setFilterValue(value);
    };

    return (
        <MainCard title="Manutenção de valores">
            <DrawerEdit open={openEdit} setOpen={setOpenEdit} currentRow={currentRow} />
            <Grid container spacing={gridSpacing}>
                <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <div style={{ width: '921px' }}>
                        <Table
                            height={400}
                            headerHeight={50}
                            bordered
                            cellBordered
                            data={applyFiltersAndSort()}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            onSortColumn={handleSortColumn}
                            loading={loading}
                            onRowClick={(rowData) => {
                                console.log(rowData);
                            }}
                        >
                            <Column width={250} align="center" fixed sortable verticalAlign="middle">
                                <HeaderCell>ID</HeaderCell>
                                <Cell dataKey="id" />
                            </Column>

                            <Column width={280} fixed sortable verticalAlign="middle">
                                <HeaderCell>Descrição</HeaderCell>
                                <Cell dataKey="productName" />
                            </Column>

                            <Column width={250} sortable verticalAlign="middle" fixed>
                                <HeaderCell>Valor</HeaderCell>
                                <Cell dataKey="productPrice">
                                    {(rowData) => {
                                        return formatarValor(rowData.productPrice);
                                    }}
                                </Cell>
                            </Column>

                            <Column width={140} verticalAlign="middle" align="center" fixed>
                                <HeaderCell>Ações</HeaderCell>
                                <Cell dataKey="acao">
                                    {(rowData) => (
                                        <div title="editar">
                                            <EditIcon
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setOpenEdit(true);
                                                    setCurrentRow(rowData);
                                                }}
                                            />
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                        </Table>
                    </div>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default MaintenanceValues;
