import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    useMediaQuery,
    MenuItem
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import InputMask from 'react-input-mask';
// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useApi } from 'Service/axios';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const FirebaseRegister = ({ ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = useState(true);
    const [seller, setSeller] = useState([]);

    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    useEffect(() => {
        changePassword('123456');
    }, []);

    const api = useApi();
    const navigate = useNavigate();

    const handleRegister = async (values) => {
        const payload = {
            name: values.name,
            email: values.email,
            login: values.login,
            phone: values.phone,
            role: values.role,
            password: values.password,
            commission: values.representativeCommission ? values.representativeCommission : 0,
            user: values.sellerSelected ? { id: values.sellerSelected } : null
        };
        console.log('🚀 ~ file: AuthRegister.js:78 ~ handleRegister ~ payload:', payload);
        try {
            const data = await api.createUser(payload);
            console.log(data);
            navigate('/main/edituser');
        } catch (error) {
            console.log('🚀 ~ file: AuthRegister.js:91 ~ handleRegister ~ error:', error.message);
        }
    };

    const getSeller = async () => {
        const response = await api.getAllUsers();
        const seller = response?.data?.filter((item) => item.role === 'Vendedor');
        setSeller(seller);
    };

    useEffect(() => {
        getSeller();
    }, []);

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12}></Grid>
            </Grid>

            <Formik
                initialValues={{
                    name: '',
                    login: '',
                    email: '',
                    phone: '',
                    role: '',
                    password: '',
                    submit: null,
                    sellerSelected: '',
                    representativeCommission: ''
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required('Nome obrigatório'),
                    login: Yup.string().max(255).required('Login obrigatório'),
                    role: Yup.string().max(255).required('Perfil obrigatório'),
                    email: Yup.string().email('tem que ser um email valido').max(255).required('Email obrigatorio'),
                    password: Yup.string().max(255).required('Senha obrigatoria'),
                    phone: Yup.string().required('O telefone é obrigatório')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        handleRegister(values);
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-name-register">Nome</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-name-register"
                                        type="text"
                                        value={values.name}
                                        name="name"
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.name && errors.name && (
                                        <FormHelperText error id="outlined-adornment-name-register">
                                            {errors.name}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-login-register">Login</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-login-register"
                                        type="text"
                                        value={values.login}
                                        name="login"
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.login && errors.login && (
                                        <FormHelperText error id="outlined-adornment-login-register">
                                            {errors.login}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <FormControl fullWidth error={Boolean(touched.phone && errors.phone)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-phone-register">Celular</InputLabel>
                            <InputMask mask="(99) 99999-9999" value={values.phone} onBlur={handleBlur} onChange={handleChange}>
                                {(inputProps) => (
                                    <OutlinedInput
                                        id="outlined-adornment-phone-register"
                                        type="text"
                                        name="phone"
                                        inputProps={inputProps}
                                    />
                                )}
                            </InputMask>
                            {touched.phone && errors.phone && (
                                <FormHelperText error id="outlined-adornment-phone-register">
                                    {errors.phone}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-role-register">Tipo de usuário</InputLabel>
                            <Select
                                style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                id="outlined-adornment-role-register"
                                type="text"
                                value={values.role}
                                name="role"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            >
                                <MenuItem value={'Vendedor'}>Vendedor </MenuItem>
                                <MenuItem value={'Representante'}>Representante</MenuItem>
                                <MenuItem value={'Gestor Comercial'}>Gestor Comercial </MenuItem>
                                <MenuItem value={'PCP'}>PCP</MenuItem>
                                <MenuItem value={'Administrador'}>Administrador</MenuItem>
                                <MenuItem value={'Manutencao'}>Manutenção de Valores</MenuItem>
                                <MenuItem value={'Custos'}>Contabilidade</MenuItem>
                                <MenuItem value={'SuperAdmin'}>Super Admin</MenuItem>
                            </Select>
                            {touched.role && errors.role && (
                                <FormHelperText error id="outlined-adornment-role-register">
                                    {errors.role}
                                </FormHelperText>
                            )}
                        </FormControl>

                        {values.role === 'Representante' && (
                            <div style={{ display: 'flex', gap: '15px' }}>
                                <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-role2-register">Vincule um vendedor</InputLabel>
                                    <Select
                                        style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                        id="outlined-adornment-role2-register"
                                        type="text"
                                        value={values.sellerSelected}
                                        name="sellerSelected"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    >
                                        <MenuItem value=""></MenuItem>
                                        {seller.map((item) => (
                                            <MenuItem value={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {touched.sellerSelected && errors.sellerSelected && (
                                        <FormHelperText error id="outlined-adornment-role-register">
                                            {errors.sellerSelected}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.representativeCommission && errors.representativeCommission)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-email-register">Comissão</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-email-register"
                                        type="number"
                                        value={values.representativeCommission}
                                        name="representativeCommission"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    {touched.representativeCommission && errors.representativeCommission && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.representativeCommission}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </div>
                        )}

                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-register">Email</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-register"
                                type="email"
                                value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.email && errors.email && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-register">Senha</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-register"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                label="Password"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                    changePassword(e.target.value);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                inputProps={{}}
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-register">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>

                        {strength !== 0 && (
                            <FormControl fullWidth>
                                <Box sx={{ mb: 2 }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <Box
                                                style={{ backgroundColor: level?.color }}
                                                sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" fontSize="0.75rem">
                                                {level?.label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </FormControl>
                        )}

                        <Grid container alignItems="center" justifyContent="space-between"></Grid>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Cadastrar usuário
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default FirebaseRegister;
