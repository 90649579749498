import { Button, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CardDark from './components/CardDark';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import FactoryIcon from '@mui/icons-material/Factory';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BarCharts from './components/BarCharts';
import GaugeCharts from './components/GaugeChart';
import ComboChart from './components/ComboChart';
import PieChart from './components/PieChart';
import { useState } from 'react';
import InvoicingFilter from './components/InvoicingFilter';
import GoalsModal from './components/Goalsmodal';
import PieChartComponent from './components/PieChart';
import { useApi } from 'Service/axios';
import SkeletonDashCards from './components/SkeletonDashCards';
import { formatarMoeda } from 'utils/formatMoeda';
import { formatarNumero } from 'utils/formatarNumeroReal';
import { formatarValorIntl } from 'utils/formatValueIntl';
import { useEffect } from 'react';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import AppliedFilters from './components/AppliedFilters';
import CloseIcon from '@mui/icons-material/Close';

const Invoicing = () => {
    const api = useApi();

    const [openFilter, setOpenFilter] = useState(false);
    const [openGoals, setOpenGoals] = useState(false);
    const [goals, setGoals] = useState('');
    const [projetosValue, setProjetoValues] = useState({});
    const [expeditionsValue, setExpeditionsValue] = useState([]);
    const [producedValue, setProducedValue] = useState([]);
    const [prevValue, setPrevValue] = useState([]);
    const [invoicedValue, setinvoicedValue] = useState([]);
    const [percentageValues, setPercentegeValues] = useState({});
    const [dataYearCharts, setDataYearCharts] = useState([]);
    const [weeksValue, setWeeksValue] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentFaturado, setCurrentFaturado] = useState(0);

    const [selectDateFilter, setSelectDateFilter] = useState(null);
    const [allGoals, setAllGoals] = useState([]);

    function agruparPorTipo(dados) {
        return dados.reduce((acc, item) => {
            if (acc[item.tipo]) {
                acc[item.tipo].push(item);
            } else {
                acc[item.tipo] = [item];
            }
            return acc;
        }, {});
    }

    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const getInvoicing = async () => {
        const dataAtual = new Date();

        // Obter primeiro dia do mês atual
        const primeiroDiaMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        const primeiroDiaFormatado = formatDate(primeiroDiaMes);

        // Obter último dia do mês atual
        const ultimoDiaMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0);
        const ultimoDiaFormatado = formatDate(ultimoDiaMes);
        try {
            const { data } = await api.getFaturamento(
                selectDateFilter ? selectDateFilter.de : primeiroDiaFormatado,
                selectDateFilter ? selectDateFilter.para : ultimoDiaFormatado
            );
            // const { data } = await api.getFaturamento(primeiroDiaFormatado, ultimoDiaFormatado);

            const itens = agruparPorTipo(data);

            const producedv = itens?.PRODUZIDO?.reduce((total, { valor }) => total + valor, 0);
            setProducedValue(producedv);

            const prev = itens?.PREVISTO?.reduce((total, { valor }) => total + valor, 0);
            setPrevValue(prev);

            const invoiced = itens?.FATURADO?.reduce((total, { valor }) => total + valor, 0);
            setinvoicedValue(invoiced);

            // const exp = itens?.EXPEDICAO?.reduce((total, { valor }) => total + valor, 0);
            // setExpeditionsValue(exp);
        } catch (error) {
            console.log('🚀 ~ getInvoicing ~ error:', error);
        }
    };

    const getExpedition = async () => {
        const dataAtual = new Date();
        const ultimoDiaMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0);
        const ultimoDiaFormatado = formatDate(ultimoDiaMes);
        try {
            const { data } = await api.getChartExpedition(selectDateFilter ? selectDateFilter.para : ultimoDiaFormatado);
            setExpeditionsValue(data[0]?.valor);
            console.log('🚀 ~ getExpedition ~ data:', data);
        } catch (error) {
            console.log('🚀 ~ getExpedition ~ error:', error);
        }
    };

    const getProject = async () => {
        const dataAtual = new Date();

        // Obter primeiro dia do mês atual
        const primeiroDiaMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        const primeiroDiaFormatado = formatDate(primeiroDiaMes);

        // Obter último dia do mês atual
        const ultimoDiaMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0);
        const ultimoDiaFormatado = formatDate(ultimoDiaMes);
        try {
            const { data } = await api.getFaturamentoProject(selectDateFilter ? selectDateFilter.para : ultimoDiaFormatado);
            const projectValue = data.reduce((total, { valor }) => total + valor, 0);
            setProjetoValues(projectValue);
        } catch (error) {
            console.log('🚀 ~ getProject ~ error:', error);
        }
    };

    useEffect(() => {
        getCurrentFaturamento();
    }, []);

    const getCurrentGoalsRequest = async () => {
        const dataAtual = new Date();
        const primeiroDiaMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        const primeiroDiaFormatado = formatDate(primeiroDiaMes);

        try {
            const { data } = await api.getCurrentGoals(primeiroDiaFormatado);

            setGoals(data[0]?.valor);
        } catch (error) {
            console.log('🚀 ~ getCurrentGoals ~ error:', error);
        }
    };
    function getMonthAndYear() {
        // Array com os nomes dos meses
        const monthNames = [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'
        ];

        // Obter a data atual
        const currentDate = new Date();

        // Obter o nome do mês atual a partir do array monthNames
        const currentMonth = monthNames[currentDate.getMonth()];

        // Obter o ano atual
        const currentYear = currentDate.getFullYear();

        // Concatenar o nome do mês e o ano atual em uma string e retornar
        return `${currentMonth} de ${currentYear}`;
    }
    const getCurrentFaturamento = async () => {
        const dataAtual = new Date();
        const primeiroDiaMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);

        const primeiroDiaFormatado = formatDate(primeiroDiaMes);

        const ultimoDiaMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0);
        const ultimoDiaFormatado = formatDate(ultimoDiaMes);

        try {
            const { data } = await api.getCurrentFaturamento(primeiroDiaFormatado, ultimoDiaFormatado);

            const totalFaturado = data.reduce((total, { valor }) => total + valor, 0);
            setCurrentFaturado(totalFaturado);
        } catch (error) {
            console.log('🚀 ~ getCurrentFaturamento ~ error:', error);
        }
    };

    const getCurrentPercentageValues = async () => {
        const dataAtual = new Date();

        // Obter primeiro dia do mês atual
        const primeiroDiaMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        const primeiroDiaFormatado = formatDate(primeiroDiaMes);

        // Obter último dia do mês atual
        const ultimoDiaMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0);
        const ultimoDiaFormatado = formatDate(ultimoDiaMes);

        try {
            const { data } = await api.getPercentege(
                selectDateFilter ? selectDateFilter.de : primeiroDiaFormatado,
                selectDateFilter ? selectDateFilter.para : ultimoDiaFormatado
            );

            setPercentegeValues(data);
        } catch (error) {
            console.log('🚀 ~ getCurrentPercentageValues ~ error:', error);
        }
    };

    // const getCurrentRequestForYears = async () => {
    //     const currentYears = new Date().getFullYear();
    //     console.log('🚀 ~ getCurrentRequestForYears ~ currentYears:', currentYears);

    //     const filterYear = selectDateFilter && selectDateFilter.de.slice(6, 10);
    //     console.log('🚀 ~ getCurrentRequestForYears ~ filterYear:', filterYear);

    //     try {
    //         const { data } = await api.getRequestMonth(selectDateFilter ? filterYear : currentYears);
    //         setDataYearCharts(data);
    //     } catch (error) {
    //         console.log('🚀 ~ getCurrentRequestForYears ~ error:', error);
    //     }
    // };

    const getCurrentRequestForYears = async () => {
        let startYear, endYear;

        if (selectDateFilter) {
            startYear = new Date(selectDateFilter.de).getFullYear();
            endYear = new Date(selectDateFilter.de).getFullYear() + 1;
        } else {
            const currentYear = new Date().getFullYear();
            const filterYear = selectDateFilter && selectDateFilter.de.slice(6, 10);

            startYear = selectDateFilter ? parseInt(filterYear) : currentYear;
            endYear = startYear + 1;
        }

        console.log('🚀 ~ getCurrentRequestForYears ~ startYear:', startYear);
        console.log('🚀 ~ getCurrentRequestForYears ~ endYear:', endYear);

        try {
            const { data } = await api.getRequestMonth(`${startYear}-01-01`, `${endYear}-01-01`);
            setDataYearCharts(data);
        } catch (error) {
            console.log('🚀 ~ getCurrentRequestForYears ~ error:', error);
        }
    };

    const getGoalsYear = async () => {
        const currentYears = new Date().getFullYear();

        const filterYear = selectDateFilter && selectDateFilter.de.slice(6, 10);
        try {
            const { data } = await api.getAllGoals(selectDateFilter ? filterYear : currentYears);
            setAllGoals(data);
        } catch (error) {
            console.log('🚀 ~ getGoalsYear ~ error:', error);
        }
    };

    const getCurrentProducedAndPrev = async () => {
        //dia e ano sem filtro

        const dataAtual = new Date();
        const primeiroDiaMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        const primeiroDiaFormatado = formatDate(primeiroDiaMes);
        const year = primeiroDiaFormatado.slice(6, 10);
        const month = primeiroDiaFormatado.slice(3, 5);

        //data e ano com filtro

        const filterYear = selectDateFilter && selectDateFilter.de.slice(6, 10);
        const filterMonth01 = selectDateFilter && selectDateFilter.de.slice(3, 5);
        const filterMonth02 = selectDateFilter && selectDateFilter.para.slice(3, 5);

        try {
            if (!selectDateFilter) {
                const { data } = await api.getProducedAndPrev(month, year);

                const semanas = {};

                data.forEach((obj) => {
                    const semana = obj.semana;

                    if (!semanas[semana]) {
                        semanas[semana] = [];
                    }

                    semanas[semana].push(obj);
                });

                const weeksValueArray = Object.values(semanas);

                setWeeksValue(weeksValueArray);
                setLoading(false);
            } else {
                const { data } = await api.getProducedAndPrevByFilter(filterMonth01, filterMonth02, filterYear);

                const semanas = {};

                data.forEach((obj) => {
                    const semana = obj.semana;

                    if (!semanas[semana]) {
                        semanas[semana] = [];
                    }

                    semanas[semana].push(obj);
                });

                const weeksValueArray = Object.values(semanas);

                setWeeksValue(weeksValueArray);
                setLoading(false);
            }
        } catch (error) {
            console.log('🚀 ~ getCurrentProducedAndPrev ~ error:', error);
        }
    };

    useEffect(() => {
        getInvoicing();
        getCurrentGoalsRequest();
        getCurrentPercentageValues();
        getCurrentRequestForYears();
        getCurrentProducedAndPrev();
        getGoalsYear();
        getProject();
        getExpedition();
    }, [selectDateFilter]);

    //funcao para transformar valores americanos em BR
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
    const costumerBoxShadow = '2px 4px 8px 0px #999';
    return (
        <>
            {loading && <CircularProgress />}
            {!loading && (
                <>
                    <InvoicingFilter open={openFilter} setOpen={setOpenFilter} setSelectDateFilter={setSelectDateFilter} />
                    <GoalsModal open={openGoals} setOpen={setOpenGoals} getCurrentGoalsRequest={getCurrentGoalsRequest} />
                    <Grid spacing={gridSpacing} sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
                        <header
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                padding: '10px 20px',
                                marginBottom: '20px'
                            }}
                        >
                            <h3>Dashboard</h3>
                            <div style={{ display: 'flex' }}>
                                <Button
                                    variant="contained"
                                    style={{ marginRight: '10px', boxShadow: '2px 4px 8px 0px #999' }}
                                    onClick={() => setOpenFilter(true)}
                                >
                                    <FilterAltOutlinedIcon />
                                </Button>
                                <Button variant="contained" sx={{ boxShadow: '2px 4px 8px 0px #999' }} onClick={() => setOpenGoals(true)}>
                                    <CrisisAlertIcon />
                                </Button>
                            </div>
                        </header>

                        {/* {expeditionsValue ? (
                            <SkeletonDashCards />
                        ) : ( */}
                        <div style={{ width: '100%', marginBottom: '20px', display: 'flex', justifyContent: 'end' }}>
                            <button style={{ maxWidth: '300px', all: 'unset' }} onClick={() => setSelectDateFilter(null)}>
                                <div style={{ cursor: 'pointer', position: 'absolute', top: '120px', right: '230px' }}>
                                    {selectDateFilter && (
                                        <AppliedFilters
                                            initialData={selectDateFilter && selectDateFilter.de}
                                            finalData={selectDateFilter && selectDateFilter.para}
                                        />
                                    )}
                                </div>
                            </button>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center', marginBottom: '20px' }}>
                            <Tooltip title={formatter.format(projetosValue)}>
                                <IconButton>
                                    <CardDark
                                        icon={<ArchitectureIcon />}
                                        name={'Projetos'}
                                        value={isNaN(projetosValue) ? 'R$ 0,00' : projetosValue}
                                        title="card"
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={formatter.format(expeditionsValue)}>
                                <IconButton>
                                    <CardDark
                                        icon={<LocalShippingIcon />}
                                        name={'Expedição'}
                                        value={isNaN(expeditionsValue) ? 'R$ 0,00' : expeditionsValue}
                                    />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={formatter.format(prevValue)}>
                                <IconButton>
                                    <CardDark
                                        icon={<FactoryIcon />}
                                        name={'Produção Previsto'}
                                        value={isNaN(prevValue) ? 'R$ 0,00' : prevValue}
                                    />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={formatter.format(producedValue)}>
                                <IconButton>
                                    <CardDark
                                        icon={<TrackChangesIcon />}
                                        name={'Produzido'}
                                        value={isNaN(producedValue) ? 'R$ 0,00' : producedValue}
                                    />
                                </IconButton>
                            </Tooltip>
                            <div>
                                <Tooltip title={formatter.format(invoicedValue)}>
                                    <IconButton>
                                        <CardDark
                                            icon={<MonetizationOnIcon />}
                                            name={'Faturamento Total'}
                                            value={isNaN(invoicedValue) ? 'R$ 0,00' : invoicedValue}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        {/* )} */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '0 auto'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    margin: '10px auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '300px'
                                }}
                            >
                                <div
                                    style={{
                                        padding: '5px 10px',
                                        backgroundColor: '#fff',
                                        borderRadius: '8px',
                                        boxShadow: costumerBoxShadow,
                                        width: '1000px'
                                    }}
                                >
                                    <BarCharts weeksValue={weeksValue} />
                                </div>

                                <div
                                    style={{
                                        position: 'relative',
                                        backgroundColor: '#fff',
                                        borderRadius: '8px',
                                        marginLeft: '20px',
                                        height: '310px',
                                        boxShadow: costumerBoxShadow,
                                        width: '398px'
                                    }}
                                >
                                    <h3 style={{ textAlign: 'center' }}>Faturamento de {getMonthAndYear()}</h3>
                                    <GaugeCharts invoicedValue={currentFaturado} goals={goals} />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            top: '220px',
                                            left: '110px'
                                        }}
                                    >
                                        <span>Faturado: {formatarValorIntl(currentFaturado)}</span>
                                        <span>Meta: {isNaN(goals) ? 'R$ 0,00' : formatter.format(goals)}</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    margin: '10px auto',
                                    display: 'flex',
                                    alignContent: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        width: '1000px',
                                        backgroundColor: '#fff',
                                        borderRadius: '8px',
                                        padding: '10px',
                                        boxShadow: costumerBoxShadow
                                    }}
                                >
                                    <ComboChart fullDate={selectDateFilter?.de} dataYearCharts={dataYearCharts} allGoals={allGoals} />
                                </div>
                                <div
                                    style={{
                                        width: '400px',
                                        backgroundColor: '#fff',
                                        borderRadius: '8px',
                                        padding: '10px',
                                        boxShadow: costumerBoxShadow,
                                        marginLeft: '20px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <span style={{ fontWeight: 'bold' }}>Pedidos Entregues</span>
                                    <PieChartComponent percentageValues={percentageValues} />
                                </div>
                            </div>
                        </div>
                    </Grid>
                </>
            )}
        </>
    );
};

export default Invoicing;
