import { Button, CircularProgress, ListItemIcon, MenuItem, Modal, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import { useDataFilterCosts } from 'store/ContextData';

const Filters = ({ open, setOpen, setFilteredList, loading }) => {
    const { setIsFilterActive, setFinalData, setInitialData, setProject } = useDataFilterCosts();
    const api = useApi();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        p: 4,
        height: 660,
        borderRadius: 8,
        background: '#fff'
    };

    const selectedStatus = [
        { id: 1, color: 'Verde' },
        { id: 2, color: 'Amarelo' },
        { id: 3, color: 'Vermelho' }
    ];

    const [loadingFilter, setLoadingFilter] = useState(false);

    const handleSubmit = async (values) => {
        setFinalData(values.dataFinal ? values.dataFinal : null);
        setInitialData(values.dataInicial ? values.dataInicial : null);
        setIsFilterActive(true);
        setProject(values.projeto ? values.projeto : null);
        loading(true);
        setLoadingFilter(true);
        try {
            const { data } = await api.filterCost(
                values.dataInicial ? values.dataInicial : null,
                values.dataFinal ? values.dataFinal : null,
                values.projeto ? values.projeto : null,
                values.status ? values.status : null
            );
            setFilteredList(data);
            if (data.listaCustos.length === 0) {
                toast.warn('Nenhum registro encontrado!');
            }
            if (data.listaCustos.length > 0) {
                toast.success('Filtro realizado com sucesso!');
                setOpen(false);
            }
            loading(false);
            setLoadingFilter(false);
        } catch (error) {
            console.log('🚀 ~ file: Filters.js:30 ~ handleSubmit ~ error:', error);
        }
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <h2 style={{ textAlign: 'center' }}>Filtro por Projeto</h2>
                <Formik
                    initialValues={{
                        filial: '',
                        projeto: '',
                        status: '',
                        dataInicial: '',
                        dataFinal: '',
                        status: ''
                    }}
                    // validate={(values) => {
                    //     const errors = {};
                    //     if (!values.email) {
                    //         errors.email = 'Required';
                    //     } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    //         errors.email = 'Invalid email address';
                    //     }
                    //     return errors;
                    // }}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px', gap: '10px' }}>
                                <h5 style={{ margin: '0' }}>Projeto</h5>
                                {/* <TextField label="Filial" value={values.filial} name="filial" onChange={handleChange} onBlur={handleBlur} /> */}
                                <TextField
                                    label="Projeto"
                                    value={values.projeto}
                                    name="projeto"
                                    onChange={(e) => {
                                        // Remove caracteres não numéricos usando uma expressão regular
                                        const numericValue = e.target.value.replace(/[^0-9]/g, '');

                                        // Atualiza o valor no estado
                                        handleChange({
                                            target: {
                                                name: 'projeto',
                                                value: numericValue
                                            }
                                        });
                                    }}
                                    onBlur={handleBlur}
                                />
                                <h5 style={{ margin: '0' }}>Cor</h5>
                                <TextField
                                    id="outlined-select-currency"
                                    value={values.status}
                                    name="status"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    select
                                    label="Selecione a cor"
                                >
                                    {selectedStatus.map((option) => (
                                        <MenuItem key={option.id} value={option.color}>
                                            <ListItemIcon>
                                                <Box
                                                    sx={{
                                                        width: '10px',
                                                        height: '10px',
                                                        borderRadius: '50%',
                                                        backgroundColor:
                                                            option.color === 'Verde'
                                                                ? '#1818'
                                                                : option.color === 'Amarelo'
                                                                ? '#FFD700'
                                                                : '#ff0000'
                                                    }}
                                                ></Box>
                                            </ListItemIcon>
                                            {option.color}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <h5 style={{ margin: '0' }}>Período</h5>
                                <h5 style={{ margin: '0', marginLeft: '8px' }}>De</h5>
                                <TextField
                                    type="date"
                                    value={values.dataInicial}
                                    name="dataInicial"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <h5 style={{ margin: '0', marginLeft: '8px' }}>Até</h5>
                                <TextField
                                    type="date"
                                    value={values.dataFinal}
                                    name="dataFinal"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <Button variant="contained" type="submit" disabled={loadingFilter ? true : false}>
                                    {loadingFilter ? <CircularProgress color="inherit" size={24} /> : 'Filtrar'}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                        setFilteredList([]);
                                        setOpen(false);
                                        setIsFilterActive(false);
                                    }}
                                >
                                    Cancelar Filtros
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
};

export default Filters;
