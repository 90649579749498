// assets
import { IconWallet } from '@tabler/icons';

const icons = {
    IconWallet
};
let budget;
const role = localStorage.getItem('role');
const roleParse = JSON.parse(role);

if (roleParse === 'Vendedor' || roleParse === 'Representante' || roleParse === 'Gestor Comercial') {
    budget = {
        id: 'budget',
        title: 'Orçamento',
        type: 'group',
        children: [
            {
                id: 'budget2',
                title: 'Orçamento de venda',
                type: 'item',
                url: '/main/orcamento-vendas',
                icon: icons.IconWallet,
                breadcrumbs: false
            }
        ]
    };
} else {
    budget = {
        id: 'budget',
        title: 'Orçamento',
        type: 'group',
        children: [
            {
                id: 'budget',
                title: 'Simulação',
                type: 'item',
                url: '/main/budget',
                icon: icons.IconWallet,
                breadcrumbs: false
            },
            {
                id: 'budget2',
                title: 'Orçamento de venda',
                type: 'item',
                url: '/main/orcamento-vendas',
                icon: icons.IconWallet,
                breadcrumbs: false
            }
        ]
    };
}

export default budget;
