import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDataFilterCosts } from 'store/ContextData';
import SimpleTable from 'ui-component/SimpleTableComponent';
import { formatarNumero } from 'utils/formatarNumeroReal';
import { formatarData } from 'utils/regex';
const TaxesModal = ({ open, setOpen, project }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        maxHeight: '700px',
        overflow: 'auto',
        borderRadius: '8px'
    };

    const api = useApi();
    const [row, setRow] = useState([]);

    const getTaxes = async () => {
        try {
            const { data } = await api.getFixes(project);
            const row = data.map((item, index) => ({
                data: formatarData(item.dtMovimento),
                nota: item.nota,
                difal: formatarNumero(item.difal),
                icms: formatarNumero(item.icms),
                pis: formatarNumero(item.pis),
                cofins: formatarNumero(item.cofins),
                irpj_csll: formatarNumero(item.irpj_csll),
                simple: item.simples,
                t_imposto: formatarNumero(item.difal + item.icms + item.pis + item.irpj_csll + item.cofins),
                id: index
            }));
            setRow(row);
            console.log('🚀 ~ file: index.js:21 ~ getTaxes ~ data:', data);
        } catch (error) {
            console.log('🚀 ~ file: index.js:22 ~ getTaxes ~ error:', error);
        }
    };

    useEffect(() => {
        getTaxes();
    }, []);

    const columns = [
        { field: 'data', headerName: 'Data', width: 130 },
        { field: 'nota', headerName: 'Nota Fiscal', width: 110 },
        { field: 'difal', headerName: 'DIFAL', type: 'number', width: 110 },
        { field: 'icms', headerName: 'ICMS', type: 'number', width: 100 },
        { field: 'pis', headerName: 'PIS', type: 'number', width: 100 },
        { field: 'cofins', headerName: 'COFINS', type: 'number', width: 100 },
        { field: 'irpj_csll', headerName: 'IRPJ + CSLL', type: 'number', width: 120 },
        { field: 'simple', headerName: 'Simples', type: 'number', width: 100 },
        { field: 't_imposto', headerName: 'T. Imposto', type: 'number', width: 130 }
    ];

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} onClick={(e) => e.stopPropagation()}>
                    <h2 style={{ textAlign: 'center' }}>{`Impostos - Projeto ${project}`}</h2>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            margin: 'auto',
                            marginTop: '10px',
                            overflow: 'auto'
                        }}
                    >
                        <SimpleTable columns={columns} rows={row} />
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default TaxesModal;
