import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Switch,
    TextField
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import ActionButtons from './components/ActionButtons';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import BudgetFormation from './components/BudgetFormation';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import ConfirmModal from 'ui-component/ConfirmModal';
import { formatarData, formatarValor } from 'utils/regex';
import SkeletonTable from 'ui-component/skeletonTable';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import GeneretePdf from './components/GeneretePdf';
import { formatarValorIntl } from 'utils/formatValueIntl';
import GeneretePrevia from './components/GeneretePrevia';
import TransferList from './components/TransferList';

const CreateNewBudget = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '90%',
        boxShadow: 24
    };

    const [openModalPdf, setOpenModalPdf] = useState(false);
    const [openModalPrevia, setOpenModalPrevia] = useState(false);

    const { id, copyId } = useParams();
    const api = useApi();
    //stats
    const [costumer, setCostumer] = useState([]);
    const [seller, setSeller] = useState([]);
    const [representative, setRepresentative] = useState([]);
    const [representativeSelected, setRepresentativeSelected] = useState([]);
    const [paymentTerms, setpaymentTerms] = useState([]);
    const [freightState, setFreightState] = useState([]);
    const [openBudgetFormation, setOpenBudgetFormation] = useState(false);
    const [formData, setFormData] = useState(null);
    const [getIdRepresentative, setGetIdRepresentative] = useState('');
    const [rows, setRows] = useState([]);
    // const [editBudget, setEditBudget] = useState();
    const [sum, setSum] = useState('');
    const [loading, setLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [observationArray, setObservationArray] = useState([]);

    const todasQuestoes = localStorage.getItem('questions');
    const role = localStorage.getItem('role');
    const roleParse = JSON.parse(role);
    const currentQuestion = JSON.parse(todasQuestoes);

    const [openDialog, setOpenDialog] = useState(false);

    // informações da tabela
    const [currentQuizId, setCurrentQuizId] = useState('');

    const columns = [
        { field: 'id', headerName: 'Sequência', flex: 1 },
        { field: 'desc', headerName: 'Descriçao', flex: 1 },
        { field: 'un', headerName: 'UN', flex: 1 },
        {
            field: 'qnt',
            headerName: 'QTD',
            flex: 1
        },
        {
            field: 'valorU',
            headerName: 'Valor Unitário',
            sortable: false,
            sortable: false,
            flex: 1,
            renderCell: (params) => ` ${formatarValorIntl(params.value)}`
        },
        {
            field: 'valorT',
            headerName: 'Valor Total',
            sortable: false,
            sortable: false,
            flex: 1,
            renderCell: (params) => ` ${formatarValorIntl(params.value)}`
        },

        {
            field: 'acao',
            headerName: 'Ações',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                setCurrentQuizId(params.row.quizId);

                return (
                    <ActionButtons
                        row={params.row}
                        formData={formData}
                        getBudget={getBudget}
                        currentIcms={currentIcms}
                        currentQuizId={currentQuizId}
                        setCurrentQuizId={setCurrentQuizId}
                    />
                );
            }
        }
    ];

    // get's

    const getCostumer = async () => {
        try {
            const res = await api.getAllCostumers();
            setCostumer(res.data);
        } catch (error) {
            console.log('🚀 ~ file: index.js:50 ~ getCostumer ~ error:', error.message);
        }
    };
    const [allIcms, setAllIcms] = useState([]);

    const getAllIcms = async () => {
        try {
            const { data } = await api.getIcms();
            setAllIcms(data);
        } catch (error) {
            console.log('🚀 ~ getAllIcms ~ error:', error);
        }
    };
    const [currentIcms, setCurrentIcms] = useState([]);
    useEffect(() => {
        const currentIcms = allIcms.filter((item) => item.id === formData?.client?.icmsId);
        setCurrentIcms(currentIcms);
    }, [formData, allIcms]);

    const getSellers = async () => {
        try {
            const res = await api.getAllUsers();
            const seller = res.data.filter((item) => item.role === 'Vendedor' || item.role === 'Gestor Comercial');
            const representative = res.data.filter((item) => item.role === 'Representante');
            const selectedRepresentative = res.data.find((item) => item?.id === getIdRepresentative);

            setRepresentativeSelected(selectedRepresentative);
            setSeller(seller);
            setRepresentative(representative);
        } catch (error) {
            console.log('🚀 ~ file: index.js:65 ~ getSellers ~ error:', error.message);
        }
    };

    const getPaymentTerms = async () => {
        try {
            const res = await api.getPaymentsTerms();
            setpaymentTerms(res.data);
        } catch (error) {
            console.log('🚀 ~ file: index.js:81 ~ getPaymentTerms ~ error:', error.message);
        }
    };

    const getFreight = async () => {
        try {
            const res = await api.getStatic();
            setFreightState(res.data);
        } catch (error) {
            console.log('🚀 ~ file: index.js:90 ~ getFreight ~ error:', error.message);
        }
    };

    const [currentMaterials, setCurrentMaterials] = useState([]);

    const getBudget = async () => {
        setLoading(true);
        try {
            const res = await api.getBudgetsOneV2(id);

            setFormData(res.data);
            setLoading(false);
            setRows(
                res?.data?.budgets?.map((item, index) => ({
                    ...item,
                    id: index + 1,
                    desc: item?.budget_Name,
                    un: item?.unitSize,
                    qnt: item?.quantity,
                    valorU: item?.unitPrice,
                    valorT: item?.totalPrice,
                    codgo: item?.id,
                    note: item?.note,
                    quizId: item?.quizId
                }))
            );
            const filterService = res?.data?.budgets.filter((item) => item.service === false);

            const totalMaterials = filterService?.flatMap((item) => item?.materials) || [];

            const materialGroups = totalMaterials.reduce((acc, material) => {
                const { name, quantity } = material;
                if (!acc[name]) {
                    acc[name] = { ...material };
                } else {
                    acc[name].quantity += quantity;
                }
                return acc;
            }, {});

            const unifiedMaterials = Object.values(materialGroups);

            setCurrentMaterials(unifiedMaterials);
        } catch (error) {
            console.log('🚀 ~ file: index.js:106 ~ getBudget ~ error:', error);
        }
    };

    const handleSum = () => {
        const soma = rows.reduce((total, { totalPrice }) => total + totalPrice, 0);

        setSum(soma);
    };

    useEffect(() => {
        if (rows) handleSum();
    }, [rows]);

    async function handleGetObservationById() {
        console.log('🚀 ~ handleGetObservationById ~ id:', id);
        try {
            const { data } = await api.getObservationsByBudget(id);

            setObservationArray(data);
        } catch (error) {
            console.log('🚀 ~ handleGetObservationById ~ error:', error);
        }
    }

    useEffect(() => {
        getSellers();
        getPaymentTerms();
        getFreight();
        getBudget();
        getAllIcms();
        handleGetObservationById();
    }, []);

    useEffect(() => {
        getCostumer();
    }, [formData?.client?.id]);

    useEffect(() => {
        getSellers();
    }, [getIdRepresentative]);

    // funcao para enviar os dados do usuario para a simulação
    const handleData = async (values) => {
        const query = {
            costumer: values.costumer ? values.costumer : formData?.client?.id,
            seller: values.seller ? values.seller : formData?.seller?.id,
            representative: values.representative ? values.representative : formData?.agent.id,
            paymentTerms: values.paymentTerms ? values.paymentTerms : formData?.paymentCondition?.id,
            packaging: values.packaging ? Number(values.packaging) : Number(formData?.packaging),
            icmsPercentage: values.icmsPercentage ? values.icmsPercentage : formData?.icmsPercentage
        };

        const data = {
            comission: values.commission ? Number(values.commission) : Number(formData?.comission),
            delivery: values.freight ? Number(values.freight) : Number(formData?.delivery),
            observation: values.description ? values.description : formData?.observation,
            createdAt: formData?.createdAt,
            packaging: values.packaging ? values.packaging : formData?.packaging,
            icmsPercentage: values.icmsPercentage ? values.icmsPercentage : formData?.icmsPercentage,
            construction: values.construction ? values.construction : formData?.construction,
            cif_fob: values?.cif_fob,
            deliveryTime: values.deliveryTime ? Number(values.deliveryTime) : Number(formData?.deliveryTime)
        };

        try {
            await api.editBudgetClient(
                formData?.id,
                query?.costumer,
                query?.seller,
                query?.representative,
                query?.paymentTerms,
                query?.packaging,
                query?.icmsPercentage,
                data
            );
            getBudget();
            toast.success('Dados atualizados');
            setIsChanged(false);
        } catch (error) {
            console.log('🚀 ~ file: DrawerInsert.js:86 ~ handleData ~ error:', error.mesage);
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (event) => {
        // Verifica se a tecla pressionada é uma vírgula e impede sua entrada
        if (event.key === ',') {
            event.preventDefault();
        }
    };

    async function handleDuplicateBudget() {
        const toastId = toast.loading('Gerando nova revisão...');
        const payload = rows.map((item) => Number(item.codgo));

        try {
            await api.duplicateBudget(id, payload, formData.reviewOf !== 0 ? formData.reviewOf : formData.id);
            toast.update(toastId, {
                render: 'Revisão gerada com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
        } catch (error) {
            console.log('🚀 ~ handleDuplicateBudget ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao gerar revisão',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    async function handleFinishedBudget() {
        const toastId = toast.loading('Finalizando orçamento...');
        try {
            await api.finishedBudget(id);
            toast.update(toastId, {
                render: 'Orçamento finalizado com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            getBudget();
            setOpenDialog(false);
        } catch (error) {
            console.log('🚀 ~ handleFinishedBudget ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao finalizar orçamento',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    return (
        <MainCard title={`Orçamento - ${copyId != 0 ? copyId : id}`}>
            <BudgetFormation open={openBudgetFormation} setOpen={setOpenBudgetFormation} formData={formData} getBudget={getBudget} />

            <Grid
                container
                spacing={gridSpacing}
                sx={{ padding: '10px 20px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
            >
                {formData !== null && (
                    <Box>
                        <Formik
                            initialValues={{
                                costumer: formData?.client?.id ? formData?.client?.id : '',
                                seller: formData?.seller?.id ? formData?.seller?.id : '',
                                representative: formData?.agent?.id ? formData?.agent?.id : '',
                                commission: '',
                                freight: '',
                                paymentTerms: '',
                                description: formData?.observation || '',
                                createdAt: formatarData(formData?.createdAt),
                                packaging: formData?.packaging,
                                icmsPercentage: formData?.client?.icmsId,
                                construction: formData?.construction || '',
                                cif_fob: formData?.cifFob || false,
                                deliveryTime: formData?.deliveryTime || ''
                            }}
                            validationSchema={Yup.object().shape({
                                costumer: Yup.string().max(255).required('Este campo deve ser preenchido'),
                                representative: Yup.string().max(255).required('Este campo deve ser preenchido')
                            })}
                            onSubmit={async (values) => {
                                console.log(values, ' meu values');
                                handleData(values);
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        width: '100%',
                                        maxWidth: '1500px',
                                        gap: '16px'
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>
                                            <FormControl fullWidth error={Boolean(touched.costumer && errors.costumer)}>
                                                <InputLabel htmlFor="costumer">Cliente</InputLabel>
                                                <Select
                                                    labelId="costumer"
                                                    id="costumer"
                                                    disabled={formData?.finished}
                                                    name="costumer"
                                                    label="Cliente"
                                                    value={values.costumer ? values.costumer : formData?.client?.id}
                                                    onChange={(e) => {
                                                        setIsChanged(true);
                                                        handleChange(e);
                                                    }}
                                                    sx={{ width: '600px', marginRight: '10px' }}
                                                >
                                                    {costumer.map((client) => (
                                                        <MenuItem key={client.id} value={client.id}>
                                                            {client.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {touched.costumer && errors.costumer && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.costumer}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="costumer">Icms</InputLabel>
                                                <Select
                                                    labelId="ICMS"
                                                    id="ICMS"
                                                    name="icmsPercentage"
                                                    label="Cliente"
                                                    disabled={formData?.finished}
                                                    value={values.icmsPercentage}
                                                    onChange={(e) => {
                                                        setIsChanged(true);
                                                        handleChange(e);
                                                    }}
                                                    sx={{ width: '200px', marginRight: '10px' }}
                                                >
                                                    {allIcms.map((icms) => (
                                                        <MenuItem key={icms.id} value={icms.id}>
                                                            {icms.state} - {icms.percentage}%
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {touched.costumer && errors.costumer && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.costumer}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>
                                        <div>
                                            <TextField
                                                label="Data de emissão"
                                                value={values.createdAt}
                                                InputLabelProps={{ shrink: true }}
                                                name="createdAt"
                                                disabled
                                            />
                                        </div>
                                        <div>
                                            <FormControl fullWidth error={Boolean(touched.seller && errors.seller)}>
                                                <InputLabel htmlFor="seller">Vendedor</InputLabel>
                                                <Select
                                                    disabled={
                                                        roleParse === 'Vendedor' || roleParse === 'Representante' || formData?.finished
                                                            ? true
                                                            : false
                                                    }
                                                    labelId="seller"
                                                    id="seller"
                                                    name="seller"
                                                    label="Vendedor"
                                                    value={values.seller ? values.seller : formData?.seller?.id}
                                                    onChange={(e) => {
                                                        setIsChanged(true);
                                                        handleChange(e);
                                                    }}
                                                    sx={{ width: '260px', marginLeft: '10px' }}
                                                >
                                                    {seller.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {touched.seller && errors.seller && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.seller}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                                        <div>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="representative">Representante</InputLabel>
                                                <Select
                                                    labelId="representative"
                                                    id="representative"
                                                    name="representative"
                                                    label="Representante"
                                                    disabled={roleParse === 'Representante' ? true : formData?.finished ? true : false}
                                                    value={values.representative ? values.representative : formData?.agent?.id}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setGetIdRepresentative(e.target.value);
                                                        setIsChanged(true);
                                                    }}
                                                    sx={{ width: '300px' }}
                                                >
                                                    {representative.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <TextField
                                                label="% de Comissão"
                                                sx={{ width: '300px' }}
                                                disabled={roleParse === 'Representante' || formData?.finished ? true : false}
                                                value={representativeSelected?.commission ?? formData?.comission ?? ''}
                                                InputLabelProps={{ shrink: true }}
                                                name="comission"
                                                onKeyPress={handleKeyPress}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setRepresentativeSelected((prev) => ({ ...prev, commission: e.target.value }));
                                                    setFieldValue('commission', e.target.value);
                                                    setIsChanged(true);
                                                }}
                                            />
                                        </div>

                                        <div>
                                            <TextField
                                                label="% de Embalagem"
                                                InputLabelProps={{ shrink: true }}
                                                disabled={formData?.finished}
                                                value={values.packaging}
                                                onKeyPress={handleKeyPress}
                                                name="packaging"
                                                onChange={(e) => {
                                                    setIsChanged(true);
                                                    handleChange(e);
                                                }}
                                                sx={{ width: '300px' }}
                                            />
                                        </div>

                                        <div>
                                            <FormControl fullWidth error={Boolean(touched.paymentTerms && errors.paymentTerms)}>
                                                <InputLabel htmlFor="paymentTerms">Cond. Pagamentos</InputLabel>
                                                <Select
                                                    labelId="paymentTerms"
                                                    id="paymentTerms"
                                                    name="paymentTerms"
                                                    label="Cond. Pagamentos"
                                                    value={values.paymentTerms ? values.paymentTerms : formData?.paymentCondition?.id}
                                                    onChange={(e) => {
                                                        setIsChanged(true);
                                                        handleChange(e);
                                                    }}
                                                    sx={{ width: '300px' }}
                                                    disabled={formData?.finished}
                                                >
                                                    {paymentTerms.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {touched.paymentTerms && errors.paymentTerms && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.paymentTerms}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <div>
                                            <TextField
                                                label="Obra"
                                                InputLabelProps={{ shrink: true }}
                                                value={values.construction}
                                                name="construction"
                                                disabled={formData?.finished}
                                                onChange={(e) => {
                                                    setIsChanged(true);
                                                    handleChange(e);
                                                }}
                                                sx={{ width: '400px' }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <TextField
                                                label="% de Frete"
                                                InputLabelProps={{ shrink: true }}
                                                value={values.freight ? values.freight : formData?.delivery}
                                                name="freight"
                                                onKeyPress={handleKeyPress}
                                                disabled={formData?.finished}
                                                onChange={(e) => {
                                                    setIsChanged(true);
                                                    handleChange(e);
                                                }}
                                                sx={{ width: '170px' }}
                                            />
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <p>CIF</p>
                                                <Switch
                                                    checked={values.cif_fob}
                                                    onChange={(e) => {
                                                        setIsChanged(true);
                                                        handleChange(e);
                                                    }}
                                                    disabled={formData?.finished}
                                                    name="cif_fob"
                                                    inputProps={{ 'aria-label': 'freight switch' }}
                                                />
                                                <p>FOB</p>
                                            </Stack>
                                        </div>
                                        <div>
                                            <TextField
                                                label="Prazo de entrega (dias)"
                                                value={values.deliveryTime}
                                                name="deliveryTime"
                                                onKeyPress={handleKeyPress}
                                                onChange={(e) => {
                                                    setIsChanged(true);
                                                    handleChange(e);
                                                }}
                                                disabled={formData?.finished}
                                                sx={{ width: '170px' }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '20px',
                                                margin: '20px'
                                            }}
                                        >
                                            <div style={{ margin: 0, marginTop: 15 }}>
                                                <TransferList
                                                    budgetId={id}
                                                    isFinished={formData?.finished}
                                                    observationArray={observationArray}
                                                    handleGetObservationById={handleGetObservationById}
                                                />
                                            </div>

                                            <Button
                                                sx={{ width: '200px' }}
                                                variant="contained"
                                                onClick={formData?.finished ? handleDuplicateBudget : () => setOpenDialog(true)}
                                            >
                                                {formData?.finished ? 'Gerar revisão' : 'Finalizar orçamento'}
                                            </Button>
                                        </div>
                                    </div>
                                    <div>
                                        <TextField
                                            sx={{ width: '100%' }}
                                            id="observations"
                                            label="Observaçães do orçamento"
                                            multiline
                                            rows={2}
                                            value={values.description}
                                            onChange={(e) => {
                                                setIsChanged(true);
                                                handleChange(e);
                                            }}
                                            name="description"
                                            disabled={formData?.finished}
                                        />
                                    </div>

                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: '15px',
                                            marginBottom: '15px'
                                        }}
                                    >
                                        <Button variant="contained" disabled={formData?.finished} sx={{ width: '150px' }} type="submit">
                                            Recalcular
                                        </Button>
                                        <Button
                                            disabled={formData?.finished}
                                            variant="contained"
                                            sx={{ width: '150px' }}
                                            onClick={() => setOpenBudgetFormation(true)}
                                        >
                                            Incluir item
                                        </Button>
                                    </Box>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        {loading ? (
                                            <SkeletonTable />
                                        ) : (
                                            rows.length > 0 && (
                                                <DataGrid
                                                    columns={columns}
                                                    hideFooter={true}
                                                    rows={rows}
                                                    columnVisibilityModel={{
                                                        codigo: false
                                                    }}
                                                    getRowId={(row) => row.id}
                                                />
                                            )
                                        )}
                                    </Box>
                                    {rows.length > 0 && (
                                        <Box sx={{ display: 'flex', justifyContent: 'end', gap: '10px' }}>
                                            <Button variant="contained" onClick={() => setOpenModalPdf(true)} disabled={isChanged}>
                                                {isChanged ? 'Recalcule a proposta !' : 'Gerar PDF'}
                                            </Button>
                                            <Modal
                                                open={openModalPdf}
                                                onClose={() => setOpenModalPdf(false)}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style}>
                                                    <PDFViewer width={'100%'} height={'100%'}>
                                                        <GeneretePdf
                                                            currentQuestions={currentQuestion}
                                                            currentData={formData}
                                                            sum={formatarValorIntl(sum)}
                                                            currentIcms={currentIcms}
                                                            observationArray={observationArray}
                                                            copyId={copyId}
                                                        />
                                                    </PDFViewer>
                                                </Box>
                                            </Modal>
                                            {roleParse !== 'Representante' && (
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        sx={{ marginRight: '15px', textDecoration: 'none' }}
                                                        onClick={() => setOpenModalPrevia(true)}
                                                    >
                                                        Gerar prévia
                                                    </Button>
                                                    <Modal
                                                        open={openModalPrevia}
                                                        onClose={() => setOpenModalPrevia(false)}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >
                                                        <Box sx={style}>
                                                            <PDFViewer width={'100%'} height={'100%'}>
                                                                <GeneretePrevia
                                                                    currentData={formData}
                                                                    currentMaterials={currentMaterials}
                                                                />
                                                            </PDFViewer>
                                                        </Box>
                                                    </Modal>
                                                </>
                                            )}

                                            <TextField
                                                label="Soma Total"
                                                value={`${formatarValorIntl(sum)}`}
                                                aria-readonly
                                                disabled
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Box>
                                    )}
                                </form>
                            )}
                        </Formik>
                    </Box>
                )}
                <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <h3 style={{ textAlign: 'center' }}>Finalizar orçamento</h3>
                    <DialogContent>
                        <DialogContentText variant="h4" id="alert-dialog-description">
                            Deseja realmente finalizar o orçamento?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setOpenDialog(false)} color="error">
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={handleFinishedBudget}>
                            Finalizar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </MainCard>
    );
};

export default CreateNewBudget;
