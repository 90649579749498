// material-ui

import { useEffect } from 'react';
import Sul from '../assets/images/Logos/sulmetais.png';
import LogoBw from '../assets/logobw2.png';
import { useState } from 'react';

const Logo = (props) => {
    const [logoStorage, setLogoStorage] = useState(null);

    useEffect(() => {
        const images = localStorage.getItem('@images');
        if (images) {
            const allImages = JSON.parse(images);
            setLogoStorage(allImages['logoPrincipal']);
        }
    }, []);

    return (
        <>
            {' '}
            {logoStorage ? (
                <img src={logoStorage} alt="logo" width={props.width} style={{ maxHeight: '100px', objectFit: 'contain' }} />
            ) : (
                <img src={LogoBw} alt="logo" width={props.width} />
            )}
        </>
    );
};

export default Logo;
