import { Button, Divider, Drawer, MenuItem, TextField } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import { formatCNPJ, formatPhoneNumber, formatarCEP, removeSpecialCharacter } from 'utils/regex';
import { useApi } from 'Service/axios';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

const DrawerEditCostumer = ({ open, setOpen, icmsProp, getCostumer, currentCostumer }) => {
    console.log('🚀 ~ file: DrawerEditCostumer.js:12 ~ DrawerEditCostumer ~ currentCostumer:', currentCostumer);
    const api = useApi();

    const [cepData, setCepData] = useState({});

    const handleChangeCep = (event) => {
        const newCep = event.target.value;

        if (newCep.length === 8) {
            getCep(newCep);
        }
        formik.setFieldValue('cep', formatarCEP(newCep));
    };

    const getCep = async (cep) => {
        try {
            const res = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            const { bairro, logradouro, localidade, uf } = res.data;

            setCepData({ state: uf, neighborhood: bairro, city: localidade, street: logradouro });
        } catch (error) {
            console.log('🚀 ~ file: DrawerAddCostumer.js:15 ~ getCep ~ error:', error.message);
        }
    };

    const handlePhoneChange = (event) => {
        const rawValue = event.target.value;
        if (rawValue.length > 15) return;
        const formattedValue = formatPhoneNumber(rawValue);

        formik.handleChange(event);
        formik.setFieldValue('phone', formattedValue);
    };

    const handlaCNPJChange = (event) => {
        const rawValue = event.target.value;
        if (rawValue.length > 18) return;
        const formattedValue = formatCNPJ(rawValue);

        formik.handleChange(event);
        formik.setFieldValue('cnpj', formattedValue);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: currentCostumer?.name,
            cnpj: currentCostumer?.cnpj,
            cep: currentCostumer?.cep,
            neighborhood: currentCostumer?.district,
            state: currentCostumer?.state,
            city: currentCostumer?.city,
            street: currentCostumer?.logradouro,
            constructions: currentCostumer?.obra,
            phone: currentCostumer?.phone,
            email: currentCostumer?.email,
            contact: currentCostumer?.agent,
            icms: currentCostumer?.icmsData?.id,
            number: currentCostumer?.number,
            complement: currentCostumer?.complement
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().max(255).required('campo obrigatório'),
            phone: Yup.string().required('O telefone é obrigatório'),
            cnpj: Yup.string().max(255).required('campo obrigatório'),
            neighborhood: Yup.string().max(255).required('campo obrigatório'),
            state: Yup.string().max(255).required('campo obrigatório'),
            street: Yup.string().max(255).required('campo obrigatório'),
            email: Yup.string().max(255).required('campo obrigatório'),
            cep: Yup.string().max(255).required('campo obrigatório')
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('🚀 ~ file: DrawerEditCostumer.js:81 ~ onSubmit: ~ values:', values);
            const payload = {
                name: values.name,
                cnpj: removeSpecialCharacter(values.cnpj),
                obra: values.constructions,
                icms: { id: values.icms },
                agent: values.contact,
                phone: removeSpecialCharacter(values.phone),
                email: values.email,
                state: values.state,
                cep: removeSpecialCharacter(values.cep),
                logradouro: values.street,
                city: values.city,
                district: values.neighborhood,
                number: values.number,
                complement: values.complement
            };
            console.log('🚀 ~ file: DrawerAddCostumer.js:106 ~ onSubmit: ~ payload:', payload);

            try {
                await api.editCostumer(currentCostumer?.id, payload);
                resetForm();
                setOpen(false);
                getCostumer();
                toast.success('Cadastro editado!');
            } catch (error) {
                console.log('🚀 ~ file: DrawerAddCostumer.js:110 ~ onSubmit: ~ error:', error.message);
            }
        }
    });

    useEffect(() => {
        formik.setFieldValue('state', cepData.state || '');
        formik.setFieldValue('neighborhood', cepData.neighborhood || '');
        formik.setFieldValue('city', cepData.city || '');
        formik.setFieldValue('street', cepData.street || '');
    }, [cepData]);

    const handleClose = () => setOpen(false);
    return (
        <Drawer anchor="right" open={open} onClose={handleClose}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginTop: '20px' }}>
                    <h2>Edite de clientes</h2>
                </div>
                <div>
                    <form
                        onSubmit={formik.handleSubmit}
                        style={{ display: 'flex', flexDirection: 'column', width: '600px', padding: '30px 50px', gap: '20px' }}
                    >
                        <Divider>Empresa</Divider>
                        <div>
                            <TextField
                                sx={{ width: '100%' }}
                                type="text"
                                label="CNPJ"
                                name="cnpj"
                                onChange={(e) => handlaCNPJChange(e)}
                                value={formik.values.cnpj}
                                error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
                                helperText={formik.touched.cnpj && formik.errors.cnpj}
                            />
                        </div>
                        <div>
                            <TextField
                                sx={{ width: '100%' }}
                                type="text"
                                label="Nome do cliente"
                                name="name"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </div>
                        <div>
                            <TextField
                                sx={{ width: '200px' }}
                                select
                                label="ICMS"
                                name="icms"
                                onChange={formik.handleChange}
                                value={formik.values.icms}
                                defaultValue={currentCostumer?.icms?.id}
                                error={formik.touched.icms && Boolean(formik.errors.icms)}
                                helperText={formik.touched.icms && formik.errors.icms}
                            >
                                <MenuItem value="">Selecione um ICMS</MenuItem>
                                {icmsProp?.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.state}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <Divider>Contato</Divider>
                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <TextField
                                    sx={{ width: '15rem' }}
                                    type="text"
                                    label="Contato"
                                    name="contact"
                                    onChange={formik.handleChange}
                                    value={formik.values.contact}
                                    error={formik.touched.contact && Boolean(formik.errors.contact)}
                                    helperText={formik.touched.contact && formik.errors.contact}
                                />
                            </div>
                            <div>
                                <TextField
                                    sx={{ width: '15rem' }}
                                    type="tel"
                                    label="Telefone"
                                    name="phone"
                                    onChange={(e) => handlePhoneChange(e)}
                                    value={formik.values.phone}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                />
                            </div>
                        </div>
                        <div>
                            <TextField
                                sx={{ width: '100%' }}
                                type="email"
                                label="E-mail"
                                name="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </div>

                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', gap: '10px' }}>
                            <div>
                                <TextField
                                    sx={{ width: '100%' }}
                                    type="text"
                                    label="Cep"
                                    name="cep"
                                    onChange={(e) => handleChangeCep(e)}
                                    value={formik.values.cep}
                                    error={formik.touched.cep && Boolean(formik.errors.cep)}
                                    helperText={formik.touched.cep && formik.errors.cep}
                                />
                            </div>
                            <div>
                                <TextField
                                    sx={{ width: '100px' }}
                                    type="text"
                                    label="Estado"
                                    name="state"
                                    onChange={formik.handleChange}
                                    value={formik.values.state}
                                    error={formik.touched.state && Boolean(formik.errors.state)}
                                    helperText={formik.touched.state && formik.errors.state}
                                />
                            </div>
                            <div>
                                <TextField
                                    sx={{ width: '100%' }}
                                    type="text"
                                    label="Cidade"
                                    name="city"
                                    onChange={formik.handleChange}
                                    value={formik.values.city}
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                    helperText={formik.touched.city && formik.errors.city}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', gap: '10px' }}>
                            <div>
                                <TextField
                                    sx={{ width: '100%' }}
                                    type="text"
                                    label="Bairro"
                                    name="neighborhood"
                                    onChange={formik.handleChange}
                                    value={formik.values.neighborhood}
                                    error={formik.touched.neighborhood && Boolean(formik.errors.neighborhood)}
                                    helperText={formik.touched.neighborhood && formik.errors.neighborhood}
                                />
                            </div>
                            <div>
                                <TextField
                                    sx={{ width: '100px' }}
                                    type="text"
                                    label=" Nº"
                                    name="number"
                                    onChange={formik.handleChange}
                                    value={formik.values.number}
                                    error={formik.touched.number && Boolean(formik.errors.number)}
                                    helperText={formik.touched.number && formik.errors.number}
                                />
                            </div>
                            <div>
                                <TextField
                                    sx={{ width: '100%' }}
                                    type="text"
                                    label="Logradouro"
                                    name="street"
                                    onChange={formik.handleChange}
                                    value={formik.values.street}
                                    error={formik.touched.street && Boolean(formik.errors.street)}
                                    helperText={formik.touched.street && formik.errors.street}
                                />
                            </div>
                        </div>
                        <div>
                            <TextField
                                sx={{ width: '100%' }}
                                type="text"
                                label="Complemento"
                                name="complement"
                                onChange={formik.handleChange}
                                value={formik.values.complement}
                            />
                        </div>
                        <Button type="submit" variant="contained" sx={{ width: '300px', margin: '20px auto' }}>
                            salvar
                        </Button>
                    </form>
                </div>
            </div>
        </Drawer>
    );
};

export default DrawerEditCostumer;
