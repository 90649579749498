import { Skeleton } from '@mui/material';
import React from 'react';

const SkeletonTable = () => {
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Skeleton variant="rectangular" sx={{ backgroundColor: '#999', margin: '0 auto' }} height={30} width={1000} />
            <Skeleton variant="rectangular" sx={{ backgroundColor: '#999', margin: '0 auto' }} height={30} width={1000} />
            <Skeleton variant="rectangular" sx={{ backgroundColor: '#999', margin: '0 auto' }} height={30} width={1000} />
            <Skeleton variant="rectangular" sx={{ backgroundColor: '#999', margin: '0 auto' }} height={30} width={1000} />
            <Skeleton variant="rectangular" sx={{ backgroundColor: '#999', margin: '0 auto' }} height={30} width={1000} />
            <Skeleton variant="rectangular" sx={{ backgroundColor: '#999', margin: '0 auto' }} height={30} width={1000} />
            <Skeleton variant="rectangular" sx={{ backgroundColor: '#999', margin: '0 auto' }} height={30} width={1000} />
            <Skeleton variant="rectangular" sx={{ backgroundColor: '#999', margin: '0 auto' }} height={30} width={1000} />
            <Skeleton variant="rectangular" sx={{ backgroundColor: '#999', margin: '0 auto' }} height={30} width={1000} />
        </div>
    );
};

export default SkeletonTable;
