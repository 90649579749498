import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import SimpleTable from 'ui-component/SimpleTableComponent';
import { formatarValorIntl } from 'utils/formatValueIntl';
import { formatarNumero } from 'utils/formatarNumeroReal';
import { formatarData } from 'utils/regex';
const ExpensesModal = ({ open, setOpen, project }) => {
    console.log('🚀 ~ file: ExpensesModal.js:10 ~ ExpensesModal ~ project:', project);
    const api = useApi();
    const [rows, setRows] = useState([]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        maxHeight: '700px',
        overflow: 'auto',
        borderRadius: '8px'
    };

    const getExpenses = async () => {
        try {
            const { data } = await api.getExpenses(project);
            const row = data.map((item, index) => ({
                ...item,
                data: formatarData(item.dtMovimento),
                cod: item.codConsumo,
                desc: item.descConsumo,
                un: item.unConsumo,
                qtd: item.qtdConsumo,
                d_fixa: formatarNumero(item.despFixa),
                d_variavel: formatarNumero(item.despVariavel),
                ggf: formatarNumero(item.ggf),
                depreciacao: formatarNumero(item.depreciacao),
                vunit: formatarNumero(item.mvlrUnitConsumo),
                vtotal: formatarNumero(item.mvlrTotalConsumo),
                analise: formatarNumero(item.analiseConsumo),
                id: index,
                total: item.despFixa + item.despVariavel + item.depreciacao + item.ggf
            }));
            setRows(row);
        } catch (error) {
            console.log('🚀 ~ file: index.js:19 ~ getExpenses ~ error:', error);
        }
    };
    useEffect(() => {
        getExpenses();
    }, []);

    const columns = [
        { field: 'data', headerName: 'Data', width: 130 },
        { field: 'cod', headerName: 'Código', width: 80 },
        { field: 'desc', headerName: 'Descrição', type: 'number', width: 310, align: 'center' },
        { field: 'un', headerName: 'UNID', type: 'number', width: 100 },
        { field: 'qtd', headerName: 'QTDE', type: 'number', width: 100 },
        { field: 'vunit', headerName: 'V. Unit.', type: 'number', width: 90 },
        { field: 'vtotal', headerName: 'V. Total', type: 'number', width: 90 },
        { field: 'd_fixa', headerName: 'D. Fixa', type: 'number', width: 100 },
        { field: 'd_variavel', headerName: 'D. Var.', type: 'number', width: 120 },
        { field: 'ggf', headerName: 'GGF', type: 'number', width: 100 },
        { field: 'depreciacao', headerName: 'Deprec.', type: 'number', width: 130 },
        {
            field: 'total',
            headerName: 'T. Despesas',
            type: 'number',
            width: 80,
            renderCell: (params) => {
                return formatarValorIntl(params.row.total);
            }
        },
        {
            field: 'analise',
            headerName: '%',
            type: 'number',
            width: 90,
            renderCell: (params) => {
                const corPerc = params.row.cor_perc; // Substitua 'kpi_resultado1' pelo nome real do campo
                let backgroundColor = '#999'; // Cor padrão

                if (corPerc === 'VERMELHO') {
                    backgroundColor = 'red';
                } else if (corPerc === 'AMARELO') {
                    backgroundColor = '#FFD700';
                } else if (corPerc === 'VERDE') {
                    backgroundColor = 'green';
                }
                return (
                    <div
                        style={{
                            backgroundColor,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '8px 5px',
                            borderRadius: 8,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '24px'
                        }}
                    >
                        {params?.row?.analiseConsumo?.toFixed(0)}%{' '}
                    </div>
                );
            }
        }
    ];

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} onClick={(e) => e.stopPropagation()}>
                    <h2 style={{ textAlign: 'center' }}>{`Despesas - Projeto ${project}`}</h2>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            margin: 'auto',
                            marginTop: '10px',
                            overflow: 'auto'
                        }}
                    >
                        <SimpleTable columns={columns} rows={rows} />
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default ExpensesModal;
