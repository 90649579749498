import { Grid, MenuItem, TablePagination, TableSortLabel, TextField } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { formatarData } from 'utils/regex';
import HoverSocialCard from './components/HoverCard';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AirIcon from '@mui/icons-material/Air';
import TimelapseIcon from '@mui/icons-material/Timelapse';

const PcpAb = () => {
    const api = useApi();
    const [rows, setRows] = useState([]);
    const [statusCounts, setStatusCounts] = useState({});
    const getData = async () => {
        try {
            const { data } = await api.getPcpAb();
            data.sort((a, b) => a.prioridade - b.prioridade);
            setRows(data);

            const statusCounts = data.reduce((counts, item) => {
                counts[item.status] = (counts[item.status] || 0) + 1;
                return counts;
            }, {});
            setStatusCounts(statusCounts);
            console.log('🚀 ~ file: index.js:13 ~ getData ~ statusCounts:', statusCounts);
        } catch (error) {
            console.log('🚀 ~ file: index.js:14 ~ getData ~ error:', error);
        }
    };
    useEffect(() => {
        getData();
    }, []);
    //paginação
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // área relacionada apenas a filtros
    const [selectedField, setSelectedField] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);

    useEffect(() => {
        setFilteredResults(rows);
    }, [rows]);

    const handleFieldChange = (event) => {
        const newSelectedField = event.target.value;
        setSelectedField(newSelectedField);
        filterResults(newSelectedField, searchTerm);
    };

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        filterResults(selectedField, newSearchTerm);
    };

    const filterResults = (field, term) => {
        const results = rows.filter((item) => {
            const fieldItem = field.toLowerCase();
            const fieldValue = item[fieldItem];

            if (fieldItem === 'maquina') {
                // Se o campo for "Maquina", use o código cod_c_trabalho para comparação
                const codCTrabalho = item['cod_c_trabalho'];
                const codCTrabalhoString = String(codCTrabalho).toLowerCase();
                return codCTrabalhoString.includes(term.toLowerCase());
            }

            if (fieldValue !== undefined) {
                const fieldValueString = String(fieldValue).toLowerCase();
                return fieldValueString.includes(term.toLowerCase());
            }

            return false;
        });
        const statusCounts = results.reduce((counts, item) => {
            counts[item.status] = (counts[item.status] || 0) + 1;
            return counts;
        }, {});
        setStatusCounts(statusCounts);
        console.log('🚀 ~ file: index.js:108 ~ results ~ results:', results);
        setFilteredResults(results);
    };

    const selectTypes = ['Setor', 'Maquina', 'Pedido'];

    const [currentColorFilter, setCurrentColorFilter] = useState(null);

    const handleColorFilter = (type) => {
        let initialRows = [...rows]; // Use rows para reiniciar o filtro

        const isSameFilter = currentColorFilter === type;

        if (isSameFilter) {
            if (searchTerm !== '') {
                initialRows = filteredResults.filter((row) => row.status === type);
                const statusCounts = initialRows.reduce((counts, item) => {
                    counts[item.status] = (counts[item.status] || 0) + 1;
                    return counts;
                }, {});
                setStatusCounts(statusCounts);
            } else {
                setCurrentColorFilter(null);
            }
        } else {
            // Aplicar o filtro de cores aos resultados originais
            initialRows = initialRows.filter((row) => row.status === type);
            setCurrentColorFilter(type);
        }

        const statusCounts = initialRows.reduce((counts, item) => {
            counts[item.status] = (counts[item.status] || 0) + 1;
            return counts;
        }, {});
        setStatusCounts(statusCounts);
        setFilteredResults(initialRows);
    };

    //order by

    const [orderByNome, setOrderByNome] = useState('asc');
    const [orderById, setOrderById] = useState('asc');

    const handleSortBySeq = () => {
        const sortedItens = [...filteredResults].sort((a, b) => {
            if (orderById === 'asc') {
                return a.seq_operacao - b.seq_operacao;
            } else {
                return b.seq_operacao - a.seq_operacao;
            }
        });

        setFilteredResults(sortedItens);
        setOrderById(orderById === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByDesc = () => {
        const sortedItens = [...filteredResults].sort((a, b) => {
            const nomeA = a.descricao.toUpperCase();
            const nomeB = b.descricao.toUpperCase();

            if (orderByNome === 'asc') {
                if (nomeA < nomeB) return -1;
                if (nomeA > nomeB) return 1;
            } else {
                if (nomeA > nomeB) return -1;
                if (nomeA < nomeB) return 1;
            }

            return 0;
        });

        setFilteredResults(sortedItens);
        setOrderByNome(orderByNome === 'asc' ? 'desc' : 'asc');
    };

    return (
        <MainCard title="Ordens abertas">
            <Grid container spacing={gridSpacing} sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                <div style={{ display: 'flex', gap: '15px', position: 'absolute', top: '-70px', right: '20px' }}>
                    <button style={{ all: 'unset', cursor: 'pointer' }} onClick={() => handleColorFilter('VERDE')}>
                        <HoverSocialCard color={'green'} secondary={statusCounts.VERDE} iconPrimary={AirIcon} />
                    </button>
                    <button style={{ all: 'unset', cursor: 'pointer' }} onClick={() => handleColorFilter('AMARELO')}>
                        <HoverSocialCard color={'#FFD700'} secondary={statusCounts.AMARELO} iconPrimary={WarningAmberIcon} />
                    </button>
                    <button style={{ all: 'unset', cursor: 'pointer' }} onClick={() => handleColorFilter('VERMELHO')}>
                        <HoverSocialCard color={'red'} secondary={statusCounts.VERMELHO} iconPrimary={TimelapseIcon} />
                    </button>
                </div>
                <div style={{ display: 'flex', position: 'absolute', top: '-65px', left: '37%', transform: 'translateX(-50%)' }}>
                    <TextField
                        sx={{ width: '170px', borderRight: 'none' }}
                        onChange={(e) => handleFieldChange(e)}
                        variant="filled"
                        select
                        label="Selecione um filtro"
                    >
                        {selectTypes.map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        variant="filled"
                        onChange={(e) => handleSearchChange(e)}
                        sx={{ width: '250px', marginLeft: '-2px' }}
                        label="Pesquise ..."
                    />
                </div>
                <div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Seq</TableCell>
                                    <TableCell align="center">ORDEM</TableCell>
                                    <TableCell align="center">PEDIDO</TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel onClick={handleSortByDesc}>DESCRIÇÃO</TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">QTD</TableCell>
                                    <TableCell align="center">UN</TableCell>
                                    <TableCell align="center">S_QTD</TableCell>
                                    <TableCell align="center">S_UN</TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel onClick={handleSortBySeq}>SEQ. OP.</TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">QTD. PROD</TableCell>
                                    <TableCell align="center">OPERAÇÃO</TableCell>
                                    <TableCell align="center">DT_PRAZO</TableCell>
                                    <TableCell align="center">COD_C_TABALHO</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? filteredResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : filterResults
                                ).map((row) => (
                                    <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {row.prioridade}
                                        </TableCell>
                                        <TableCell align="center">{row.ordem}</TableCell>
                                        <TableCell align="center">{row.pedido}</TableCell>
                                        <TableCell align="center">{row.descricao}</TableCell>
                                        <TableCell align="center">{row.quantidade}</TableCell>
                                        <TableCell align="center">{row.unidade}</TableCell>
                                        <TableCell align="center">{row.s_quantidade}</TableCell>
                                        <TableCell align="center">{row.s_unidade}</TableCell>
                                        <TableCell align="center">{row.seq_operacao}</TableCell>
                                        <TableCell align="center">{row.percentual.toFixed(1)} %</TableCell>
                                        <TableCell align="center">{row.operacao}</TableCell>
                                        <TableCell align="center">{formatarData(row.dt_prazo)}</TableCell>
                                        <TableCell align="center">{row.cod_c_trabalho}</TableCell>
                                        <TableCell align="right">
                                            {row.status === 'VERMELHO' && (
                                                <div
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundColor: '#FF6961',
                                                        borderRadius: '50%'
                                                    }}
                                                ></div>
                                            )}
                                            {row.status === 'VERDE' && (
                                                <div
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundColor: '#8FBC8F',
                                                        borderRadius: '50%'
                                                    }}
                                                ></div>
                                            )}
                                            {row.status === 'AMARELO' && (
                                                <div
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundColor: '#FFD700',
                                                        borderRadius: '50%'
                                                    }}
                                                ></div>
                                            )}
                                            {row.status == null && (
                                                <div
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundColor: '#CCC',
                                                        borderRadius: '50%'
                                                    }}
                                                ></div>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </Grid>
        </MainCard>
    );
};

export default PcpAb;
