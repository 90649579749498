import {
    Autocomplete,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import SendIcon from '@mui/icons-material/Send';

import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const PreviousPage = () => {
    const api = useApi();
    const navigate = useNavigate();
    const [filteredList, setFilteredList] = useState([]);
    const [openMp, setOpenMp] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const getList = async () => {
        const response = await api.getAllList();
        const allItems = response.data;

        // Criar um novo array com nome do produto e ID correspondente
        const productsWithId = allItems.map((item) => {
            return {
                productName: item.finalProduct.productName,
                id: item.finalProduct.id,
                unit: item.finalProduct.unit.unitSize,
                group: item.finalProduct.productGroups.map((item) => item.groupDescription)
            };
        });

        // Filtrar itens repetidos pelo nome do produto
        const uniqueProducts = productsWithId.filter((product, index, self) => {
            return self.findIndex((p) => p.productName === product.productName) === index;
        });
        setFilteredList(uniqueProducts);
    };

    useEffect(() => {
        getList();
    }, []);

    const showList = (productId) => {
        navigate(`/main/material/${productId}`);
    };
    console.log(selectedProduct);

    //func orderby
    const [orderByNome, setOrderByNome] = useState('asc');
    const [orderById, setOrderById] = useState('asc');

    const handleSortById = () => {
        const sortedItens = [...filteredList].sort((a, b) => {
            if (orderById === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setFilteredList(sortedItens);
        setOrderById(orderById === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByNome = () => {
        const sortedItens = [...filteredList].sort((a, b) => {
            const nomeA = a.productName.toUpperCase();
            const nomeB = b.productName.toUpperCase();

            if (orderByNome === 'asc') {
                if (nomeA < nomeB) return -1;
                if (nomeA > nomeB) return 1;
            } else {
                if (nomeA > nomeB) return -1;
                if (nomeA < nomeB) return 1;
            }

            return 0;
        });

        setFilteredList(sortedItens);
        setOrderByNome(orderByNome === 'asc' ? 'desc' : 'asc');
    };

    return (
        <>
            <MainCard>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <h2>Lista de material</h2>
                </div>

                <Box>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', margin: '20px 0' }}>
                        <Button variant="contained" onClick={() => navigate('/main/material/cadastro-materia-prima')}>
                            Criar Lista de material
                        </Button>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={filteredList}
                            getOptionLabel={(list) => list?.productName}
                            value={selectedProduct}
                            onChange={(event, newValue) => {
                                setSelectedProduct(newValue);
                            }}
                            sx={{ width: 250 }}
                            renderInput={(params) => <TextField {...params} label="Pesquisar produto" />}
                        />
                    </div>
                    <TableContainer>
                        <Table sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel onClick={handleSortById}>Id</TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel onClick={handleSortByNome}>Produto</TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">Unidade</TableCell>
                                    <TableCell align="center">Grupo(s)</TableCell>
                                    <TableCell align="center">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* 
                                    se selectedProduct for nulo mostra o filtered, logica para filtro funcionar
                                */}
                                {selectedProduct !== null ? (
                                    <TableRow key={selectedProduct.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {selectedProduct.id}
                                        </TableCell>
                                        <TableCell align="center">{selectedProduct.productName}</TableCell>
                                        <TableCell align="center">{selectedProduct.unit}</TableCell>
                                        <TableCell align="center">
                                            {selectedProduct.group.length > 1
                                                ? `${selectedProduct.group[0]} + ${selectedProduct.group.length - 1}`
                                                : selectedProduct.group[0]}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button variant="contained" onClick={() => showList(selectedProduct?.id)}>
                                                Consultar lista
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    filteredList.map((row) => (
                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{
                                                    maxWidth: '150px',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {row.productName}
                                            </TableCell>
                                            <TableCell align="center">{row.unit}</TableCell>
                                            <TableCell align="center">
                                                {row.group.length > 1 ? `${row.group[0]} + ${row.group.length - 1}` : row.group[0]}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => showList(row?.id)}>
                                                    Consultar lista
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </MainCard>
        </>
    );
};

export default PreviousPage;
