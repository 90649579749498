import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDataFilterCosts } from 'store/ContextData';
import { formatarNumero } from 'utils/formatarNumeroReal';
import { formatarData } from 'utils/regex';
import SimpleTable from '../../../../ui-component/SimpleTableComponent';
import { formatarValorIntl } from 'utils/formatValueIntl';

const ConsumptionModal = ({ open, setOpen, project }) => {
    console.log('🚀 ~ file: ExpensesModal.js:10 ~ ExpensesModal ~ project:', project);
    const api = useApi();
    const [rows, setRows] = useState([]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        maxHeight: '700px',
        overflow: 'auto',
        borderRadius: '8px'
    };

    const { isFilterActive, finalData, initialData } = useDataFilterCosts();

    const getConsumtionFiltredRout = async () => {
        try {
            const { data } = await api.getConsumtionFiltred(initialData, finalData, project);

            const row = data.map((item, index) => ({
                ...item,
                data: formatarData(item.dtMovimento),
                cod: item.codConsumo,
                Desc: item.descConsumo,
                un: item.unConsumo,
                qtd: formatarNumero(item.qtdConsumo),
                m_prima: formatarNumero(item.mprimaConsumo),
                beneficiamento: formatarNumero(item.mbenefConsumo),
                vlrunit: formatarNumero(item.mvlrUnitConsumo),
                vlrtotal: formatarNumero(item.mvlrTotalConsumo),
                id: index,
                analise: item.analiseConsumo,
                percent: item.analiseConsumo
            }));
            setRows(row);
        } catch (error) {
            console.log('🚀 ~ file: index.js:25 ~ getConsumptionFiltered ~ error:', error);
        }
    };

    const getConsumption = async () => {
        try {
            const { data } = await api.getConsumption(project);
            const row = data.map((item, index) => ({
                ...item,
                data: formatarData(item.dtMovimento),
                cod: item.codConsumo,
                Desc: item.descConsumo,
                un: item.unConsumo,
                qtd: formatarNumero(item.qtdConsumo),
                m_prima: formatarNumero(item.mprimaConsumo),
                beneficiamento: formatarNumero(item.mbenefConsumo),
                vlrunit: formatarNumero(item.mvlrUnitConsumo),
                vlrtotal: formatarNumero(item.mvlrTotalConsumo),
                id: index,
                analise: item.analiseConsumo,
                percent: item.analiseConsumo
            }));
            setRows(row);
        } catch (error) {
            console.log('🚀 ~ file: index.js:15 ~ getConsumption ~ error:', error);
            console.log('entrou aqui', error);
        }
    };

    useEffect(() => {
        if (isFilterActive) getConsumtionFiltredRout();
        if (!isFilterActive) getConsumption();
    }, [isFilterActive]);

    const getBallColor = (value) => {
        const correctValue = Number(value).toFixed(2);
        if (correctValue <= 30) {
            return 'green';
        } else if (correctValue <= 50) {
            return 'yellow';
        } else {
            return 'red';
        }
    };

    const BallRenderer = ({ value }) => {
        const color = getBallColor(value);

        return (
            <div
                style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    backgroundColor: color,
                    margin: '0 auto'
                }}
            />
        );
    };

    const columns = [
        { field: 'data', headerName: 'Data', width: 130 },
        { field: 'cod', headerName: 'Código', width: 100 },
        { field: 'Desc', headerName: 'Descrição', type: 'number', width: 300 },
        { field: 'un', headerName: 'UNID', type: 'number', width: 100 },
        { field: 'qtd', headerName: 'QTDE', type: 'number', width: 100 },
        { field: 'vlrunit', headerName: 'V. Unit.', type: 'number', width: 100 },
        { field: 'vlrtotal', headerName: 'V. Total', type: 'number', width: 130 },
        { field: 'm_prima', headerName: 'M. Prima', type: 'number', width: 100 },
        { field: 'beneficiamento', headerName: 'Benef.', type: 'number', width: 100 },
        {
            field: 'consumo',
            headerName: 'T. Consumo',
            type: 'number',
            width: 80,
            renderCell: (params) => {
                return formatarValorIntl(params.row.mprimaConsumo + params.row.mbenefConsumo);
            }
        },

        {
            field: 'analise',
            headerName: '%',
            type: 'number',
            width: 80,
            renderCell: (params) => {
                const cor = params.row.analise.toFixed(0); // Substitua 'kpi_resultado1' pelo nome real do campo
                let backgroundColor = '#999'; // Cor padrão

                if (cor <= 30) {
                    backgroundColor = 'green';
                } else if (cor > 30 && cor <= 50) {
                    backgroundColor = '#FFD700';
                } else if (cor > 50) {
                    backgroundColor = 'red';
                }
                return (
                    <div
                        style={{
                            backgroundColor,
                            padding: '8px 5px',
                            borderRadius: 8,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '24px'
                        }}
                    >
                        {cor}%
                    </div>
                );
            }
        }
        // {
        //     field: 'analise',
        //     headerName: 'Análise',
        //     width: 100,
        //     renderCell: (params) => <BallRenderer value={params.row.analise} />
        // }
    ];

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} onClick={(e) => e.stopPropagation()}>
                    <h2 style={{ textAlign: 'center' }}>{`Consumo - Projeto ${project}`}</h2>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            margin: 'auto',
                            marginTop: '10px',
                            overflow: 'auto'
                        }}
                    >
                        <SimpleTable columns={columns} rows={rows} />
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default ConsumptionModal;
