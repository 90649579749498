import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';

const EditSkeleton = () => {
    return (
        <>
            <Skeleton animation="wave" variant="text" width={500} />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Skeleton animation="wave" variant="rounded" width={220} height={35} />
                <Skeleton animation="wave" variant="rounded" width={100} height={35} />
                <Skeleton animation="wave" variant="rounded" width={100} height={35} />
                <Skeleton animation="wave" variant="rounded" width={220} height={35} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Skeleton animation="wave" variant="rounded" width={185} height={35} />
                <Skeleton animation="wave" variant="rounded" width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" width={70} height={35} />
                <Skeleton animation="wave" variant="rounded" width={195} height={35} />
                <Skeleton animation="wave" variant="rounded" width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" width={30} height={35} />
            </Box>
            {Array.from({ length: 4 }).map((item, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Skeleton animation="wave" variant="rounded" width={220} height={35} />
                    <Skeleton animation="wave" variant="rounded" width={290} height={35} />
                    <Skeleton animation="wave" variant="rounded" width={40} height={35} />
                    <Skeleton animation="wave" variant="rounded" width={40} height={35} />
                    <Skeleton animation="wave" variant="rounded" width={40} height={35} />
                </Box>
            ))}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '280px', marginTop: '30px' }}>
                <Skeleton animation="wave" variant="rounded" width={200} height={35} />
                <Skeleton animation="wave" variant="rounded" width={200} height={35} />
            </Box>
        </>
    );
};

export default EditSkeleton;
