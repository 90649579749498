import { Grid } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { DataGrid, useGridApiContext } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useApi } from 'Service/axios';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import SecondModal from '../components/SecondModal';
import SimilarPcp from './components/SimilarPcp';
import Similarnv3 from './components/Similarnv3';

const CustomDataGrid = styled(DataGrid)`
    .MuiDataGrid-row.Mui-selected {
        background-color: #999;
    }
`;
const OrderAnalysis = () => {
    const { id } = useParams();
    const api = useApi();
    const [selectedRow, setSelectedRow] = useState(null);
    const [secondTable, setSecondTable] = useState(false);
    const [thirdTable, setThirdTable] = useState(false);
    const [openSimilarnv3, setOpenSimilarnv3] = useState(false);
    const [codnv3, setCodnv3] = useState(null);

    const [secondTableData, setSecondTableData] = useState({});
    const [similarnv2, setSimilarnv2] = useState({});

    const [firstRows, setFirstRows] = useState([]);
    const [secondRows, setSecondRows] = useState(null);

    const [openSimilar, setOpenSimilar] = useState(false);
    const [openNv2Similar, setOpenNv2Similar] = useState(false);

    const [thirdTableData, setThirdTableData] = useState(null);
    const [currentnv3, setCurrentNv3] = useState(null);

    const handleRowClick = (params) => {
        setSelectedRow(params.id);
    };

    const firstColumns = [
        { field: 'codigo', headerName: 'Código', width: 100 },
        { field: 'descricao', headerName: 'Descrição', width: 600 },
        { field: 'un', headerName: 'UN', width: 100 },
        { field: 'qtd', headerName: 'Qtd', width: 100 },
        { field: 'reservado', headerName: 'Reservado', width: 100 },
        { field: 'disponivel', headerName: 'Disponível', width: 100 },
        { field: 'compra', headerName: 'Compra', width: 100 },
        {
            field: 'similar',
            headerName: 'Similar',
            with: 100,
            renderCell: (params) => (
                <div style={{ display: 'flex', gap: '15px' }}>
                    <div title="Análise" style={{ cursor: 'pointer' }}>
                        <RemoveRedEyeIcon
                            onClick={() => {
                                setOpenSimilar(true);
                                setSecondTableData(params.row);
                            }}
                        />
                    </div>
                    <div title="Mostrar Similar" style={{ cursor: 'pointer' }}>
                        <PlaylistAddIcon
                            onClick={() => {
                                getNv2Pcp(params.row);
                                setSecondTableData(params.row);
                                console.log(params.row);
                            }}
                        />
                    </div>
                </div>
            )
        }
    ];

    const secondColumns = [
        { field: 'codigo', headerName: 'Código', width: 100 },
        { field: 'descricao', headerName: 'Descrição', width: 600 },
        { field: 'un', headerName: 'UN', width: 100 },
        { field: 'qtd', headerName: 'Qtd', width: 100 },
        { field: 'reservado', headerName: 'Reservado', width: 100 },
        { field: 'disponivel', headerName: 'Disponível', width: 100 },
        { field: 'compra', headerName: 'Compra', width: 100 },
        {
            field: 'similar',
            headerName: 'Similar',
            width: 100,
            renderCell: (params) => (
                <div style={{ display: 'flex', gap: '15px' }}>
                    <div title="Análise" style={{ cursor: 'pointer' }}>
                        <RemoveRedEyeIcon
                            onClick={() => {
                                setOpenNv2Similar(true);
                                setSimilarnv2(params.row);
                            }}
                        />
                    </div>
                    <div title="Mostrar Similar" style={{ cursor: 'pointer' }}>
                        <PlaylistAddIcon
                            onClick={() => {
                                getNv3pcp(params.row);
                                setCodnv3(params.row);
                            }}
                        />
                    </div>
                </div>
            )
        }
    ];
    const thirdColumns = [
        { field: 'codigo', headerName: 'Código', width: 100 },
        { field: 'descricao', headerName: 'Descrição', width: 600 },
        { field: 'un', headerName: 'UN', width: 100 },
        { field: 'qtd', headerName: 'Qtd', width: 100 },
        { field: 'reservado', headerName: 'Reservado', width: 100 },
        { field: 'disponivel', headerName: 'Disponível', width: 100 },
        { field: 'compra', headerName: 'Compra', width: 100 },
        {
            field: 'similar',
            headerName: 'Similar',
            width: 100,
            renderCell: (params) => (
                <div style={{ display: 'flex', gap: '15px' }}>
                    <div title="Análise" style={{ cursor: 'pointer' }}>
                        <RemoveRedEyeIcon
                            onClick={() => {
                                setOpenSimilarnv3(true);
                                setCurrentNv3(params.row);
                            }}
                        />
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getMaterial();
    }, []);

    const getMaterial = async () => {
        try {
            const { data } = await api.getStock(id);
            console.log('🚀 ~ file: index.js:122 ~ getMaterial ~ data:', data);
            const firstRows = data.map((item, index) => ({
                ...item,
                id: index,
                codigo: item?.lis_pro_in_codigo,
                descricao: item?.lis_pro_st_descricao,
                un: item?.lis_uni_st_unidade,
                qtd: Math.ceil(item?.lis_dde_re_qtde_necessaria),
                reservado: item?.lis_mvs_re_quantidade_re,
                disponivel: item?.lis_mvs_re_quantidade,
                compra: item?.lis_pedido_aberto
            }));
            setFirstRows(firstRows);
        } catch (error) {
            console.log('🚀 ~ file: FirstModal.js:47 ~ get ~ error:', error);
        }
    };

    const getNv2Pcp = async (id) => {
        try {
            const { data } = await api.getnv2Pcp(id?.codigo);

            setThirdTable(false);
            const secondRows = data.map((item, index) => ({
                ...item,
                id: index,
                codigo: item?.lts_pro_in_codigo,
                descricao: item?.lts_pro_st_descricao,
                un: item?.lts_uni_st_unidade,
                qtd: Math.ceil(item?.lts_com_re_quantidade),
                reservado: item?.lts_com_re_quantidade_re,
                disponivel: item?.lts_mvs_re_quantidade,
                compra: item?.lts_pedido_aberto
            }));
            setSecondRows(secondRows);
            setSecondTable(true);
            console.log('🚀 ~ file: index.js:152 ~ getNv2Pcp ~ data:', data);
        } catch (error) {
            console.log('🚀 ~ file: index.js:153 ~ getNv2Pcp ~ error:', error);
        }
    };

    const getNv3pcp = async (cod) => {
        try {
            const { data } = await api.getNv3pcp(cod?.codigo);
            const thirdRow = data.map((item, index) => ({
                ...item,
                id: index,
                codigo: item?.lts_pro_in_codigo,
                descricao: item?.lts_pro_st_descricao,
                un: item?.lts_uni_st_unidade,
                qtd: Math.ceil(item?.lts_com_re_quantidade),
                reservado: item?.lts_com_re_quantidade_re,
                disponivel: item?.lts_mvs_re_quantidade,
                compra: item?.lts_pedido_aberto
            }));
            setThirdTableData(thirdRow);
            setThirdTable(true);
        } catch (error) {
            console.log('🚀 ~ file: index.js:189 ~ getNv3pcp ~ error:', error.message);
        }
    };

    return (
        <MainCard title={`Análise da Ordem [ ${id} ]`}>
            <Grid container spacing={gridSpacing}>
                <SecondModal open={openSimilar} setOpen={setOpenSimilar} currentRow={secondTableData} />
                <SimilarPcp open={openNv2Similar} setOpen={setOpenNv2Similar} currentRow={similarnv2} />
                <Similarnv3 open={openSimilarnv3} setOpen={setOpenSimilarnv3} currentRow={currentnv3} />
                <h3 style={{ marginLeft: '10px' }}>Lista de Materias</h3>
                <div style={{ maxHeight: 300, width: '100%', padding: '10px' }}>
                    <CustomDataGrid
                        rows={firstRows}
                        columns={firstColumns}
                        onRowClick={handleRowClick}
                        hideFooter
                        selectionModel={selectedRow ? [selectedRow] : []}
                        rowHeight={40}
                    />
                </div>
                {secondTable && (
                    <>
                        <h3 style={{ marginLeft: '10px' }}>Lista de materias 2º [{secondTableData.codigo}]</h3>
                        <div style={{ maxHeight: 300, width: '100%', padding: '10px' }}>
                            <DataGrid rows={secondRows} columns={secondColumns} hideFooter rowHeight={40} />
                        </div>
                    </>
                )}

                {thirdTable && (
                    <>
                        <h3 style={{ marginLeft: '10px' }}>Lista de materias 3º [{codnv3?.codigo}]</h3>
                        <div style={{ maxHeight: 300, width: '100%', padding: '10px' }}>
                            <DataGrid rows={thirdTableData} columns={thirdColumns} hideFooter rowHeight={40} />
                        </div>
                    </>
                )}
            </Grid>
        </MainCard>
    );
};

export default OrderAnalysis;
