import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';

const EditQuizSkeleton = () => {
    return (
        <Box>
            {[1, 2, 3, 4, 5, 6].map((item, index) => (
                <Skeleton key={index} animation="wave" variant="rounded" width={460} height={40} sx={{ marginBottom: '10px' }} />
            ))}
        </Box>
    );
};

export default EditQuizSkeleton;
