import { Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router';
import Labor from './Labor';
import ExpensesModal from './ExpensesModal';
import ConsumptionModal from './ConsumptionModal';
import TaxesModal from './TaxesModal';
const Actions = ({ params }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const [openLabor, setOpenLabor] = useState(false);
    const [currentProject, setCurrentProject] = useState(null);
    const [openExpenses, setOpenExpenses] = useState(false);
    const [openConsumption, setOpenConsumption] = useState(false);
    const [openTaxes, setOpenTaxes] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentProject(null);
    };
    return (
        <div>
            {currentProject && <Labor open={openLabor} setOpen={setOpenLabor} project={currentProject} />}
            {currentProject && <ExpensesModal open={openExpenses} setOpen={setOpenExpenses} project={currentProject} />}
            {currentProject && <ConsumptionModal open={openConsumption} setOpen={setOpenConsumption} project={currentProject} />}
            {currentProject && <TaxesModal open={openTaxes} setOpen={setOpenTaxes} project={currentProject} />}

            <Button
                id={`button-${params.row.id}`}
                aria-controls={open ? `menu-${params.row.id}` : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="contained"
                //Renderizar uma das tres cores que vem do params.row.status
                color={params.row.status === 'VERDE' ? 'success' : params.row.status === 'AMARELO' ? 'warning' : 'error'}
            >
                <MoreVertIcon />
            </Button>
            <Menu
                id={`menu-${params.row.id}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': `button-${params.row.id}`
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setOpenConsumption(true);
                        setCurrentProject(params.row.projeto);
                    }}
                >
                    Consumo
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setOpenExpenses(true);
                        setCurrentProject(params.row.projeto);
                    }}
                >
                    Despesas
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setOpenTaxes(true);
                        setCurrentProject(params.row.projeto);
                    }}
                >
                    Impostos
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setOpenLabor(true);
                        setCurrentProject(params.row.projeto);
                    }}
                >
                    Mão de obra
                </MenuItem>
            </Menu>
        </div>
    );
};

export default Actions;
