import { Box, Skeleton } from '@mui/material';

const SkeletonBudget = () => {
    return (
        <Box sx={{ display: 'flex', gap: '70px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '300px', backgroundColor: '#999' }} />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '500px', backgroundColor: '#999' }} />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '500px', backgroundColor: '#999' }} />
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: '1rem', width: '500px', marginBottom: '50px', backgroundColor: '#999' }}
                />

                <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '300px', backgroundColor: '#999' }} />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '500px', backgroundColor: '#999' }} />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '500px', backgroundColor: '#999' }} />

                <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={700}
                    height={40}
                    sx={{ fontSize: '1rem', width: '500px', backgroundColor: '#999' }}
                />
            </Box>
        </Box>
    );
};

export default SkeletonBudget;
