import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Grid,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Stack,
    Chip
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { CardFilteredArea, Header, HeaderAreaButtons, HoverSocialCardArea, SwitchArea } from './styles';
import HoverSocialCard from './components/HoverCard';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Actions from './components/Actions';
import { useApi } from 'Service/axios';
import { ModalLoadAnimated } from 'ui-component/LoadBw';
import { formatarNumero } from 'utils/formatarNumeroReal';
import { FilterModal } from './components/Filter';
import TotalIncomeDarkCard from './components/TotalIncomeDarkCard';

const presentationMode = {
    h1: '45px',
    button: '20px',
    span: '18px',
    th: '20px',
    tb: '18px'
};

const normalMode = {
    h1: '24px',
    button: '16px',
    span: '16px',
    th: '16px',
    tb: '14px'
};

export function PreriodResult() {
    const api = useApi();
    const [isPresentationMode, setIsPresentationMode] = useState(false);
    const [load, setLoad] = useState(false);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [dateFilterFrom, setDateFilterFrom] = useState('');
    const [dateFilterTo, setDateFilterTo] = useState('');
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [totalIncome, setTotalIncome] = useState(0);

    const handleSwitchChange = (event) => {
        setIsPresentationMode(event.target.checked);
    };

    const currentMode = isPresentationMode ? presentationMode : normalMode;

    function formatarData(data) {
        const meses = [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'
        ];

        const partes = data.split('-'); // Divide a data em partes [ano, mês]
        const ano = partes[0];
        const mes = parseInt(partes[1], 10) - 1; // Converte o mês para índice (0-11)

        return `${meses[mes]} de ${ano}`;
    }

    async function getCosts() {
        setLoad(true);
        try {
            const { data } = await api.getProjCost();
            const formatRow = data.map((item, index) => ({
                ...item,
                id: index,
                mes: formatarData(item.mesMovimento),
                resultado1: formatarNumero(item.resultado1),
                resultado2: formatarNumero(item.resultado2),
                tconsumo: formatarNumero(item.tconsumo),
                tcusto: formatarNumero(item.tcusto),
                tdespesa: formatarNumero(item.tdespesa),
                tfaturado: formatarNumero(item.tfaturado),
                timpostos: formatarNumero(item.timpostos),
                tpedido: formatarNumero(item.tpedido),
                status: item.status,
                pResultado1: item.perc_resultado1,
                pResultado2: item.perc_resultado2,
                kpiResultado1: item.kpi_resultado1,
                kpiResultado2: item.kpi_resultado2
            }));
            console.log('🚀 ~ formatRow ~ formatRow:', formatRow);

            const sortedRows = [...formatRow].sort((a, b) => b.projeto - a.projeto);
            setRows(sortedRows);
        } catch (error) {
            console.log('🚀 ~ getCosts ~ error:', error);
        } finally {
            setLoad(false);
        }
    }

    const formatterCurrency = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

    async function getCostsFiltered() {
        setLoad(true);
        try {
            const { data } = await api.filterProjCost(dateFilterFrom, dateFilterTo);
            setTotalIncome(formatterCurrency.format(data.totalFaturado));
            const formatRow = data.listaCustos.map((item, index) => ({
                ...item,
                id: index,
                mes: formatarData(item.mesMovimento),
                resultado1: formatarNumero(item.resultado1),
                resultado2: formatarNumero(item.resultado2),
                tconsumo: formatarNumero(item.tconsumo),
                tcusto: formatarNumero(item.tcusto),
                tdespesa: formatarNumero(item.tdespesa),
                tfaturado: formatarNumero(item.tfaturado),
                timpostos: formatarNumero(item.timpostos),
                tpedido: formatarNumero(item.tpedido),
                status: item.status,
                pResultado1: item.perc_resultado1,
                pResultado2: item.perc_resultado2,
                kpiResultado1: item.kpi_resultado1,
                kpiResultado2: item.kpi_resultado2
            }));

            setRows(formatRow);
            setDateFilterFrom('');
            setDateFilterTo('');
        } catch (error) {
            console.log('🚀 ~ getCosts ~ error:', error);
        } finally {
            setLoad(false);
        }
    }

    const [countColor, setCountColor] = useState({
        green: 0,
        yellow: 0,
        red: 0,
        total: 0
    });

    const countColors = () => {
        const colorCount = {
            green: 0,
            yellow: 0,
            red: 0,
            total: 0
        };

        if (!isFilterActive) {
            rows?.forEach((row) => {
                if (row.status === 'VERDE') {
                    colorCount.green++;
                } else if (row.status === 'AMARELO') {
                    colorCount.yellow++;
                } else if (row.status === 'VERMELHO') {
                    colorCount.red++;
                }
            });
        }
        if (isFilterActive) {
            rows?.forEach((row) => {
                if (row.status === 'VERDE') {
                    colorCount.green++;
                } else if (row.status === 'AMARELO') {
                    colorCount.yellow++;
                } else if (row.status === 'VERMELHO') {
                    colorCount.red++;
                }
                //Somar todas a cores e aplicar em total
                colorCount.total = colorCount.green + colorCount.yellow + colorCount.red;
            });
        }
        setCountColor(colorCount);
    };

    useEffect(() => {
        countColors();
    }, [isFilterActive, rows]);

    useEffect(() => {
        if (isFilterActive) {
            getCostsFiltered();
        } else {
            getCosts();
        }
    }, [isFilterActive]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <MainCard
            title={
                <h1
                    style={{
                        fontSize: currentMode.h1,
                        margin: '0'
                    }}
                >
                    Resultado por Período
                </h1>
            }
            isOption
        >
            <Grid container spacing={gridSpacing} sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                {load && <ModalLoadAnimated />}
                <SwitchArea>
                    <Switch
                        checked={isPresentationMode}
                        onChange={handleSwitchChange}
                        inputProps={{ 'aria-label': 'Presentation Mode Toggle' }}
                    />
                    <label>{isPresentationMode ? <CancelPresentationIcon /> : <SlideshowIcon />}</label>
                </SwitchArea>
                <Header>
                    <HeaderAreaButtons>
                        <FilterModal
                            currentMode={currentMode}
                            setFrom={setDateFilterFrom}
                            setTo={setDateFilterTo}
                            from={dateFilterFrom}
                            to={dateFilterTo}
                            setIsFilterActive={setIsFilterActive}
                        />
                        <Button variant="contained" sx={{ fontSize: currentMode.button }}>
                            Atualizar
                        </Button>
                        {isFilterActive && (
                            <Stack direction="row" spacing={1}>
                                <Chip
                                    label="Limpar Filtro"
                                    variant="outlined"
                                    color="error"
                                    onDelete={() => {
                                        setIsFilterActive(false);
                                        setDateFilterFrom('');
                                        setDateFilterTo('');
                                    }}
                                />
                            </Stack>
                        )}
                    </HeaderAreaButtons>
                    {isFilterActive && (
                        <CardFilteredArea>
                            <TotalIncomeDarkCard data={totalIncome} count={countColor} />
                        </CardFilteredArea>
                    )}

                    <HoverSocialCardArea>
                        <button style={{ all: 'unset', cursor: 'pointer' }}>
                            <HoverSocialCard color={'green'} secondary={countColor.green.toString()} iconPrimary={PlaylistAddCheckIcon} />
                        </button>
                        <button style={{ all: 'unset', cursor: 'pointer' }}>
                            <HoverSocialCard
                                color={'#FFD700'}
                                secondary={countColor.yellow.toString()}
                                iconPrimary={ReportGmailerrorredIcon}
                            />
                        </button>
                        <button style={{ all: 'unset', cursor: 'pointer' }}>
                            <HoverSocialCard color={'red'} secondary={countColor.red.toString()} iconPrimary={AccessTimeIcon} />
                        </button>
                    </HoverSocialCardArea>
                </Header>
                <Box>
                    <TableContainer sx={{ maxHeight: '500px', marginTop: '20px' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontSize: currentMode.th, fontWeight: 'bold' }}>Período</TableCell>
                                    <TableCell sx={{ fontSize: currentMode.th, fontWeight: 'bold' }}>T. Pedido</TableCell>
                                    <TableCell sx={{ fontSize: currentMode.th, fontWeight: 'bold' }}>T. Faturado</TableCell>
                                    <TableCell sx={{ fontSize: currentMode.th, fontWeight: 'bold' }}>T. Consumo</TableCell>
                                    <TableCell sx={{ fontSize: currentMode.th, fontWeight: 'bold' }}>T. Despesa</TableCell>
                                    <TableCell sx={{ fontSize: currentMode.th, fontWeight: 'bold' }}>T. Custo</TableCell>
                                    <TableCell sx={{ fontSize: currentMode.th, fontWeight: 'bold' }}>T. Impostos</TableCell>
                                    <TableCell sx={{ fontSize: currentMode.th, fontWeight: 'bold' }}>Result 1</TableCell>
                                    <TableCell sx={{ fontSize: currentMode.th, fontWeight: 'bold' }}>%</TableCell>
                                    <TableCell sx={{ fontSize: currentMode.th, fontWeight: 'bold' }}>Result 2</TableCell>
                                    <TableCell sx={{ fontSize: currentMode.th, fontWeight: 'bold' }}>%</TableCell>
                                    <TableCell sx={{ fontSize: currentMode.th, fontWeight: 'bold' }}>Det</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => {
                                    let backgroundColor1 = '#999';
                                    let backgroundColor2 = '#999';

                                    if (data.kpiResultado1 === 'VERMELHO') {
                                        backgroundColor1 = 'red';
                                    } else if (data.kpiResultado1 === 'AMARELO') {
                                        backgroundColor1 = '#FFD700';
                                    } else if (data.kpiResultado1 === 'VERDE') {
                                        backgroundColor1 = 'green';
                                    }

                                    if (data.kpiResultado2 === 'VERMELHO') {
                                        backgroundColor2 = 'red';
                                    } else if (data.kpiResultado2 === 'AMARELO') {
                                        backgroundColor2 = '#FFD700';
                                    } else if (data.kpiResultado2 === 'VERDE') {
                                        backgroundColor2 = 'green';
                                    }

                                    return (
                                        <TableRow key={index}>
                                            <TableCell sx={{ fontSize: currentMode.tb, fontWeight: 'bold' }}>{data.mes}</TableCell>
                                            <TableCell sx={{ fontSize: currentMode.tb, fontWeight: 'bold' }}>{data.tpedido}</TableCell>
                                            <TableCell sx={{ fontSize: currentMode.tb, fontWeight: 'bold' }}>{data.tfaturado}</TableCell>
                                            <TableCell sx={{ fontSize: currentMode.tb, fontWeight: 'bold' }}>{data.tconsumo}</TableCell>
                                            <TableCell sx={{ fontSize: currentMode.tb, fontWeight: 'bold' }}>{data.tdespesa}</TableCell>
                                            <TableCell sx={{ fontSize: currentMode.tb, fontWeight: 'bold' }}>{data.tcusto}</TableCell>
                                            <TableCell sx={{ fontSize: currentMode.tb, fontWeight: 'bold' }}>{data.timpostos}</TableCell>
                                            <TableCell
                                                sx={{
                                                    fontSize: currentMode.tb,
                                                    padding: '10px 5px',
                                                    borderRadius: 1,
                                                    fontWeight: 'bold',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {data.resultado1}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    fontSize: currentMode.tb,
                                                    backgroundColor: backgroundColor1,
                                                    color: '#fff',
                                                    padding: '10px 5px',
                                                    borderRadius: 1,
                                                    fontWeight: 'bold',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {Number(data.pResultado1).toFixed(0)} %
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    fontSize: currentMode.tb,
                                                    padding: '10px 5px',
                                                    borderRadius: 1,
                                                    fontWeight: 'bold',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {data.resultado2}
                                            </TableCell>

                                            <TableCell
                                                sx={{
                                                    fontSize: currentMode.tb,
                                                    backgroundColor: backgroundColor2,
                                                    color: '#fff',
                                                    padding: '10px 5px',
                                                    borderRadius: 1,
                                                    fontWeight: 'bold',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {Number(data.pResultado2).toFixed(0)} %
                                            </TableCell>
                                            <TableCell sx={{ fontSize: currentMode.tb }}>
                                                <Actions params={data} currentMode={currentMode} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Grid>
        </MainCard>
    );
}
