import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Chart } from 'react-google-charts';

export default function BarCharts({ weeksValue }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (weeksValue) {
            const formattedData = weeksValue.map((weekData) => {
                const previsto = weekData?.find((obj) => obj.tipo === 'PREVISTO');
                const produzido = weekData?.find((obj) => obj.tipo === 'PRODUZIDO');

                return [previsto?.semana, Number(previsto?.valor), Number(produzido?.valor)];
            });

            setData(formattedData);
            setLoading(false);
        }
    }, [weeksValue]);

    return (
        <div>
            {loading && <CircularProgress />}
            {!loading && (
                <Chart
                    width={'100%'}
                    height={'300px'}
                    chartType="Bar"
                    loader={<div>Loading Chart</div>}
                    data={[['Semana', 'Previsto', 'Produzidos'], ...data]}
                    options={{
                        chart: {
                            title: 'Previsto x Produzido'
                        },
                        colors: ['#7e57c2', '#409ae9']
                    }}
                />
            )}
        </div>
    );
}
