import { useNavigate } from 'react-router';
import { Button, Tooltip } from '@mui/material';
import AddchartIcon from '@mui/icons-material/Addchart';
import DeleteIcon from '@mui/icons-material/Delete';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

import { useState } from 'react';

const ActionsButtonsFirstTable = ({ row, setOpenConfirm, setCurrentRow }) => {
    console.log('🚀 ~ ActionsButtonsFirstTable ~ row:', row);
    const navigate = useNavigate();
    const api = useApi();

    const handleEdit = () => {
        navigate(`/main/comercial/detalhes-ro/${row.id}`);
    };

    return (
        <div style={{ display: 'flex', gap: '10px', cursor: 'pointer' }}>
            <Tooltip title="Consultar" onClick={handleEdit}>
                <AddchartIcon />
            </Tooltip>
            <Tooltip
                title="Deletar"
                onClick={() => {
                    setOpenConfirm(true);
                    setCurrentRow(row.id);
                }}
            >
                <DeleteIcon />
            </Tooltip>
        </div>
    );
};

export default ActionsButtonsFirstTable;
