import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    ButtonGroup,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    FormHelperText,
    TableContainer,
    IconButton,
    Table,
    TableHead,
    Tooltip,
    TableRow,
    TableCell,
    TableBody,
    Collapse,
    Divider,
    TablePagination,
    TableSortLabel
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';

import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import DeleteIcon from '@mui/icons-material/Delete';
import { IconPencil } from '@tabler/icons';
import { useApi } from 'Service/axios';
import CardQuestion from 'ui-component/cardQuestion';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SubCard from 'ui-component/cards/SubCard';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import SaveIcon from '@mui/icons-material/Save';
import Autocomplete from '@mui/material/Autocomplete';
import { toast } from 'react-toastify';
import { useCallback } from 'react';

const initialState = {
    formula: '',
    description: '',
    value: 0,
    product: { id: null },
    publicDescription: ''
};

// ============== || QUESTION VIEW || ==============
function QuestionView() {
    //logicas para paginaçao e adiçao de input
    const [etapaAtual, setEtapaAtual] = useState(1);
    const [inputs, setInputs] = useState(['']);

    //switch
    const [isChecked, setIsChecked] = useState(false);

    //Pergunta
    const [questionDescription, setQuestionDescription] = useState('');
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [answers, setAnswers] = useState([]);

    const api = useApi();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [message, setMessage] = useState(false);

    async function handleSubmit(event) {
        event.preventDefault();

        if ((isChecked && questionDescription === '') || (!isChecked && questionDescription === '')) {
            setError(true);
            return;
        } else if (etapaAtual === 1 && !isChecked) {
            let tipo = 'Aberta';
            const responseQuestion = await api.createQuestion(tipo, questionDescription);
            setEtapaAtual(1);
            setFormOpen(false);
            setQuestionDescription('');
            get();
        } else {
            if (etapaAtual === 2) {
                try {
                    let tipo = 'Fechada';
                    if (isChecked) {
                        tipo = 'Fechada';

                        // Verificar se todas as respostas têm uma descrição
                        const hasEmptyDescription = campos.some((answer) => answer.description === '');

                        if (hasEmptyDescription) {
                            setError(true);
                            return;
                        }
                    } else {
                        tipo = 'Aberta';
                    }
                    const responseQuestion = await api.createQuestion(tipo, questionDescription);
                    const questionid = responseQuestion.data.id;
                    let respostas = campos;

                    const answerPromises = respostas.map(async (answer) => {
                        const data = await api.createAnswer(
                            answer.formula,
                            answer.description,
                            answer.value,
                            questionid,
                            answer.product,
                            answer.publicDescription
                        );
                    });

                    // wait for all promises to resolve
                    await Promise.all(answerPromises);
                    toast.success('Pergunta adicionada!');
                    setQuestionDescription('');
                    setEtapaAtual(1);
                    setFormOpen(false);
                    get();
                    window.location.reload();
                } catch (error) {
                    console.log(error);
                    setError(true);
                }
            }
        }
    }

    function handleBack(event) {
        event.preventDefault();
        setEtapaAtual(etapaAtual - 1);
    }

    function handleSwitchChange(event) {
        setIsChecked(!isChecked);
    }

    const handleDeleteQuestion = async (id) => {
        try {
            const response = await api.deleteQuestion(id);

            if (response?.status === 200) {
                get();
                return true;
            } else {
                return false;
            }
        } catch (error) {
            // Se ocorrer um erro na chamada à API, você pode retornar false
            return false;
        }
    };

    const getAllProducts = async () => {
        const response = await api.getAllProducts();
        setAllProducts(response.data);
    };
    const [currentItem, setCurrentItem] = useState([]);
    const [loadingEdit, setLoadingEdit] = useState(false);

    const get = useCallback(async () => {
        setLoadingEdit(true);
        try {
            const response = await api.getAllQuestion();
            setLoading(false);
            setData(response.data);
            console.log('🚀 ~ file: index.js:190 ~ get ~ response.data:', response.data);
            setCurrentItem(response.data);
            setAnswers((prevAnswers) => [...prevAnswers, response.data.answer]);
            // for (const item of response.data) {
            //     const resposta = await api.getQuestionAndAnswers(item.id);
            //     console.log('🚀 ~ file: index.js:194 ~ get ~ resposta:', resposta.data);
            //     setAnswers((prevAnswers) => [...prevAnswers, resposta.data]);
            // }
            setLoadingEdit(false);
        } catch (error) {
            console.error('Erro ao obter dados:', error);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        get();
        getAllProducts();
    }, []);

    const refreashArr = () => {
        get();
    };

    let primaryProducts = allProducts.filter((product) => product.productFinal === false);

    const [campos, setCampos] = useState([initialState]);

    const adicionarCampo = () => {
        setCampos([
            ...campos,
            {
                formula: '',
                description: '',
                value: 0,
                product: { id: null },
                publicDescription: ''
            }
        ]);
    };

    // const removerCampo = () => {
    //     setCampos(campos.slice(0, -1));
    // };

    const removerCampo = (indiceCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos.splice(indiceCampo, 1);
            return novosCampos;
        });
    };

    const atualizarCampo = (indiceCampo, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            if (campo === 'value') {
                novosCampos[indiceCampo][campo] = Number(valor);
            } else {
                novosCampos[indiceCampo][campo] = valor;
            }
            if (campo === 'product') {
                novosCampos[indiceCampo][campo] = { id: Number(valor) };
            }
            if (campo === 'publicDescription') {
                novosCampos[indiceCampo][campo] = valor;
            }
            return novosCampos;
        });
    };
    const [openAutoComplete, setOpenAutoComplete] = useState(false);
    function renderEtapaAtual() {
        switch (etapaAtual) {
            case 1:
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <Typography variant="h3">Tipo de pergunta</Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>Aberta</Typography>
                            <Switch onChange={handleSwitchChange} inputProps={{ 'aria-label': 'ant design' }} />
                            <Typography>Fechada</Typography>
                        </Stack>
                        <Typography variant="p" sx={{ marginTop: '1rem', fontSize: '20px' }}>
                            Pergunta
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <TextField
                                placeholder="Título da pergunta..."
                                type="text"
                                error={error}
                                value={questionDescription}
                                helperText={error ? 'Insira uma Pergunta' : null}
                                sx={{ background: 'white' }}
                                onChange={(event) => {
                                    setQuestionDescription(event.target.value);
                                    setError(false);
                                }}
                            />

                            {!isChecked ? (
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        sx={{ display: 'flex', marginTop: '3rem', width: '30%' }}
                                        type="submit"
                                        disabled={message}
                                        onClick={handleSubmit}
                                    >
                                        Cadastrar
                                    </Button>
                                </Box>
                            ) : (
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    {' '}
                                    <Button
                                        variant="contained"
                                        sx={{ display: 'flex', marginTop: '3rem', width: '30%' }}
                                        type="submit"
                                        disabled={!questionDescription}
                                        onClick={(e) => {
                                            handleSubmit(e);
                                            setEtapaAtual(etapaAtual + 1);
                                        }}
                                    >
                                        Avançar
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                );
            case 2:
                return (
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        gap: '1rem',
                                        flexDirection: 'column'
                                    }}
                                >
                                    {campos.map((campo, indiceCampo) => (
                                        <Box
                                            key={indiceCampo}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                boxShadow: '1px 2px 5px #000',
                                                padding: '.6em',
                                                borderRadius: '5px'
                                            }}
                                        >
                                            <Typography variant="h5" sx={{ marginBottom: '2em', marginTop: '2em' }}>
                                                Resposta N° {indiceCampo + 1}
                                            </Typography>
                                            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                <FormControl sx={{ width: '48%', mb: '15px' }}>
                                                    <TextField
                                                        label="Valor Associado"
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        value={campo.value}
                                                        onChange={(e) => atualizarCampo(indiceCampo, 'value', e.target.value)}
                                                    />
                                                </FormControl>
                                            </Box>

                                            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                                                <FormControl sx={{ width: '100%', mb: '15px' }}>
                                                    <InputLabel id="product">Produto Associado</InputLabel>
                                                    <Select
                                                        labelId="product"
                                                        label="product"
                                                        value={campo.product?.id || ''}
                                                        onChange={(e) => atualizarCampo(indiceCampo, 'product', e.target.value)}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Selecione um produto
                                                        </MenuItem>
                                                        {primaryProducts.map((product) => (
                                                            <MenuItem key={product.id} value={product.id}>
                                                                {product.productName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl sx={{ width: '100%', mb: '15px' }}>
                                                    <TextField
                                                        label="Descrição privada"
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={error}
                                                        value={campo.description}
                                                        helperText={error ? 'Insira uma descrição privada' : null}
                                                        onChange={(e) => {
                                                            setError(false);
                                                            atualizarCampo(indiceCampo, 'description', e.target.value);
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl sx={{ width: '100%', mb: '15px' }}>
                                                    <TextField
                                                        label="Descrição pública"
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={error}
                                                        value={campo.publicDescription}
                                                        helperText={error ? 'Insira uma descrição pública' : null}
                                                        onChange={(e) => {
                                                            setError(false);
                                                            atualizarCampo(indiceCampo, 'publicDescription', e.target.value);
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Button fullWidth onClick={() => removerCampo(indiceCampo)}>
                                                <DeleteIcon sx={{ transition: 'all .15s ease-in', ':hover': { color: 'red' } }} />
                                            </Button>
                                        </Box>
                                    ))}
                                </Box>
                                <ButtonGroup sx={{ width: '100%' }}>
                                    <Button fullWidth onClick={adicionarCampo}>
                                        Adicionar resposta
                                    </Button>
                                </ButtonGroup>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    sx={{ marginTop: '1rem', alignSelf: 'flex-start', width: '150px' }}
                                    type="submit"
                                    onClick={handleBack}
                                >
                                    voltar
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ marginLeft: '1rem', marginTop: '1rem', alignSelf: 'flex-end', width: '150px' }}
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Cadastrar
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                );
            default:
                return null;
        }
    }

    const [formOpen, setFormOpen] = useState(false);

    const [loading, setLoading] = useState(true);

    function Row({ question, id, products, type, answers, description, bodyFormula, currentItem, loadingEdit }) {
        console.log('🚀 ~ file: index.js:474 ~ Row ~ currentItem:', currentItem);
        // console.log('🚀 ~ file: index.js:474 ~ Row ~ question:', question);
        // console.log('🚀 ~ file: index.js:474 ~ Row ~ answers:', answers);
        const theme = useTheme();
        const api = useApi();
        const [open, setOpen] = useState(false);
        const [campos, setCampos] = useState([initialState]);
        const [newQuestionName, setNewQuestionName] = useState(question?.description);
        const [data, setData] = useState([]);
        const [openTable, setOpenTable] = useState(false);
        const [openDialog, setOpenDialog] = useState(false);
        const [openEdit, setOpenEdit] = useState(false);
        const [openSnakBar, setOpenSnackBar] = useState(false);
        const [openSnakBarCancelado, setOpenSnackBarCancelado] = useState(false);
        const primaryProducts = products.filter((item) => item.productFinal === false);
        const [editFormulas, setEditFormulas] = useState([]);
        const [editValoresAssociados, setEditValoresAssociados] = useState([]);
        const [editProducts, setEditProducts] = useState([]);
        const [editpublicDescription, setEditPublicDescription] = useState('');
        const [newAnswers, setNewAnswers] = useState('');
        const [newPublickDescription, setNewPublicDescription] = useState([]);
        const handleClickOpen = () => {
            setOpenDialog(true);
        };

        const handleClose = () => {
            setOpenDialog(false);
            setOpenSnackBarCancelado(true);
        };

        const handleCloseEdit = () => {
            setOpenEdit(false);
            setOpenSnackBarCancelado(true);
        };

        const handleCloseSnackBar = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpenSnackBar(false);
            setOpenSnackBarCancelado(false);
        };

        // const handleActiveSubTable = (e) => {
        //     e.preventDefault();
        //     setOpenTable(!openTable);
        // };

        const handleClickOpenEdit = () => {
            setOpenEdit(true);
        };

        const handleDelete = async () => {
            try {
                const response = await api.deleteQuestion(id);
                window.location.reload();
            } catch (error) {
                toast.error('Para deletar esta pergunta, remova todas as respostas cadastradas nela');
            }
        };
        const [click, setClick] = useState(true);

        const handleDeleteAnswer = async (id, index) => {
            setClick(false);
            const response = await api.deleteAnswer(id);
            setData((prevData) => {
                const updatedAnswers = [...prevData?.answers];
                updatedAnswers.splice(index, 1);
                return { ...prevData, answers: updatedAnswers };
            });
            get();
            setClick(true);
        };

        const get = async () => {
            const selected = currentItem.filter((item) => item.id === id);
            // console.log('🚀 ~ file: index.js:551 ~ get ~ selected:', selected);
            // const response = await api.getQuestionAndAnswers(id);
            // console.log(answers, 'answer 3');
            // setData(response.data);
            // console.log('🚀 ~ file: index.js:550 ~ get ~ response.data:', response.data);
            const newData = {
                answers: selected[0]?.answers,
                question: {
                    description: selected[0]?.description,
                    formula: selected[0]?.formula,
                    type: selected[0]?.type
                }
            };
            setData(newData);
            console.log('🚀 ~ file: index.js:564 ~ get ~ newData:', newData);
        };

        const handleEditAnswer = async (id, index) => {
            const updatedFormula = editFormulas[index];
            const updatedAnswer = newAnswers[index];
            const updatedValorAssociado = editValoresAssociados[index];
            const updatedProduct = editProducts[index];
            const updatePublicDescription = editpublicDescription[index];

            const updatedAnswerObject = {
                formula: updatedFormula ? updatedFormula : data?.answers[index]?.formula,
                value: parseFloat(updatedValorAssociado) || data?.answers[index]?.value,
                description: updatedAnswer ? updatedAnswer : data?.answers[index]?.description,
                product: updatedProduct ? { id: updatedProduct } : data?.answers[index]?.product || null,
                publicDescription: updatePublicDescription ? updatePublicDescription : data?.answers[index]?.publicDescription
            };

            try {
                const response = await api.updateAnswer(id, updatedAnswerObject);
                console.log(response.data);
                setOpen(false);
                setNewAnswers('');
                setEditProducts([]);
                setEditValoresAssociados([]);
                setEditFormulas([]);
                setEditPublicDescription('');
                get();
                window.location.reload();
            } catch (error) {
                console.log(error.message);
            }
        };

        const handleAddNewAnswer = async () => {
            let respostas = campos;

            const answerPromises = respostas.map(async (answer) => {
                const data = await api.createAnswer(
                    answer.formula,
                    answer.description,
                    answer.value,
                    id,
                    answer.product,
                    answer.publicDescription
                );
            });

            // wait for all promises to resolve
            await Promise.all(answerPromises);

            setOpen(false);
            setCampos([initialState]);
            get();
        };

        const handleEditQuestion = async () => {
            try {
                const reponse = await api.updateQuestion(id, newQuestionName, type, bodyFormula, answers);
                console.log('response', reponse.data);
                toast.success('Descrição editada com sucesso! ');
                window.location.reload();
            } catch (error) {
                console.log(error.message);
            }
            // setOpenEdit(false);
            // setOpenSnackBar(true);
            // get();
        };

        const removerCampo = (indiceCampo) => {
            setCampos((prevCampos) => {
                const novosCampos = [...prevCampos];
                novosCampos.splice(indiceCampo, 1);
                return novosCampos;
            });
        };

        const atualizarCampo = (indiceCampo, campo, valor) => {
            setCampos((prevCampos) => {
                const novosCampos = [...prevCampos];
                if (campo === 'value') {
                    novosCampos[indiceCampo][campo] = Number(valor);
                } else {
                    novosCampos[indiceCampo][campo] = valor;
                }

                if (campo === 'product') {
                    novosCampos[indiceCampo][campo] = { id: Number(valor) };
                }
                return novosCampos;
            });
        };

        const handleEditValorAssociado = (newValue, index) => {
            const updatedValoresAssociados = [...editValoresAssociados];
            updatedValoresAssociados[index] = data?.answers[index]?.value || '';
            updatedValoresAssociados[index] = newValue;
            setEditValoresAssociados(updatedValoresAssociados);
        };

        const handleEditProduct = (newValue, index) => {
            const updatedProducts = [...editProducts];
            updatedProducts[index] = data?.answers[index]?.product?.id || '';
            updatedProducts[index] = newValue;
            setEditProducts(updatedProducts);
        };

        const handleNewAnswer = (newValue, index) => {
            const updatedNewAnswers = [...newAnswers];
            updatedNewAnswers[index] = data?.answers[index]?.description || '';
            updatedNewAnswers[index] = newValue;
            setNewAnswers(updatedNewAnswers);
        };

        const handlePublicDescription = (newpublicDescription, index) => {
            const update = [...newPublickDescription];
            update[index] = data?.answers[index]?.publicDescription || '';
            update[index] = newpublicDescription;
            setEditPublicDescription(update);
        };

        useEffect(() => {
            get();
        }, [id]);

        return (
            <>
                <TableRow>
                    <TableCell component="th" scope="row" align="center">
                        {question.id}
                    </TableCell>
                    <TableCell align="center">{question.description}</TableCell>
                    <TableCell align="center">{question.type}</TableCell>

                    <TableCell align="center">
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <Divider />
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box sx={{ alignSelf: 'flex-start' }}>
                                    <Tooltip title="Editar" onClick={handleClickOpenEdit}>
                                        <IconButton>
                                            <IconPencil />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Excluir" onClick={handleClickOpen}>
                                        <IconButton>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Dialog
                                        open={openDialog}
                                        keepMounted
                                        onClose={handleClose}
                                        aria-describedby="alert-dialog-slide-description"
                                    >
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-slide-description">
                                                tem certeza que quer deletar essa pergunta?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose}>cancelar</Button>
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    handleDelete();
                                                }}
                                            >
                                                Deletar
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    {/* editar dialog 
                                    
                                    essa area para edit
                                    
                                    */}
                                    {loadingEdit ? (
                                        <CircularProgress />
                                    ) : (
                                        <Dialog
                                            open={openEdit}
                                            keepMounted
                                            onClose={handleCloseEdit}
                                            aria-describedby="alert-dialog-slide-description"
                                        >
                                            <DialogContent
                                                sx={{ display: 'flex', flexDirection: 'column', minWidth: '600px', gap: '1rem' }}
                                            >
                                                <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>Editar Pergunta</Typography>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center,',
                                                        flexDirection: 'column',
                                                        width: '100%'
                                                    }}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        value={newQuestionName}
                                                        onChange={(e) => {
                                                            setNewQuestionName(e.target.value);
                                                        }}
                                                    />
                                                    <Button
                                                        sx={{ marginTop: '1em', maxWidth: '40px' }}
                                                        fullWidth
                                                        onClick={handleEditQuestion}
                                                        variant="outlined"
                                                    >
                                                        Salvar
                                                    </Button>
                                                </Box>

                                                {click && data?.question?.type === 'Fechada' && (
                                                    <>
                                                        <Typography sx={{ mt: '1rem', fontSize: '18px', fontWeight: 700 }}>
                                                            Editar Respostas
                                                        </Typography>
                                                        {data?.answers.map((item, index) => {
                                                            return (
                                                                <Box key={index} sx={{ marginBottom: '2em' }}>
                                                                    <FormControl sx={{ width: '100%', mb: '15px' }}>
                                                                        <TextField
                                                                            label="Valor Associado"
                                                                            type="number"
                                                                            defaultValue={item?.value}
                                                                            placeholder="Valor associado"
                                                                            onChange={(e) =>
                                                                                handleEditValorAssociado(e.target.value, index)
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                    <FormControl sx={{ width: '100%', mb: '15px' }}>
                                                                        <InputLabel id="produto associado">Produto associado *</InputLabel>
                                                                        <Select
                                                                            sx={{ width: '100%' }}
                                                                            labelId="produto associado"
                                                                            defaultValue={(item.product && item?.product.id) || ''}
                                                                            label="produto associado"
                                                                            onChange={(e) => handleEditProduct(e.target.value, index)}
                                                                            required
                                                                        >
                                                                            {primaryProducts &&
                                                                                primaryProducts.map((product) => (
                                                                                    <MenuItem key={product.id} value={product.id}>
                                                                                        {product.productName}
                                                                                    </MenuItem>
                                                                                ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormControl sx={{ width: '100%', mb: '15px' }}>
                                                                        <TextField
                                                                            label="Descrição privada"
                                                                            fullWidth
                                                                            defaultValue={item?.description}
                                                                            onChange={(e) => handleNewAnswer(e.target.value, index)}
                                                                            required
                                                                        />
                                                                    </FormControl>
                                                                    <FormControl sx={{ width: '100%', mb: '15px' }}>
                                                                        <TextField
                                                                            label="Descrição pública"
                                                                            fullWidth
                                                                            defaultValue={item?.publicDescription}
                                                                            onChange={(e) => handlePublicDescription(e.target.value, index)}
                                                                            required
                                                                        />
                                                                    </FormControl>
                                                                    {/* Restante do código */}
                                                                    <Box
                                                                        sx={{
                                                                            height: '100%',
                                                                            display: 'flex',
                                                                            alignItems: 'center'
                                                                        }}
                                                                    >
                                                                        <ButtonGroup
                                                                            sx={{
                                                                                display: 'flex',
                                                                                width: '100%',
                                                                                justifyContent: 'space-between'
                                                                            }}
                                                                        >
                                                                            <Tooltip
                                                                                title="Excluir"
                                                                                onClick={() => {
                                                                                    handleDeleteAnswer(item.id, index);
                                                                                }}
                                                                            >
                                                                                <IconButton>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip
                                                                                title="Atualizar"
                                                                                onClick={() => {
                                                                                    handleEditAnswer(item.id, index);
                                                                                }}
                                                                            >
                                                                                <IconButton>
                                                                                    <IconPencil />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </ButtonGroup>
                                                                    </Box>
                                                                    <Typography
                                                                        sx={{ textAlign: 'center', marginBottom: '1em', marginTop: '1em' }}
                                                                    >
                                                                        <Divider />
                                                                    </Typography>
                                                                </Box>
                                                            );
                                                        })}
                                                        <Typography sx={{ mt: '1rem', fontSize: '18px', fontWeight: 700 }}>
                                                            Adicionar nova resposta
                                                        </Typography>
                                                        {campos.map((campo, indiceCampo) => (
                                                            <Box
                                                                key={indiceCampo}
                                                                sx={{
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.12)',
                                                                    padding: '.6em',
                                                                    borderRadius: '5px',
                                                                    backgroundColor: 'rgba(0,0,0,0.05)'
                                                                }}
                                                            >
                                                                <Typography variant="h5" sx={{ marginBottom: '2em', marginTop: '2em' }}>
                                                                    Resposta N° {indiceCampo + 1}
                                                                </Typography>
                                                                <Box
                                                                    sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
                                                                >
                                                                    <FormControl sx={{ width: '48%', mb: '15px' }}>
                                                                        <TextField
                                                                            label="Valor Associado"
                                                                            type="number"
                                                                            InputLabelProps={{
                                                                                shrink: true
                                                                            }}
                                                                            value={campo.value}
                                                                            onChange={(e) =>
                                                                                atualizarCampo(indiceCampo, 'value', e.target.value)
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Box>

                                                                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                                                                    <FormControl sx={{ width: '100%', mb: '15px' }}>
                                                                        <InputLabel id="product">Produto Associado</InputLabel>
                                                                        <Select
                                                                            labelId="product"
                                                                            label="Produto Associado"
                                                                            value={campo.product?.id || ''}
                                                                            onChange={(e) =>
                                                                                atualizarCampo(indiceCampo, 'product', e.target.value)
                                                                            }
                                                                        >
                                                                            <MenuItem value="" disabled>
                                                                                Selecione um produto
                                                                            </MenuItem>
                                                                            {primaryProducts.map((product) => (
                                                                                <MenuItem key={product.id} value={product.id}>
                                                                                    {product.productName}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormControl sx={{ width: '100%', mb: '15px' }}>
                                                                        <TextField
                                                                            label="Descrição privada"
                                                                            type="text"
                                                                            InputLabelProps={{
                                                                                shrink: true
                                                                            }}
                                                                            value={campo.description}
                                                                            onChange={(e) =>
                                                                                atualizarCampo(indiceCampo, 'description', e.target.value)
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                    <FormControl sx={{ width: '100%', mb: '15px' }}>
                                                                        <TextField
                                                                            label="Descrição pública"
                                                                            type="text"
                                                                            InputLabelProps={{
                                                                                shrink: true
                                                                            }}
                                                                            error={error}
                                                                            value={campo.publicDescription}
                                                                            helperText={error ? 'Insira uma descrição' : null}
                                                                            onChange={(e) => {
                                                                                setError(false);
                                                                                atualizarCampo(
                                                                                    indiceCampo,
                                                                                    'publicDescription',
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                                <ButtonGroup
                                                                    sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
                                                                >
                                                                    <Button
                                                                        sx={{
                                                                            transition: 'all .15s ease-in',
                                                                            ':hover': { color: 'red' },
                                                                            width: '50%'
                                                                        }}
                                                                        onClick={() => removerCampo(indiceCampo)}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </Button>
                                                                    <Button
                                                                        sx={{
                                                                            transition: 'all .15s ease-in',
                                                                            ':hover': { color: 'grey' },
                                                                            width: '50%'
                                                                        }}
                                                                        onClick={handleAddNewAnswer}
                                                                    >
                                                                        <SaveIcon />
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </Box>
                                                        ))}
                                                        {/* <Button onClick={adicionarCampo}>Adicionar Resposta</Button> */}
                                                    </>
                                                )}
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseEdit}>cancelar</Button>
                                            </DialogActions>
                                        </Dialog>
                                    )}

                                    <Snackbar open={openSnakBar} autoHideDuration={1000} onClose={handleCloseSnackBar}>
                                        <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                                            deletado com sucesso!
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={openSnakBarCancelado} autoHideDuration={1000} onClose={handleCloseSnackBar}>
                                        <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                                            Cancelado
                                        </Alert>
                                    </Snackbar>
                                </Box>
                            </Box>
                        </Box>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    const [selectedQuestion, setSelectedQuestion] = useState(null);

    //func orderby
    const [orderByNome, setOrderByNome] = useState('asc');
    const [orderById, setOrderById] = useState('asc');

    const handleSortById = () => {
        const sortedItens = [...data].sort((a, b) => {
            if (orderById === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setData(sortedItens);
        setOrderById(orderById === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByNome = () => {
        const sortedItens = [...data].sort((a, b) => {
            const nomeA = a.description.toUpperCase();
            const nomeB = b.description.toUpperCase();

            if (orderByNome === 'asc') {
                if (nomeA < nomeB) return -1;
                if (nomeA > nomeB) return 1;
            } else {
                if (nomeA > nomeB) return -1;
                if (nomeA < nomeB) return 1;
            }

            return 0;
        });

        setData(sortedItens);
        setOrderByNome(orderByNome === 'asc' ? 'desc' : 'asc');
    };

    return (
        <MainCard title="Perguntas">
            <Grid container spacing={gridSpacing}>
                {!formOpen && (
                    <Grid item container sm={12} columns={12} spacing={2} sx={{ justifyContent: 'center' }}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '2em', padding: '1em' }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setFormOpen(!formOpen);
                                }}
                                sx={{ textTransform: 'none' }}
                            >
                                Cadastrar nova pergunta
                            </Button>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={data}
                                getOptionLabel={(question) => question.description}
                                value={selectedQuestion}
                                onChange={(event, newValue) => {
                                    setSelectedQuestion(newValue);
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Pesquisar Perguntas" />}
                            />
                        </Box>
                        {loading && <CircularProgress sx={{ marginTop: '2em' }} aria-label="progress" />}
                        {!loading && data.length === 0 ? (
                            <>
                                <Box sx={{ display: 'flex', mt: '5rem', justifyContent: 'center', width: '100%' }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>
                                        Ainda nao existem perguntas criadas
                                    </Typography>
                                </Box>
                            </>
                        ) : (
                            <>
                                {' '}
                                <TableContainer>
                                    <Table aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                {/* <TableCell sx={{ pl: 3 }} /> */}
                                                <TableCell align="center">
                                                    <TableSortLabel onClick={handleSortById}>Id</TableSortLabel>
                                                </TableCell>

                                                <TableCell align="center">
                                                    <TableSortLabel onClick={handleSortByNome}>Descrição</TableSortLabel>
                                                </TableCell>
                                                <TableCell align="center">Tipo</TableCell>
                                                <TableCell align="right">Ações</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data

                                                .filter((question) =>
                                                    selectedQuestion ? question.description.includes(selectedQuestion.description) : true
                                                )
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((question, index) => (
                                                    <Row
                                                        key={index}
                                                        question={question}
                                                        id={question.id}
                                                        products={allProducts}
                                                        type={question.type}
                                                        bodyFormula={question.bodyFormula}
                                                        description={question.description}
                                                        answers={question.answers}
                                                        questionID={question[index]}
                                                        currentItem={currentItem}
                                                        loadingEdit={loadingEdit}
                                                    />
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {data.length > 5 && (
                                    <TablePagination
                                        rowsPerPageOptions={[5, 15, 100]}
                                        component="div"
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                )}
                            </>
                        )}
                    </Grid>
                )}
                {formOpen && (
                    <Grid item xs={12} sm={12}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            {/* <Button
                                onClick={() => {
                                    setFormOpen(!formOpen);
                                    setEtapaAtual(1);
                                }}
                            >
                                Fechar Cadastro
                            </Button> */}
                        </Box>
                        {renderEtapaAtual()}
                    </Grid>
                )}
            </Grid>
        </MainCard>
    );
}

export default QuestionView;
