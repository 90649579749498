export function formatarNumero(valor) {
    // Verifica se o valor é um número
    if (typeof valor !== 'number') {
        return 'Formato inválido';
    }

    // Converte o número para string e divide em parte inteira e decimal
    const partes = valor.toFixed(2).toString().split('.');

    // Adiciona os pontos de milhar na parte inteira
    partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Retorna o valor formatado
    return partes.join(',');
}
