import React, { createContext, useContext } from 'react';
import { useState } from 'react';

// Defina o contexto
const MyContext = createContext();

// Defina um provedor que usará useReducer para gerenciar o estado
const ContextDataProvider = ({ children }) => {
    const [initialData, setInitialData] = useState(null);
    const [finalData, setFinalData] = useState(null);
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [project, setProject] = useState(null);

    return (
        <MyContext.Provider
            value={{ initialData, setInitialData, finalData, setFinalData, isFilterActive, setIsFilterActive, setProject, project }}
        >
            {children}
        </MyContext.Provider>
    );
};

const useDataFilterCosts = () => {
    const context = useContext(MyContext);
    if (!context) {
        console.log('useMyContext deve ser usado dentro de um MyContextProvider');
    }
    return context;
};

export { ContextDataProvider, useDataFilterCosts };
