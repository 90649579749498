import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, FormControl, IconButton, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { useFormula } from 'hooks/useFormula';
import DeleteIcon from '@mui/icons-material/Delete';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};

export default function ModalUrl({ openUrlModal, setOpenUrlModal, currentQuesiton }) {
    console.log('🚀 ~ file: index.js:24 ~ ModalUrl ~ currentQuesiton:', currentQuesiton);
    const { position } = useFormula();
    console.log('currentQuesiton', position);
    const { id } = useParams();
    const [url, setUrl] = useState('');
    const api = useApi();
    const handleClose = () => setOpenUrlModal(false);

    const handleSendUrl = async () => {
        if (url.trim() === '') {
            toast.warn('O campo deve ser preenchido corretamente');
            return;
        }

        // Função para verificar se a URL é válida
        const isValidUrl = (url) => {
            try {
                new URL(url);
                return true;
            } catch (error) {
                return false;
            }
        };

        if (!isValidUrl(url)) {
            toast.warn('A URL inserida não é válida');
            return;
        }

        const payload = {
            id: position.id,
            openQuestionRestriction: position.openQuestionRestriction,
            position: position.position,
            imageUrl: url,
            haveBodyFormula: position.haveBodyFormula,
            question: currentQuesiton
        };

        try {
            const response = await api.updateImageAndOpenQuestion(currentQuesiton.positionId, payload);
            console.log('response image', response.data);
            toast.success('Imagem adicionada com sucesso!');
            handleClose();
            setUrl('');
            window.location.reload();
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleDeleteImageUrl = async () => {
        const payload = {
            id: position.id,
            openQuestionRestriction: position.openQuestionRestriction,
            position: position.position,
            imageUrl: null,
            haveBodyFormula: position.haveBodyFormula,
            question: currentQuesiton
        };

        try {
            const response = await api.updateImageAndOpenQuestion(position.id, payload);
            toast.success('Imagem Deletada com sucesso!');
            handleClose();
            setUrl('');
            window.location.reload();
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <div>
            <Modal open={openUrlModal} onClose={handleClose}>
                <Box sx={style}>
                    <h4>Insira uma URL válida no campo abaixo</h4>
                    <FormControl sx={{ width: '100%', mb: '15px' }}>
                        <TextField
                            id="product"
                            label="URL"
                            multiline
                            maxRows={2}
                            onChange={(event) => setUrl(event.target.value)}
                            value={url}
                        />
                    </FormControl>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                        {position.imageUrl !== null && (
                            <Button variant="outlined" onClick={handleDeleteImageUrl}>
                                Deletar Imagem <DeleteIcon />{' '}
                            </Button>
                        )}
                        <Button variant="contained" onClick={handleSendUrl}>
                            Salvar imagem &nbsp; &nbsp; <SendIcon />
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
