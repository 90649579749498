import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Drawer, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const style = {
    width: '300px',
    padding: '50px 60px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
};

export default function EditModal({ open, setOpen, get, icms }) {
    const api = useApi();

    const handleClose = () => {
        setOpen(false);
        setPercentagem('');
        setStateBr([]);
    };

    const [percentagem, setPercentagem] = useState('');
    const [stateBr, setStateBr] = useState([]);

    useEffect(() => {
        setPercentagem(icms?.percent);

        const arraystats = icms?.state?.split('/');
        setStateBr(arraystats || []);
    }, [icms]);
    // Defina os estados

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setStateBr(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const estadosBrasil = [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO',
        'EX'
    ];

    const editIcmsModal = async () => {
        const stateSelected = stateBr?.join('/');

        // const editedPercentagem = percentagem === '' ? icms.percentage : Number(percentagem);

        const editedState = stateBr === undefined ? icms.state : stateSelected;

        const payload = {
            state: editedState,
            percentage: percentagem === undefined ? Number(icms.percentage) : Number(percentagem)
        };
        console.log('🚀 ~ file: EditModal.js:86 ~ editIcmsModal ~ payload:', payload);

        try {
            await api.editIcms(icms.id, payload);
            toast.success('ICMS editado com sucesso');
            handleClose();
            get();
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <div>
            <Drawer open={open} anchor="right" onClose={() => setOpen(false)}>
                <Box sx={style}>
                    <div>
                        <h3>Edição de ICMS</h3>
                    </div>
                    <div>
                        <FormControl sx={{ width: '100%', marginBottom: '20px' }}>
                            <InputLabel id="demo-multiple-name-label">Estados</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                label="Estados"
                                multiple
                                value={stateBr}
                                defaultValue={icms?.state}
                                onChange={handleChange}
                            >
                                {estadosBrasil.map((state) => (
                                    <MenuItem key={state} value={state}>
                                        {state}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Porcentagem"
                            type="number"
                            value={percentagem}
                            defaultValue={icms?.percentage}
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) => {
                                setPercentagem(event.target.value === '' ? '' : Number(event.target.value));
                            }}
                        />
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <Button variant="contained" sx={{ width: '200px' }} onClick={editIcmsModal}>
                            Salvar
                        </Button>
                    </div>
                </Box>
            </Drawer>
        </div>
    );
}
