import { Fab, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { useParams, useNavigate } from 'react-router';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { formatarNumero } from 'utils/formatarNumeroReal';
import { formatarData } from 'utils/regex';
import { useDataFilterCosts } from 'store/ContextData';
import ReplyIcon from '@mui/icons-material/Reply';

const Consumption = () => {
    const navigate = useNavigate();
    const { isFilterActive, finalData, initialData } = useDataFilterCosts();

    const { id } = useParams();
    const api = useApi();
    const [rows, setRows] = useState([]);

    const getConsumtionFiltredRout = async () => {
        try {
            const { data } = await api.getConsumtionFiltred(initialData, finalData, id);

            const row = data.map((item, index) => ({
                ...item,
                data: formatarData(item.dtMovimento),
                cod: item.codConsumo,
                Desc: item.descConsumo,
                un: item.unConsumo,
                qtd: formatarNumero(item.qtdConsumo),
                m_prima: formatarNumero(item.mprimaConsumo),
                beneficiamento: formatarNumero(item.mbenefConsumo),
                vlrunit: formatarNumero(item.mvlrUnitConsumo),
                vlrtotal: formatarNumero(item.mvlrTotalConsumo),
                id: index,
                analise: item.analiseConsumo
            }));
            setRows(row);
            console.log('🚀 ~ file: index.js:24 ~ getConsumtionFiltredRout ~ data:', data);
        } catch (error) {
            console.log('🚀 ~ file: index.js:25 ~ getConsumptionFiltered ~ error:', error);
        }
    };

    const getConsumption = async () => {
        try {
            const { data } = await api.getConsumption(id);
            console.log('🚀 ~ file: index.js:14 ~ getConsumption ~ data:', data);
            const row = data.map((item, index) => ({
                ...item,
                data: formatarData(item.dtMovimento),
                cod: item.codConsumo,
                Desc: item.descConsumo,
                un: item.unConsumo,
                qtd: formatarNumero(item.qtdConsumo),
                m_prima: formatarNumero(item.mprimaConsumo),
                beneficiamento: formatarNumero(item.mbenefConsumo),
                vlrunit: formatarNumero(item.mvlrUnitConsumo),
                vlrtotal: formatarNumero(item.mvlrTotalConsumo),
                id: index,
                analise: item.analiseConsumo
            }));
            setRows(row);
        } catch (error) {
            console.log('🚀 ~ file: index.js:15 ~ getConsumption ~ error:', error);
        }
    };

    useEffect(() => {
        if (isFilterActive) getConsumtionFiltredRout();
        if (!isFilterActive) getConsumption();
    }, [isFilterActive]);

    const getBallColor = (value) => {
        const correctValue = Number(value).toFixed(2);
        console.log('🚀 ~ file: index.js:46 ~ getBallColor ~ correctValue:', correctValue);
        if (correctValue <= 30) {
            return 'green';
        } else if (correctValue <= 50) {
            return 'yellow';
        } else {
            return 'red';
        }
    };

    const BallRenderer = ({ value }) => {
        const color = getBallColor(value);

        return (
            <div
                style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    backgroundColor: color,
                    margin: '0 auto'
                }}
            />
        );
    };

    const columns = [
        { field: 'data', headerName: 'Data', width: 130 },
        { field: 'cod', headerName: 'Código', width: 100 },
        { field: 'Desc', headerName: 'Descrição', type: 'number', width: 300 },
        { field: 'un', headerName: 'UNID', type: 'number', width: 100 },
        { field: 'qtd', headerName: 'QTDE', type: 'number', width: 100 },
        { field: 'vlrunit', headerName: 'VLR. Unitário', type: 'number', width: 100 },
        { field: 'vlrtotal', headerName: 'VLR. Total', type: 'number', width: 130 },
        { field: 'm_prima', headerName: 'M. Prima', type: 'number', width: 100 },
        { field: 'beneficiamento', headerName: 'Beneficiamento', type: 'number', width: 120 },

        {
            field: 'analise',
            headerName: 'Análise',
            width: 140,
            renderCell: (params) => <BallRenderer value={params.row.analise} />
        }
    ];

    return (
        <MainCard title={`Consumo - Projeto ${id}`}>
            <Fab
                onClick={() => navigate(-1)}
                variant="extended"
                sx={{ position: 'fixed', bottom: '50px', display: 'flex', alignItems: 'center', gap: '5px' }}
            >
                <ReplyIcon />
                voltar
            </Fab>
            <Grid container spacing={gridSpacing} sx={{ display: 'flex', flexDirection: 'column' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        margin: 'auto',
                        marginTop: '10px',
                        overflow: 'auto'
                    }}
                >
                    <DataGrid columns={columns} rows={rows} hideFooter autoHeight sx={{ maxWidth: '1350px', marginLeft: '5px' }} />
                </div>
            </Grid>
        </MainCard>
    );
};

export default Consumption;
