// assets
import { IconBrackets, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconBrackets,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const unit = {
    id: 'unit',
    title: 'Unidade',
    type: 'group',
    children: [
        {
            id: 'util-typography',
            title: 'Unidade',
            type: 'item',
            url: '/main/unit',
            icon: icons.IconBrackets,
            breadcrumbs: false
        }
    ]
};

export default unit;
