import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useApi } from 'Service/axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Formula from './components/Formula';
import OpenRestriction from './components/OpenRestriction';
import FormulaCloseQuestion from './components/FormulaCloseQuestion';
import ConfirmModal from 'ui-component/ConfirmModal';
import { useEffect } from 'react';
export default function ModalQuestions({
    openUrlModal,
    setOpenUrlModal,
    question,
    selectedList,
    currentId,
    questionType,
    currentOption,
    indexposition,
    quizId,
    positionModel,
    savingPosition
}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: questionType === 'Fechada' ? 1000 : 450,
        borderRadius: '10px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        height: questionType === 'Fechada' ? '100%' : '400px',
        padding: '20px',
        height: 'auto'
    };

    const handleClose = () => {
        setOpenUrlModal(false);
    };

    return (
        <>
            {questionType === 'Fechada' && (
                <Modal open={openUrlModal} onClose={handleClose}>
                    <Box sx={style}>
                        <div>
                            <FormulaCloseQuestion
                                selectedList={selectedList}
                                currentId={currentId}
                                setOpenAllModal={setOpenUrlModal}
                                question={question}
                                savingPosition={savingPosition}
                            />
                        </div>
                    </Box>
                </Modal>
            )}
            {questionType === 'Aberta' && (
                <Modal open={openUrlModal} onClose={handleClose}>
                    <Box sx={style}>
                        <OpenRestriction
                            indexposition={indexposition}
                            quesitonObj={currentOption}
                            quizId={quizId}
                            setOpen={setOpenUrlModal}
                            positionModel={positionModel}
                        />
                    </Box>
                </Modal>
            )}
        </>
    );
}
ModalQuestions.propTypes = {
    Selected: PropTypes.func
};
