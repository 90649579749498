import { createContext, useState } from 'react';

export const FormulaContext = createContext();

const ContextFormula = ({ children }) => {
    const [selectedList, setSelectedList] = useState([]);
    const [currentIdContext, setCurrentIdContext] = useState('');
    const [question, setQuestion] = useState([]);
    const [currentQuestionContext, setCurrentQuestionContext] = useState([]);
    const [position, setPosition] = useState('');
    const [quizIdContext, setQuizIdContext] = useState('');

    return (
        <FormulaContext.Provider
            value={{
                selectedList,
                setSelectedList,
                currentIdContext,
                setCurrentIdContext,
                question,
                setQuestion,
                setCurrentQuestionContext,
                currentQuestionContext,
                position,
                setPosition,
                quizIdContext,
                setQuizIdContext
            }}
        >
            {children}
        </FormulaContext.Provider>
    );
};

export default ContextFormula;
