// assets
import { IconListDetails, IconChartHistogram } from '@tabler/icons';

// constant
const icons = {
    IconListDetails,
    IconChartHistogram
};

const pcp = {
    id: 'pcp',
    title: 'PCP',
    type: 'group',
    children: [
        {
            id: 'faturamento-project',
            title: 'Dashboard',
            type: 'item',
            url: '/main/dashboard/faturamento',
            icon: icons.IconChartHistogram,
            breadcrumbs: false
        },
        {
            id: 'project',
            title: 'Projetos',
            type: 'item',
            url: '/main/pcp/mp-em-projetos',
            icon: icons.IconListDetails,
            breadcrumbs: false
        },
        {
            id: 'util-typography',
            title: 'Ordens FP',
            type: 'item',
            url: '/main/pcp/ordemfp',
            icon: icons.IconListDetails,
            breadcrumbs: false
        },
        {
            id: 'util-typography-pcp-LB',
            title: 'Ordens LB',
            type: 'item',
            url: '/main/pcp/ordemlb',
            icon: icons.IconListDetails,
            breadcrumbs: false
        },
        {
            id: 'util-typography-pcp-AB',
            title: 'Ordens AB',
            type: 'item',
            url: '/main/pcp/ordemab',
            icon: icons.IconListDetails,
            breadcrumbs: false
        }
    ]
};

export default pcp;
