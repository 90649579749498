import React from 'react';
import { Box, Button, TextField, Typography, TableCell, TableRow, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { IconPencil } from '@tabler/icons';
import { useApi } from 'Service/axios';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

function Row({ group, getGroups }) {
    const theme = useTheme();
    const api = useApi();
    const [openTable, setOpenTable] = useState(false);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openSnakBar, setOpenSnackBar] = useState(false);
    const [openSnakBarCancelado, setOpenSnackBarCancelado] = useState(false);
    const [newGroupName, setNewGroupName] = useState(group?.name);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenEdit = () => {
        setOpenEdit(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenSnackBarCancelado(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setOpenSnackBarCancelado(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
        setOpenSnackBarCancelado(false);
    };

    const handleDeleteGroup = async (e, groupId) => {
        e.preventDefault();
        try {
            const response = await api.deleteGroupColor(groupId);
        } catch (error) {
            window.alert('Ocorreu um erro, tente novamente');
        }
        getGroups();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await api.updateGroupColor(group?.id, newGroupName);
        window.location.reload();
    };

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ pl: 3 }} />
                {/* <TableCell sx={{ pl: 3 }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpenTable(!openTable)}>
                        {openTable ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell> */}
                <TableCell component="th" scope="row" align="left">
                    {group?.id}
                </TableCell>
                <TableCell align="right">{group?.name}</TableCell>
                <TableCell align="right">
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box sx={{ alignSelf: 'flex-end' }}>
                                <Tooltip title="Editar">
                                    <IconButton>
                                        <IconPencil onClick={handleClickOpenEdit} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Excluir" onClick={handleClickOpen}>
                                    <IconButton>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                                <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-slide-description">
                                            tem certeza que quer deletar esse grupo?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose}>cancelar</Button>
                                        <Button
                                            variant="contained"
                                            onClick={(e) => {
                                                handleDeleteGroup(e, group?.id);
                                            }}
                                        >
                                            Deletar
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                {/* editar dialog */}
                                <Dialog open={openEdit} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', minWidth: '600px', gap: '1rem' }}>
                                        <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>Editar Grupo de cores</Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                            <TextField
                                                sx={{ marginBottom: '1em' }}
                                                fullWidth
                                                value={newGroupName}
                                                placeholder="Nome do grupo"
                                                onChange={(e) => {
                                                    setNewGroupName(e.target.value);
                                                }}
                                            />
                                        </Box>
                                        <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                                            Editar
                                        </Button>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseEdit}>cancelar</Button>
                                    </DialogActions>
                                </Dialog>
                                <Snackbar open={openSnakBar} autoHideDuration={1000} onClose={handleCloseSnackBar}>
                                    <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                                        deletado com sucesso!
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openSnakBarCancelado} autoHideDuration={1000} onClose={handleCloseSnackBar}>
                                    <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                                        Cancelado
                                    </Alert>
                                </Snackbar>
                            </Box>
                        </Box>
                    </Box>
                </TableCell>
            </TableRow>
        </>
    );
}

export default Row;
