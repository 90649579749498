import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useFormula } from 'hooks/useFormula';
import { useNavigate } from 'react-router';
import ModalQuestions from '../ModalQuestions';
import { useState } from 'react';
import { useEffect } from 'react';

export default function RedirectModal({
    open,
    setOpen,
    currentAnswers,
    question,
    selectedList,
    currentId,
    questionType,
    currentOption,
    indexposition,
    quizId,
    pos,
    positionModel,
    savingPosition
}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px'
    };

    const { setQuestion, setCurrentIdContext, setSelectedList, setCurrentQuestionContext, setPosition, setQuizIdContext } = useFormula();

    const navigate = useNavigate();
    const [openNewRestriction, setOpenNewRestricion] = useState(false);

    const handleClose = () => setOpen(false);

    useEffect(() => {
        setSelectedList(selectedList);
        console.log(currentOption);
    }, []);

    const formulaPortal = () => {
        setQuestion(question);
        setCurrentIdContext(currentId);
        setSelectedList(selectedList);
        setCurrentQuestionContext(currentOption);
        navigate('/main/formula');
        setPosition(positionModel);
        setQuizIdContext(quizId);
    };

    return (
        <div>
            <ModalQuestions
                openUrlModal={openNewRestriction}
                setOpenUrlModal={setOpenNewRestricion}
                question={question}
                selectedList={selectedList}
                currentId={currentId}
                questionType={questionType}
                currentOption={currentOption}
                indexposition={indexposition}
                quizId={quizId}
                positionModel={positionModel}
                savingPosition={savingPosition}
            />
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <h3 style={{ margin: 0 }}>Área de restrições</h3>
                    <span style={{ display: 'block', marginTop: '5px', marginBottom: '20px' }}>Escolha uma opção!</span>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                        <Button onClick={() => setOpen(false)} variant="text">
                            Voltar
                        </Button>
                        {positionModel.haveBodyFormula && questionType === 'Fechada' && (
                            <Button variant="outlined" onClick={formulaPortal}>
                                Editar Restrições
                            </Button>
                        )}

                        <Button
                            variant="contained"
                            onClick={() => {
                                setOpen(false);
                                setOpenNewRestricion(true);
                            }}
                        >
                            Restrição
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
