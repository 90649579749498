import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const DeleteUnity = ({ open, setOpen, data, get }) => {
    const api = useApi();
    const handleClose = () => setOpen(false);

    const handleDeleteUnit = async (id) => {
        console.log(id);
        try {
            const response = await api.deleteUnit(id);
            console.log(response.data);
            get();
            setOpen(false);
        } catch (error) {
            // setErrorMensage('Esta unidade esta ligada a um produto');
            toast.error('Não foi possível prosseguir, a unidade esta registrada em algum produto cadastrado');
            console.log(error.message);
        }
    };

    return (
        <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    tem certeza que quer deletar esse item de id {data.id}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>cancelar</Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        handleDeleteUnit(data.id);
                    }}
                >
                    Deletar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteUnity;
