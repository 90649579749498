import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import SimpleTable from 'ui-component/SimpleTableComponent';
import { formatarValorIntl } from 'utils/formatValueIntl';
import { formatarNumero } from 'utils/formatarNumeroReal';
import { formatarData } from 'utils/regex';
const Labor = ({ open, setOpen, project }) => {
    console.log('🚀 ~ file: Labor.js:10 ~ Labor ~ project:', project);
    const api = useApi();
    const [labor, setLabor] = useState([]);

    const getLabor = async () => {
        try {
            const { data } = await api.getMo(project);
            console.log('🚀 ~ getLabor ~ data:', data);
            const row = data.map((item, index) => ({
                ...item,
                id: index,
                data: formatarData(item.dtMovimento),
                m_o_direta: formatarNumero(item.mo_direta),
                m_o_indireta: formatarNumero(item.mo_indireta),
                vlr_total: formatarNumero(item.mvlrUnitConsumo * item.qtdConsumo),
                vlr_unit: formatarNumero(item.mvlrUnitConsumo),
                cod: item.codConsumo,
                Desc: item.descConsumo,
                un: item.unConsumo,
                qtd: formatarNumero(item.qtdConsumo),
                status: item.kpiColor,
                kpi: item.kpi
            }));
            setLabor(row);
        } catch (error) {
            console.log('🚀 ~ file: Labor.js:14 ~ getLabor ~ error:', error);
        }
    };

    useEffect(() => {
        getLabor();
    }, [project]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        maxHeight: '700px',
        overflow: 'auto',
        borderRadius: '8px'
    };
    const columns = [
        { field: 'data', headerName: 'Data', width: 130 },
        { field: 'cod', headerName: 'Código', width: 100 },
        { field: 'Desc', headerName: 'Descrição', type: 'number', width: 300 },
        { field: 'un', headerName: 'UNID', type: 'number', width: 100 },
        { field: 'qtd', headerName: 'QTDE', type: 'number', width: 100 },
        { field: 'vlr_unit', headerName: 'V. Unitário', width: 100 },
        { field: 'vlr_total', headerName: 'V. Total', width: 100 },
        { field: 'm_o_direta', headerName: 'M. O. Direta', width: 130 },
        { field: 'm_o_indireta', headerName: 'M. O. Indireta', width: 170 },
        {
            field: 'consumo',
            headerName: 'T. M. Obra',
            type: 'number',
            width: 80,
            renderCell: (params) => {
                return formatarValorIntl(params.row.mo_direta + params.row.mo_indireta);
            }
        },
        {
            field: 'kpi',
            headerName: 'KPI',
            width: 120,
            renderCell: (params) => {
                const corPerc = params.row.status; // Substitua 'kpi_resultado1' pelo nome real do campo
                let backgroundColor = '#999'; // Cor padrão

                if (corPerc === 'VERMELHO') {
                    backgroundColor = 'red';
                } else if (corPerc === 'AMARELO') {
                    backgroundColor = '#FFD700';
                } else if (corPerc === 'VERDE') {
                    backgroundColor = 'green';
                }
                return (
                    <div
                        style={{
                            backgroundColor,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '8px 5px',
                            borderRadius: 8,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '24px'
                        }}
                    >
                        {params?.row?.kpi?.toFixed(0)}%{' '}
                    </div>
                );
            }
        }
    ];

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} onClick={(e) => e.stopPropagation()}>
                    <h2 style={{ textAlign: 'center' }}>{`Mão de obra - Projeto ${project}`}</h2>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            margin: 'auto',
                            marginTop: '10px',
                            overflow: 'auto'
                        }}
                    >
                        <SimpleTable columns={columns} rows={labor} />
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default Labor;
