import {
    Button,
    Dialog,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField
} from '@mui/material';
import { Box } from '@mui/system';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';

const Similarnv3 = ({ open, setOpen, currentRow }) => {
    console.log('🚀 ~ file: SecondModal.js:21 ~ SecondModal ~ currentRow:', currentRow);

    const api = useApi();
    const [data, setData] = useState([]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        maxHeight: 700,
        borderRadius: 4
    };

    const getStocks = async () => {
        try {
            const res = await api.getSimilarnv2Pcp(currentRow?.tlts_pro_in_codigo);
            console.log('🚀data ', res.data);
            setData(res.data);
        } catch (error) {
            console.log('🚀 ~ file: FirstModal.js:47 ~ get ~ error:', error);
        }
    };

    useEffect(() => {
        getStocks();
    }, [currentRow]);

    return (
        <Modal open={open} keepMounted onClose={() => setOpen(false)}>
            <Box sx={style}>
                <h3>Similares</h3>
                <Box sx={{ display: 'flex', gap: '1em', marginBottom: '2em', width: '100%' }}>
                    <TextField sx={{ width: '20%' }} value={currentRow?.tlts_pro_in_codigo} placeholder="ORDEM" disabled />
                    <TextField sx={{ width: '70%' }} value={currentRow?.tlts_pro_st_descricao} placeholder="ITEM" disabled />
                    <TextField sx={{ width: '10%' }} value={currentRow?.tlts_uni_st_unidade} placeholder="UN" disabled />
                    <TextField sx={{ width: '20%' }} value={currentRow?.tlts_com_re_quantidade} placeholder="QUANTIDADE" disabled />
                </Box>
                <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: '500px' }}>
                    <Table sx={{ minWidth: 750 }} stickyHeader size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel>Código</TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel>Descriçao</TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel>UN</TableSortLabel>
                                </TableCell>
                                <TableCell align="center">Saldo</TableCell>
                                <TableCell align="center">Compra</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length > 0 ? (
                                data.map((row) => (
                                    <TableRow key={row.item1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {row.slts_pro_in_codigo}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                maxWidth: 200
                                            }}
                                        >
                                            {row.slts_pro_st_descricao}
                                        </TableCell>
                                        <TableCell align="center">{row.slts_uni_st_unidade}</TableCell>

                                        <TableCell align="center">{row.slts_com_re_quantidade}</TableCell>
                                        <TableCell align="center">{row.slts_pedido_aberto}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {'-'}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            maxWidth: 120,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        {'-'}
                                    </TableCell>
                                    <TableCell align="center">{'-'}</TableCell>
                                    <TableCell align="center">{'-'}</TableCell>
                                    <TableCell align="center">{'-'}</TableCell>
                                    <TableCell align="center">{'-'}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal>
    );
};

export default Similarnv3;
