import { Button, Drawer, TextField } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';

const DrawerEdit = ({ open, setOpen, currentRow }) => {
    const [price, setPrice] = useState('');

    useEffect(() => {
        setPrice(currentRow.productPrice);
    }, [currentRow]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        alert(price);
    };

    return (
        <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
            <div style={{ width: '400px', padding: '20px' }}>
                <div>
                    <h2 style={{ marginBottom: '-10px' }}>Atualizando valor do material:</h2>
                    <h3>{currentRow?.productName}</h3>
                </div>

                <div>
                    <form onSubmit={handleSubmit} style={{ marginTop: '40px' }}>
                        <TextField
                            sx={{ width: '100%', marginBottom: '16px' }}
                            label="Unidade de medida"
                            InputLabelProps={{ shrink: true }}
                            disabled
                            value={currentRow?.unit?.unitSize}
                        />
                        <TextField
                            disabled
                            value={currentRow?.groupColor?.name}
                            sx={{ width: '100%', marginBottom: '16px' }}
                            label="Grupo de cor"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            sx={{ width: '100%', marginBottom: '36px' }}
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            label="Preço"
                            InputLabelProps={{ shrink: true }}
                        />
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <Button type="submit" variant="contained" sx={{ width: '200px', margin: '0 auto' }}>
                                Enviar para análise
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </Drawer>
    );
};

export default DrawerEdit;
