import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    TextField,
    Typography,
    Box,
    InputLabel,
    Select,
    MenuItem,
    ButtonGroup,
    FormControl,
    OutlinedInput,
    Chip,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    TablePagination,
    Collapse,
    Tooltip,
    IconButton
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { IconPencil } from '@tabler/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import SubCard from 'ui-component/cards/SubCard';
import { toast } from 'react-toastify';

// =============== TABELA ================

function Row({ product, units, groups, origins, colors, handleDeleteProduct, getProduct }) {
    const theme = useTheme();
    const api = useApi();
    const [openTable, setOpenTable] = useState(false);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openSnakBar, setOpenSnackBar] = useState(false);
    const [openSnakBarCancelado, setOpenSnackBarCancelado] = useState(false);

    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [unit, setUnit] = useState('');
    const [group, setGroup] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);

    const [buy, setBuy] = useState('');
    const [primary, setPrimary] = useState(product.productPrimary);
    const [fabricated, setFabricated] = useState('');
    const [intermediary, setIntermediary] = useState();
    const [generic, setGeneric] = useState('');
    const [final, setFinal] = useState('');
    useEffect(() => {
        setName(product.productName);
        setPrice(product.productPrice);
        setUnit(product.unit.id);
        setGroup(product.productGroups);
        setBuy(product.productBuy);
        setFabricated(product.productFabricated);
        setIntermediary(product.productIntermediary);
        setGeneric(product.productGeneric);
        setFinal(product.productFinal);
    }, [product]);

    useEffect(() => {
        if (product !== undefined) {
            setSelectedGroup(product.productGroups.map((item) => item.id));
        }
    }, [product]);

    const handleUpdateProduct = async (e, id) => {
        console.log('group', group);
        console.log('group1', selectedGroup);
        const groups = selectedGroup.map((id) => ({ id: id }));

        e.preventDefault();
        // const groups = group.map((item) => ({ id: parseInt(item) }));
        const transformedProduct = {
            productName: name,
            unit: unit,
            productBuy: buy,
            productPrimary: primary,
            productFabricated: fabricated,
            productIntermediary: false,
            productGeneric: generic,
            productFinal: final,
            productPrice: price,
            productGroup: groups,
            service: product.service
        };
        console.log('🚀 ~ handleUpdateProduct ~ transformedProduct:', transformedProduct);
        try {
            await api.updateProduct(id, transformedProduct, groups);
            setOpen(false);
            setOpenEdit(false);
            setOpenSnackBar(true);
            getProduct();
            toast.success('Produto atualizado com sucesso!');
        } catch (error) {
            console.log('🚀 ~ file: index.js:106 ~ handleUpdateProduct ~ error:', error);
            toast.error('Erro ao atualizar produto!');
        }
    };

    const handleClickOpenEdit = () => {
        setOpenEdit(true);
        //console.log(test);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenEdit(false);
        setOpenSnackBarCancelado(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setOpenSnackBarCancelado(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
        setOpenSnackBarCancelado(false);
    };

    const handleDelete = async () => {
        handleDeleteProduct(product.id);
        setOpen(false);
        setOpenEdit(false);
        setOpenSnackBar(true);
    };

    const handleChangeGroupsSelect = (event) => {
        const {
            target: { value }
        } = event;
        setSelectedGroup(value);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    style={{ maxWidth: '130px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                >
                    {product?.productName}
                </TableCell>
                <TableCell align="center">{product?.unit?.unitSize}</TableCell>
                <TableCell align="center">
                    {product.productPrimary && <Typography>Matéria Prima</Typography>}
                    {product.productFinal && <Typography>Produto Final</Typography>}
                    {product.service && <Typography>Serviço</Typography>}
                </TableCell>
                <TableCell align="center">
                    {product.productIntermediary && <Typography>Produto Intermediário</Typography>}
                    {product.productBuy && <Typography>Produto Comprado</Typography>}
                    {product.productFabricated && <Typography>Produto Fabricado</Typography>}
                    {generic && <Typography>Produto Genérico</Typography>}
                    {product.service && <Typography>Serviço</Typography>}
                </TableCell>

                <TableCell align="right">
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box sx={{ alignSelf: 'flex-end' }}>
                                <Tooltip title="Editar">
                                    <IconButton onClick={handleClickOpenEdit}>
                                        <IconPencil />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Excluir">
                                    <IconButton onClick={handleClickOpen}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>

                                <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-slide-description">
                                            Tem certeza que quer deletar esse produto ?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose}>cancelar</Button>
                                        <Button variant="contained" onClick={handleDelete}>
                                            Deletar
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog open={openEdit} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                                    <DialogContent
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            minWidth: '600px',
                                            gap: '1rem',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography>EDITANDO</Typography>
                                        <TextField fullWidth value={name} label="Produto" onChange={(e) => setName(e.target.value)} />
                                        {product.productFinal ||
                                            (product.service && (
                                                <FormControl sx={{ width: '100%', mb: '15px' }}>
                                                    <InputLabel id="demo-multiple-checkbox">Grupos *</InputLabel>
                                                    <Select
                                                        labelId="grupo"
                                                        value={selectedGroup}
                                                        label="Grupos"
                                                        multiple
                                                        onChange={handleChangeGroupsSelect}
                                                        required
                                                    >
                                                        {groups.map((group) => (
                                                            <MenuItem key={group.id} value={group.id}>
                                                                {group.groupDescription}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ))}
                                        <FormControl sx={{ width: '100%', mb: '15px' }}>
                                            <InputLabel id="unit">Unidade de medida *</InputLabel>
                                            <Select
                                                labelId="unit"
                                                value={unit}
                                                label="Unidade de medida"
                                                onChange={(e) => setUnit(e.target.value)}
                                                required
                                            >
                                                {units &&
                                                    units.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.unitSize}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            <input type="hidden" name="unit" value={unit} />
                                        </FormControl>

                                        {product.productPrimary === true && (
                                            <TextField
                                                label="Tipo do produto"
                                                fullWidth
                                                variant="outlined"
                                                value="Matéria Prima"
                                                type="text"
                                                disabled
                                            />
                                        )}
                                        {product.productFinal === true && (
                                            <TextField
                                                label="Tipo do produto"
                                                fullWidth
                                                variant="outlined"
                                                value="Produto Final"
                                                type="text"
                                                disabled
                                            />
                                        )}
                                        {product.productIntermediary === true && (
                                            <Typography
                                                variant="body1"
                                                sx={{ whiteSpace: 'nowrap', textAlign: 'center', marginTop: '20px' }}
                                            >
                                                Produto Intermediário
                                            </Typography>
                                        )}
                                        {product.productBuy === true && (
                                            <TextField
                                                label="Tipo do produto"
                                                fullWidth
                                                variant="outlined"
                                                value="Produto Comprado"
                                                type="text"
                                                disabled
                                            />
                                        )}
                                        {product.productFabricated === true && (
                                            <TextField
                                                label="Tipo do produto"
                                                fullWidth
                                                variant="outlined"
                                                value="Produto Fabricado"
                                                type="text"
                                                disabled
                                            />
                                        )}
                                        {product.productGeneric === true && (
                                            <TextField
                                                label="Tipo do produto"
                                                fullWidth
                                                variant="outlined"
                                                value="Produto Genérico"
                                                type="text"
                                                disabled
                                            />
                                        )}
                                        {product.productFinal === true || product.productGeneric ? (
                                            <TextField
                                                label="Valor do produto"
                                                fullWidth
                                                variant="outlined"
                                                value={price}
                                                type="number"
                                                disabled
                                            />
                                        ) : (
                                            <TextField
                                                label="Valor do produto"
                                                fullWidth
                                                variant="outlined"
                                                value={price}
                                                type="number"
                                                onChange={(e) => setPrice(e.target.value)}
                                                required
                                            />
                                        )}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseEdit}>cancelar</Button>
                                        <Button onClick={(e) => handleUpdateProduct(e, product.id)}>Editar</Button>
                                    </DialogActions>
                                </Dialog>
                                <Snackbar open={openSnakBar} autoHideDuration={1000} onClose={handleCloseSnackBar}>
                                    <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                                        deletado com sucesso!
                                    </Alert>
                                </Snackbar>

                                <Snackbar open={openSnakBarCancelado} autoHideDuration={1000} onClose={handleCloseSnackBar}>
                                    <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                                        Cancelado
                                    </Alert>
                                </Snackbar>
                            </Box>
                        </Box>
                    </Box>
                </TableCell>
            </TableRow>
            {/* COLAPSE */}
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    {/* <Collapse in={openTable} timeout="auto" unmountOnExit>
                        {openTable && (
                            <Box sx={{ margin: 1 }}>
                                <TableContainer>
                                    <SubCard
                                        sx={{ bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : 'grey.50', mb: 2 }}
                                        title="Informações Adicionais do produto"
                                        content={false}
                                    >
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Grupo de cores</TableCell>
                                                    <TableCell align="center">Grupo de produtos</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableCell component="th" scope="row">
                                                    {product.groupColors.length > 0 ? (
                                                        product.groupColors.map((item) => {
                                                            return (
                                                                <Typography key={item.id} sx={{ mt: '1rem', mb: '1rem' }}>
                                                                    {item.name}
                                                                </Typography>
                                                            );
                                                        })
                                                    ) : (
                                                        <Typography sx={{ mt: '1rem', mb: '1rem' }}>
                                                            Sem gurpo de cores cadastrado para este produto
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography sx={{ mt: '1rem', mb: '1rem' }}>
                                                        {product?.productGroup?.groupDescription}
                                                    </Typography>
                                                </TableCell>
                                            </TableBody>
                                        </Table>
                                    </SubCard>
                                </TableContainer>
                            </Box>
                        )}
                    </Collapse> */}
                </TableCell>
            </TableRow>
        </>
    );
}

export default Row;
