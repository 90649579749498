import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';

const Analyse = ({ open, setOpen, currentRow }) => {
    const api = useApi();
    const [row, setRow] = useState([]);
    const getData = async () => {
        try {
            const { data } = await api.getPcdPcpProjects(currentRow.pedInCodigo);

            const row = data.map((item, index) => ({
                ...item,
                id: index,
                status: item?.itpSituacao,
                pcpInCodgo: item?.pdcInCodigo,
                qtd: item?.qtdPdcProjeto,
                qtdRec: item?.qtdBaixada
            }));
            setRow(row);
        } catch (error) {
            console.log('🚀 ~ getData ~ error:', error);
        }
    };

    useEffect(() => {
        getData();
    }, [currentRow]);

    const columns = [
        { field: 'pcpInCodgo', headerName: 'PDC', width: 300 },
        // {
        //     field: 'qtd',
        //     headerName: 'QTD',
        //     width:200,
        //     renderCell: (params) => {
        //         return <p>{params.row.qtd.toFixed(2)}</p>;
        //     }
        // },
        // { field: 'qtdRec', headerName: 'QTD REC', width:200 },
        { field: 'status', headerName: 'status', width: 200 }
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: '#fff',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px'
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <h3 style={{ textAlign: 'center' }}>PDC do projeto {currentRow.pedInCodigo}</h3>
                <DataGrid sx={{ maxHeight: '400px', width: '100%' }} rows={row} columns={columns} hideFooter />
            </Box>
        </Modal>
    );
};

export default Analyse;
