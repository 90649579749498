import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { IconPencil } from '@tabler/icons';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import DeleteIcon from '@mui/icons-material/Delete';
import AddModal from './components/AddModal';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import ConfirmModal from 'ui-component/ConfirmModal';
import EditModal from './components/EditModal';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';

const Icms = () => {
    const api = useApi();

    const [addModal, setAddModal] = useState(false);
    const [icmsRegistred, setIcmsRegistred] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [selectedIcms, setSelectedIcms] = useState([]);

    const getIcms = async () => {
        try {
            const { data } = await api.getIcms();
            const row = data.map((item) => ({
                id: item.id,
                percent: item.percentage,
                state: item.state
            }));
            setIcmsRegistred(row);
        } catch (error) {
            console.log(error.message);
        }
    };

    const deletIcms = async () => {
        try {
            await api.deleteIcms(selectedId);
            getIcms();
            toast.success('ICMS deletado com sucesso!');
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        getIcms();
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'state', headerName: 'Estado', flex: 1 },
        { field: 'percent', headerName: 'Porcentagem', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            sortable: false,
            flex: 1,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Editar">
                        <IconButton
                            onClick={() => {
                                setEditModal(true);
                                setSelectedIcms(params.row);
                            }}
                        >
                            <IconPencil />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir">
                        <IconButton
                            onClick={() => {
                                setSelectedId(params.row.id);
                                setShowDelete(true);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
    ];

    return (
        <MainCard title="ICMS">
            <AddModal open={addModal} setOpen={setAddModal} get={getIcms} />
            <EditModal open={editModal} setOpen={setEditModal} get={getIcms} icms={selectedIcms} />
            <ConfirmModal
                button1={'Cancelar'}
                button2={'Deletar'}
                firstMessage={'Deseja mesmo deletar este ICMS?'}
                secondMessage={'Após deletar os dados serão perdidos'}
                open={showDelete}
                setOpen={setShowDelete}
                func={deletIcms}
            />
            <Grid container spacing={gridSpacing} sx={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ margin: '20px' }}>
                    <Button variant="contained" onClick={() => setAddModal(true)}>
                        Cadastrar ICMS
                    </Button>
                </div>

                <div style={{ height: 550, display: 'flex', justifyContent: 'center' }}>
                    <DataGrid rows={icmsRegistred} columns={columns} hideFooter checkboxSelection={false} />
                </div>
            </Grid>
        </MainCard>
    );
};

export default Icms;
