import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

export default function ConfirmModal({ open, setOpen, func, firstMessage, secondMessage, button1, button2, func2, width }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width ? width : '400',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px'
    };

    const handleClose = () => setOpen(false);

    const handleFunc2 = () => {
        handleClose();

        if (typeof func2 === 'function') {
            func2();
        }
    };

    const handleDelete = () => {
        func();
        handleClose();
    };
    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <h3 style={{ margin: 0 }}>{firstMessage}</h3>
                    <span style={{ display: 'block', marginTop: '5px', marginBottom: '20px' }}>{secondMessage}</span>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                        {button1 && (
                            <Button onClick={handleFunc2} variant="text">
                                {button1}
                            </Button>
                        )}
                        <Button onClick={handleDelete} variant="contained">
                            {button2}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
