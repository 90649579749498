import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { Button, Grid, TextField, Typography, Box, CircularProgress, Divider, IconButton, Tooltip, TablePagination } from '@mui/material';
import { useState } from 'react';
import CardItem from 'ui-component/CardItem';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import ModalError from 'views/pages/modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconPencil } from '@tabler/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import Snackbar from '@mui/material/Snackbar';
import StraightenIcon from '@mui/icons-material/Straighten';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteUnity from './components/DeleteUnity';

function UnitView() {
    const [data, setData] = useState([]);
    const [unitName, setUnitName] = useState('');
    const [error, setError] = useState(false);

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState({});

    const [openSnakBar, setOpenSnackBar] = useState(false);
    const [openSnakBarCancelado, setOpenSnackBarCancelado] = useState(false);

    const [newName, setNewName] = useState();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    //modal error variables logic
    const [isOpen, setIsOpen] = useState(false);
    const [errorMensage, setErrorMensage] = useState('');

    const api = useApi();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const existe = data.find((item) => item.unitSize === unitName) !== undefined;

        if (unitName === '' || existe) {
            setError(true);
            return;
        } else {
            try {
                const response = await api.createUnit(unitName);
                if (response.status === 200 || response.status === 201) {
                    setUnitName('');
                    get();
                    setFormOpen(false);
                }
            } catch (err) {
                setErrorMensage('Algo deu errado');
                setIsOpen(true);
            }
        }
    };

    const get = async () => {
        const response = await api.getAllUnits();
        setLoading(false);
        setData(response.data);
    };

    useEffect(() => {
        get();
    }, []);

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const [loading, setLoading] = useState(true);

    const [formOpen, setFormOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenEdit = (itemId) => {
        setOpenEdit((prevState) => ({
            ...prevState,
            [itemId]: true
        }));
    };

    const handleCloseEdit = (itemId) => {
        setOpenEdit((prevState) => ({
            ...prevState,
            [itemId]: false
        }));
    };
    const handleClose = () => {
        setOpen(false);
        setOpenSnackBarCancelado(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
        setOpenSnackBarCancelado(false);
    };

    const handleDeleteUnit = async (id) => {
        console.log(id);
        try {
            const response = await api.deleteUnit(id);
            get();
            setOpen(false);
        } catch (error) {
            setIsOpen(true);
            setErrorMensage('Esta unidade esta ligada a um produto');
            window.alert('Não foi possível prosseguir, a unidade esta registrada em algum produto cadastrado');
        }
    };

    const handleEdit = async (id, newName) => {
        if (newName === '' || newName === undefined) {
            const response = await api.updateUnit(id, newName);
        } else {
            const response = await api.updateUnit(id, newName);
            get();
        }
        //console.log(newName);
        setOpen(false);
        setOpenEdit(false);
        setOpenSnackBar(true);
    };

    console.log(data);

    const [selectedSearchUnit, setSelectedSearchUnit] = useState(null);
    const [rowSelected, setRowSelected] = useState([]);
    return (
        <MainCard title="Unidade">
            <Grid container spacing={gridSpacing}>
                <DeleteUnity open={open} setOpen={setOpen} get={get} data={rowSelected} />
                {!formOpen && (
                    <Grid item container sm={12} columns={12} spacing={2} sx={{ justifyContent: 'center' }}>
                        {loading && <CircularProgress sx={{ marginTop: '2em', marginLeft: '2em' }} aria-label="progress" />}
                        {!loading && data.length === 0 && (
                            <Box sx={{ display: 'flex', mt: '5rem', justifyContent: 'center', width: '100%' }}>
                                <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>Ainda nao existem unidades criadas</Typography>
                            </Box>
                        )}
                        <Box
                            sx={{
                                width: '100%',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '2em',
                                padding: '1em'
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setFormOpen(!formOpen);
                                }}
                                variant="contained"
                                sx={{ textTransform: 'none' }}
                            >
                                Cadastrar nova unidade
                            </Button>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={data}
                                getOptionLabel={(unit) => unit?.unitSize}
                                value={selectedSearchUnit}
                                onChange={(event, newValue) => {
                                    setSelectedSearchUnit(newValue);
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Pesquisar Unidade" />}
                            />
                        </Box>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                <TableHead>
                                    {data.length > 0 && (
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell align="right">Unidade de medida</TableCell>
                                            <TableCell align="right">Ações</TableCell>
                                        </TableRow>
                                    )}
                                </TableHead>
                                <TableBody>
                                    {!loading &&
                                        data.length > 0 &&
                                        data
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .filter((unit) =>
                                                selectedSearchUnit ? unit?.unitSize.includes(selectedSearchUnit?.unitSize) : true
                                            )
                                            .map((row, index) => (
                                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="row">
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell align="right">{row.unitSize}</TableCell>
                                                    <TableCell align="right">
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between'
                                                                }}
                                                            >
                                                                <Box sx={{ alignSelf: 'flex-end' }}>
                                                                    <Tooltip title="Editar" onClick={() => handleClickOpenEdit(row.id)}>
                                                                        <IconButton>
                                                                            <IconPencil />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Excluir" onClick={handleClickOpen}>
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                setRowSelected(row);
                                                                                setOpen(true);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    {/* <Dialog
                                                                        open={open}
                                                                        keepMounted
                                                                        onClose={handleClose}
                                                                        aria-describedby="alert-dialog-slide-description"
                                                                    >
                                                                        <DialogContent>
                                                                            <DialogContentText id="alert-dialog-slide-description">
                                                                                tem certeza que quer deletar esse item de id{' '}
                                                                                {data[index]?.unitSize} ?
                                                                            </DialogContentText>
                                                                        </DialogContent>
                                                                        <DialogActions>
                                                                            <Button onClick={handleClose}>cancelar</Button>
                                                                            <Button
                                                                                variant="contained"
                                                                                onClick={() => {
                                                                                    handleDeleteUnit(row.id);
                                                                                }}
                                                                            >
                                                                                Deletar
                                                                            </Button>
                                                                        </DialogActions>
                                                                    </Dialog> */}
                                                                    {/* editar dialog */}
                                                                    <Dialog
                                                                        open={openEdit[row.id] || false}
                                                                        keepMounted
                                                                        onClose={() => handleCloseEdit(row.id)}
                                                                        aria-describedby="alert-dialog-slide-description"
                                                                    >
                                                                        <DialogContent>
                                                                            <Typography>
                                                                                Editando unidade - <strong>{row.unitSize}</strong>
                                                                            </Typography>
                                                                            <TextField
                                                                                defaultValue={row.unitSize}
                                                                                sx={{ marginTop: '1rem' }}
                                                                                onChange={(e) => {
                                                                                    setNewName(e.target.value);
                                                                                }}
                                                                            />
                                                                        </DialogContent>
                                                                        <DialogActions>
                                                                            <Button onClick={() => handleCloseEdit(row.id)}>
                                                                                cancelar
                                                                            </Button>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    handleEdit(row.id, newName);
                                                                                    setNewName(''); // Limpa o valor do newName após a edição
                                                                                    handleCloseEdit(row.id); // Fecha o diálogo de edição
                                                                                }}
                                                                            >
                                                                                Editar
                                                                            </Button>
                                                                        </DialogActions>
                                                                    </Dialog>
                                                                    <Snackbar
                                                                        open={openSnakBar}
                                                                        autoHideDuration={1000}
                                                                        onClose={handleCloseSnackBar}
                                                                    >
                                                                        <Alert
                                                                            onClose={handleCloseSnackBar}
                                                                            severity="success"
                                                                            sx={{ width: '100%' }}
                                                                        >
                                                                            Editado com sucesso!
                                                                        </Alert>
                                                                    </Snackbar>
                                                                    <Snackbar
                                                                        open={openSnakBarCancelado}
                                                                        autoHideDuration={1000}
                                                                        onClose={handleCloseSnackBar}
                                                                    >
                                                                        <Alert
                                                                            onClose={handleCloseSnackBar}
                                                                            severity="success"
                                                                            sx={{ width: '100%' }}
                                                                        >
                                                                            Cancelado
                                                                        </Alert>
                                                                    </Snackbar>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {data.length > 5 && (
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        )}
                    </Grid>
                )}

                {formOpen && (
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                marginLeft: '1em',
                                marginBottom: '2em',
                                padding: '1em'
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setFormOpen(!formOpen);
                                }}
                                variant="contained"
                            >
                                Ver todas as unidades
                            </Button>
                        </Box>
                        <form style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <Typography sx={{ fontSize: '20px' }}>Cadastrar nova unidade</Typography>
                            <TextField
                                id="outlined-read-only-input"
                                label="unidade"
                                value={unitName}
                                error={error}
                                helperText={error ? 'Este campo não pode ficar vazio ou ter o mesmo valor de um campo já existente ' : null}
                                variant="outlined"
                                onChange={(event) => {
                                    setUnitName(event.target.value);
                                    setError(false);
                                }}
                            />
                            <Button variant="contained" color="primary" type="submit" onClick={(e) => handleSubmit(e)}>
                                cadastrar
                            </Button>
                        </form>
                        <ModalError isOpen={isOpen} handleClose={handleCloseModal} text={errorMensage} />
                    </Grid>
                )}
            </Grid>
        </MainCard>
    );
}

export default UnitView;
