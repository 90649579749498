import { Button, FormControl, FormControlLabel, ListItemButton, ListItemText, Modal, TextField } from '@mui/material';
import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { formatarData } from 'utils/regex';

const InvoicingFilter = ({ open, setOpen, setSelectDateFilter }) => {
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        background: '#fff',
        boxShadow: 24,
        padding: 20,
        borderRadius: 6
    };

    const handleFilter = (e) => {
        e.preventDefault();
        setSelectDateFilter({
            de: formatarData(from),
            para: formatarData(to)
        });
        setFrom('');
        setTo('');
        setOpen(false);
        toast.success('Filtro aplicado com sucesso!, aguarde alguns instantes.');
    };

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={style}>
                    <h3>Filtro de faturamento</h3>
                    <form style={{ width: '100%' }} onSubmit={handleFilter}>
                        <TextField
                            sx={{ width: '100%', marginBottom: '20px' }}
                            type="date"
                            id="de-date"
                            label="De"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            value={from}
                            onChange={(e) => setFrom(e.target.value)}
                        />

                        <TextField
                            sx={{ width: '100%' }}
                            type="date"
                            id="para-date"
                            label="Até"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            value={to}
                            onChange={(e) => setTo(e.target.value)}
                        />

                        <Button variant="contained" type="submit" sx={{ marginTop: '25px', width: '100%' }}>
                            Filtrar
                        </Button>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default InvoicingFilter;
