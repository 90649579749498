import { Button, Modal, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';

const EditOpenQuestion = ({ open, setOpen, get, currentQuestion }) => {
    console.log('🚀 ~ file: EditOpenQuestion.js:9 ~ EditOpenQuestion ~ currentQuestion:', currentQuestion);
    const api = useApi();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: '8px',
        maxHeight: '650px',
        overflow: 'auto'
    };

    const [title, setTitle] = useState('');
    useEffect(() => {
        setTitle(currentQuestion?.description);
    }, [currentQuestion]);

    const handleEdit = async (e) => {
        e.preventDefault();
        const bodyFormula = [
            {
                formulas: [
                    {
                        type: 'if'
                    }
                ]
            }
        ];
        try {
            await api.updateQuestion(currentQuestion?.id, title, currentQuestion?.type, bodyFormula, currentQuestion.answers);

            toast.success('Descrição editada com sucesso!');
            setOpen(false);
            get();
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <h3>Edição de pergunta aberta</h3>
                {currentQuestion?.description && (
                    <Box>
                        <form onSubmit={(e) => handleEdit(e)}>
                            <TextField
                                label="Título da pergunta aberta"
                                sx={{ width: '100%' }}
                                value={title}
                                onChange={(event) => setTitle(event.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                            <Box sx={{ width: '100%', textAlign: 'center' }}>
                                <Button variant="contained" sx={{ margin: '20px auto' }} type="submit">
                                    Editar Pergunta
                                </Button>
                            </Box>
                        </form>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default EditOpenQuestion;
