export function adjustDateTimeForBrazil(dateTimeStr) {
    // Criar um objeto Date a partir da string ISO
    let dateTime = new Date(dateTimeStr);

    // Diminuir 3 horas para ajustar ao fuso horário do Brasil
    dateTime.setHours(dateTime.getHours());
    //No local fica 3 horas a mais, mas, no deploy fica a hora certa

    // Formatar a data e hora no formato dd/MM/yyyy HH:mm
    let day = String(dateTime.getDate()).padStart(2, '0');
    let month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
    let year = dateTime.getFullYear();
    let hours = String(dateTime.getHours()).padStart(2, '0');
    let minutes = String(dateTime.getMinutes()).padStart(2, '0');

    return `Atualizado às ${hours}:${minutes} do dia ${day}/${month}/${year} `;
}
