import { Box, Skeleton } from '@mui/material';
import React from 'react';

const SkeletonRo = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                        <Skeleton variant="rectangular" animation="wave" sx={{ height: '30px', width: '150px', backgroundColor: '#999' }} />
                        <Skeleton variant="rectangular" animation="wave" sx={{ height: '30px', width: '150px', backgroundColor: '#999' }} />
                    </Box>
                    <Skeleton variant="rectangular" animation="wave" sx={{ height: '30px', width: '150px', backgroundColor: '#999' }} />
                </Box>
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: '1rem', width: '500px', marginBottom: '50px', backgroundColor: '#999' }}
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        marginTop: '50px',
                        justifyContent: 'center',
                        alignItems: 'center', // Adicionando alignItems para centralizar verticalmente
                        width: '100%'
                    }}
                >
                    <Skeleton variant="rectangular" animation="wave" sx={{ height: '30px', width: '80%', backgroundColor: '#999' }} />
                    <Skeleton variant="rectangular" animation="wave" sx={{ height: '30px', width: '80%', backgroundColor: '#999' }} />
                    <Skeleton variant="rectangular" animation="wave" sx={{ height: '30px', width: '80%', backgroundColor: '#999' }} />
                    <Skeleton variant="rectangular" animation="wave" sx={{ height: '30px', width: '80%', backgroundColor: '#999' }} />
                    <Skeleton variant="rectangular" animation="wave" sx={{ height: '30px', width: '80%', backgroundColor: '#999' }} />
                </Box>
            </Box>
        </Box>
    );
};

export default SkeletonRo;
