import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Typography, Box } from '@mui/material';
import { gridSpacing } from 'store/constant';

const ErrorView = ({ setShowError, setSelectedQuiz, setIsChecked }) => {
    return (
        <MainCard title="Erro ao salvar Lista !">
            <Grid container spacing={gridSpacing}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '1rem',
                        width: '100%',
                        padding: '1.6em'
                    }}
                >
                    <Typography variant="h4">
                        Algo deu errado! Você precisa cadastrar no mínimo uma matéria prima para prosseguir com a criação da lista de
                        material.
                    </Typography>
                    <Button
                        sx={{ marginTop: '2em' }}
                        onClick={() => {
                            setShowError(false);
                            setSelectedQuiz(undefined);
                            setIsChecked(false);
                        }}
                    >
                        Voltar
                    </Button>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default ErrorView;
