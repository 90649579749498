import {
    Autocomplete,
    Button,
    CircularProgress,
    Drawer,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import InsertSkeleton from './InsertSkeleton';

const DrawerInsertRo = ({ open, setOpen, quizList, getTable }) => {
    const api = useApi();
    const [loading, setLoading] = useState(false);
    const [loadingConfirm, setLoadingConfirm] = useState(false);

    //constrole de formulario
    const [questionClose, setQuestionClose] = useState([]);
    const [typeInputStart, setTypeInputStart] = useState('select');
    const [typeInputEnd, setTypeInputEnd] = useState('select');

    //form
    const [selectedQuiz, setSelectedQuiz] = useState(null);
    const [fixedCost, setFixedCost] = useState('');
    const [value1, setvalue1] = useState(null);
    const [value2, setvalue2] = useState(null);

    const getCurrentQuiz = async (id) => {
        setLoading(true);
        try {
            const { data } = await api.questionCloseById(id);
            console.log('🚀 ~ file: DrawerInsertRo.js:30 ~ getCurrentQuiz ~ data:', data);
            setQuestionClose(data);
            setLoading(false);
        } catch (error) {
            console.log('🚀 ~ file: DrawerInsertRo.js:28 ~ getCurrentQuiz ~ error:', error);
        }
    };

    useEffect(() => {
        if (selectedQuiz) {
            console.log('🚀 ~ file: DrawerInsertRo.js:26 ~ useEffect ~ selectedQuiz:', selectedQuiz);
            getCurrentQuiz(selectedQuiz.id);
        }
    }, [selectedQuiz]);

    const handleSubmit = async (event) => {
        setLoadingConfirm(true);
        event.preventDefault();
        if (!selectedQuiz || !fixedCost || !value1 || !value2) return toast.warn('Todos os campos devem ser preenchidos');

        const payload = {
            fixedCost,
            value1: typeInputStart === 'text' ? value1 : null,
            value2: typeInputEnd === 'text' ? value2 : null,
            question1: typeInputStart === 'select' ? { id: value1 } : null,
            question2: typeInputEnd === 'select' ? { id: value2 } : null,
            roFormula: null
        };

        try {
            await api.createRo(selectedQuiz.id, payload);
            setLoadingConfirm(false);
            setFixedCost('');
            setvalue1(null);
            setvalue2(null);
            setSelectedQuiz(null);
            setOpen(false);
            getTable();
            window.location.reload();
            toast.success('Ro cadastrado com sucesso');
            setQuestionClose([]);
        } catch (error) {
            console.log('🚀 ~ file: DrawerInsertRo.js:43 ~ handleSubmit ~ error:', error);
        }
    };

    return (
        <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
            <form onSubmit={(event) => handleSubmit(event)} style={{ padding: '15px', width: '700px' }}>
                <div>
                    <h2>Inserir novo R.O</h2>
                </div>
                <div>
                    <FormControl>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ width: 300, marginBottom: '20px' }}
                            options={quizList}
                            value={selectedQuiz}
                            onChange={(e, newValue) => setSelectedQuiz(newValue)}
                            getOptionLabel={(option) => option?.productName}
                            renderInput={(params) => <TextField {...params} label="Selecione um Questionário" />}
                        />
                    </FormControl>
                </div>
                <div>
                    <TextField
                        label="Custo fixo"
                        value={fixedCost}
                        onChange={(e) => setFixedCost(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>
                        }}
                    />
                </div>
                {questionClose.length > 0 && (
                    <>
                        <h3>Fórmula de Calculo</h3>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <FormControl>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {typeInputStart === 'select' ? (
                                        <>
                                            <InputLabel id="Minitial" InputLabelProps={{ shrink: true }}>
                                                Opção 01
                                            </InputLabel>
                                            <Select
                                                sx={{ width: '250px' }}
                                                labelId="Minitial"
                                                value={value1}
                                                onChange={(e) => setvalue1(e.target.value)}
                                                label=" Opção 01"
                                            >
                                                <MenuItem value="">Selecione uma opção</MenuItem>
                                                {questionClose?.map((item) => (
                                                    <MenuItem key={item?.id} value={item?.id}>
                                                        {item?.description}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </>
                                    ) : (
                                        <TextField
                                            sx={{ width: '250px' }}
                                            onChange={(e) => setvalue1(e.target.value)}
                                            type="number"
                                            label=" Opção 01"
                                        />
                                    )}
                                    <div style={{ display: 'flex', gap: '10px', cursor: 'pointer', marginLeft: '20px' }}>
                                        <PinOutlinedIcon
                                            onClick={() => setTypeInputStart('text')}
                                            color={typeInputStart === 'text' ? 'primary' : ''}
                                        />
                                        <FormatListBulletedIcon
                                            onClick={() => setTypeInputStart('select')}
                                            color={typeInputStart === 'select' ? 'primary' : ''}
                                        />
                                    </div>
                                </div>
                            </FormControl>
                            <h4>x</h4>
                            <FormControl>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {typeInputEnd === 'select' ? (
                                        <>
                                            <InputLabel id="MEnd" InputLabelProps={{ shrink: true }}>
                                                Opção 02
                                            </InputLabel>
                                            <Select
                                                sx={{ width: '250px' }}
                                                value={value2}
                                                onChange={(e) => setvalue2(e.target.value)}
                                                labelId="MEnd"
                                                label="Opção 02"
                                            >
                                                <MenuItem value="">Selecione uma opção</MenuItem>
                                                {questionClose?.map((item) => (
                                                    <MenuItem key={item?.id} value={item?.id}>
                                                        {item?.description}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </>
                                    ) : (
                                        <TextField
                                            sx={{ width: '250px' }}
                                            onChange={(e) => setvalue2(e.target.value)}
                                            type="number"
                                            label="Opção 02"
                                        />
                                    )}
                                    <div style={{ display: 'flex', gap: '10px', cursor: 'pointer', marginLeft: '20px' }}>
                                        <PinOutlinedIcon
                                            onClick={() => setTypeInputEnd('text')}
                                            color={typeInputEnd === 'text' ? 'primary' : ''}
                                        />
                                        <FormatListBulletedIcon
                                            onClick={() => setTypeInputEnd('select')}
                                            color={typeInputEnd === 'select' ? 'primary' : ''}
                                        />
                                    </div>
                                </div>
                            </FormControl>
                        </div>
                    </>
                )}
                {loading && <InsertSkeleton />}
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
                    <Button variant="contained" sx={{ width: '300px' }} type="submit">
                        {loadingConfirm ? <CircularProgress size={24} color="inherit" sx={{ marginRight: 1 }} /> : 'Adicionar'}
                    </Button>
                </div>
            </form>
        </Drawer>
    );
};

export default DrawerInsertRo;
