import { Button, Divider, Drawer, MenuItem, TextField } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import { formatCNPJ, formatPhoneNumber, formatarCEP, removeSpecialCharacter } from 'utils/regex';
import { useApi } from 'Service/axios';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

const DrawerAddCostumer = ({ open, setOpen, icms, getCostumer }) => {
    const api = useApi();
    const [apiCnpjData, setApiCnpjData] = useState({});
    const [cepData, setCepData] = useState({});

    const handleChangeCep = (event) => {
        const newCep = event.target.value;

        if (newCep.length === 8) {
            getCep(newCep);
        }
        formik.setFieldValue('cep', formatarCEP(newCep));
    };

    const getCep = async (cep) => {
        try {
            const res = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

            const { bairro, logradouro, localidade, uf } = res.data;

            setCepData({ state: uf, neighborhood: bairro, city: localidade, street: logradouro });
        } catch (error) {
            console.log('🚀 ~ file: DrawerAddCostumer.js:15 ~ getCep ~ error:', error.message);
        }
    };

    const handlePhoneChange = (event) => {
        const rawValue = event.target.value;
        if (rawValue.length > 15) return;
        const formattedValue = formatPhoneNumber(rawValue);

        formik.handleChange(event);
        formik.setFieldValue('phone', formattedValue);
    };

    const handlaCNPJChange = async (event) => {
        const rawValue = event.target.value;
        if (rawValue.length > 18) return;
        const formattedValue = formatCNPJ(rawValue);

        formik.handleChange(event);
        formik.setFieldValue('cnpj', formattedValue);

        if (rawValue.length === 18) {
            console.log('entrou aqui');
            try {
                const { data } = await axios.get(`https://publica.cnpj.ws/cnpj/${removeSpecialCharacter(rawValue)}`);
                console.log('🚀 ~ handlaCNPJChange ~ data:', data);
                setApiCnpjData(data);
            } catch (error) {
                console.log('🚀 ~ handlaCNPJChange ~ error:', error);
            }
        }
    };

    const [loadRequest, setLoadRequest] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: apiCnpjData?.razao_social || '',
            cnpj: formatCNPJ(apiCnpjData?.estabelecimento?.cnpj) || '',
            cep: apiCnpjData?.estabelecimento?.cep || '',
            neighborhood: apiCnpjData?.estabelecimento?.bairro || '',
            state: apiCnpjData?.estabelecimento?.estado?.nome || '',
            city: apiCnpjData?.estabelecimento?.cidade?.nome || '',
            street: apiCnpjData?.estabelecimento?.logradouro || '',
            constructions: '',
            phone: '',
            phone: '',
            email: apiCnpjData?.estabelecimento?.email || '',
            contact: '',
            icms: '',
            number: '',
            complement: ''
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().max(255).required('campo obrigatório'),
            phone: Yup.string().required('O telefone é obrigatório'),
            cnpj: Yup.string().max(255).required('campo obrigatório'),
            neighborhood: Yup.string().max(255).required('campo obrigatório'),
            state: Yup.string().max(255).required('campo obrigatório'),
            street: Yup.string().max(255).required('campo obrigatório'),
            email: Yup.string().max(255).required('campo obrigatório'),
            cep: Yup.string().max(255).required('campo obrigatório'),
            icms: Yup.string().max(255).required('campo obrigatório')
        }),
        onSubmit: async (values, { resetForm }) => {
            const payload = {
                name: values.name,
                cnpj: removeSpecialCharacter(values.cnpj),
                obra: values.constructions,
                icms: { id: values.icms },
                agent: values.contact,
                phone: removeSpecialCharacter(values.phone),
                email: values.email,
                state: values.state,
                cep: removeSpecialCharacter(values.cep),
                logradouro: values.street,
                city: values.city,
                district: values.neighborhood,
                number: values.number,
                complement: values.complement
            };

            setLoadRequest(true);

            try {
                await api.addnewCostumer(payload);
                resetForm();
                setOpen(false);
                getCostumer();
                toast.success('Cliente adicionado com sucesso!');
                setLoadRequest(false);
            } catch (error) {
                setLoadRequest(false);
                toast.error('Verifique se todos os campos estão preenchidos corretamente ou se já existe um cliente com esse CNPJ!');
                console.log('🚀 ~ file: DrawerAddCostumer.js:110 ~ onSubmit: ~ error:', error.message);
            }
        }
    });

    useEffect(() => {
        formik.setFieldValue('state', cepData.state || '');
        formik.setFieldValue('neighborhood', cepData.neighborhood || '');
        formik.setFieldValue('city', cepData.city || '');
        formik.setFieldValue('street', cepData.street || '');
    }, [cepData]);

    const handleClose = () => setOpen(false);
    return (
        <Drawer anchor="right" open={open} onClose={handleClose}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginTop: '1.25rem' }}>
                    <h2>Cadastro de clientes</h2>
                </div>
                <div>
                    <form
                        onSubmit={formik.handleSubmit}
                        style={{ display: 'flex', flexDirection: 'column', width: '37.5rem', padding: '1.25rem 3.125rem', gap: '.625rem' }}
                    >
                        <Divider>Empresa</Divider>
                        <div>
                            <TextField
                                sx={{ width: '100%' }}
                                type="text"
                                label="CNPJ"
                                name="cnpj"
                                onChange={(e) => handlaCNPJChange(e)}
                                value={formik.values.cnpj}
                                error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
                                helperText={formik.touched.cnpj && formik.errors.cnpj}
                            />
                        </div>
                        <div>
                            <TextField
                                sx={{ width: '100%' }}
                                type="text"
                                label="Nome do cliente"
                                name="name"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </div>

                        <div>
                            <TextField
                                sx={{ width: '12.5rem' }}
                                select
                                label="ICMS"
                                name="icms"
                                onChange={formik.handleChange}
                                value={formik.values.icms}
                                error={formik.touched.icms && Boolean(formik.errors.icms)}
                                helperText={formik.touched.icms && formik.errors.icms}
                            >
                                <MenuItem value="">Selecione um ICMS</MenuItem>
                                {icms.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.state}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <Divider>Contato</Divider>

                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <TextField
                                    sx={{ width: '15rem', marginRight: '.625rem' }}
                                    type="text"
                                    label="Contato"
                                    name="contact"
                                    onChange={formik.handleChange}
                                    value={formik.values.contact}
                                    error={formik.touched.contact && Boolean(formik.errors.contact)}
                                    helperText={formik.touched.contact && formik.errors.contact}
                                />
                            </div>
                            <div>
                                <TextField
                                    sx={{ width: '15rem' }}
                                    type="tel"
                                    label="Telefone"
                                    name="phone"
                                    onChange={(e) => handlePhoneChange(e)}
                                    value={formik.values.phone}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                />
                            </div>
                        </div>
                        <div>
                            <TextField
                                sx={{ width: '100%' }}
                                type="email"
                                label="E-mail"
                                name="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </div>
                        <Divider>Endereço</Divider>
                        {/* <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <TextField
                                    sx={{ width: '100%' }}
                                    type="text"
                                    label="Obra"
                                    name="constructions"
                                    onChange={formik.handleChange}
                                    value={formik.values.constructions}
                                    error={formik.touched.constructions && Boolean(formik.errors.constructions)}
                                    helperText={formik.touched.constructions && formik.errors.constructions}
                                />
                            </div>
                        </div> */}
                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', gap: '.625rem' }}>
                            <div>
                                <TextField
                                    sx={{ width: '100%' }}
                                    type="text"
                                    label="Cep"
                                    name="cep"
                                    onChange={(e) => handleChangeCep(e)}
                                    value={formik.values.cep}
                                    error={formik.touched.cep && Boolean(formik.errors.cep)}
                                    helperText={formik.touched.cep && formik.errors.cep}
                                />
                            </div>
                            <div>
                                <TextField
                                    sx={{ width: '100%' }}
                                    type="text"
                                    label="Rua"
                                    name="street"
                                    onChange={formik.handleChange}
                                    value={formik.values.street}
                                    error={formik.touched.street && Boolean(formik.errors.street)}
                                    helperText={formik.touched.street && formik.errors.street}
                                />
                            </div>
                            <div>
                                <TextField
                                    sx={{ width: '6.25rem' }}
                                    type="text"
                                    label=" Nº"
                                    name="number"
                                    onChange={formik.handleChange}
                                    value={formik.values.number}
                                    error={formik.touched.number && Boolean(formik.errors.number)}
                                    helperText={formik.touched.number && formik.errors.number}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', gap: '.625rem' }}>
                            <div>
                                <TextField
                                    sx={{ width: '100%' }}
                                    type="text"
                                    label="Bairro"
                                    name="neighborhood"
                                    onChange={formik.handleChange}
                                    value={formik.values.neighborhood}
                                    error={formik.touched.neighborhood && Boolean(formik.errors.neighborhood)}
                                    helperText={formik.touched.neighborhood && formik.errors.neighborhood}
                                />
                            </div>
                            <div>
                                <TextField
                                    sx={{ width: '100%' }}
                                    type="text"
                                    label="Cidade"
                                    name="city"
                                    onChange={formik.handleChange}
                                    value={formik.values.city}
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                    helperText={formik.touched.city && formik.errors.city}
                                />
                            </div>
                            <div>
                                <TextField
                                    sx={{ width: '6.25rem' }}
                                    type="text"
                                    label="Estado"
                                    name="state"
                                    onChange={formik.handleChange}
                                    value={formik.values.state}
                                    error={formik.touched.state && Boolean(formik.errors.state)}
                                    helperText={formik.touched.state && formik.errors.state}
                                />
                            </div>
                        </div>

                        <div>
                            <TextField
                                sx={{ width: '100%' }}
                                type="text"
                                label="Complemento"
                                name="complement"
                                onChange={formik.handleChange}
                                value={formik.values.complement}
                            />
                        </div>

                        <Button type="submit" disabled={loadRequest} variant="contained" sx={{ width: '18.75rem', margin: '1.25rem auto' }}>
                            {loadRequest ? 'Carregando...' : 'Cadastrar'}
                        </Button>
                    </form>
                </div>
            </div>
        </Drawer>
    );
};

export default DrawerAddCostumer;
