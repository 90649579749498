import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function FilterModal({ currentMode, setFrom, setTo, setIsFilterActive, to, from }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleFilter() {
        if (from === '' || to === '') return toast.error('Selecione um período válido');
        setOpen(false);
        setIsFilterActive(true);
    }

    return (
        <React.Fragment>
            <Button onClick={handleClickOpen} variant="contained" sx={{ fontSize: currentMode.button }}>
                Filtro
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <h2>Filtro por período</h2>
                </DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label htmlFor="de">De</label>
                        <TextField id="de" type="month" value={from} onChange={(e) => setFrom(e.target.value)} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label htmlFor="Para">Para</label>
                        <TextField id="Para" type="month" value={to} onChange={(e) => setTo(e.target.value)} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleFilter}>
                        Filtrar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
