import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Register from 'views/pages/authentication/authentication3/Register3';
import EditUser from 'views/users/EditUser';
import ProducView from 'views/ProductView';
import UnitView from 'views/Unityview';
import QuestionView from 'views/QuestionView';
import QuestionnairesView from 'views/QuestionnairesView';
import BudgetView from 'views/BudgetView/index';
import ColorView from 'views/ColorView';
import CreateView from 'views/QuestionnairesView/Createview';
import Material from 'views/ProductView/Material/Material';
import NotAllowed from '../views/NotAllowed';
import GroupColor from 'views/GroupColor';
import EditView from 'views/QuestionnairesView/EditView';
import EditMaterial from 'views/ProductView/Material/EditMaterial/EditMaterial';
import EditMpForm from 'views/ColorView/Edit';
import OrdemFP from 'views/Pcp';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const Group = Loadable(lazy(() => import('views/groupView')));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

import { getAccessLevel } from '../utils/getAccessLevel';
import Formula from 'views/QuestionnairesView/EditView/components/ModalQuestions/components/Formula';
import PreviousPage from 'views/ProductView/Material/PreviousPage';
import NewMp from 'views/ProductView/Material/NewMp';
import Icms from 'views/IcmsView/Icms';
import CostumerView from 'views/CostumerView';
import Payments from 'views/Payments';
import NovaTela from 'views/NovaTela';
import OperationalResult from 'views/NovaTela';
import InsertRo from 'views/NovaTela/components/InsertRo';

//protected router
import RoData from 'views/NovaTela/components/RoData';
import SalesBudget from 'views/SalesBudget';
import CreateNewBudget from 'views/CreateNewBudget';
import OrdemLB from 'views/PcpLB';
import MaintenanceValues from 'views/MaintenanceValues';
import OrderAnalysis from 'views/Pcp/OrderAnalysis';
import QuestionPage from 'views/QuestionPage';
import QuestionPageId from 'views/QuestionPageId';
import PcpAb from 'views/PcpAB';
import ProjectResult from 'views/Costs/ProjectResult';
import Consumption from 'views/Costs/Consumption';
import Expenses from 'views/Costs/Expenses';
import Taxes from 'views/Costs/Taxes';
import Invoicing from 'views/Invoicing';
import ProjectMo from 'views/Pcp/ProjectsMp';
import ProjectMpId from 'views/Pcp/ProjectMpId';
import { PreriodResult } from 'views/Costs/PeriodResult';
import ExcelSpreadsheet from 'views/ExcelSpreadsheet';

// ==============================|| MAIN ROUTING ||============================== //
const id = localStorage.getItem('id');
const role = localStorage.getItem('role');
const roleParse = JSON.parse(role);

const MainRoutes = {
    path: '/main',
    element: <MainLayout />,
    children: [
        {
            path: '/main/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/main/products',
            element: <ProducView />
        },
        {
            path: '/main/material/:id',
            element: <Material />
        },
        {
            path: '/main/material',
            element: <PreviousPage />
        },
        {
            path: '/main/material/cadastro-materia-prima',
            element: <NewMp />
        },
        {
            path: '/main/editmaterial/:id',
            element: <EditMaterial />
        },
        {
            path: '/main/register',
            element: <Register />
        },
        {
            path: '/main/edituser',
            element: <EditUser />
        },
        {
            path: '/main/unit',
            element: <UnitView />
        },
        {
            path: '/main/groups',
            element: <Group />
        },
        {
            path: '/main/questions',
            element: <QuestionPage />
            // element: <QuestionView />
        },
        {
            path: '/main/questions/:id',
            element: <QuestionPageId />
        },
        {
            path: '/main/questionnaires',
            element: <QuestionnairesView />
        },
        {
            path: '/main/questionaires/create/:id',
            element: <CreateView />
        },
        {
            path: '/main/questionaires/edit/:id/:name',
            element: <EditView />
        },
        {
            path: '/main/budget',
            element: <BudgetView />
        },
        {
            path: '/main/orcamento-vendas',
            element: <SalesBudget />
        },
        {
            path: '/main/novo-orcamento/:id/:copyId',
            element: <CreateNewBudget />
        },
        {
            path: '/main/cores',
            element: <ColorView />
        },
        {
            path: '/main/editMp/:id',
            element: <EditMpForm />
        },
        {
            path: '/main/groupcolor',
            element: id ? <GroupColor /> : <NotAllowed />
        },
        {
            path: '/main/formula',
            element: <Formula />
        },
        {
            path: '/main/comercial/icms',
            element: <Icms />
        },
        {
            path: '/main/comercial/clientes',
            element: <CostumerView />
        },
        {
            path: '/main/comercial/condpagamentos',
            element: <Payments />
        },
        {
            path: '/main/comercial/resultado-operacional',
            element: <OperationalResult />
        },
        {
            path: '/main/comercial/detalhes-ro/:id/',
            element: <RoData />
        },
        {
            path: '/main/comercial/manutencao-valores',
            element: <MaintenanceValues />
        },
        {
            path: '/main/pcp/ordemfp',
            element: <OrdemFP />
        },
        {
            path: '/main/pcp/ordemab',
            element: <PcpAb />
        },
        {
            path: '/main/pcp/mp-em-projetos',
            element: <ProjectMo />
        },
        {
            path: '/main/pcp/analise-ordens/:id',
            element: <OrderAnalysis />
        },
        {
            path: '/main/pcp/mp-em-projetos/:id',
            element: <ProjectMpId />
        },
        {
            path: '/main/pcp/ordemlb',
            element: <OrdemLB />
        },
        {
            path: '/main/not-allowed',
            element: <NotAllowed />
        },
        // custos
        {
            path: '/main/resultado-projeto',
            element: <ProjectResult />
        },
        {
            path: '/main/resultado-projeto/consumo/:id',
            element: <Consumption />
        },
        {
            path: '/main/resultado-projeto/despesas/:id',
            element: <Expenses />
        },
        {
            path: '/main/resultado-projeto/impostos/:id',
            element: <Taxes />
        },
        {
            path: '/main/resultado-periodo',
            element: <PreriodResult />
        },
        {
            path: '/main/dashboard/faturamento',
            element: <Invoicing />
        },
        {
            path: '/main/importador-precos',
            element: <ExcelSpreadsheet />
        }
    ]
};

export default MainRoutes;
