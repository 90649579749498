import {
    Button,
    Fade,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    withStyles
} from '@mui/material';
import { IconPencil } from '@tabler/icons';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatCNPJ, formatPhone } from 'utils/regex';
import { useState } from 'react';
import DrawerAddCostumer from './components/DrawerAddCostumer';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DrawerEditCostumer from './components/DrawerEditCostumer';
import ConfirmModal from 'ui-component/ConfirmModal';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';

const CostumerView = () => {
    const api = useApi();

    const [openAddDrawer, setOpenDrawer] = useState(false);
    const [openEditDrawer, setOpenEditDrawer] = useState(false);
    const [icms, setIcms] = useState([]);
    const [costumer, setCostumer] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentCostumer, setCurrentCostumer] = useState(null);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const open = Boolean(anchorEl);

    const getIcms = async () => {
        try {
            const response = await api.getIcms();
            setIcms(response.data);
        } catch (error) {
            error.message;
        }
    };

    const getCostumer = async () => {
        try {
            const { data } = await api.getAllCostumers();

            const row = data.map((item) => ({
                ...item,
                id: item?.id,
                name: item?.name,
                city: item?.city,
                obra: item?.obra,
                phone: item?.phone,
                email: item?.email,
                cnpj: item?.cnpj,
                icms: item?.icms.state,
                percentage: item?.percentage,
                state: item?.state,
                agent: item?.agent,
                icmsData: item?.icms
            }));
            setCostumer(row);
        } catch (error) {
            console.log('🚀 ~ file: index.js:86 ~ getCostumer ~ error:', error);
        }
    };

    const handleDelete = async () => {
        try {
            await api.deleteCostumer(currentCostumer.id);
            toast.success('Cadastro deletado com sucesso!');
            getCostumer();
        } catch (error) {
            console.log('🚀 ~ file: index.js:66 ~ handleDelete ~ error:', error);
        }
    };

    useEffect(() => {
        getIcms();
        getCostumer();
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'name', headerName: 'Empresa', flex: 1 },
        { field: 'cnpj', headerName: 'CNPJ', flex: 1 },
        { field: 'city', headerName: 'Cidade', flex: 1 },
        { field: 'state', headerName: 'UF', flex: 0.5 },
        // { field: 'obra', headerName: 'Obra', flex: 1 },
        { field: 'agent', headerName: 'Contato', flex: 1 },
        { field: 'phone', headerName: 'Telefone', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            sortable: false,
            flex: 0.5,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Ações">
                        <IconButton
                            id="fade-button"
                            aria-controls={open ? 'fade-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setCurrentCostumer(params.row);
                            }}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                    </Tooltip>

                    <Menu
                        id="fade-menu"
                        MenuListProps={{
                            'aria-labelledby': 'fade-button'
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                        TransitionComponent={Fade}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 1px 5px rgba(0,0,0,0.1))',
                                borderRadius: 0
                            }
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                setAnchorEl(null);
                                setOpenEditDrawer(true);
                            }}
                        >
                            <IconPencil />
                            Editar
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setAnchorEl(null);
                                setOpenConfirmModal(true);
                            }}
                        >
                            <DeleteIcon />
                            Deletar
                        </MenuItem>
                    </Menu>
                </div>
            )
        }
    ];

    return (
        <MainCard title="Página de clientes">
            <DrawerAddCostumer open={openAddDrawer} setOpen={setOpenDrawer} icms={icms} getCostumer={getCostumer} />
            <DrawerEditCostumer
                open={openEditDrawer}
                setOpen={setOpenEditDrawer}
                getCostumer={getCostumer}
                icmsProp={icms}
                currentCostumer={currentCostumer}
            />
            <ConfirmModal
                firstMessage={'Deseja mesmo deletar esse cadastro?'}
                secondMessage={'Os dados não podrão ser recuperados!'}
                setOpen={setOpenConfirmModal}
                open={openConfirmModal}
                button2={'Deletar'}
                button1={'Cancelar'}
                func={handleDelete}
            />
            <Grid container spacing={gridSpacing} sx={{ padding: '10px 20px' }}>
                <div>
                    <Button onClick={() => setOpenDrawer(true)} variant="contained">
                        Cadastrar novo cliente
                    </Button>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px' }}>
                    <DataGrid rows={costumer} columns={columns} hideFooter checkboxSelection={false} />
                </div>
            </Grid>
        </MainCard>
    );
};

export default CostumerView;
