import { Button, Chip, Grid, IconButton, Menu, MenuItem, Pagination, Skeleton, TablePagination, Tooltip } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Filters from './components/Filters';
import { useState } from 'react';
import { TroubleshootRounded } from '@mui/icons-material';
import { DataGrid, GridPagination, GridToolbar } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import Actions from './components/Actions';
import { formatPrice } from 'utils/formatPrice';
import { formatarNumero } from 'utils/formatarNumeroReal';
import { useDataFilterCosts } from 'store/ContextData';
import { adjustDateTimeForBrazil } from 'utils/dataFusoBr';
import { toast } from 'react-toastify';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AirIcon from '@mui/icons-material/Air';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import HoverSocialCard from './components/HoverCard';
import { green } from '@mui/material/colors';
import TotalIncomeDarkCard from './components/TotalIncomeDarkCard';
import TotalIncomeLightCard from './components/TotalIncomeLightCard';
import SkeletonTable from 'ui-component/skeletonTable';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
const ProjectResult = () => {
    const { isFilterActive, setIsFilterActive, finalData, initialData, project } = useDataFilterCosts();
    const [rows, setRows] = useState([]);
    const [openFilter, setOpenFilter] = useState(false);
    const [filteredList, setFilteredList] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [totalValueFiltered, setTotalValueFiltered] = useState(0);
    const [copy, setCopy] = useState([]);
    const [copyFiltered, setCopyFiltered] = useState([]);

    const api = useApi();
    const [updateLoading, setUpdateLoading] = useState(false);
    const updateCost = async () => {
        setUpdateLoading(true);
        toast.warn('Atualizando custos .... Isso pode levar alguns minutos!');
        try {
            await api.updateCost();
            setUpdateLoading(false);
            getAllCost();
        } catch (error) {
            console.log('🚀 ~ file: index.js:20 ~ updateCost ~ error:', error);
        }
    };

    const getFilteredRows = () => {
        const formatRowFiltered = filteredList?.listaCustos?.map((item, index) => ({
            ...item,
            id: index,
            pedido: item.pedido,
            projeto: item.projeto,
            resultado1: formatarNumero(item.resultado1),
            resultado2: formatarNumero(item.resultado2),
            tconsumo: formatarNumero(item.tconsumo),
            tcusto: formatarNumero(item.tcusto),
            tdespesa: formatarNumero(item.tdespesa),
            tfaturado: formatarNumero(item.tfaturado),
            timpostos: formatarNumero(item.timpostos),
            tpedido: formatarNumero(item.tpedido),
            status: item?.status,
            pResultado1: item.perc_resultado1,
            pResultado2: item.perc_resultado2
        }));
        setFilteredRows(formatRowFiltered);
        setCopyFiltered(copyFiltered);
        setTotalValueFiltered(formatarNumero(filteredList?.totalFaturado));
    };

    const [loading, setLoading] = useState(false);

    const getAllCost = async () => {
        setLoading(true);
        try {
            const { data } = await api.getCost();
            setLoading(false);
            const formatRow = data.map((item, index) => ({
                ...item,
                id: index,
                pedido: item.pedido,
                projeto: item.projeto,
                resultado1: formatarNumero(item.resultado1),
                resultado2: formatarNumero(item.resultado2),
                tconsumo: formatarNumero(item.tconsumo),
                tcusto: formatarNumero(item.tcusto),
                tdespesa: formatarNumero(item.tdespesa),
                tfaturado: formatarNumero(item.tfaturado),
                timpostos: formatarNumero(item.timpostos),
                tpedido: formatarNumero(item.tpedido),
                status: item.status,
                pResultado1: item.perc_resultado1,
                pResultado2: item.perc_resultado2
            }));

            const sortedRows = [...formatRow].sort((a, b) => b.projeto - a.projeto);
            setRows(sortedRows);
            setCopy(sortedRows);
        } catch (error) {
            console.log('🚀 ~ file: index.js:28 ~ getAllCost ~ error:', error);
        }
    };

    useEffect(() => {
        getAllCost();
    }, []);
    useEffect(() => {
        getFilteredRows();
    }, [isFilterActive, filteredList]);

    //logica para o menu opções
    const [countColor, setCountColor] = useState({
        green: 0,
        yellow: 0,
        red: 0,
        total: 0
    });

    const countColors = () => {
        const colorCount = {
            green: 0,
            yellow: 0,
            red: 0,
            total: 0
        };

        if (!isFilterActive) {
            rows?.forEach((row) => {
                if (row.status === 'VERDE') {
                    colorCount.green++;
                } else if (row.status === 'AMARELO') {
                    colorCount.yellow++;
                } else if (row.status === 'VERMELHO') {
                    colorCount.red++;
                }
            });
        }
        if (isFilterActive) {
            filteredRows?.forEach((row) => {
                if (row.status === 'VERDE') {
                    colorCount.green++;
                } else if (row.status === 'AMARELO') {
                    colorCount.yellow++;
                } else if (row.status === 'VERMELHO') {
                    colorCount.red++;
                }
                //Somar todas a cores e aplicar em total
                colorCount.total = colorCount.green + colorCount.yellow + colorCount.red;
            });
        }
        setCountColor(colorCount);
    };

    useEffect(() => {
        countColors();
    }, [filteredRows, rows]);

    const columns = [
        { field: 'projeto', headerName: 'Projeto', width: 90 },
        { field: 'pedido', headerName: 'Pedido', width: 90 },
        { field: 'tpedido', headerName: 'T. Pedido', type: 'number', width: 120 },
        { field: 'tfaturado', headerName: 'T. Faturado', type: 'number', width: 120 },
        { field: 'tconsumo', headerName: 'T. Consumo', type: 'number', width: 120 },
        { field: 'tdespesa', headerName: 'T. Despesa', type: 'number', width: 120 },
        { field: 'tcusto', headerName: 'T. Custo', type: 'number', width: 120 },
        { field: 'timpostos', headerName: 'T. Impostos', type: 'number', width: 120 },
        {
            field: 'resultado1',
            headerName: 'Result 1',
            type: 'number',
            align: 'center',
            width: 120,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            padding: '10px 5px',
                            borderRadius: 8
                        }}
                    >
                        {params.row.resultado1}
                    </div>
                );
            }
        },
        {
            field: 'pResultado1',
            headerName: '%',
            width: 70,
            type: 'number',
            align: 'center',
            renderCell: (params) => {
                const kpiResultado1 = params.row.kpi_resultado1; // Substitua 'kpi_resultado1' pelo nome real do campo
                let backgroundColor = '#999'; // Cor padrão

                if (kpiResultado1 === 'VERMELHO') {
                    backgroundColor = 'red';
                } else if (kpiResultado1 === 'AMARELO') {
                    backgroundColor = '#FFD700';
                } else if (kpiResultado1 === 'VERDE') {
                    backgroundColor = 'green';
                }
                return (
                    <div
                        style={{
                            backgroundColor,
                            padding: '10px 5px',
                            borderRadius: 8,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '24px'
                        }}
                    >
                        {params?.row?.pResultado1?.toFixed(0)}%
                    </div>
                );
            }
        },
        {
            field: 'resultado2',
            headerName: 'Result 2',
            align: 'center',
            width: 120,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            padding: '10px 5px',
                            borderRadius: 8
                        }}
                    >
                        {params.row.resultado2}
                    </div>
                );
            }
        },
        {
            field: 'pResultado2',
            headerName: '%',
            width: 70,
            type: 'number',
            align: 'center',
            renderCell: (params) => {
                const kpiResultado2 = params.row.kpi_resultado2; // Substitua 'kpi_resultado1' pelo nome real do campo
                let backgroundColor = '#999'; // Cor padrão

                if (kpiResultado2 === 'VERMELHO') {
                    backgroundColor = 'red';
                } else if (kpiResultado2 === 'AMARELO') {
                    backgroundColor = '#FFD700';
                } else if (kpiResultado2 === 'VERDE') {
                    backgroundColor = 'green';
                }

                return (
                    <div
                        style={{
                            backgroundColor,
                            padding: '10px 5px',
                            borderRadius: 8,
                            fontWeight: 'bold',
                            fontSize: '24px',
                            color: '#fff'
                        }}
                    >
                        {params?.row?.pResultado2?.toFixed(0)}%
                    </div>
                );
            }
        },
        {
            field: 'Acoes',
            headerName: 'Det',
            width: 70,
            align: 'center',
            renderCell: (params) => <Actions params={params} />
        }
    ];

    // Configuração de paginação
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    // Restante do seu código ...

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const colorMap = {
        green: 'VERDE',
        yellow: 'AMARELO',
        red: 'VERMELHO'
    };
    const [currentFilterType, setCurrentFilterType] = useState(null);
    const handleColorFilter = (type) => {
        const initialRows = [...copy];
        const initialRowsFilter = [...copyFiltered];
        let filteredRows;

        if (type === currentFilterType) {
            setCurrentFilterType(null);
            filteredRows = isFilterActive ? initialRowsFilter : initialRows;
        } else {
            setCurrentFilterType(type);

            const filterCondition = (row) => row.status === colorMap[type];

            if (isFilterActive) {
                filteredRows = initialRowsFilter?.filter(filterCondition) || initialRowsFilter;
            } else {
                filteredRows = initialRows.filter(filterCondition) || initialRows;
            }
        }

        if (isFilterActive) {
            setFilteredRows(initialRowsFilter);
        } else {
            setRows(filteredRows);
        }
    };

    return (
        <MainCard
            title={
                <h1
                    style={{
                        fontSize: '45px',
                        margin: '0'
                    }}
                >
                    Resultado do Projeto
                </h1>
            }
        >
            <Filters open={openFilter} loading={setLoading} setOpen={setOpenFilter} setFilteredList={setFilteredList} />
            <Grid container spacing={gridSpacing} sx={{ display: 'flex', flexDirection: 'column' }}>
                {!loading && (
                    <div style={{ display: 'flex', gap: '15px', position: 'absolute', top: '15%', right: '5%' }}>
                        <button style={{ all: 'unset', cursor: 'pointer' }}>
                            <HoverSocialCard color={'green'} secondary={countColor.green.toString()} iconPrimary={PlaylistAddCheckIcon} />
                        </button>
                        <button style={{ all: 'unset', cursor: 'pointer' }}>
                            <HoverSocialCard color={'#FFD700'} secondary={countColor.yellow.toString()} iconPrimary={WarningAmberIcon} />
                        </button>
                        <button style={{ all: 'unset', cursor: 'pointer' }}>
                            <HoverSocialCard color={'red'} secondary={countColor.red.toString()} iconPrimary={TimelapseIcon} />
                        </button>
                    </div>
                )}

                <div
                    style={{
                        margin: '20px',
                        display: 'flex',
                        gap: '20px',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                        <Button
                            sx={{
                                fontSize: '20px'
                            }}
                            variant="contained"
                            onClick={() => setOpenFilter(TroubleshootRounded)}
                        >
                            Filtros
                        </Button>
                        <Button
                            sx={{
                                fontSize: '20px'
                            }}
                            variant="contained"
                            onClick={updateCost}
                            disabled={updateLoading}
                        >
                            Atualizar
                        </Button>

                        {isFilterActive && filteredList?.listaCustos?.length > 0 && (
                            <button style={{ all: 'unset' }} onClick={() => setFilteredList([])}>
                                <Chip
                                    sx={{
                                        fontSize: '20px'
                                    }}
                                    label="Desabilitar Filtro"
                                    color="error"
                                    onDelete={() => setIsFilterActive(false)}
                                />
                            </button>
                        )}
                    </div>

                    <div>
                        {isFilterActive && filteredList?.listaCustos?.length > 0 && (
                            <div style={{ marginRight: '20px', display: 'flex', gap: '10px', alignItems: 'center', marginTop: '10px' }}>
                                <TotalIncomeDarkCard data={totalValueFiltered} count={countColor} />
                                <TotalIncomeLightCard project={project} initialData={initialData} finalData={finalData} />
                            </div>
                        )}
                    </div>
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    {rows.length > 0 && (
                        <>
                            <p style={{ width: '50%', marginLeft: '15px', fontSize: '18px' }}>
                                {adjustDateTimeForBrazil(rows[0]?.atualizacao)}
                            </p>
                            <span style={{ width: '50%', textAlign: 'right', fontSize: '18px' }}>
                                {'Resultado 1 = Consumo de MP + Beneficiamento | Resultado 2 = Custo Total'}
                            </span>
                        </>
                    )}
                </div>
                {loading ? (
                    <SkeletonTable />
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            marginLeft: '15px'
                        }}
                    >
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.field}
                                                align={column.align || 'left'}
                                                style={{ width: column.width, fontSize: 20 }}
                                            >
                                                {column.headerName}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(isFilterActive && filteredRows?.length > 0 ? filteredRows : rows)
                                        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                                        .map((row) => (
                                            <TableRow key={row.id}>
                                                {columns.map((column) => (
                                                    <TableCell style={{ fontSize: 20 }} key={column.field} align={column.align || 'left'}>
                                                        {column.renderCell ? column.renderCell({ row }) : row[column.field]}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell sx={{ fontSize: 16 }} colSpan={columns.length} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={isFilterActive ? filteredList.listaCustos?.length : rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                )}
            </Grid>
        </MainCard>
    );
};

export default ProjectResult;
