import { Fab, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate, useParams } from 'react-router';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { formatarNumero } from 'utils/formatarNumeroReal';
import { formatarData } from 'utils/regex';
import ReplyIcon from '@mui/icons-material/Reply';

const Taxes = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const api = useApi();
    const [row, setRow] = useState([]);

    const getTaxes = async () => {
        try {
            const { data } = await api.getFixes(id);
            const row = data.map((item, index) => ({
                data: formatarData(item.dtMovimento),
                nota: item.nota,
                difal: formatarNumero(item.difal),
                icms: formatarNumero(item.icms),
                pis: formatarNumero(item.pis),
                cofins: formatarNumero(item.cofins),
                irpj_csll: formatarNumero(item.irpj_csll),
                simple: item.simples,
                t_imposto: formatarNumero(item.timpostos),
                id: index
            }));
            setRow(row);
            console.log('🚀 ~ file: index.js:21 ~ getTaxes ~ data:', data);
        } catch (error) {
            console.log('🚀 ~ file: index.js:22 ~ getTaxes ~ error:', error);
        }
    };

    useEffect(() => {
        getTaxes();
    }, []);

    const columns = [
        { field: 'data', headerName: 'Data', width: 130 },
        { field: 'nota', headerName: 'Nota Fiscal', width: 110 },
        { field: 'difal', headerName: 'DIFAL', type: 'number', width: 110 },
        { field: 'icms', headerName: 'ICMS', type: 'number', width: 100 },
        { field: 'pis', headerName: 'PIS', type: 'number', width: 100 },
        { field: 'cofins', headerName: 'COFINS', type: 'number', width: 100 },
        { field: 'irpj_csll', headerName: 'IRPJ + CSLL', type: 'number', width: 120 },
        { field: 'simple', headerName: 'Simples', type: 'number', width: 100 },
        { field: 't_imposto', headerName: 'T. Imposto', type: 'number', width: 130 }
    ];

    return (
        <MainCard title={`Impostos - Projeto ${id}`}>
            <Fab
                onClick={() => navigate(-1)}
                variant="extended"
                sx={{ position: 'fixed', bottom: '50px', display: 'flex', alignItems: 'center', gap: '5px' }}
            >
                <ReplyIcon />
                voltar
            </Fab>
            <Grid container spacing={gridSpacing} sx={{ display: 'flex', flexDirection: 'column' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        margin: 'auto',
                        marginTop: '10px',
                        overflow: 'auto'
                    }}
                >
                    <DataGrid columns={columns} rows={row} hideFooter autoHeight sx={{ maxWidth: '1350px', marginLeft: '5px' }} />
                </div>
            </Grid>
        </MainCard>
    );
};

export default Taxes;
