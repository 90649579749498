import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button,
    Box,
    TextField
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { ExcelRenderer } from 'react-excel-renderer';
import { styled } from '@mui/system';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import { ModalLoadAnimated } from 'ui-component/LoadBw';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: '1px solid #ddd',
    padding: theme.spacing(1),
    textAlign: 'left'
}));

const StyledTableHeaderCell = styled(StyledTableCell)(({ theme }) => ({
    backgroundColor: '#f0f0f0',
    fontWeight: 'bold',
    position: 'sticky',
    top: 0,
    zIndex: 1
}));

const ExcelSpreadsheet = () => {
    const [cols, setCols] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const api = useApi();
    const [file, setFile] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        handleFile(file);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.xls,.xlsx',
        maxFiles: 1,
        noClick: true
    });

    const handleFile = (file) => {
        setLoading(true);
        if (file.type !== 'application/vnd.ms-excel' && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            toast.error('Por favor, selecione um arquivo .xls ou .xlsx');
            setLoading(false);
            return;
        }

        ExcelRenderer(file, (err, resp) => {
            if (err) {
                console.error(err);
                setLoading(false);
            } else {
                setCols(resp.cols);
                setRows(resp.rows);
                setLoading(false);
                setFile(file);
            }
        });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        handleFile(file);
    };

    async function handleSend(file) {
        const toastId = toast.loading('Enviando arquivo...');
        try {
            await api.sendExcelSheet(file);
            toast.update(toastId, { render: 'Arquivo enviado com sucesso!', type: 'success', isLoading: false, autoClose: 3000 });
            resetStates();
        } catch (error) {
            console.log('🚀 ~ handleSend ~ error:', error);
            toast.update(toastId, { render: 'Erro ao enviar arquivo', type: 'error', isLoading: false, autoClose: 3000 });
        }
    }

    async function handleImport() {
        setLoading(true);
        try {
            const response = await api.getExcelSheet();
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'exported_table.xlsx'; // Nome do arquivo que será baixado
            document.body.appendChild(a);
            a.click();
            a.remove();

            URL.revokeObjectURL(url);
            setLoading(false);
        } catch (error) {
            console.log('🚀 ~ handleImport ~ error:', error);
            setLoading(false);
        }
    }

    function resetStates() {
        setCols([]);
        setRows([]);
        setFile(null);
    }

    return (
        <MainCard title="Importador / Exportador de preços">
            <Grid
                container
                justifyContent="center"
                spacing={3}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}
            >
                <Box sx={{ position: 'absolute', top: '-75px', right: 10, display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <Button variant="contained" onClick={handleImport}>
                        Exportar tabela
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ margin: '20px 0' }}
                        onClick={() => handleSend(file)}
                        disabled={!file} // Desabilita o botão se nenhum arquivo estiver carregado
                    >
                        Enviar Arquivo
                    </Button>
                </Box>

                <div
                    {...getRootProps()}
                    style={{
                        border: '2px dashed #ccc',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        borderRadius: '8px',
                        margin: '20px 0 0 0',
                        width: '700px'
                    }}
                >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <Typography>Solte o arquivo .xls ou .xlsx aqui ...</Typography>
                    ) : (
                        <Typography>Arraste e solte um arquivo .xls ou .xlsx aqui.</Typography>
                    )}
                </div>
                <TextField type="file" accept=".xls,.xlsx" onChange={handleFileChange} style={{ margin: '20px 0', width: '700px' }} />

                {loading ? (
                    <ModalLoadAnimated open={loading} />
                ) : (
                    rows.length > 0 && (
                        <TableContainer component={Paper} sx={{ maxHeight: 500, width: '100%' }}>
                            <Table stickyHeader aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        {rows[0].map((col, index) => (
                                            <StyledTableHeaderCell key={index}>{col}</StyledTableHeaderCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.slice(1).map((row, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            {row.map((cell, cellIndex) => (
                                                <StyledTableCell key={cellIndex} onClick={() => console.log(cell, 'clicou no cell')}>
                                                    {cell}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}
            </Grid>
        </MainCard>
    );
};

export default ExcelSpreadsheet;
