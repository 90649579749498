import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formatarValorIntl } from 'utils/formatValueIntl';
import { TextField } from '@mui/material';

const AnalysisModal = ({ open, setOpen, data, currentIcms }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        backgroundColor: '#fff',
        boxShadow: 24,
        padding: 4,
        maxHeight: '500px',
        borderRadius: '8px'
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div style={style}>
                <h2 style={{ textAlign: 'center' }}>Parâmetros do {data.budget_Name}</h2>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Produto</TableCell>
                                <TableCell align="center">Qtd</TableCell>
                                <TableCell align="center">Un</TableCell>
                                <TableCell align="center">Vlr. Venda</TableCell>
                                <TableCell align="center">Vlr. M. Prima</TableCell>

                                <TableCell align="center">IPI</TableCell>
                                <TableCell align="center">ICMS</TableCell>
                                <TableCell align="center">PIS</TableCell>
                                <TableCell>Cofins</TableCell>
                                <TableCell align="center">Custo Fixo</TableCell>
                                <TableCell align="center">R.O</TableCell>
                                <TableCell align="center">Markup</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.materials
                                .filter((item) => item.quantity > 0)
                                .map((row, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="center">{row.name}</TableCell>
                                        <TableCell align="center">{row.quantity}</TableCell>
                                        <TableCell align="center">{row.unity}</TableCell>
                                        <TableCell align="center">{formatarValorIntl(row.totalPriceWithPercentages)}</TableCell>
                                        <TableCell align="center">{formatarValorIntl(row.price)}</TableCell>

                                        <TableCell align="center">{row.ipi} %</TableCell>
                                        <TableCell align="center">{currentIcms[0]?.percentage} %</TableCell>
                                        <TableCell align="center">{row.pis} %</TableCell>
                                        <TableCell align="center">{row.cofins} %</TableCell>
                                        <TableCell align="center">{row.roFixedCost} %</TableCell>
                                        <TableCell align="center">{row.roOperationalResult} %</TableCell>
                                        <TableCell align="center">{row.markup} %</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'end', gap: '10px', margin: '15px 0' }}>
                    <TextField label="Área [m²]" value={data.quantity} aria-readonly disabled InputLabelProps={{ shrink: true }} />
                    <TextField
                        label="Valor Unitário"
                        value={`R$ ${formatarValorIntl(data.unitPrice)}`}
                        aria-readonly
                        disabled
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        label="Valor Total"
                        value={`R$ ${formatarValorIntl(data.totalPrice)}`}
                        aria-readonly
                        disabled
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default AnalysisModal;
