import { Fab, Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { Box } from '@mui/system';
import GridOnIcon from '@mui/icons-material/GridOn';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { numberFormatCompact } from 'utils/regex';
import { DataGrid } from '@mui/x-data-grid';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useState } from 'react';
import AddNote from './components/AddNote';
import Analyse from './components/Analyse';
import { useParams } from 'react-router';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import ReplyIcon from '@mui/icons-material/Reply';
import { useNavigate } from 'react-router';
import { formatarValorIntl } from 'utils/formatValueIntl';

const ProjectMpId = () => {
    const api = useApi();
    const navigate = useNavigate();
    const { id } = useParams();

    const [openNote, setOpenNote] = useState(false);
    const [openAnalyse, setOpenAnalyse] = useState(false);
    const [rows, setRows] = useState([]);
    const [currentRow, setCurrentRow] = useState({});
    const getOne = async () => {
        try {
            const { data } = await api.getOnePcpProduct(id);
            console.log('🚀 ~ getOne ~ data:', data);

            const row = data.map((item) => ({
                ...item,
                id: item.id,
                pedido: item.pedInCodigo,
                alt: item.comStAlternativo,
                desc: item.itpStDescricao,
                un: item.uniStUnidade,
                qtd: item.qtdProgramacao,
                vn: formatarValorIntl(item.itpReValorunitario)
            }));
            setRows(row);
        } catch (error) {
            console.log('🚀 ~ getOne ~ error:', error);
        }
    };
    useEffect(() => {
        getOne();
    }, [id]);

    const columns = [
        { field: 'pedido', headerName: 'Pedido', width: 120 },
        { field: 'desc', headerName: 'Item do Pedido', flex: 400 },
        { field: 'un', headerName: 'UN', width: 120 },
        { field: 'qtd', headerName: 'QTD', width: 120 },
        { field: 'vn', headerName: 'Valor Unitário', width: 120 },
        {
            field: 'actions',
            headerName: 'Ações',
            width: 120,
            renderCell: (params) => {
                return (
                    <Box>
                        <Tooltip title="Análisar">
                            <IconButton
                                onClick={() => {
                                    setOpenAnalyse(true);
                                    setCurrentRow(params.row);
                                }}
                            >
                                <RemoveRedEyeIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Observação">
                            <IconButton
                                onClick={() => {
                                    setOpenNote(true);
                                    setCurrentRow(params.row);
                                }}
                            >
                                <WarningAmberIcon color={params?.row?.observacao?.trim() ? 'primary' : ''} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            }
        }
    ];
    return (
        <MainCard title={'Pedidos Vinculados'}>
            <AddNote open={openNote} setOpen={setOpenNote} currentRow={currentRow} get={getOne} />
            <Analyse open={openAnalyse} setOpen={setOpenAnalyse} currentRow={currentRow} />
            <Grid container spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Fab variant="extended" sx={{ position: 'absolute', left: '20px', bottom: '50px' }} onClick={() => navigate(-1)}>
                    <ReplyIcon sx={{ mr: 1 }} />
                    Voltar
                </Fab>
                <Box sx={{ height: 400, width: '100%', margin: '20px auto' }}>
                    <DataGrid rows={rows} columns={columns} hideFooter />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default ProjectMpId;
