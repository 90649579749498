import MainCard from 'ui-component/cards/MainCard';
import { Box, Grid, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Link } from 'react-router-dom';

function NotAllowed() {
    return (
        <MainCard title="Acesso negado">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em' }}>
                    <Typography variant="h5">Faça o login para ter acesso!</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em' }}>
                    <Link to="/">Fazer Login</Link>
                </Box>
            </Grid>
        </MainCard>
    );
}

export default NotAllowed;
